
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Spinner, Stack, Table } from 'react-bootstrap'
import { FormDataType, schema } from './BulkEmail.config';
import { Controller, useForm } from 'react-hook-form';

import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux';
import { adminBulkEmailSendThunk } from '../../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../../lib/redux/constants/status.constant';
import Card from '../../../../components/_ui/card/Card.component'
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import AdminLayout from "../../components/adminLayout/AdminLayout.component"
import BulkEmailNav from '../../components/bulkEmailNav/Nav.component'
import Typography from "../../../../components/_ui/typography/Typography.component"
import TextEditor from '../../../../components/_ui/textEditor/TextEditor.component';



export default function BulkEmail() {

  const dispatch = useReduxDispatch()
  const { adminBulkEmailSend } = useReduxSelector(state => state.admin)
  const { handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
    },
  })


  const onSubmit = (data: FormDataType) => {
    dispatch(adminBulkEmailSendThunk(data))
    reset()
  };

  return (
    <Layout title='Payments' user={true}>
      <AdminLayout nav={<BulkEmailNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Bulk Mail</Typography>
          <Typography $variant='body1'>Bulk email allows you to efficiently reach a large audience with a single message, saving time and resources</Typography>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>

            {/* Select Users */}
            <Stack direction='vertical' gap={3}>
              <div>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                  <Typography $variant='h6'>Select Type</Typography>
                </div>
                <Form.Group controlId='select-user'>
                  <Controller
                    name="targetType"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Select {...field}>
                        <option value=''>Please select type</option>
                        <option value='all_admins'>All Admins</option>
                        <option value='all_users'>All Users</option>
                        <option value='all_tenants'>All Tenants</option>
                        <option value='all_landlords'>All Landlords</option>
                        <option value='new_users_7_Days'>New Users 7 Days</option>
                      </Form.Select>
                    )}
                  />
                  {errors.targetType && <Form.Text className='text-danger'>{errors.targetType.message}</Form.Text>}
                </Form.Group>
              </div>


              <div>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                  <Typography $variant='h6'>Subject</Typography>
                </div>
                <Controller
                  name="subject"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control {...field} placeholder="Enter Subject" />
                  )}
                />
                {errors.subject && <Form.Text className='text-danger'>{errors.subject.message}</Form.Text>}
              </div>

              <div>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                  <Typography $variant='h6'>Email Template</Typography>
                </div>

                <Controller name='body' control={control}
                  render={({ fieldState: { error }, field }) =>
                    <>
                      <TextEditor {...field} placeholder='write your post content here' />
                      {error?.message && <Form.Text className='text-danger'>{error?.message}</Form.Text>}
                    </>
                  }
                />
              </div>


              {/* Submit type button */}
              <div className='d-flex justify-content-end mt-3'>
                <Button className='px-5' type='submit' $loading={adminBulkEmailSend.status === THUNK_STATUS.LOADING || isSubmitting}>Save</Button>
              </div>

            </Stack>
          </Card>
        </form>

      </AdminLayout>
    </Layout >
  )
}
