import * as yup from 'yup'
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { Col, FloatingLabel, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'

import { areAllValuesZero } from "../../../../utils"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import { setFindHomeSignupTenantFormData } from "../../../../lib/redux/slices/auth.slice"
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import background_image from '../../../../assets/images/register/background_003.jpg';
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import useIsExtactUrlMatch from "../../hooks/useIsExtactUrlMatch.hook"
import useFindHomeSignupRedirection from "../../hooks/useFindHomeSignupRedirection.hook"



export default function ProfileOnBoardStep3() {
  useFindHomeSignupRedirection()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  let matchUrlPath = useIsExtactUrlMatch('/onboard/tenant/')

  const { user, findHomeSignupTenantFormData } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)


  const { handleSubmit, register, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      familyMembers: user?.profile?.familyMembers || findHomeSignupTenantFormData.formData.profile.familyMembers || { children: 0, cats: 0, dogs: 0, adults: 0 }
    }
  })


  const onSubmit = async (data: FormData) => {
    if (areAllValuesZero(data.familyMembers)) {
      return toast.error('Family members should be greater than 0')
    }
    if (!matchUrlPath && user?._id) {
      dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          navigate('/')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))
    } else {
      dispatch(setFindHomeSignupTenantFormData({ activeStep: 4, formData: { profile: { ...data } } }))
      navigate('/auth/onboard/tenant/salary')
    }
    // dispatch(setprofileOnboardFormData(data))
    // navigate('/tenant/profile/step-4')
  }


  return (
    <Layout title='Tenant Step 3' user={!matchUrlPath} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress={matchUrlPath ? "45" : "30"}
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Family Members' description='How many people will be living in the property?' />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className='row-gap-2'>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <FloatingLabel controlId='familyMembers.adults' label='Adults'>
                      <Form.Control type='number' autoFocus placeholder='ignore' isInvalid={Boolean(errors.familyMembers?.adults)} {...register('familyMembers.adults')} />
                    </FloatingLabel>
                    <Form.Text className='text-danger'>{errors.familyMembers?.adults?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <FloatingLabel controlId='familyMembers.children' label='Children'>
                      <Form.Control type='number' placeholder='ignore' isInvalid={Boolean(errors.familyMembers?.children)} {...register('familyMembers.children')} />
                    </FloatingLabel>
                    <Form.Text className='text-danger'>{errors.familyMembers?.children?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <FloatingLabel controlId='dogs' label='Dogs'>
                      <Form.Control type='number' placeholder='ignore' isInvalid={Boolean(errors.familyMembers?.dogs)} {...register('familyMembers.dogs')} />
                    </FloatingLabel>
                    <Form.Text className='text-danger'>{errors.familyMembers?.dogs?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <FloatingLabel controlId='familyMembers.cats' label='Cats'>
                      <Form.Control type='number' placeholder='ignore' isInvalid={Boolean(errors.familyMembers?.cats)} {...register('familyMembers.cats')} />
                    </FloatingLabel>
                    <Form.Text className='text-danger'>{errors.familyMembers?.cats?.message}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit' $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  familyMembers: yup.object({
    adults: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).required(),
    children: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).required(),
    dogs: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).required(),
    cats: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).required(),
  })

})

type FormData = yup.InferType<typeof schema>