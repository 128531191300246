
import { MdCheck, MdRemoveRedEye } from 'react-icons/md'
import { Modal } from 'react-bootstrap'
import { VerifyDocumentProps } from './VerifyDocument.interface'
import Button from '../../../../components/_ui/button/Button.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import placeholderImage from '../../../../assets/images/placeholder.jpg'
import { useState } from 'react'
import { convertToTitleCase } from '../../../../utils'



function ImageModal(props: any) {
  const { image } = props
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header closeButton>
        <Typography $variant='subtitle1' className='text-dark'>Image</Typography>
      </Modal.Header>
      <Modal.Body>
        <img src={image || placeholderImage} className='rounded' />
      </Modal.Body>
    </Modal>
  );
}


export default function VerifyDocument(props: VerifyDocumentProps) {
  const { data } = props
  const img = data.image || ''


  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState('')


  const handleImageShow = (img: string) => {
    setImage(img)
    setModalShow(true)
  }


  return (
    <>
      <div className='border bg-white p-2 rounded d-flex align-items-center justify-content-between my-1'>
        <Typography $variant='subtitle2' className='p-0'>
          {data?.type && <MdCheck className='text-success fs-4 me-2' />}
          {convertToTitleCase(data?.type)}
        </Typography>
        <div className='d-flex align-items-center gap-1'>
          {img &&
            <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleImageShow(img)} >
              <MdRemoveRedEye className='fs-5' />
            </Button>
          }
        </div>
      </div>
      <ImageModal show={modalShow} onHide={() => setModalShow(false)} image={image} />
    </>
  )
}

