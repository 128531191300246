import { useEffect } from 'react'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { Alert, Badge, Col, Container, Row, Spinner, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { plansThunk } from '../../../lib/redux/actions/plan.action'
import { PlanDTO } from '../../../dtos/Plan.dto'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Card from '../../../components/_ui/card/Card.component'
import Button from '../../../components/_ui/button/Button.component'



export default function Boost() {
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)
  const { status, data } = useReduxSelector(state => state.plan.plans)


  const sortDataByDays = (data: PlanDTO[]): PlanDTO[] => {
    const sortedData = [...data]
    return sortedData.sort((a, b) => a.days - b.days)
  }


  useEffect(() => {
    dispatch(plansThunk({ type: 'boost' }))
  }, [])


  return (
    <Layout title='Boost Your Profile' user={true} feedbackShow={false}>
      <Container>

        <div className='d-flex flex-column gap-0 mb-3' style={{marginLeft: 10}}>
          <Typography $variant="h3">Boost your profile</Typography>
          <Typography $variant='body1'>Purchase a boost for your profile to increase your chances of being picked for a rental property</Typography>
        </div>

        {(status === THUNK_STATUS.SUCCESS && data) &&
          <Row className='row-gap-4'>
            {sortDataByDays(data).map((item, index) =>
              <Col key={index} md={4}>
                <Card className={`text-center position-relative ${item.label ? 'border border-2 border-primary' : 'shadow-none border border-1'}`}>
                  <Badge as={Typography} $variant='body3' className='position-absolute top-15 left-15 text-white text-capitalize py-2 fw-light'>{item.label}</Badge>
                  <Stack className='py-5 my-4' gap={2}>
                    <Typography $variant='subtitle1'>{item.days} Day</Typography>
                    <Typography $variant='h1' as='div' className='fs-1 m-0'>${item.price}</Typography>
                  </Stack>
                  <Stack gap={3}>
                    <Typography $variant='body1'>{item.description}</Typography>
                    <Button className='w-100' as={Link} to={`/store/checkout?plan=${item._id}&type=${item.type}`} $variant={item.label ? 'filled' : 'outlined'}>Buy Now</Button>
                  </Stack>
                </Card>
              </Col>
            )}
          </Row>
        }


        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
        {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}
      </Container>
    </Layout>
  )
}