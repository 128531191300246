import axios from "axios"
import { IFindHomeSignUpFormData, IRegisterFormData } from "../slices/auth.slice"



{/* APIs */ }
const signUp = async (data: IRegisterFormData | IFindHomeSignUpFormData) => {
  return await axios.post('/user/register', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const phoneNoVerification = async (data: IPhoneNoVerification) => {
  return await axios.post('/user/phoneNoVerification', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const verifyOtpWithSocialLogin = async (data: IVerifyOtpWithSocialLogin) => {
  return await axios.post('/user/socialLoginOtpVerify', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const verifyOtp = async (data: IVerifyOtp) => {
  return await axios.post('/user/verify', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const login = async (data: ILogin) => {
  return await axios.post('/user/login', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const currentUser = async () => {
  return await axios.get('/user/profile')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const resendOtp = async (data: IResendOtp) => {
  return await axios.post('/user/resentOtp', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const forgotPassword = async (data: IForgotPassword) => {
  return await axios.post('/user/forgotPassword', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const updatePassword = async (data: IUpdatePassword) => {
  return await axios.post('/user/updatePassword', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const resetPassword = async (data: IResetPassword) => {
  return await axios.post('/user/verifyForgotPassword', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const updateProfile = async (data: IUpdateProfile) => {
  return await axios.put('/user/updateProfile', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

// Update Role login 
const updateRole = async (data: IUpdateRole) => {
  return await axios.put('/user/updateRole', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


// social apple login 
const socialLogin = async (data: ISocialLogin) => {
  return await axios.post('/user/socialLogin', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


// social google login 
const googleSocialLogin = async (data: ISocialLogin) => {
  return await axios.post('/user/googleSocialLogin', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}



{/* Interface */ }
interface IVerifyOtp {
  user_id: string
  otp: string
  phone: string
}

interface ILogin {
  email: string
  password: string
  //remember_me: boolean
}

interface IResendOtp {
  user_id: string
  phone: string
}

interface IForgotPassword {
  email: string
}

interface IUpdatePassword {
  password: string
  newPassword: string
}

interface IResetPassword {
  newPassword: string
  token: string
}

interface IUpdateProfile {
  name: string
  phone: string
}

interface IPhoneNoVerification {
  phone: string
  user_id: string
}
interface IVerifyOtpWithSocialLogin {
  phone: string
  user_id: string
  otp: string
}

interface IUpdateRole {
  _id: string | null
  name: string | undefined
  userRoles: string | undefined
  userSystemInfo?: {
    browser_details: string | undefined | null,
    operating_system: string | undefined | null,
    submission_date_time: Date,
    time_zone: string | undefined | null
  }
}

interface ISocialLogin {
  id_token: string
}


export const authService = {
  signUp,
  phoneNoVerification,
  verifyOtpWithSocialLogin,
  verifyOtp,
  login,
  currentUser,
  resendOtp,
  forgotPassword,
  updatePassword,
  resetPassword,
  updateProfile,
  updateRole,
  socialLogin,
  googleSocialLogin
}