import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { Col, Container, Row, Form, InputGroup } from 'react-bootstrap'
import { StyledForm } from '../login/component/loginForm/LoginForm.style'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { resetPasswordThunk } from '../../../lib/redux/actions/auth.action'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'
import * as yup from 'yup'
import OnBoardAuthBanner from '../../../components/authLayout/onBoardAuthBanner/onBoardAuthBanner.component'
import RegisterLayout from '../register/component/registerLayout/RegisterLayout.component'



export default function ResetPassword(props: any) {
  const { className } = props
  const navigate = useNavigate()
  const distpatch = useReduxDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [confirmShowPassword, setConfirmShowPassword] = useState(false)
  const token = window.location.search.replace('?token=', '')
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })
  const { status } = useReduxSelector(state => state.auth.resetPassword)


  const onSubmit = (data: FormData) => {
    distpatch(resetPasswordThunk({ newPassword: data.password, token: token as string })).unwrap()
      .then((response) => { toast.success(response.data.message); navigate('/auth/login') })
      .catch((err) => console.log('err'))
  }


  return (
    <Layout title='Reset Password' user={false} header={false} footer={false}>

      <RegisterLayout
        
        columnFirst={
          <StyledForm as={Form} className={className} noValidate onSubmit={handleSubmit(onSubmit)}>
      
              <div className='mb-4'>
                <Typography $variant='h1' className='m-0'>Reset Password</Typography>
              </div>

              <Form.Group className='mb-3' controlId='password'>

                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control type={showPassword ? 'text' : 'password'} className='fs-6' size='lg' isInvalid={Boolean(errors.password)} {...register('password')} />
                  <InputGroup.Text className='cursor-pointer' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <MdVisibility className='fs-5' /> : <MdVisibilityOff className='fs-5' />}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Text className='text-danger'>{errors.password?.message}</Form.Text>
              </Form.Group>


              <Form.Group className='mb-3' controlId='confirmPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control type={confirmShowPassword ? 'text' : 'password'} className='fs-6' size='lg' isInvalid={Boolean(errors.confirmPassword)} {...register('confirmPassword')} />
                  <InputGroup.Text className='cursor-pointer' onClick={() => setConfirmShowPassword(!confirmShowPassword)}>
                    {showPassword ? <MdVisibility className='fs-5' /> : <MdVisibilityOff className='fs-5' />}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Text className='text-danger'>{errors.confirmPassword?.message}</Form.Text>
              </Form.Group>

              <Row className='align-items-center'>
                <Col xs='auto'>
                  <Button type='submit' $loading={status === THUNK_STATUS.LOADING}>Submit</Button>
                </Col>
              </Row>

          </StyledForm>
        }
        columnSecond={<OnBoardAuthBanner />}
      />

    </Layout >
  )
}


{/* == Validation == */ }
const schema = yup.object({
  password: yup.string().trim().required(),
  confirmPassword: yup.string().trim().required()
})

type FormData = yup.InferType<typeof schema>