
import { Col, Row } from 'react-bootstrap';
import Typography from '../../../../components/_ui/typography/Typography.component';
import Pill from '../../../../components/_ui/pill/Pill';
import { MdCheck } from 'react-icons/md';

export default function OtherInfo(props: { title: string, payload: { type: string, data: string[] } }) {
  const { title, payload } = props
  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <div className="pb-4 border-bottom">
            <Typography $variant="h6" className={"mb-4"}>
              {title}
            </Typography>

            <div className="d-flex flex-wrap">
              {payload &&
                payload.data?.map((item, index) => {
                  return (
                    item && (
                      <Pill key={index} text={item} icon={<MdCheck color="14b514" />} />
                    )
                  );
                })}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
