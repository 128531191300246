import * as yup from 'yup'
import { Link } from "react-router-dom"
// import { GoogleLogin } from "@react-oauth/google"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Row, Col, Stack, Spinner } from "react-bootstrap"
import { StyledForm } from "./LoginForm.style"
import { LoginFormProps } from "./LoginForm.interface"
import { emailTest, passwordTest } from "../../../../../utils"
import { loginThunk } from '../../../../../lib/redux/actions/auth.action'
import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux'
import { THUNK_STATUS } from '../../../../../lib/redux/constants/status.constant'
import Typography from "../../../../../components/_ui/typography/Typography.component"
import Button from "../../../../../components/_ui/button/Button.component"
import AppleAuthLogin from '../../../../../components/_ui/appleLogin/AppleAuthLogin.component'
import Googlelogin from "../../../../../components/_ui/google-login/GoogleLogin.component"

import { analytics, logEvent } from "../../../../../firebase";


export default function LoginForm(props: LoginFormProps) {
  const { className } = props
  const dispatch = useReduxDispatch()
  const { status } = useReduxSelector(state => state.auth.login)
  const { socialLogin, googleSocialLogin } = useReduxSelector(state => state.auth)
  // const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })


  const onSubmit = (formData: FormData) => {
    //dispatch(handleRemember(formData.remember_me))
    dispatch(loginThunk(formData))
  }


  return (
    <StyledForm as={Form} className={className} noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className='mb-4' onClick={() => {
        console.log("test");
        logEvent(analytics, 'conversion', {'send_to': 'AW-11419506857/H_24CNvw8qgZEKmxn8Uq'});
      }}>
        <Typography $variant='h3' className='m-0'>Sign In</Typography>
      </div>

      <Form.Group className='mb-3' controlId='email'>
        <Form.Label>Email/Username</Form.Label>
        <Form.Control type='email' className='fs-6' size='lg' isInvalid={Boolean(errors.email)} {...register('email')} />
        <Form.Text className='text-danger'>{errors.email?.message}</Form.Text>
      </Form.Group>

      <Form.Group className='mb-2' controlId='password'>
        <Form.Label>Password</Form.Label>
        { /*<InputGroup>
            <Form.Control type={showPassword ? 'text' : 'password'} className='fs-6' size='lg' isInvalid={Boolean(errors.password)} {...register('password')} />
            <InputGroup.Text className='cursor-pointer' onClick={() => setShowPassword(!showPassword)}>
              {showPassword ?
                <MdVisibility className='fs-5' />
                :
                <MdVisibilityOff className='fs-5' />
              }
            </InputGroup.Text>
            </InputGroup>*/ }
        <Form.Control type='password' className='fs-6' size='lg' isInvalid={Boolean(errors.password)} {...register('password')} />
        <Form.Text className='text-danger'>{errors.password?.message}</Form.Text>
      </Form.Group>

      { /*<Form.Group className='mb-3' controlId='remember'>
          <Form.Check type='checkbox' defaultChecked label='Remember me' {...register('remember_me')} />
          </Form.Group>*/ }

      <Row className='mb-5'>
        <Col className='d-flex justify-content-end align-items-center'>
          <Typography $variant='body1' as={Link} to='/auth/forgot-password' $hover>Forgot your password?</Typography>
        </Col>
      </Row>

      <Row>
        <Col>
          <Stack gap={3}>
            <Button type="submit" className="w-100" $loading={status === THUNK_STATUS.LOADING}>Sign In</Button>
            <div className="text-center">OR</div>
            {socialLogin.status === THUNK_STATUS.LOADING ? <div className='center h-100'><Spinner /></div> : <AppleAuthLogin />}

            {googleSocialLogin.status === THUNK_STATUS.LOADING ? <div className='center h-100'><Spinner /></div> : <Googlelogin />}

          </Stack>
        </Col>
      </Row>

    </StyledForm>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  email: yup.string().trim().required().email().min(2).max(100).test(emailTest),
  password: yup.string().trim().required().min(6).max(100).test(passwordTest),
  //remember_me: yup.bool().required().oneOf([true, false])
})

type FormData = yup.InferType<typeof schema>