import { styled, css } from "styled-components"
import { CardProps } from "./Card.interface"



const Card = styled.div<CardProps>`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  border-radius: var(--bs-border-radius);
  padding: 1rem;
  transition: box-shadow 0.3s;
  background: #fff;

  ${props => props.$hover && css`
    cursor: pointer;
    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[15]};
    }
  `}

  ${props => props.$selected && css`
    outline: 0;
    --shadow-opacity: 0.3;
    box-shadow: 0 0 0 3px rgba(${({ theme }) => theme.primary.mainRgb}, var(--shadow-opacity)) !important;
    &:hover {
      --shadow-opacity: 0.7;
    }
  `}
`


export default Card