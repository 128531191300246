import styled from "styled-components";


export const BgUpSellContainer = styled.div`
background: linear-gradient(261deg, #aaffbc -11.65%, #e5fae9 152.54%);
padding: 18px;
position: sticky;
bottom: 0;
box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
border: 1px solid #3ab455;
`