import styled from "styled-components"


export const StyledBox = styled.div`
  .bg-box{
    position: relative;
    z-index: 1;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top:0;
      bottom: 0;
      width: 50vw;
      background-color: inherit;
      z-index: -1;
    }
  }
`