import { ShortListedPropertyProps } from './ShortListedProperty.interface'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../../components/_ui/card/Card.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import placeholderImage from '../../../../assets/images/placeholder.jpg'



export default function ShortListedProperty(props: ShortListedPropertyProps) {
  const { data, propertyId, className = '', addressClassName = '', size = 'medium' } = props
  const address = data.property.address.name || data.property.address.city + ' ' + data.property.address.state
  const img = data.property.image[0] || ''
  const link = `/profile/${address?._id}${propertyId ? `?propertyId=${propertyId}` : ''}`

  return (
    <Card className={`p-0 overflow-hidden d-block position-relative`} as={Link} to={`/properties/profile/${data.property._id}`} $hover >
      <img src={img || placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75', opacity: .9 }} />
      <div className={`${size === 'small' ? 'px-3 py-2' : 'p-3'} d-flex justify-content-between gap-3`}>
        <Typography $variant='subtitle2' className={`text-dark fw-semibold py-1 align-self-center ${addressClassName}`}>
          {address}
        </Typography>
      </div>
    </Card>
  )
}