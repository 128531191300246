import Cookies from "js-cookie";

const getCurrentDateTime = new Date()


const getTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}

const getBrowserName = (): string => {
  let userAgent: string | null = navigator.userAgent;
  let browser: string = "Other browser";
  let browserVersion: string | undefined;

  if (userAgent !== null) {
    if (userAgent.match(/edg/i)) {
      browser = "edge";
      browserVersion = userAgent?.match(/edg\/([\d.]+)/i)?.[1];
    } else if (userAgent.match(/firefox|fxios/i)) {
      browser = "firefox";
      browserVersion = userAgent?.match(/firefox\/([\d.]+)/i)?.[1];
    } else if (userAgent.match(/opr\//i)) {
      browser = "opera";
      browserVersion = userAgent.match(/opr\/([\d.]+)/i)?.[1];
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      browser = "chrome";
      browserVersion = userAgent.match(/(chrome|chromium|crios)\/([\d.]+)/i)?.[2];
    } else if (userAgent.match(/safari/i)) {
      browser = "safari";
      browserVersion = userAgent.match(/safari\/([\d.]+)/i)?.[1];
    }

    // 'browser' is already initialized as "Other browser" if no match is found.
    browserVersion = browserVersion ?? "NA";
  }

  return `${browser} (Version: ${browserVersion})`;
};


const detectDeviceAndOS = (): string => {
  const userAgent: string | null = window.navigator.userAgent;
  let deviceType: string = "Desktop";
  let operatingSystem: string = "NA";
  let osVersion: string = "NA";

  if (userAgent !== null) {
    if (/Mobi/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
      deviceType = /Tablet|iPad/i.test(userAgent) ? "Tablet" : "Mobile";

      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        operatingSystem = "iOS";
        osVersion = /OS ([0-9_]+)/.exec(userAgent)![1].replace(/_/g, ".");
      } else if (/Android/i.test(userAgent)) {
        operatingSystem = "Android";
        osVersion = /Android ([0-9.]+)/.exec(userAgent)![1];
      }
    } else if (/Win/i.test(userAgent)) {
      operatingSystem = "Windows";
      osVersion = /Windows NT ([0-9.]+)/.exec(userAgent)![1];
    } else if (/Mac/i.test(userAgent)) {
      operatingSystem = "macOS";
      osVersion = /Mac OS X ([0-9_]+)/.exec(userAgent)![1].replace(/_/g, ".");
    } else if (/Linux/i.test(userAgent)) {
      operatingSystem = "Linux";
    } else {
      deviceType = "NA";
      operatingSystem = "NA";
      osVersion = "NA";
    }
  }

  return `${deviceType}, ${operatingSystem}, ${osVersion}`;
};


const userSystemInfo = () => {
  return {
    submission_date_time: getCurrentDateTime,
    time_zone: getTimeZone(),
    browser_details: getBrowserName(),
    operating_system: detectDeviceAndOS(),
    // search_from: Cookies.get('url-referrer')
  }
}


export { userSystemInfo, detectDeviceAndOS, getBrowserName, getTimeZone, getCurrentDateTime }