import axios from "axios"


{/* APIs */ }
const packageDealByType = async (params: { ruleType: string }) => {
  return await axios.post('/user/packageDealByType', params)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


export const packageService = {
  packageDealByType,
}