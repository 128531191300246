import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Stack,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux";
import { setRegisterFormData } from "../../../../lib/redux/slices/auth.slice";
import { signUpThunk } from "../../../../lib/redux/actions/auth.action";
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant";
import { useForm } from "react-hook-form";
import { fullnameTest, emailTest, passwordTest } from "../../../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import Layout from "../../../../components/_layout/Layout.component";
import Typography from "../../../../components/_ui/typography/Typography.component";
import Button from "../../../../components/_ui/button/Button.component";
import RegisterLayout from "../component/registerLayout/RegisterLayout.component";
import background_image from "../../../../assets/images/register/background_008.jpg";
import AppleAuthLogin from "../../../../components/_ui/appleLogin/AppleAuthLogin.component";
import PhoneInput from "../../../../components/_ui/phoneInput/PhoneInput.component";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { userSystemInfo } from "../../../../utils/userSystemInfo";
import Googlelogin from "../../../../components/_ui/google-login/GoogleLogin.component";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup
      .string()
      .email()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      )
      .required(),
    password: yup.string().required().trim().min(6, "Enter strong password"),
    phone: yup
      .string()
      .required()
      .test("isValidPhone", "Phone number is not valid", (value) =>
        isValidPhoneNumber(value)
      ),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const { formData } = useReduxSelector((state) => state.auth.register);
  const { socialLogin, googleSocialLogin } = useReduxSelector((state) => state.auth);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState(formData.phone as string);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: FormData) => {
    dispatch(
      signUpThunk({
        ...data,
        userRoles: "landlord",
        userSystemInfo: userSystemInfo(),
      })
    );
  };

  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    dispatch(
      setRegisterFormData({
        [event.currentTarget.name]: event.currentTarget.value,
      })
    );
  };

  return (
    <Layout
      title="Create an Account"
      user={false}
      header={false}
      footer={false}
    >
      <RegisterLayout
        headerActionTitle="Already have an account?"
        headerActionButtonText="Sign In"
        headerActionButtonLink="/auth/login"
        progress="100"
        bannerText="Finding a tenant has never been easier"
        columnFirst={
          <>
            <div className="d-flex flex-column gap-1">
              <Typography $variant="body1">
                Find a tenant for your property
              </Typography>
              <Typography $variant="h1">Create Account</Typography>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Control
                  type="text"
                  // defaultValue={formData.name}
                  onKeyUp={handleChange}
                  className="fs-6"
                  size="lg"
                  placeholder="Full Name"
                  isInvalid={Boolean(errors.name)}
                  autoFocus={false}
                  {...register("name", {
                    required: true,
                    pattern: /^\\S+@\\S+$/,
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.name?.message}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="text"
                  defaultValue={formData.email}
                  onKeyUp={handleChange}
                  className="fs-6"
                  size="lg"
                  placeholder="Email"
                  isInvalid={Boolean(errors.email)}
                  autoFocus={false}
                  {...register("email")}
                />
                <Form.Text className="text-danger">
                  {errors.email?.message}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    defaultValue={formData.password}
                    autoComplete="new-password"
                    placeholder="Password"
                    onKeyUp={handleChange}
                    className="fs-6"
                    size="lg"
                    isInvalid={Boolean(errors.password)}
                    autoFocus={false}
                    {...register("password")}
                  />
                  <InputGroup.Text
                    className="cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <MdVisibility className="fs-5" />
                    ) : (
                      <MdVisibilityOff className="fs-5" />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Text className="text-danger">
                  {errors.password?.message}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="mobile">
                <PhoneInput
                  value={phone}
                  onChange={(value: string) => {
                    setValue("phone", value, {
                      shouldValidate: true,
                    });
                    dispatch(setRegisterFormData({ phone: value }));
                  }}
                  placeholder={"Mobile Phone"}
                  className={`form-control fs-6 form-control-lg ${isSubmitted && !isValidPhoneNumber(phone)
                    ? "is-invalid"
                    : ""
                    }`}
                />
                <Form.Text className="text-danger">
                  {errors.phone?.message}
                </Form.Text>
              </Form.Group>

              <Row>
                <Col>
                  <Stack gap={2}>
                    <Button type="submit" className="w-100">
                      Create Account
                    </Button>
                    <div className="text-center">OR</div>
                    <div className="d-flex flex-column flex-sm-row w-100 gap-3">
                      <div className="flex-1">
                        {socialLogin.status === THUNK_STATUS.LOADING ? (<div className="center h-100"><Spinner /></div>) : <AppleAuthLogin />}
                      </div>
                      <div className="flex-1">
                        {googleSocialLogin.status === THUNK_STATUS.LOADING ? <div className='center h-100'><Spinner /></div> : <Googlelogin />}
                      </div>
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Form>
          </>
        }
        columnSecond={
          <div
            className="h-100 w-100"
            style={{
              backgroundImage: `url(${background_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
        }
      />
    </Layout>
  );
}
