import { createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { Status } from '../../../interfaces/Status.type'
import { adminAgentsListThunk, adminAgentsVerifyThunk, agentGetTenantSearchBySuburbThunk, agentRegisterThunk, manageByAgentOrSelfThunk, searchAgentsByPropertyThunk } from '../actions/agent.action'



/* == Initial State == */
const initialState: IAgentSlice = {
  agentRegister: {
    status: '',
  },
  searchAgentsByProperty: {
    status: '',
    data: [],
    total: 0
  },
  manageByAgentOrSelf: {
    status: ''
  },
  adminAgentsList: {
    status: '',
    data: [],
    total: 0
  },
  adminAgentsVerify: {
    status: '',
  },
  searchTenantBySuburb: {
    status: '',
    data: [],
    total: 0
  },
}


/* == Slice == */
export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {},

  extraReducers: (builder) => {

    {/* Agent Register */ }
    builder.addCase(agentRegisterThunk.pending, (state, action) => {
      state.agentRegister.status = THUNK_STATUS.LOADING
    })

    builder.addCase(agentRegisterThunk.fulfilled, (state, action) => {
      state.agentRegister.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(agentRegisterThunk.rejected, (state, action) => {
      state.agentRegister.status = THUNK_STATUS.FAILED
    })


    {/* Search Agents By Property */ }
    builder.addCase(searchAgentsByPropertyThunk.pending, (state, action) => {
      state.searchAgentsByProperty.status = THUNK_STATUS.LOADING
    })

    builder.addCase(searchAgentsByPropertyThunk.fulfilled, (state, action) => {
      state.searchAgentsByProperty.status = THUNK_STATUS.SUCCESS
      state.searchAgentsByProperty.data = action.payload.data.result
      state.searchAgentsByProperty.total = action.payload.data.totalRecords
    })

    builder.addCase(searchAgentsByPropertyThunk.rejected, (state, action) => {
      state.searchAgentsByProperty.status = THUNK_STATUS.FAILED
    })


    {/*  Manage By Agent Or Self */ }
    builder.addCase(manageByAgentOrSelfThunk.pending, (state, action) => {
      state.manageByAgentOrSelf.status = THUNK_STATUS.LOADING
    })

    builder.addCase(manageByAgentOrSelfThunk.fulfilled, (state, action) => {
      state.manageByAgentOrSelf.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(manageByAgentOrSelfThunk.rejected, (state, action) => {
      state.manageByAgentOrSelf.status = THUNK_STATUS.FAILED
    })


    {/* /admin agents List  */ }
    builder.addCase(adminAgentsListThunk.pending, (state, action) => {
      state.adminAgentsList.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminAgentsListThunk.fulfilled, (state, action) => {
      state.adminAgentsList.status = THUNK_STATUS.SUCCESS
      state.adminAgentsList.data = action.payload.data.info.result
      state.adminAgentsList.total = action.payload.data.info.totalRecords
    })

    builder.addCase(adminAgentsListThunk.rejected, (state, action) => {
      state.adminAgentsList.status = THUNK_STATUS.FAILED
    })


    {/* /admin agents verify  */ }
    builder.addCase(adminAgentsVerifyThunk.pending, (state, action) => {
      state.adminAgentsVerify.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminAgentsVerifyThunk.fulfilled, (state, action) => {
      state.adminAgentsVerify.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(adminAgentsVerifyThunk.rejected, (state, action) => {
      state.adminAgentsVerify.status = THUNK_STATUS.FAILED
    })


    {/* Search tenant By suburb */ }
    builder.addCase(agentGetTenantSearchBySuburbThunk.pending, (state, action) => {
      state.searchTenantBySuburb.status = THUNK_STATUS.LOADING
    })

    builder.addCase(agentGetTenantSearchBySuburbThunk.fulfilled, (state, action) => {
      state.searchTenantBySuburb.status = THUNK_STATUS.SUCCESS
      state.searchTenantBySuburb.data = action.payload.data.result
      state.searchTenantBySuburb.total = action.payload.data.totalRecords
    })

    builder.addCase(agentGetTenantSearchBySuburbThunk.rejected, (state, action) => {
      state.searchTenantBySuburb.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface IAgentSlice {
  agentRegister: {
    status: Status
  }
  manageByAgentOrSelf: {
    status: Status
  }
  searchAgentsByProperty: {
    status: Status
    data: any
    total: 0
  }
  adminAgentsList: {
    status: Status
    data: any
    total: 0
  }
  adminAgentsVerify: {
    status: Status
  }
  searchTenantBySuburb: {
    status: Status
    data: any
    total: 0
  }
}


export const { } = agentSlice.actions
export default agentSlice.reducer
