import { css, styled } from "styled-components"
import { IVariant, TypographyProps } from "./Typography.interface"



const Typography = styled.div.attrs((props: any) => ({
  as: props.as || selectAs(props.$variant)
})) <TypographyProps>`

  ${(props) => props.$variant === 'h1' && css`
    font-size: 28px;
    font-weight: 700;
    color: var(--bs-dark);
    margin-bottom:1em;
    line-height: 1;
    word-wrap: break-word;
  `}

  ${(props) => props.$variant === 'h2' && css`
    font-size: 24px;
    font-weight: 700;
    color: var(--bs-dark);
    margin: 0;
  `}

  ${(props) => props.$variant === 'h3' && css`
    font-size: 22px;
    font-weight: 700;
    color: var(--bs-dark);
    margin: 0;
  `}

  ${(props) => props.$variant === 'h4' && css`
    font-size: 20px;
    font-weight: 700;
    color: var(--bs-dark);
    margin: 0;
  `}

  ${(props) => props.$variant === 'h5' && css`
    font-size: 18px;
    font-weight: 700;
    color: var(--bs-dark);
    margin: 0;
  `}

  ${(props) => props.$variant === 'subtitle1' && css`
    font-size: 17px;
    font-weight: 600;
    color: var(--bs-secondary);
    margin: 0;
  `}

  ${(props) => props.$variant === 'subtitle2' && css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    color: var(--bs-secondary);
    margin: 0;
  `}

  ${(props) => props.$variant === 'body1' && css`
    font-size: 14px;
    font-weight: normal;
    color: var(--bs-secondary);
    margin: 0;

    color: #6d7580;
    font-size: 1rem;
    font-weight: normal;
    line-height: 30px;
  `}

  ${(props) => props.$variant === 'body2' && css`
    font-size: 13px;
    font-weight: normal;
    color: rgba(0,0,0,.5);
    margin: 0;
  `}

  ${(props) => props.$variant === 'body3' && css`
    font-size: 12px;
    font-weight: normal;
    color: rgba(0,0,0,.5);
    margin: 0;
  `}

  ${(props) => props.$hover && css`
    &:hover {
      cursor: pointer;
      color: var(--primary-main) !important;
      text-decoration: underline;
    } 
  `}
`



function selectAs(props: IVariant) {
  if (props === 'h1') return 'h1'
  if (props === 'h2') return 'h2'
  if (props === 'h3') return 'h3'
  if (props === 'h4') return 'h4'
  if (props === 'h5') return 'h5'
  if (props === 'h6') return 'h6'
  if (props === 'body1') return 'div'
  if (props === 'body2') return 'div'
  if (props === 'subtitle1') return 'div'
  if (props === 'subtitle2') return 'div'
  return 'div'
}



Typography.defaultProps = {
  $variant: 'body1'
}

export default Typography