import axios from "axios"



{/* APIs */ }
const tenantVerfication = async (params: IPagination) => {
  return await axios.get('/admin/verification/tenants', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const propertyVerfication = async (params: IPagination) => {
  return await axios.get('/admin/verification/properties', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminAllProperties = async (params: IPagination) => {
  return await axios.get('/admin/allProperties', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminUpdatePropertyStatus = async (data: IUpdatePropertyStatus) => {
  return await axios.put('/admin/updatePropertyStatus', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const adminPlanUsers = async (params: IPagination) => {
  return await axios.get('/admin/plan/users', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminAccountsData = async (params: IPagination) => {
  return await axios.get('/admin/dashboard', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminUserAccounts = async (params: IPagination) => {
  return await axios.post('/admin/user/accounts', params)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminUserDetailById = async (params: { id: string }) => {
  return await axios.get('/admin/detailsById', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}



const adminUpdateTenantStatus = async (data: IUpdatePropertyStatus) => {
  return await axios.put('/admin/updateTenantStatus', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminAttachment = async (data: IAdminAttachment) => {
  return await axios.post('/admin/updateAdminAttachments', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const getTenantReports = async () => {
  const result = await axios.get('/admin/reports/tenant-list');
  return result.data;
}

const adminBulkEmailSend = async (data: { targetType: string, subject: string, body: string }) => {
  return await axios.post('/admin/sendBulkNotification', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const adminAllBulkEmail = async (params: IPagination) => {
  return await axios.get('/admin/allBulkNotification', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminGetUserSessions = async (params: { id: string }) => {
  return await axios.get('/admin/user/sessions', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminUserAccountsLogin = async (params: { email: string }) => {
  return await axios.post('/admin/userAccountLogin', params)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


export const adminService = {
  tenantVerfication,
  propertyVerfication,
  adminUpdatePropertyStatus,
  adminPlanUsers,
  adminAllProperties,
  adminAccountsData,
  adminUserAccounts,
  adminUserDetailById,
  adminUpdateTenantStatus,
  adminAttachment,
  getTenantReports,
  adminBulkEmailSend,
  adminAllBulkEmail,
  adminGetUserSessions,
  adminUserAccountsLogin
}


{/* Interface */ }
export interface IPagination {
  page: number
  size: number
  search?: string
}


interface IUpdatePropertyStatus {
  id: string | number
  status: string
  rejectReason: string | null
}

interface IAdminAttachment {
  id: number | string
  adminAttachments: string[]
}