import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { isValidPhoneNumber } from "react-phone-number-input"

import { phoneNoVerificationThunk } from '../../../../../lib/redux/actions/auth.action'
import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux'
import PhoneInput from '../../../../_ui/phoneInput/PhoneInput.component'
import Button from '../../../../_ui/button/Button.component'
import Typography from '../../../../_ui/typography/Typography.component'
import toast from 'react-hot-toast'
import { THUNK_STATUS } from '../../../../../lib/redux/constants/status.constant'


interface IPhoneNumberProps {
  phone: string
  setPhone: any
  handleOtpShow: () => void
}


export default function PhoneNumber(props: IPhoneNumberProps) {
  const { handleOtpShow, phone, setPhone } = props

  const dispatch = useReduxDispatch()
  const { user, phoneNoVerification } = useReduxSelector(state => state.auth)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitted(true)
    if (!isValidPhoneNumber(phone)) return
    console.log(phone)
    dispatch(phoneNoVerificationThunk({ phone, user_id: user?._id as string })).unwrap()
      .then((res) => {
        toast.success(res.message || '')
        handleOtpShow()
      }).catch((err) => {
        console.log(err)
      })
  }

  const handleChange = (value: any) => {
    setPhone(value || '')
  }


  return (
    <Form noValidate onSubmit={onSubmit}>
      <div className="d-flex flex-column justify-content-center align-items-center gap-3">
        <Typography $variant='body1' id="contained-modal-title-vcenter text-center mb-3">Increase your chance of finding a home by verifying your account </Typography>

        <Form.Group className="w-100" controlId="phone">
          <Form.Label>Your mobile number</Form.Label>
          <PhoneInput
            value={phone}
            onChange={handleChange}
            placeholder={'04__ ___ ___'}
            className={(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}
            numberInputProps={{
              className: `form-control fs-6 form-control-lg ${(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}`,
              autoFocus: false,

            }}
          />
          <Form.Text className="text-danger">{isSubmitted && !isValidPhoneNumber(phone) && 'Enter valid phone'}</Form.Text>
        </Form.Group>

        <Button type='submit' $loading={phoneNoVerification.status === THUNK_STATUS.LOADING} disabled={!isValidPhoneNumber(phone)} className='w-100'>Send Verification Code</Button>
      </div>
    </Form>
  )
}
