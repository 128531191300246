import styled from 'styled-components';

export const CountdownContainer = styled.div`

  padding: 0.5rem;
  display: flex;
  flex-flow: 'row';
  align-items: center;
  justify-content: space-between;

  .box{
    background-color: #eee;
    color: #000;
    margin: .2rem;
    padding: .5rem;
    text-align: center; 
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    border-radius: 10px;
  }
  .time{
    font-size: 14px;
    font-weight: bold;
  }
  .text{
    font-size: 10px;
  }
`;