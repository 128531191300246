import { Roles } from "../interfaces/Roles.type"



export function filterByRoles(items: any[], userRoles: Roles[]) {
  const filteredMenu = items.filter(item => {
    if (!item.permission || item.permission.some((role: Roles) => userRoles.includes(role))) {
      if (item.children) {
        item.children = item.children.filter((childItem: any) =>
          !childItem.permission || childItem.permission.some((role: Roles) => userRoles.includes(role))
        )
      }
      return true
    }
    return false
  })

  return filteredMenu
}
