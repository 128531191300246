import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Roles } from "../../../../interfaces/Roles.type"
import { setGoogleLoginFormData } from "../../../../lib/redux/slices/auth.slice"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import Layout from "../../../../components/_layout/Layout.component"
import background_image from '../../../../assets/images/register/background_001.jpg';
import RegisterLayout from "../../register/component/registerLayout/RegisterLayout.component"
import SocialLoginStep1 from "../../components/socialLogin/step-1/SocialLoginStep1"



export default function GoogleStep1() {

  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { formData } = useReduxSelector(state => state.auth.googleLogin)

  let sessionToken = sessionStorage.getItem('token')
  let sessionId = sessionStorage.getItem('id')

  useEffect(() => {
    if (!(sessionToken && sessionId)) navigate('/auth/login')
  }, [sessionToken, sessionId])

  const handleNext = () => {
    navigate('/auth/google/step-2')
  }

  const handleCard = (role: Roles) => {
    dispatch(setGoogleLoginFormData({ userRoles: role }))
  }


  return (
    <Layout title='Register Step 1' user={false} header={false} footer={false}>
      <RegisterLayout
        progress="50"
        columnFirst={
          <SocialLoginStep1 handleCard={handleCard} handleNext={handleNext} formData={formData} />
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}
