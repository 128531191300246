import { Col, Row } from 'react-bootstrap'
import { MdOutlineHome, MdOutlinePerson } from 'react-icons/md'
import { StyledCheckIcon } from './SocialLoginStep1.style'

import Typography from '../../../../../components/_ui/typography/Typography.component'
import Card from "../../../../../components/_ui/card/Card.component"
import Button from '../../../../../components/_ui/button/Button.component'
import SocialLoginStep1Props from './SocialLoginStep1.interface'



export default function SocialLoginStep1(props: SocialLoginStep1Props) {
  const { handleCard, handleNext, formData } = props
  return (
    <>
      {/* Heading */}
      <div className='d-flex flex-column gap-2 mb-5'>
        {/* <Steps active={1} /> */}
        <Typography $variant='h3'>Create an Account</Typography>
        <Typography $variant='body1'>Before we get started, are you a tenant looking for a rental property or are you a landlord looking for tenants?</Typography>
      </div>

      {/* Content */}
      <Row className='row-gap-4 mb-3'>
        <Col xs={12} sm={6}>
          <Card className='text-center  cursor-pointer position-relative' tabIndex={0} onClick={() => handleCard('tenant')} $hover $selected={formData?.userRoles?.includes('tenant')}>
            {formData?.userRoles?.includes('tenant') && <StyledCheckIcon />}
            <MdOutlinePerson className="mb-2 text-secondary fs-1" />
            <Typography $variant="h2">I'm a Tenant</Typography>
            <Typography $variant="body1" as="p" className="mt-2">Looking to rent a house</Typography>
          </Card>
        </Col>
        <Col xs={12} sm={6}>
          <Card className='text-center  cursor-pointer position-relative' tabIndex={0} onClick={() => handleCard('landlord')} $hover $selected={formData?.userRoles?.includes('landlord')}>
            {formData?.userRoles?.includes('landlord') && <StyledCheckIcon />}
            <MdOutlineHome className="mb-2 text-secondary fs-1" />
            <Typography $variant="h2">I'm a Landlord</Typography>
            <Typography $variant="body1" as="p" className="mt-2">Looking for a tenant</Typography>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button $variant='filled' className='w-100' disabled={!Boolean(formData.userRoles?.length)} onClick={() => handleNext()}>Continue</Button>
        </Col>
      </Row>
    </>
  )
}
