import { useEffect, useRef, useState } from 'react'
import { Container, Spinner, Alert, Row, Col, Stack, Badge } from 'react-bootstrap'
import { MdHotel, MdOutlineBathtub, MdDirectionsCar } from "react-icons/md"
import { Link, useNavigate } from 'react-router-dom'
import { propertyListByUserThunk } from '../../lib/redux/actions/property.action'
import { useReduxDispatch, useReduxSelector } from '../../hooks/redux'
import { PropertyDTO } from '../../dtos/Property.dto'
import Typography from "../../components/_ui/typography/Typography.component"
import Layout from "../../components/_layout/Layout.component"
import Button from '../../components/_ui/button/Button.component'
import Card from '../../components/_ui/card/Card.component'
import placeholderImage from '../../assets/images/placeholder.jpg'
import NoRecordFound from '../../components/_ui/noRecordFound/NoRecordFound.component'



export default function PropertiesList() {

  const PAGE_SIZE = 6
  const lastPropertyRef = useRef<any>()
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [properties, setProperties] = useState<PropertyDTO[]>([])
  const { user } = useReduxSelector(state => state.auth)
  const { total } = useReduxSelector(state => state.property.propertyListByUser)



  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !loading) fetchProperties()
    })

    if (lastPropertyRef.current && hasMore) observer.observe(lastPropertyRef.current)

    return () => {
      if (lastPropertyRef.current) observer.unobserve(lastPropertyRef.current)
    }
  }, [properties])


  const fetchProperties = async () => {
    setLoading(true)
    await dispatch(propertyListByUserThunk({ size: PAGE_SIZE, page })).unwrap()
      .then(response => {
        const data = response.data.list
        if (!Boolean(data.length)) return setHasMore(false)
        setProperties(prev => [...prev, ...data])
        setPage(prev => prev + 1)
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }


  useEffect(() => { fetchProperties() }, [])


  useEffect(() => {
    if (!Boolean(user?.propertyCount)) {
      navigate('/landlord/profile/step-1')
    }
  }, [total])

  const chooseTenantButton = (event: React.MouseEvent, item: any) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(`/properties/tenants/${item._id}`)
  }

  const handleNavigate = (event: React.MouseEvent, item: any) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(`profile/${item._id}`)
  }


  return (
    <Layout title='My Properties' user={true}>
      <Container>
        <div className='d-flex justify-content-between align-items-start mb-heading'>
          <Typography $variant='h1' className='mb-0 d-flex align-items-center gap-2'>My Properties <Typography $variant='body2' as={Badge} pill bg='secondary' className='fw-normal text-white'>{total}</Typography></Typography>
          <Button as={Link} to='/landlord/profile/step-1'>Add New</Button>
        </div>

        <Stack gap={4}>
          {Boolean(properties.length) &&
            <div>
              <Row className='row-gap-4'>
                {properties.map((item, index) =>
                  <Col xs={12} sm={6} md={4} key={index}>
                    <Card className='p-0 overflow-hidden d-block position-relative' $hover>
                      <Link to={`profile/${item._id}`/*`edit/${item._id}`*/}>
                        <Badge as={Typography} $variant='body3' className='position-absolute top-15 left-15 text-white text-capitalize py-2 fw-light'>{item?.status === 'verified' ? 'Active' : item?.status}</Badge>
                        <img src={item.image[0] || placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75' }} />
                      </Link>
                      <div className='p-3 d-flex flex-column gap-3'>
                        <Typography $variant='subtitle2' title={item.address.name} className='text-dark text-nowrap fw-semibold overflow-hidden text-overflow-ellipsis'>
                          {item.address.name}
                        </Typography>
                        <Stack direction='horizontal' className='justify-content-between'>
                          <div className='center flex-column'>
                            <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                              <MdHotel className='fs-3' /> {item.numberOfRoom}
                            </div>
                            <Typography $variant='body3' className=''>Bedooms</Typography>
                          </div>
                          <div className='center flex-column'>
                            <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                              <MdOutlineBathtub className='fs-3' /> {item.numberOfWashroom}
                            </div>
                            <Typography $variant='body3' className=''>Bathrooms</Typography>
                          </div>
                          <div className='center flex-column'>
                            <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                              <MdDirectionsCar className='fs-3' /> {item.numberOfParking}
                            </div>
                            <Typography $variant='body3' className=''>Parking</Typography>
                          </div>
                        </Stack>

                        <div className='d-flex justify-content-end'>
                          {item?.status === 'verified' ? (<Button className='w-100' onClick={event => chooseTenantButton(event, item)}>
                            Choose a tenant
                          </Button>) : (<Button $variant='outlined' className='w-100' onClick={event => handleNavigate(event, item)}>
                            View Listing
                          </Button>)
                          }

                        </div>


                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
              <div ref={lastPropertyRef} />
            </div>
          }

          {loading && <Spinner className='m-auto d-block' variant='secondary' />}
          {!loading && !Boolean(properties.length) && <NoRecordFound className='mb-0' variant='light'>No records found</NoRecordFound>}
        </Stack>
      </Container>
    </Layout >
  )
}
