import { useState, useEffect } from 'react';
import { get, DataSnapshot, ref, onValue, off, query, orderByChild, equalTo, limitToFirst, orderByKey, limitToLast } from 'firebase/database';
import { database } from '../../../../firebase'; // Import your Firebase configuration
import { useReduxSelector } from '../../../../hooks/redux';
import { FirebaseError } from 'firebase/app';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../../../../components/_ui/card/Card.component';
import Typography from '../../../../components/_ui/typography/Typography.component';
import moment from 'moment';
import NoRecordFound from '../../../../components/_ui/noRecordFound/NoRecordFound.component';
import MsgImg from '../../../../assets/images/messages.svg'


export interface Message {
  message: string;
  senderUid: string;
  recipientUid: string;
  timestamp: number;
  sendByName: string;
}


const ChatComponent: React.FC = () => {
  const { user } = useReduxSelector(state => state.auth)
  const recipientUid = user?.fireBaseUserId
  const [messages, setMessages] = useState<Message[]>([]);


  const fetchTopChatsForRecipient = async () => {
    try {
      const chatsRef = ref(database, 'chats');
      const snapshot: DataSnapshot = await get(chatsRef);

      if (snapshot.exists()) {
        const recentMessages: any[] = [];

        snapshot.forEach((chatSnapshot) => {
          const messages = chatSnapshot.child('messages');
          messages.forEach((messageSnapshot) => {
            const messageData = messageSnapshot.val();
            if (messageData.recipientUid === recipientUid) {
              recentMessages.push(messageData)
            }
          });
        });

        recentMessages.sort((a, b) => b.timestamp - a.timestamp);

        const last3Messages = recentMessages.slice(0, 3);
        setMessages(last3Messages)

      } else {
        setMessages([])
        console.log('No messages found for recipient UID:', recipientUid);
      }
    } catch (error) {
      setMessages([])
      console.error('Error fetching recent messages:', error as FirebaseError);
    }
  };


  useEffect(() => {
    fetchTopChatsForRecipient();
  }, [recipientUid]);


  return (
    <Stack gap={3}>
      <div className='d-flex justify-content-between align-items-center'>
        <Typography $variant='h4'>Message</Typography>
        <Typography $variant='body3' as={Link} to={`/messages`} className='text-end text-decoration-underline text-black-50' $hover >View All</Typography>
      </div>

      <Stack gap={2}>
        {messages.map((message) => (
          <Card key={message.timestamp} as={Link} to={`/messages`} >

            <div className='d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-3'>
              <div>
                <Typography $variant='subtitle1' className=''>{message.sendByName}</Typography>
                <Typography $variant='body1' className='line-3'>{message.message}</Typography>
              </div>
              <div className='flex-shrink-0'>
                <Typography $variant='body2'>{moment(message.timestamp).calendar()}</Typography>
              </div>
            </div>
          </Card>
        ))}

        {!Boolean(messages?.length) && <NoRecordFound image={MsgImg} className='text-center w-100'>
          <Typography $variant='h4'>Conversation</Typography>
          <Typography $variant='body1'>No records found</Typography>
        </NoRecordFound>}
      </Stack>
    </Stack>
  );
};

export default ChatComponent;
