import Typography from '../../../components/_ui/typography/Typography.component'
import { OnBoardHeadingProps } from './OnBoardHeading.interface'
import { StyledOnboardWrapper } from './OnBoardHeading.style'


export default function OnBoardHeading(props: OnBoardHeadingProps) {
  const { heading, description } = props

  return (
    <div className='d-flex align-items-center h-100 bg-box'>
      <div className='w-100 d-flex justify-content-center align-items-center'>
        <StyledOnboardWrapper>
          <div className='d-flex flex-column gap-2 mb-3'>
            <Typography $variant='h3'>{heading || ''}</Typography>
            <Typography $variant='body1'>{description || ''}</Typography>
          </div>
        </StyledOnboardWrapper>
      </div>
    </div>

  )
}
