import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Stack } from "react-bootstrap";
import { MdFacebook, MdSchedule, MdWorkspacePremium } from "react-icons/md";
import moment from "moment";

import { ConfirmatonPopup } from "../../../../components/modalPopup/confirmation/ConfirmationPopup.component";
import { AcceptPopup } from "../../../../components/modalPopup/accept/AcceptPopup.component";
import Button from "../../../../components/_ui/button/Button.component";
import Countdown from "../../../../components/_ui/countDown/Countdown.component";
import Typography from "../../../../components/_ui/typography/Typography.component";

import Lottie from "lottie-react";
import animationClock from "../../../../assets/lottie/clock.json";

export default function Sidebar({ payload }: { payload: any }) {

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false)
  const handleModal = (state: boolean) => setShowModal(state)

  return (
    <Stack gap={4} className="position-sticky top-15">
      <Row>
        <Col xs={12} md={12}>
          {
            Boolean(moment(payload?.endDate)?.isAfter(moment(), 'day')) ?
              <div className="border bg-light rounded p-3">
                <div className="d-flex flex-column align-items-center py-4">
                  <MdWorkspacePremium color="#ccc" size={60} className="mb-4" />
                  <Typography $variant="h5" className={"mb-3"}>Premium Account</Typography>
                  <Typography $variant="body1" className={"mb-4 text-center"}>
                    You have purchased an account boost, you're profile will be seen first by landlords in the locations you've selected.
                    <Countdown startDate={moment(payload?.endDate || null).add(1, 'days')} />
                  </Typography>
                </div>
              </div>
              : <div className="border bg-light rounded p-3 mb-4">
                <div className="d-flex flex-column align-items-center py-4">
                  <Lottie animationData={animationClock} loop={true} style={{width: 60}} className="mb-4" />

                  <Typography $variant="h5" className={"mb-3"}>Go Premium</Typography>
                  <Typography $variant="body1" className={"mb-4 text-center"}>Upgrade your account to receive all the benefits, plus more exposure and our guarantee you'll find a house.</Typography>
                  <Button as={Link} to="/store/upgrade">Upgrade Account</Button>
                </div>
              </div>
          }
        </Col>
      </Row>

      {/* <Row>
        <Col xs={12} md={12}>
          <div className="border rounded py-5 d-flex align-items-center flex-column">
            <Typography $variant="h6" className={"mb-3"}>
              Social Media Verifications
            </Typography>

            <div className="d-flex py-3 mb-4">
              <Row>
                <Col xs={3} md={3}>
                  <MdFacebook color="#0d6efd" size={40} />
                </Col>
                <Col xs={3} md={3}>
                  <MdFacebook color="#ccc" size={40} />
                </Col>
                <Col xs={3} md={3}>
                  <MdFacebook color="#ccc" size={40} />
                </Col>
                <Col xs={3} md={3}>
                  <MdFacebook color="#ccc" size={40} />
                </Col>
              </Row>
            </div>

            <Button $variant="outlined">Edit Verifications</Button>
          </div>
        </Col>
      </Row> */}
      { /*<Row>
        <Col xs={12} md={12} className="d-flex justify-content-center">
          <Button $variant="text" onClick={() => handleModal(true)}>
            Report this person
          </Button>
        </Col>
    </Row>*/ }

      <ConfirmatonPopup
        data={{
          heading: "Report user",
          description: "Are you sure you want to report this user?",
          buttonName: "Yes",
        }}
        show={showModal}
        onHide={() => handleModal(false)}
        callBack={() => setShowAcceptModal(true)}
      />

      <AcceptPopup
        content="User Reported!"
        show={showAcceptModal}
        onHide={() => setShowAcceptModal(false)}
      />

    </Stack>
  );
}
