import React, { useEffect, useRef } from 'react'
import { LeftSideChat, RightSideChat } from './ChatView.style'
import Typography from '../../../../components/_ui/typography/Typography.component'
import { useReduxSelector } from '../../../../hooks/redux'



export default function Chats({ item, index }: IChatMessages) {

  const { user } = useReduxSelector(state => state.auth)

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' });
  }, []);


  return (
    <div key={index} ref={ref}>
      {item.senderUid === user?.fireBaseUserId ?
        <div className='d-flex justify-content-end'>
          <RightSideChat>
            <Typography $variant='body2' >{item.message}</Typography>
          </RightSideChat>
        </div>
        :
        <div className='d-flex justify-content-start'>
          <LeftSideChat>
            <Typography $variant='body2' >{item.message}</Typography>
          </LeftSideChat>
        </div>
      }
    </div>
  )
}


interface IChatMessages {
  item: any
  index: number
}