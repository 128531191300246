import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import PaypalCheckoutButton from './component/paypalCheckoutButton/PaypalCheckoutButton.component'



export default function PaypalPayment() {

  const initialOptions = {
    "clientId": process.env.REACT_APP_PAYPAL_CILENT_ID as string,
    "currency": "AUD",
    "enable-funding": "",
    "disable-funding": "paylater,venmo,card",
    "data-sdk-integration-source": "integrationbuilder_sc",
  }


  return (
    <div>
      <PayPalScriptProvider options={initialOptions}>
        <PaypalCheckoutButton />
      </PayPalScriptProvider>
    </div>
  )
}
