import { createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { Status } from '../../../interfaces/Status.type'
import { chatPropertyShortlistedThunk, chatTenantShortListThunk } from '../actions/chat.action'



/* == Initial State == */
const initialState: IChatSlice = {
  chatTenantShotList: {
    status: '',
    data: [],
    total: 0
  },
  chatSelectData: {
    data: []
  },
  chatUnreadMsgCount: {
    countMsg: 0
  }
}


/* == Slice == */
export const chatSlice = createSlice({
  name: 'Chat',
  initialState,
  reducers: {
    selectSidebarChat: (state, action) => {
      state.chatSelectData.data = action.payload
    },
    setChatUnreadMsgCount: (state, action) => {
      state.chatUnreadMsgCount.countMsg = action.payload
    }
  },
  extraReducers: (builder) => {

    {/* chat Tenant ShotList */ }
    builder.addCase(chatTenantShortListThunk.pending, (state, action) => {
      state.chatTenantShotList.status = THUNK_STATUS.LOADING
    })

    builder.addCase(chatTenantShortListThunk.fulfilled, (state, action) => {
      state.chatTenantShotList.status = THUNK_STATUS.SUCCESS
      state.chatTenantShotList.data = action.payload.data.result
    })

    builder.addCase(chatTenantShortListThunk.rejected, (state, action) => {
      state.chatTenantShotList.status = THUNK_STATUS.FAILED
    })

    {/* chat send */ }
    builder.addCase(chatPropertyShortlistedThunk.pending, (state, action) => {
      state.chatTenantShotList.status = THUNK_STATUS.LOADING
    })

    builder.addCase(chatPropertyShortlistedThunk.fulfilled, (state, action) => {
      state.chatTenantShotList.status = THUNK_STATUS.SUCCESS
      let useAbleData: any = action.payload.data.result
      let newArray = useAbleData.map((item: any) => {
        return { ...item?.property?.userId, ...item?.property, property_id: item.property._id }
      })
      state.chatTenantShotList.data = newArray
    })

    builder.addCase(chatPropertyShortlistedThunk.rejected, (state, action) => {
      state.chatTenantShotList.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface IChatSlice {
  chatTenantShotList: {
    status: Status
    data: any
    total: number
  },
  chatSelectData: {
    data: any
  },
  chatUnreadMsgCount: {
    countMsg: number
  }
}






export const { selectSidebarChat, setChatUnreadMsgCount } = chatSlice.actions
export default chatSlice.reducer
