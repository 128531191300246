import { useLayoutEffect, useState } from 'react'
import { useReduxSelector } from './redux'
import { areAllValuesZero } from '../utils'



export const useTenantProfileStep = () => {
  const { user } = useReduxSelector(state => state.auth)
  const { suburb, weeklyBudget, familyMembers, salary, currentStatus, profilePhotos, description }: any = user?.profile || {}
  const [activeStep, setActiveStep] = useState<string | null>(null)


  function checkAllInfo() {
    return Boolean(!Boolean(suburb.length) && weeklyBudget === null && areAllValuesZero(familyMembers) && salary === undefined && currentStatus === null && /*!Boolean(profilePhotos.length) &&*/ description === null)
  }
  // console.log({ checkAllInfo: checkAllInfo(), suburb, weeklyBudget, familyMembers, salary, currentStatus, profilePhotos, description })

  useLayoutEffect(() => {
    const steps = [
      { check: checkAllInfo(), step: '/' },
      { check: !Boolean(suburb?.length), step: '/tenant/profile/location' },
      { check: weeklyBudget === null, step: '/tenant/profile/budget' },
      { check: areAllValuesZero(familyMembers), step: '/tenant/profile/family' },
      { check: salary?.type === null, step: '/tenant/profile/salary' },
      { check: currentStatus === null, step: '/tenant/profile/current-status' },
      //{ check: !Boolean(profilePhotos?.length), step: '/tenant/profile/photos' },
      { check: description === null, step: '/tenant/profile/about-me' },
    ];

    for (const step of steps) {
      if (step.check) {
        setActiveStep(step.step);
        break;
      }
    }
  }, [suburb, weeklyBudget, familyMembers, salary, currentStatus, profilePhotos, description])

  return activeStep
}
