import { styled } from "styled-components"


export const StyledOtpInput = styled.div`
  display: flex;
  width: 100%;
  max-width: 290px;
  column-gap: 20px;

  .otp-input {
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    border-radius: var(--bs-border-radius);
  }
`