import { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { geocodeByAddress, geocodeByPlaceId } from "react-google-places-autocomplete"
import { getPlaceIdFromLocation, numberTest, stringTest } from "../../../../utils"
import { setLandlordProfileOnboardFormData } from "../../../../lib/redux/slices/user.slice"
import * as yup from 'yup'
import toast from "react-hot-toast"
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import MapAutocomplete from "../../../../components/_ui/mapAutocomplete/MapAutocomplete.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoarding from "../../../../components/authLayout/onBoardInfomation/OnBoarding.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import Card from "../../../../components/_ui/card/Card.component"
import background_image from '../../../../assets/images/register/background_006.jpg';



export default function StepProperty1() {
  const MAX_IMAGE_UPLOAD = 5
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)
  const { formData } = useReduxSelector(state => state.user.landlordProfileOnboard)
  const navigate = useNavigate()
  const [loading, setLoading] = useState({
    form: false,
    address: false
  })


  const { handleSubmit, register, formState: { errors, isSubmitted }, control, setValue, watch, getValues, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      suburb: formData.suburb || [],
      address: {
        name: formData?.address?.name || '',
        isManual: formData?.address?.isManual || false,
        city: formData?.address?.city || '',
        state: formData?.address?.state || '',
        placeId: formData?.address?.placeId || '',
        country: 'Australia',
        postcode: formData?.address?.postcode,
        latitude: formData?.address?.latitude,
        longitude: formData?.address?.longitude,
      }
    }
  })


  const [isManualAddress, setIsManualAddress] = useState(getValues('address.isManual'))

  const clearAddress = () => {
    setValue('address.name', '')
    setValue('address.city', '')
    setValue('address.state', '')
    setValue('address.placeId', '')
    setValue('address.postcode', '')
    setValue('address.latitude', null)
    setValue('address.longitude', null)
    setValue('suburb', undefined)
    if (isSubmitted) trigger()
  }


  const handlePlaceSelected = async (data: any) => {
    if (!data) return clearAddress()
    setLoading(items => ({ ...items, address: true }))

    try {
      let postcode: string = ''
      const [response] = await geocodeByPlaceId(data.value.place_id)
      setValue('address.name', data.label)
      setValue('address.latitude', response.geometry.location.lat())
      setValue('address.longitude', response.geometry.location.lng())
      setValue('address.placeId', response.place_id)
      trigger('address.name')

      for (const component of response.address_components) {
        if (component.types.includes('locality')) setValue('address.city', component.long_name)
        else if (component.types.includes('administrative_area_level_1')) setValue('address.state', component.long_name)
        else if (component.types.includes('country')) setValue('address.country', component.long_name)
        else if (component.types.includes('postal_code')) postcode = component.long_name
      }
      setValue('suburb', [{ name: `${getValues('address.city')}`, postcode }])
      setValue('address.postcode', postcode)
    }
    catch (error) {
      console.log('Google Map API Error @geocodeByPlaceId: ', error)
      toast.error('Something went wrong with address')
    }
    finally { setLoading(items => ({ ...items, address: false })) }
  }


  const onSubmit = async (data: FormData) => {
    try {
      setLoading(items => ({ ...items, form: true }))

      if (data.address.isManual) {
        const fullAddress = `${data.address.name}, ${data.address.city}, ${data.address.state}, ${data.address.country}`
        try {
          await geocodeByAddress(fullAddress)
            .then(([response]) => {
              if (!response) throw `Unable to find address`
              setValue('address.latitude', response.geometry.location.lat())
              setValue('address.longitude', response.geometry.location.lng())
              setValue('address.placeId', response.place_id)
            })
        }
        catch (error) {
          console.log('An error occurred. Please try again another address', error)
        }
      }

      let addressString = `${data?.address?.city} ${data?.address?.state} ${data?.address?.country}`;
      let getPlaceIdFromGoogleApi = await getPlaceIdFromLocation({ name: addressString })

      if (!!getPlaceIdFromGoogleApi) {
        setValue('address.placeId', getPlaceIdFromGoogleApi)
        const finalData: any = getValues()
        dispatch(setLandlordProfileOnboardFormData(finalData))
        navigate('/landlord/profile/step-2')
      } else {
        toast.error('Please fill property address available on google map.')
      }
    }
    catch (error) { console.error('An error occurred:', error) }
    finally { setLoading(items => ({ ...items, form: false })) }
  }

  console.log(errors)

  return (
    <Layout title='Landlord Step 1' user={true} header={false} footer={false}>
      <OnBoardProfileLayout
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Location' description={`What's your property address?`} />

            {/* Form */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Address */}
              <div className='position-relative z-1'>
                {isManualAddress ?
                  <Card className='animate__animated animate__fadeIn gap-3 d-flex flex-column border mb-1'>
                    <Typography $variant='subtitle1' className='text-body-secondary'><span>Enter Property Address Manually</span></Typography>
                    <Form.Group controlId='address'>
                      <Form.Label>Address</Form.Label>
                      <Form.Control type='text' className='fw-medium' isInvalid={Boolean(errors.address?.name)} {...register('address.name')} />
                      <Form.Text className='text-danger'>{errors.address?.name?.message}</Form.Text>
                    </Form.Group>
                    <Row className='row-gap-3'>
                      <Col xs={12} sm={6}>
                        <Form.Group controlId='country'>
                          <Form.Label>Country</Form.Label>
                          <Form.Control type='text' className='fw-medium' disabled isInvalid={Boolean(errors.address?.country)} {...register('address.country')} />
                          <Form.Text className='text-danger'>{errors.address?.country?.message}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group controlId='state'>
                          <Form.Label>State</Form.Label>
                          <Form.Control type='text' className='fw-medium' isInvalid={Boolean(errors.address?.state)} {...register('address.state')} />
                          <Form.Text className='text-danger'>{errors.address?.state?.message}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group controlId='city'>
                          <Form.Label>City</Form.Label>
                          <Form.Control type='text' className='fw-medium' isInvalid={Boolean(errors.address?.city)} {...register('address.city')} />
                          <Form.Text className='text-danger'>{errors.address?.city?.message}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group controlId='postcode'>
                          <Form.Label>Post Code</Form.Label>
                          <Form.Control type='text' className='fw-medium' isInvalid={Boolean(errors.address?.postcode)} {...register('address.postcode')} />
                          <Form.Text className='text-danger'>{errors.address?.postcode?.message}</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card>
                  :
                  <Form.Group controlId='name' className='animate__animated animate__fadeIn'>
                    <label className='w-100 d-block'>
                      <Controller
                        name="address.name"
                        control={control}
                        render={({ field: { value, onChange, ...items } }) => (
                          <MapAutocomplete
                            isInvalid={Boolean(errors.address?.name)}
                            selectProps={{
                              ...items as any,
                              value: value ? { label: value, value: { place_id: value } } : undefined,
                              defaultOptions: value ? [{ label: value, value: { place_id: value } }] : undefined,
                              onChange: handlePlaceSelected,
                              isClearable: true,
                              isLoading: loading.address,
                            }}
                          />
                        )}
                      />
                    </label>
                  </Form.Group>
                }

                <div className='d-flex justify-content-between gap-5'>
                  <Form.Text className='text-danger'>{!isManualAddress && errors.address?.name?.message}</Form.Text>
                  <Form.Check type="switch" id="manual-address" reverse className='text-primary cursor-pointer fw-normal m-0 mt-1' onClick={event => { setIsManualAddress(event.currentTarget.checked); clearAddress() }} {...register('address.isManual')}
                    label={
                      <Typography $variant='body2'>Can't find your property?&nbsp; <Typography $variant='body2' as='span' className='text-primary'>Enter Manually</Typography></Typography>
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' $loading={loading?.form} type='submit'>Continue</Button>
              </div>

            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  suburb: yup.array().of(
    yup.object({
      name: yup.string().notRequired(),
      postcode: yup.string().notRequired()
    })),
  address: yup.object({
    name: yup.string().trim().required('Address is required *').min(2, 'Enter valid address').max(200, 'Enter valid address').test('', 'Enter valid address', stringTest),
    isManual: yup.boolean().required(),
    country: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(stringTest),
      otherwise: schema => schema.notRequired()
    }),
    state: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(stringTest),
      otherwise: schema => schema.notRequired()
    }),
    city: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(stringTest),
      otherwise: schema => schema.notRequired()
    }),
    placeId: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().notRequired(),
      otherwise: schema => schema.notRequired()
    }),
    postcode: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(numberTest),
      otherwise: schema => schema.notRequired()
    }),
    latitude: yup.number().nullable().when('isManual', {
      is: true,
      then: schema => schema.notRequired(),
      otherwise: schema => schema.notRequired()
    }),
    longitude: yup.number().nullable().when('isManual', {
      is: true,
      then: schema => schema.notRequired(),
      otherwise: schema => schema.notRequired()
    }),
  }),
})

type FormData = yup.InferType<typeof schema>
