import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { MdAccountBox, MdMail, MdCall, MdVerified, MdWeb, MdSmartphone, MdSchedule } from 'react-icons/md';
import { convertToMelbourneTime, convertToTitleCase, isVerificationImage } from '../../../../../utils';
import { Stack, Table, Alert, Row, Col, Spinner } from 'react-bootstrap'
import { RejectConfirm } from '../../../../../components/modalPopup/rejectConfirm/RejectConfirm.component';
import { ConfirmatonPopup } from '../../../../../components/modalPopup/confirmation/ConfirmationPopup.component';
import { DataConfirmationTypes } from '../../../../../components/modalPopup/confirmation/ConfirmatonPopupProps.interface';
import { adminUpdateTenantStatusThunk, adminUserDetailByIdThunk, adminGetUserSessionsThunk } from '../../../../../lib/redux/actions/admin.action';
import { rejectConfirmationTypes } from '../../../../../components/modalPopup/rejectConfirm/RejectConfirm.interface';
import { TenantDetailProps } from './TenantDetail.interface';
import moment from 'moment';
import Typography from "../../../../../components/_ui/typography/Typography.component"
import VerifyDocument from '../../../../properties/profile/components/VerifyDocument.component';
import NoRecordFound from '../../../../../components/_ui/noRecordFound/NoRecordFound.component';
import Button from '../../../../../components/_ui/button/Button.component';
import placeholderImage from '../../../../../assets/images/placeholder.jpg'
import Card from '../../../../../components/_ui/card/Card.component';
import Attachment from './components/Attachment.component';
import UserInfo from '../../../../dashboard/components/profileListing/UserInfo';
import Gallery from '../../../../dashboard/components/profileListing/Gallery';
import ProfilePlaceholder from '../../../../../assets/images/profile-placeholder.svg'


export default function TenantDetail(props: TenantDetailProps) {
  const { data } = props
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { userId } = useParams()
  const [confirmationShow, setconfirmationShow] = useState(false)
  const [rejectReason, setrejectReason] = useState('')
  const [rejectShowModal, setrejectShowModal] = useState(false)
  const [valueAcceptReject, setValueAcceptReject] = useState('')
  const { userRoles } = useReduxSelector(state => state?.auth)
  const { userSessions } = useReduxSelector(state => state?.admin)

  useEffect(() => {
    dispatch(adminGetUserSessionsThunk({ id: userId as string })).then(() => {
      // console.log(userSessions);
      // console.log("TESDT")
    });


  }, [])

  const handleAcceptReject = (status: string) => {
    dispatch(adminUpdateTenantStatusThunk({ id: userId as string, status, rejectReason })).unwrap()
      .then((response) => {
        setrejectReason('')
        dispatch(adminUserDetailByIdThunk({ id: userId as string }))
        toast.success(response?.data?.status)
      })
      .catch((err) => setrejectReason(''))
  }



  const dataConfirmation: DataConfirmationTypes = {
    heading: 'Accept Confirmation',
    description: 'Are you sure you want to accept the tenant ?',
    buttonName: 'Accept'
  }

  const rejectDataConfirmation: rejectConfirmationTypes = {
    heading: 'Reject Confirmation',
    descriptionFirst: 'Are you sure you want to reject the tenant ?',
    descriptionSeceond: 'Are you sure ?',
    buttonNameFirst: 'Reject',
    buttonNameSecond: 'Confirm Reject'
  }


  return (
    <>
      {/* Tenant Profile */}
      <Card as={Stack} gap={2} className='mb-3'>

        <Stack gap={4}>
          <Row>
            <Col xs={12} md={12}>
              {
                Boolean(data?.profile?.profilePhotos?.length)
                  ? <Gallery payload={data?.profile?.profilePhotos} />
                  : <img src={ProfilePlaceholder} className='w-100 h-100 rounded border border-light-subtle' style={{ maxHeight: 'max(100px, calc(100vh - 300px))' }} />
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <UserInfo payload={data?.profile} info={data} />
            </Col>
          </Row>
        </Stack>

      </Card>

      {/* User Detail */}
      <Card as={Stack} gap={3} className='mb-3'>
        <Typography $variant='h5'>User Details</Typography>
        <div className='d-flex flex-column flex-lg-row align-items-lg-center justify-content-between gap-3'>
          <BasicUserInfo dataInfo={{ heading: 'Name', value: data?.name, logo: <MdAccountBox size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Email', value: data?.email, logo: <MdMail size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Phone', value: data?.phone, logo: <MdCall size={24} /> }} />
        </div>
      </Card>

      {/* Browser Detail */}
      <Card as={Stack} gap={3} className='mb-3'>
        <Typography $variant='h5'>Device Details</Typography>
        <div className='d-flex flex-column flex-lg-row align-items-lg-center justify-content-between gap-3'>
          <BasicUserInfo dataInfo={{ heading: 'Browser', value: data?.userSystemInfo?.browser_details, logo: <MdWeb size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Operating System', value: data?.userSystemInfo?.operating_system, logo: <MdSmartphone size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Timezone', value: data?.userSystemInfo?.time_zone, logo: <MdSchedule size={24} /> }} />
        </div>
      </Card>


      {/* Properties */}
      <Card as={Stack} gap={3} className='mb-3'>
        <Typography $variant='h5'>Shortlisted Properties</Typography>
        <div className="row ">
          {
            data?.tenatShortlistData?.propertyName &&
            <>
              <div className='col-4'>
                <Typography $variant='subtitle2' className='align-self-center'> Property Name</Typography>
              </div>
              <div className='col-8'>
                <Typography $variant='body1' className='align-self-center'>{data?.tenatShortlistData?.propertyName}</Typography>
              </div>
            </>
          }
          {!Boolean(data?.tenatShortlistData?.propertyName) && <div className='px-2'><NoRecordFound /></div>}
        </div>
      </Card>

      {/* Verification */}
      <Card as={Stack} gap={2} className='mb-3'>

        <Typography $variant='h5'>Verification {data?.profile?.isBlueTickNeeded === 'accept' && <MdVerified className='text-blue fs-4' />}</Typography>

        {data?.profile?.verificationDocuments?.map((item: any, index: number) =>
          (item.image !== '' ? <VerifyDocument key={index} data={item} /> : null)
        )}

        {isVerificationImage(data?.profile?.verificationDocuments) ? <NoRecordFound>No Documents Found</NoRecordFound> : null}


        <div className='d-flex justify-content-end gap-2'>
          {data?.profile?.isBlueTickNeeded === 'pending' &&
            <>
              <Button $variant='outlined' onClick={() => { setValueAcceptReject('reject'); setrejectShowModal(true) }} >Reject</Button>
              <Button $variant='outlined' onClick={() => { setconfirmationShow(true); setValueAcceptReject('verified') }}>Accept</Button>
            </>
          }
        </div>


        {/*Boolean(userRoles.includes('admin')) && <div><Attachment {...props} /></div>*/}

      </Card>




      {/* Property payment Detail */}
      <Card as={Stack} className='mb-3'>
        <Typography $variant='h5' className='mb-2'>Payments</Typography>
        <Table striped>
          <thead>
            <tr><th>Plan Name</th><th>Amount</th><th>Date</th></tr>
          </thead>
          <tbody>
            {data && data.tenantPlanPurchaseData &&
              data.tenantPlanPurchaseData.map((item: any, index: number) => (
                <tr>
                  <td>{item?.planName}</td>
                  <td>${item.planType}</td>
                  <td>{convertToMelbourneTime(item.createdAt).format('L')}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {!Boolean(data?.tenantPlanPurchaseData?.length) && <NoRecordFound />}
      </Card>

      {/* Sessions Log */}
      <Card as={Stack}>
        <Typography $variant='h5' className='mb-2'>Session Log</Typography>
        <Table striped>
          <thead>
            <tr><th>SessionID</th><th>Date</th><th>Time</th></tr>
          </thead>
          <tbody>
            {data &&
              userSessions.data.map((item: any, index: number) => (
                <tr>
                  <td>{item?._id}</td>
                  <td>{convertToMelbourneTime(item.createdAt).format('L')}</td>
                  <td>{convertToMelbourneTime(item.createdAt).format('LTS')}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {!Boolean(userSessions.data.length) && <NoRecordFound />}
      </Card>

      {/* Reject pop-up */}
      <RejectConfirm data={rejectDataConfirmation} show={rejectShowModal} onHide={() => setrejectShowModal(false)} callBack={() => handleAcceptReject('reject')}
        setrejectReason={setrejectReason} rejectReason={rejectReason} />

      {/* Confirmation pop-up */}
      <ConfirmatonPopup data={dataConfirmation} show={confirmationShow} onHide={() => setconfirmationShow(false)} callBack={() => handleAcceptReject('accept')} />

    </>
  )
}


{/* Basic user info */ }
const BasicUserInfo = (props: BasicInfoData) => {
  const { dataInfo } = props
  return <>
    <div className='d-flex justify-content-between gap-1'>
      <Typography $variant='subtitle2' className='d-flex align-items-center gap-2'>{dataInfo.logo} {dataInfo?.value}</Typography>
    </div>
  </>

}


{/*  interface */ }
interface BasicInfoData {
  dataInfo: {
    heading: string
    value: string | null | undefined
    logo?: any
  }
}