import * as yup from 'yup'
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap"

import { currencyCheck } from "../../../../utils"
import { formatCurrency } from "../../../../utils/number"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import { setFindHomeSignupTenantFormData } from "../../../../lib/redux/slices/auth.slice"
import Layout from "../../../../components/_layout/Layout.component"
import useIsExtactUrlMatch from "../../hooks/useIsExtactUrlMatch.hook"
import Button from "../../../../components/_ui/button/Button.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import background_image from '../../../../assets/images/register/background_001.jpg';
import useFindHomeSignupRedirection from "../../hooks/useFindHomeSignupRedirection.hook"



export default function ProfileOnBoardStep4() {
  useFindHomeSignupRedirection()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  let matchUrlPath = useIsExtactUrlMatch('/onboard/tenant/')


  const { user, findHomeSignupTenantFormData } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)

  const { handleSubmit, register, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      salary: {
        earn: formatCurrency(user?.profile?.salary?.earn as string || findHomeSignupTenantFormData.formData.profile.salary?.earn as string) || '',
        type: user?.profile?.salary?.type || findHomeSignupTenantFormData.formData.profile.salary?.type || ''
      }
    }
  })


  const onSubmit = async (data: FormData) => {

    data.salary.earn = data.salary.earn.replaceAll(',', '')
    if (!matchUrlPath && user?._id) {

      dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          navigate('/')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))
    } else {
      dispatch(setFindHomeSignupTenantFormData({ activeStep: 5, formData: { profile: { ...data } } }))
      navigate('/auth/onboard/tenant/current-status')
    }
    // dispatch(setprofileOnboardFormData(data))
    // navigate('/tenant/profile/step-5')
  }


  return (
    <Layout title='Tenant Step 4' user={!matchUrlPath} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress={matchUrlPath ? "60" : "40"}
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Salary' description='What is the household combined income?' />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={7} md={7}>
                  <Form.Group controlId='earn'>

                    <InputGroup>
                      <InputGroup.Text className='fw-medium'>$</InputGroup.Text>
                      <Form.Control type='text' autoFocus size='sm' value={formatCurrency(watch('salary.earn'))} isInvalid={Boolean(errors.salary?.earn)} {...register('salary.earn')} style={{ height: 38 }} />
                    </InputGroup>
                    <Form.Text className='text-danger'>{errors.salary?.earn?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={5} md={5} style={{ paddingLeft: 0 }}>
                  <Form.Group>
                    <Form.Select isInvalid={Boolean(errors.salary?.type)} {...register('salary.type')}>
                      <option value='' disabled>Please select one</option>
                      <option value='per_year'>Per year</option>
                      <option value='per_month'>Per month</option>
                      <option value='per_day'>Per day</option>
                    </Form.Select>
                    <Form.Text className='text-danger'>{errors.salary?.earn?.message}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit' $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  salary: yup.object({
    earn: yup.string().trim().required().min(1).max(1000).matches(/^[0-9,]*(\.\d{1,2})?$/, 'Invalid amount')
      .test('decimal', 'Only two decimal places are allowed', currencyCheck),
    type: yup.string().trim().required().max(100),
  }),
})

type FormData = yup.InferType<typeof schema>