import { Container } from "react-bootstrap"
import { MdCheckCircle, MdThumbUpOffAlt } from "react-icons/md"
import { useReduxSelector } from "../../hooks/redux"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Layout from "../../components/_layout/Layout.component"
import Typography from "../../components/_ui/typography/Typography.component"
import Button from "../../components/_ui/button/Button.component"
import Avatar from "../../components/avatar/Avatar.component"
import toast from 'react-hot-toast'



export default function ThankYou() {
  const { user } = useReduxSelector(state => state.auth)
  console.log(user)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()


  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchParams.get('type') === 'blue_tick') {
        if (user?.profile?.isBlueTickNeeded === 'document_pending') {
          navigate('/tenant/verify')
          toast.success('Payment Successful')
        }
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [])


  return (
    <Layout title='Thank You' user={true}>
      <Container className="text-center center flex-column h-100">
        <Avatar $size={100} $icon={<MdThumbUpOffAlt className="fs-1 text-success" />} />
        <Typography $variant="h1" className='mt-4 mb-2'>Thank You!</Typography>
        <Typography $variant="subtitle2"><MdCheckCircle className="text-success fs-3" /> &nbsp; Payment Done Successfully</Typography>
        <Button $variant="outlined" className="mt-4" as={Link} to='/'>Go To Dashboard</Button>
      </Container>
    </Layout >
  )
}
