export const stringTest = (value: string) => value.match(/\d+/g)?.join('') !== value.replace(/\s/g, '')

export const fullnameTest = (value: string) => /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i.test(value)

export const passwordTest = (value: string) => /^(?=.*\d).{6,}$/.test(value)


export const onlyNumberTest = (value: number | null | undefined) => /^[0-9]+$/.test(String(value))

export const numberTest = (value: string | number | null | undefined) => /^[0-9]+$/.test(String(value))


export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const emailTest = (value: string) => emailRegex.test(value)


export const checkMinLengthMSG = (number: number) => `Minimum length is ${number} digits`
export const checkMaxLengthMSG = (number: number) => `Maximum length is ${number} digits`
export const onlyDigitEnterMSG = () => 'Please enter digits only'


export const currencyCheck = (value: string | undefined) => {
  if (!value) return true;
  return /^([0-9,]*\.\d{1,2}|[0-9,]+)$/.test(
    value.replace(/,/g, '')
  );
}


export const fileSizeTest = ({ value, size }: { value: string | File | undefined, size: number }) => {
  if (value instanceof File) return value.size <= (size * 1024 * 1024 * 5); // 5 MB limit
  return true;
}