import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import { useReduxDispatch, useReduxSelector } from './redux';
import { currentUserThunk } from '../lib/redux/actions/auth.action';
import { handleLoader } from '../lib/redux/slices/layout.slice';
import { storeUserSessionThunk } from '../lib/redux/actions/user.action';



export default function useAuthentication() {
  const dispatch = useReduxDispatch();
  const { user } = useReduxSelector(state => state.auth)

  useEffect(() => {
    ; (async () => {
      if (Cookies.get("token")) {
        await dispatch(currentUserThunk()).unwrap().then((res) => {
          const sessionData = sessionStorage.getItem('userSessionRecorded');
          if (!sessionData) {
            sessionStorage.setItem('userSessionRecorded', 'true');
            dispatch(storeUserSessionThunk())
          }
        })
      };
      dispatch(handleLoader(false));
    })();
  }, []);
}
