import { createSlice, PayloadAction } from '@reduxjs/toolkit'



/* == Initial State == */
const initialState = {
  menu: false,
  loading: true
}


/* == Slice == */
export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {

    handleLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

  }
})


export const { handleLoader } = layoutSlice.actions
export default layoutSlice.reducer