import { Link } from 'react-router-dom'
import { Col, Row, Stack } from 'react-bootstrap'
import Card from '../../../../components/_ui/card/Card.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import { LandlordCountCardProps } from './LandlordCount.interface'



export default function LandlordCountCard(props: LandlordCountCardProps) {
  const { data } = props


  return (
    <Row className='row-gap-3'>
      <Col xs={12} md={4}>
        <Card as={Stack} gap={3} className='h-100 align-items-center'>
          <div className='d-flex align-items-center justify-content-between gap-3'>
            <Typography $variant='body1'>Shortlisted Tenants</Typography>
            {/*<Typography $variant='body3' as={Link} className='text-end text-decoration-underline text-black-50' $hover>View</Typography> */}
          </div>
          <Typography $variant='h1' as='div' className='fs-3 mb-2'>{data.shortlist_count}</Typography>
        </Card>
      </Col>

      <Col xs={12} md={4}>
        <Card as={Stack} gap={3} className='h-100 align-items-center'>
          <div className='d-flex align-items-center justify-content-between gap-3'>
            <Typography $variant='body1'>My Properties</Typography>
            {/*<Typography $variant='body3' as={Link} to='/properties' className='text-end text-decoration-underline text-black-50' $hover>View</Typography> */}
          </div>
          <Typography $variant='h1' as='div' className='fs-3 mb-2'>{data.property_count}</Typography>
        </Card>
      </Col>

      <Col xs={12} md={4}>
        <Card as={Stack} gap={3} className='h-100 align-items-center'>
          <div className='d-flex align-items-center justify-content-between gap-3'>
            <Typography $variant='body1'>Available Tenants</Typography>
          </div>
          <Typography $variant='h1' as='div' className='fs-3 mb-2'>{data.available_tenants}</Typography>
        </Card>
      </Col>
    </Row>
  )
}
