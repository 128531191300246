import { useEffect } from "react"
import { Col, Container, Row, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { handleSteps, setRegisterFormData } from "../../../../lib/redux/slices/auth.slice"
import { useForm } from 'react-hook-form'
import { emailTest } from "../../../../utils"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import Steps from "../component/steps/Steps.component"
import Button from "../../../../components/_ui/button/Button.component"
import RegisterLayout from "../component/registerLayout/RegisterLayout.component"

import background_image from '../../../../assets/images/register/background_003.jpg';

export default function RegisterStep3() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { formData } = useReduxSelector(state => state.auth.register)
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })


  useEffect(() => {
    if (!formData.userRoles) navigate('/auth/register/step-1')
  }, [formData])


  const onSubmit = (data: FormData) => {
    dispatch(handleSteps(4))
    dispatch(setRegisterFormData(data))
  }


  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    dispatch(setRegisterFormData({ email: event.currentTarget.value }))
  }


  return (
    <Layout title='Register Step 3' user={false} header={false} footer={false}>

      <RegisterLayout
        //headerActionTitle="Already have an account?"
        //headerActionButtonText="Sign In"
        //headerActionButtonLink="/auth/login"
        progress="55"
        bannerText="Create your Rentless profile"
        columnFirst={
          <>
            {/* <Steps active={2} /> */}

            <div className='d-flex flex-column gap-1 mb-3'>
              <Typography $variant='body1'>Step 2</Typography>
              <Typography $variant="h3">Enter your email</Typography>
            </div>

            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control type="email" defaultValue={formData.email} onKeyUp={handleChange} className="fs-6" size="lg" placeholder="john.smith@gmail.com" isInvalid={Boolean(errors.email)} autoFocus={false} {...register('email')} />
                <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
              </Form.Group>

              <Row>
                <Col>
                  <Button type='submit' className='w-100' disabled={!Boolean(formData.email?.length)}>Continue</Button>
                </Col>
              </Row>
              
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  email: yup.string().trim().required().email().min(2).max(100).test(emailTest)
})

type FormData = yup.InferType<typeof schema>