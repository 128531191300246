import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useReduxSelector } from "../../hooks/redux";


// without login access pages
export default function AuthRoutes({ children }: { children?: ReactNode }) {
  const { isLogggedIn } = useReduxSelector(state => state.auth)

  return !isLogggedIn ? children : <Navigate to='/' />
}