import * as yup from 'yup'
import { currencyCheck, fileSizeTest, stringTest } from '../../../../../utils'
import { isValidPhoneNumber } from 'react-phone-number-input'



{/* == Validation == */ }
export const schema = yup.object({
  suburb: yup.array().of(
    yup.object({
      name: yup.string().notRequired(),
      postcode: yup.string().notRequired(),
      placeId: yup.string().notRequired(),
    })).max(100),
  profilePhotos: yup.array().of(yup.mixed<string | File>().test('fileSize', 'File size limit is 5 MB', value => fileSizeTest({ value, size: 2 }))).default([]),
  description: yup.string().trim().max(10000, 'Explain under ${max} characters').nullable(),
  currentStatus: yup.string().trim().required().max(100),
  weeklyBudget: yup.string().trim().required().min(1).max(1000).matches(/^[0-9,]*(\.\d{1,2})?$/, 'Invalid amount')
    .test('decimal', 'Only two decimal places are allowed', currencyCheck)
    .test('isAbove100', '$100 is below the weekly average', (value) => {
      const budget = value.replaceAll(',', '')
      if (Number(budget) > 100) return true
      else return false
    }),
  familyMembers: yup.object({
    adults: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).nullable(),
    children: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).nullable(),
    dogs: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).nullable(),
    cats: yup.number().min(0).max(1000).transform(value => isNaN(value) ? null : value).nullable(),
  }),
  movingDate: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, 'Moving Date must be in the format DD-MM-YYYY'),
  isShareHouse: yup.boolean(),
  otherInformation: yup.array(),
  vehicles: yup.array().of(
    yup.object({
      model: yup.string().trim().required().min(2).max(200).test(stringTest),
      color: yup.string().trim().required().min(2).max(100).test(stringTest),
      vehicleNumber: yup.string().trim().matches(/^[a-zA-Z0-9]*$/).max(7, 'Input must be at most 7 characters').required()
    })
  ),
  references: yup.array().of(yup.object({
    name: yup.string().trim().required().min(2).max(100).test(stringTest),
    number: yup.mixed().nullable().test('', 'Enter valid Phone No.', value => isValidPhoneNumber(String(value)))
  })),
  salary: yup.object({
    type: yup.string().trim().required().max(100),
    earn: yup.string().trim().required().min(1).max(1000).matches(/^[0-9,]*(\.\d{1,2})?$/, 'Invalid amount')
      .test('decimal', 'Only two decimal places are allowed', currencyCheck),
  }),
  // address: yup.object({
  //   name: yup.string().trim().required('Address is required *').min(2, 'Enter valid address').max(200, 'Enter valid address').test('', 'Enter valid address', stringTest),
  //   isManual: yup.boolean().required(),
  //   country: yup.string().trim().required().min(2).max(100).test(stringTest),
  //   state: yup.string().trim().required().min(2).max(100).test(stringTest),
  //   city: yup.string().trim().required().min(2).max(100).test(stringTest),
  //   latitude: yup.number().nullable().notRequired(),
  //   longitude: yup.number().nullable().notRequired(),
  // })
})

export type FormDataType = yup.InferType<typeof schema>