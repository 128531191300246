import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { plansThunk, planByIdThunk, tenantPurchasePlanThunk, updateTenantDocumentsThunk, paymentDataThunk, tenantPurchasePlanStatusThunk, stripeIdentityCreateVerificationThunk } from '../actions/plan.action'
import { Status } from '../../../interfaces/Status.type'
import { PaymentDTO, PlanDTO } from '../../../dtos/Plan.dto'



/* == Initial State == */
const initialState: IPlanSlice = {
  plans: {
    status: '',
    data: null
  },
  planById: {
    status: '',
    data: null
  },
  paymentData: {
    status: '',
    data: null,
  },
  tenantPurchasePlan: {
    status: '',
    data: null
  },
  tenantPurchasePlanStatus: {
    status: '',
  },
  updateTenantDocuments: {
    status: '',
  },
  stripeIdentityCreateVerify: {
    status: '',
  },
  checkoutModal: {
    status: false,
    selectPlanDetail: null
  }
}



/* == Slice == */
export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    handleCheckoutModal: (state, action: PayloadAction<{ status?: boolean, selectPlanDetail?: PlanDTO | null }>) => {
      state.checkoutModal = { ...state.checkoutModal, ...action.payload }
    },
  },


  extraReducers: (builder) => {

    {/* Plans */ }
    builder.addCase(plansThunk.pending, (state) => {
      state.plans.status = THUNK_STATUS.LOADING
    })

    builder.addCase(plansThunk.fulfilled, (state, action) => {
      state.plans.status = THUNK_STATUS.SUCCESS
      state.plans.data = action.payload.data.plans
    })

    builder.addCase(plansThunk.rejected, (state) => {
      state.plans.status = THUNK_STATUS.FAILED
    })


    {/* Plans By Id */ }
    builder.addCase(planByIdThunk.pending, (state) => {
      state.planById.status = THUNK_STATUS.LOADING
    })

    builder.addCase(planByIdThunk.fulfilled, (state, action) => {
      state.planById.status = THUNK_STATUS.SUCCESS
      state.planById.data = action.payload.data.plan_info
    })

    builder.addCase(planByIdThunk.rejected, (state) => {
      state.planById.status = THUNK_STATUS.FAILED
    })


    {/* Payment Data */ }
    builder.addCase(paymentDataThunk.pending, (state) => {
      state.paymentData.status = THUNK_STATUS.LOADING
    })

    builder.addCase(paymentDataThunk.fulfilled, (state, action) => {
      state.paymentData.status = THUNK_STATUS.SUCCESS
      state.paymentData.data = action.payload.data.info
    })

    builder.addCase(paymentDataThunk.rejected, (state) => {
      state.paymentData.status = THUNK_STATUS.FAILED
    })


    {/* Tenant Purchase Plan */ }
    builder.addCase(tenantPurchasePlanThunk.pending, (state) => {
      state.tenantPurchasePlan.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantPurchasePlanThunk.fulfilled, (state) => {
      state.tenantPurchasePlan.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(tenantPurchasePlanThunk.rejected, (state) => {
      state.tenantPurchasePlan.status = THUNK_STATUS.FAILED
    })


    {/* Tenant Purchase Plan Status */ }
    builder.addCase(tenantPurchasePlanStatusThunk.pending, (state) => {
      state.tenantPurchasePlanStatus.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantPurchasePlanStatusThunk.fulfilled, (state) => {
      state.tenantPurchasePlanStatus.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(tenantPurchasePlanStatusThunk.rejected, (state) => {
      state.tenantPurchasePlanStatus.status = THUNK_STATUS.FAILED
    })


    {/* Update Tenant Documents */ }
    builder.addCase(updateTenantDocumentsThunk.pending, (state) => {
      state.updateTenantDocuments.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updateTenantDocumentsThunk.fulfilled, (state) => {
      state.updateTenantDocuments.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(updateTenantDocumentsThunk.rejected, (state) => {
      state.updateTenantDocuments.status = THUNK_STATUS.FAILED
    })


    {/* stripe identity Create Verification Tenant Documents */ }
    builder.addCase(stripeIdentityCreateVerificationThunk.pending, (state) => {
      state.stripeIdentityCreateVerify.status = THUNK_STATUS.LOADING
    })

    builder.addCase(stripeIdentityCreateVerificationThunk.fulfilled, (state) => {
      state.stripeIdentityCreateVerify.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(stripeIdentityCreateVerificationThunk.rejected, (state) => {
      state.stripeIdentityCreateVerify.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface IPlanSlice {
  plans: {
    status: Status
    data: PlanDTO[] | null
  }
  planById: {
    status: Status
    data: PlanDTO | null
  }
  paymentData: {
    status: Status
    data: PaymentDTO | null
  }
  tenantPurchasePlan: {
    status: Status
    data: any
  },
  tenantPurchasePlanStatus: {
    status: Status
  },
  updateTenantDocuments: {
    status: Status
  },
  stripeIdentityCreateVerify: {
    status: Status
  },
  checkoutModal: {
    status: boolean,
    selectPlanDetail: PlanDTO | null
  }
}



export const { handleCheckoutModal } = planSlice.actions
export default planSlice.reducer