import { useNavigate } from "react-router-dom"

import Layout from "../../../components/_layout/Layout.component"
import Typography from "../../../components/_ui/typography/Typography.component"
import Button from "../../../components/_ui/button/Button.component"
import background_image from '../../../assets/images/register/background_002.jpg';
import RegisterLayout from "../register/component/registerLayout/RegisterLayout.component"



export default function UserNotFound() {
  const navigate = useNavigate()

  return (
    <Layout title='User Not Found' user={false} header={false} footer={false}>
      <RegisterLayout
        columnFirst={
          <div className='d-flex flex-column gap-1 mb-3'>
            <Typography $variant='h2'>Oops !</Typography>
            <Typography $variant="body1">The email is hidden in apple account, so try another way.</Typography>

            <Button onClick={() => navigate('/auth/login')}>Try again</Button>
          </div>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout>
  )
}
