import { Form, Stack } from "react-bootstrap"
import Layout from "../../../components/_layout/Layout.component"
import Card from "../../../components/_ui/card/Card.component"
import Typography from "../../../components/_ui/typography/Typography.component"
import SettingLayout from "../components/settingLayout/SettingLayout.component"
import { SwitchInput } from "./SwitchInput.style"
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from 'yup'
import Button from "../../../components/_ui/button/Button.component"
import { useReduxDispatch, useReduxSelector } from "../../../hooks/redux"
import { notificationSettingsThunk } from "../../../lib/redux/actions/user.action"
import { toast } from "react-hot-toast"
import { THUNK_STATUS } from "../../../lib/redux/constants/status.constant"
import { useEffect } from "react"
import { getProfileThunk } from "../../../lib/redux/actions/auth.action"



export default function Notifications() {

  const dispatch = useReduxDispatch()
  const { status } = useReduxSelector(state => state.user.notificationSettings)
  const { user } = useReduxSelector(state => state.auth)
  const { handleSubmit, register, formState: { errors }, setValue } = useForm<FormData>({ resolver: yupResolver(schema) })

  useEffect(() => {
    setValue('isNotificationNewMessage', Boolean(user?.isNotificationNewMessage))
    setValue('isNotificationPromotions', Boolean(user?.isNotificationPromotions))
    setValue('isNotificationShortlisted', Boolean(user?.isNotificationShortlisted))
  }, [])



  const onSubmit = (formData: FormData) => {
    dispatch(notificationSettingsThunk(formData)).unwrap()
      .then(response => {
        dispatch(getProfileThunk()).unwrap().then().catch((err) => console.log(err))
      }
      )
      .catch((err) => console.log('err => '))
  }


  return (
    <Layout title='Notifications' user={true}>
      <SettingLayout>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Notifications</Typography>
          <Typography $variant='body1'>Keep notifications on so you dont miss anything</Typography>
        </div>

        <Stack as={Form} gap={3} onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <div>
              <Form.Group controlId='name' className="d-flex justify-content-between align-items-center">
                <Typography $variant='h5'>New Messages</Typography>
                <SwitchInput type="switch" id="custom-switch" {...register('isNotificationNewMessage')} />
              </Form.Group>
            </div>
          </Card>
          <Card>
            <div>
              <Form.Group controlId='name' className="d-flex justify-content-between align-items-center">
                <Typography $variant='h5'>Promotions</Typography>
                <SwitchInput type="switch" id="custom-switch" {...register('isNotificationPromotions')} />
              </Form.Group>
            </div>
          </Card>
          <Card>
            <div>
              <Form.Group controlId='name' className="d-flex justify-content-between align-items-center">
                <Typography $variant='h5'>Shortlisted</Typography>
                <SwitchInput type="switch" id="custom-switch" {...register('isNotificationShortlisted')} />
              </Form.Group>
            </div>
          </Card>
          <div className='d-flex justify-content-end'>
            <Button $variant='filled' type="submit" $loading={status === THUNK_STATUS.LOADING}>Apply</Button>
          </div>
        </Stack>

      </SettingLayout>
    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  isNotificationShortlisted: yup.boolean().required(),
  isNotificationPromotions: yup.boolean().required(),
  isNotificationNewMessage: yup.boolean().required()
})

type FormData = yup.InferType<typeof schema>