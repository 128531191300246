import { ReactNode } from "react";
import AliceCarousel from "react-alice-carousel";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import { Button } from "./Button";
import { LightImage } from "../lightImage/LightImage.component";
const responsive = {
  0: {
    items: 1,
  },
  568: {
    items: 2,
  },
  1024: {
    items: 3,
    itemsFit: "contain",
  },
};

export function GalleryCarousel({ payload }: { payload: string[] | any }) {
  const items =
    payload &&
    payload.length !== 0 &&
    payload.map((item: string, index: number) => (
      <LightImage item={item} index={index} />
    ));
  const carouselItems: ReactNode[] = Array.isArray(items) ? items : [];

  const renderNextButton = ({ isDisabled }: { isDisabled?: boolean }) =>
    payload?.length > 3 ? (
      <Button
        isDisabled={isDisabled || false}
        icon={<MdArrowRight color="14b514" size={20} />}
        buttonType="next"
      />
    ) : null;

  const renderPrevButton = ({ isDisabled }: { isDisabled?: boolean }) =>
    payload?.length > 3 ? (
      <Button
        isDisabled={isDisabled || false}
        icon={<MdArrowLeft color="14b514" size={20} />}
        buttonType="prev"
      />
    ) : null;

  return (
    <>
      <AliceCarousel
        mouseTracking
        items={carouselItems}
        responsive={responsive}
        disableDotsControls
        disableButtonsControls={payload?.length <= 3 ? true : false}
        renderNextButton={renderNextButton}
        renderPrevButton={renderPrevButton}
      />
    </>
  );
}
