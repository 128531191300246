import { useEffect, useState } from 'react'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { propertyDetailsThunk } from '../../../lib/redux/actions/property.action'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import PropertyForm from '../component/propertyForm/PropertyForm.component'
import TipsAndSuggestions from '../component/tipsAndSuggestions/TipsAndSuggestions.component'
import Card from '../../../components/_ui/card/Card.component'


export default function PropertiesEdit() {
  const { propertyId } = useParams()
  const dispatch = useReduxDispatch()
  const { status } = useReduxSelector(state => state.property.propertyDetails)


  useEffect(() => {
    dispatch(propertyDetailsThunk({ id: propertyId as string }))
  }, [])


  return (
    <Layout title='Properties Edit' user={true}>
      <Container>
        <Typography $variant='h1'>Edit your property</Typography>

        <Row className='row-gap-5'>

          <Col xs={12} lg={9}>
            <Card>
              {status === THUNK_STATUS.SUCCESS && <PropertyForm mode='edit' />}
              {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
              {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}
            </Card>
          </Col>

          <Col xs={12} lg={3}>
            <TipsAndSuggestions className='position-sticky top-15' />
          </Col>

        </Row>

      </Container>
    </Layout>
  )
}
