import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { ChangeEvent, useRef, useState } from "react"
import { Col, Form, Row, Stack } from "react-bootstrap"
import { MdAdd, MdClose, MdOutlineFileUpload } from "react-icons/md"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { uploadFileThunk } from "../../../../lib/redux/actions/upload.action"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import { StyledImageBox, StyledUploadImage } from "../../../settings/tenant-profile/component/profileForm/ProfileForm.style"
import Layout from "../../../../components/_layout/Layout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import Button from "../../../../components/_ui/button/Button.component"
import * as yup from 'yup'
import toast from "react-hot-toast"
import background_image from '../../../../assets/images/register/background_007.jpg';



export default function ProfileOnBoardStep6() {
  const MAX_IMAGE_UPLOAD = 5
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)
  const { status } = useReduxSelector(state => state.upload.uploadFile)
  const fileInputRef = useRef<null | HTMLInputElement>(null)


  const { handleSubmit, register, control, watch, formState: { errors, isSubmitted }, setValue, getValues, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      profilePhotos: user?.profile?.profilePhotos || [],
    }
  })

  const [profilePhotos, setProfilePhotos] = useState<any[]>(getValues('profilePhotos'))


  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    let files = Array.from(event.target.files)
    setProfilePhotos(items => {
      items = [...items, ...files]
      if (items.length > MAX_IMAGE_UPLOAD) alert(`You can upload maximum ${MAX_IMAGE_UPLOAD} images.`)
      items = items.slice(0, MAX_IMAGE_UPLOAD)
      return items
    })
  }


  const handleImageRemove = (index: number) => {
    setProfilePhotos(items => items.filter((item, itemIndex) => index !== itemIndex))
    if (fileInputRef?.current?.value) fileInputRef.current.value = ''
  }


  const onSubmit = async (data: FormData) => {
    if (profilePhotos.length === 0) {
      toast.error('Atleast one upload')
      return
    }
    try {
      let finalImages: any = []
      const formData = new FormData()
      formData.append('folder', 'TenantImages')
      profilePhotos.forEach(item => (item instanceof File) ? formData.append('files', item) : finalImages.push(item))

      if (formData.getAll('files').length > 0) {
        const uploadResponse = await dispatch(uploadFileThunk(formData)).unwrap()
        finalImages = finalImages.concat(uploadResponse.data.urls)
      }

      setProfilePhotos(finalImages)
      setValue('profilePhotos', finalImages)

      const finalData = getValues()

      dispatch(updateTenantProfileThunk({ ...user?.profile, ...finalData })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          navigate('/tenant/profile/vehicles')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))
      // dispatch(setprofileOnboardFormData(finalData))
      // navigate('/tenant/profile/step-7')
    }
    catch (error) { }
  }


  return (
    <Layout title='Tenant Step 6' user={true} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress="60"

        headerActionTitle=""
        headerActionButtonText="Skip"
        headerActionButtonLink="/tenant/profile/vehicles"

        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Upload Photo' description="Uploading a photo of yourself will give you a much higher chance of being chosen for a property." />

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className='g-3'>
                <Col md={12} xs={6}>
                <Form.Group controlId='upload' className='h-100'>
                  <StyledUploadImage className='center position-relative rounded border px-2 py-3 border-dashed h-100  bg-light'>
                    <Stack gap={1} className='center'>
                      <Form.Control type='file' accept='image/*' title='' tabIndex={-1} multiple className='position-absolute inset-0 opacity-0' onChange={handleImageChange} ref={fileInputRef} />
                      <MdAdd className='fs-1 text-secondary' />
                      <Typography>Upload Photo</Typography>
                    </Stack>
                  </StyledUploadImage>
                </Form.Group>
                </Col>
                {profilePhotos && Boolean(profilePhotos?.length) && profilePhotos.map((item, index) =>
                  <StyledImageBox as={Col} xs={6} sm={6} md={4} lg={3} key={index}>
                    <div className='h-100 border rounded overflow-hidden position-relative animate__animated animate__fadeIn'>
                      <div className='tools d-flex gap-1'>
                        <Button className='bg-body' $variant='text' $icon type='button' onClick={() => handleImageRemove(index)}>
                          <MdClose className='fs-6' />
                        </Button>
                      </div>
                      <img key={index} src={typeof item === 'object' ? URL.createObjectURL(item) : item} className='w-100 h-100 cursor-pointer cover-photo' />
                    </div>
                  </StyledImageBox>
                )}
              </Row>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' $loading={status === THUNK_STATUS.LOADING} type='submit'>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}





{/* == Validation == */ }
const schema = yup.object({
  profilePhotos: yup.array().of(yup.string().url()).default([]),
})

type FormData = yup.InferType<typeof schema>