import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import Cookies from 'js-cookie'



export function handleError(error: AxiosError | any) {
  console.log(error)
  let showToast = true
  let message: string | undefined;

  if (error.request.status === 401) {
    Cookies.remove('token')
    showToast = false
    if (!window.location.href.includes('/auth/login')) window.location.href = '/auth/login'
  }
  else if (error.code === "ERR_NETWORK") message = error.message + ' ' + `Server Isn't Running`
  else if (error.request.status >= 400 && error.request.status <= 499) { message = error.response?.data?.message }
  else if (error.request.status === 0) message = 'Something went wrong with server'
  else if (error.request.status > 499) message = 'Something went wrong with server'

  console.log(error.request.status)
  message = message || error.message
  showToast && toast.error(message as string, { id: message })
  console.error(`😲 OMG Api Failed - Details:`, { apiRoute: error?.config?.url, error })
  return Promise.reject(error)
}


// This function is work get place id find from full address
export const getPlaceIdFromLocation = async (params: { name: string }): Promise<string | null> => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'X-Goog-FieldMask': 'places.id,places.displayName,places.formattedAddress,places.priceLevel',
  };

  if (process.env.REACT_APP_GOOGLE_API_KEY) {
    headers['X-Goog-Api-Key'] = process.env.REACT_APP_GOOGLE_API_KEY;
  }

  const requestData = { textQuery: params.name };
  try {
    const response = await fetch('https://places.googleapis.com/v1/places:searchText', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData?.places[0]?.id;
  } catch (error) {
    console.error('Error:', (error as Error).message);
    return null;
  }
};
