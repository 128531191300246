import { useMemo } from "react"
import { OtpInputProps } from "./OtpInput.interface"
import { StyledOtpInput } from "./OtpInput.style"
import { Form } from "react-bootstrap"



export default function OtpInput({ value, valueLength, onChange }: OtpInputProps) {
  const RE_DIGIT = new RegExp(/^\d+$/)

  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items: Array<string> = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]
      if (RE_DIGIT.test(char)) items.push(char)
      else { items.push('') }
    }

    return items
  }, [value, valueLength])


  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null
    if (nextElementSibling) nextElementSibling.focus()
  }


  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null
    if (previousElementSibling) previousElementSibling.focus()
  }


  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue)
    if (!isTargetValueDigit && targetValue !== '') return
    targetValue = isTargetValueDigit ? targetValue : ' '

    if (targetValue.length === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)
      onChange(newValue)
      if (!isTargetValueDigit) return
      focusToNextInput(target)
      const nextElementSibling = target.nextElementSibling as HTMLInputElement | null
      if (nextElementSibling) nextElementSibling.focus()
    }
    else {
      onChange(targetValue)
      target.blur()
    }
  }


  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const target = e.target as HTMLInputElement

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value
    target.setSelectionRange(0, targetValue.length)
    if (e.key !== 'Backspace' || targetValue !== '') return
    focusToPrevInput(target)
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null
    if (previousElementSibling) previousElementSibling.focus()
  }


  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null
    if (prevInputEl && prevInputEl.value === '') return prevInputEl.focus()
    target.setSelectionRange(0, target.value.length)
  }


  return (
    <StyledOtpInput>
      {valueItems.map((digit, idx) =>
        <Form.Control key={idx} type="text" inputMode="numeric" autoComplete="one-time-code" pattern="\d{1}" className="otp-input" maxLength={valueLength} value={digit} onKeyDown={inputOnKeyDown} onFocus={inputOnFocus} onChange={(e: any) => inputOnChange(e, idx)} />
      )}
    </StyledOtpInput>
  )
}