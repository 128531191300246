import { Outlet, RouteObject } from "react-router-dom";

import SignIn from "../pages/auth/login/Login.page";
import RegisterTenant from "../pages/auth/register/tenant/tenant.page";
import RegisterLandlord from "../pages/auth/register/landlord/landlord.page";
import RegisterStep1 from "../pages/auth/register/step-1/Step1.page";
import RegisterStep2 from "../pages/auth/register/step-2/Step2.page";
import RegisterStep3 from "../pages/auth/register/step-3/Step3.page";
import RegisterStep4 from "../pages/auth/register/step-4/Step4.page";
import RegisterStep5 from "../pages/auth/register/step-5/Step5.page";
import ForgotPassword from "../pages/auth/forgot-password/ForgotPassword.page";
import VerifyOtp from "../pages/auth/verify-otp/VerifyOtp.page";
import AgentRegister from "../pages/auth/agent/Register.page";
import ResetPassword from "../pages/auth/reset-password/ResetPassword.component";
import AuthRoutes from "./components/AuthRoutes";
import AppleStep1 from "../pages/auth/apple-login/step-1/AppleStep1.page";
import AppleStep2 from "../pages/auth/apple-login/step-2/AppleStep2.page";
import UserNotFound from "../pages/auth/user-not-found/UserNotFound.page";
import GoogleStep1 from "../pages/auth/google-login/step-1/GoogleStep1.page";
import GoogleStep2 from "../pages/auth/google-login/step-2/GoogleStep2.page";
import ProfileOnBoardStep1 from "../pages/tenant/profile/location/Location.page";
import ProfileOnBoardStep2 from "../pages/tenant/profile/budget/Budget.page";
import ProfileOnBoardStep3 from "../pages/tenant/profile/family/Family.page";
import ProfileOnBoardStep4 from "../pages/tenant/profile/salary/Salary.page";
import ProfileOnBoardStep5 from "../pages/tenant/profile/current-status/CurrentStatus.page";

export const authRoutes: RouteObject[] = [
  {
    path: "/auth",
    element: (
      <AuthRoutes>
        <Outlet />
      </AuthRoutes>
    ),
    children: [
      { path: "login", element: <SignIn /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "verify-otp", element: <VerifyOtp /> },
      { path: "user-not-found", element: <UserNotFound /> },
      {
        path: "register",
        children: [
          { path: "tenant", element: <RegisterTenant /> },
          { path: "landlord", element: <RegisterLandlord /> },
          { path: "step-1", element: <RegisterStep1 /> },
          { path: "step-2", element: <RegisterStep2 /> },
          { path: "step-3", element: <RegisterStep3 /> },
          { path: "step-4", element: <RegisterStep4 /> },
          { path: "step-5", element: <RegisterStep5 /> },
        ],
      },
      {
        path: "onboard/tenant",
        children: [
          { path: "location", element: <ProfileOnBoardStep1 /> },
          { path: "weekly-budget", element: <ProfileOnBoardStep2 /> },
          { path: "family-member", element: <ProfileOnBoardStep3 /> },
          { path: "salary", element: <ProfileOnBoardStep4 /> },
          { path: "current-status", element: <ProfileOnBoardStep5 /> },
        ],
      },
      {
        path: "apple",
        children: [
          { path: "step-1", element: <AppleStep1 /> },
          { path: "step-2", element: <AppleStep2 /> },
        ],
      },
      {
        path: "google",
        children: [
          { path: "step-1", element: <GoogleStep1 /> },
          { path: "step-2", element: <GoogleStep2 /> },
        ],
      },
      {
        path: "agent",
        children: [{ path: "register", element: <AgentRegister /> }],
      },
    ],
  },
];
