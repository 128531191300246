import { createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { Status } from '../../../interfaces/Status.type'
import { adminAccountsDataThunk, adminAllBulkEmailThunk, adminAllPropertiesThunk, adminAttachmentThunk, adminBulkEmailSendThunk, adminPlanUsersThunk, adminUpdatePropertyStatusThunk, adminUpdateTenantStatusThunk, adminUserAccountsLoginThunk, adminUserDetailByIdThunk, propertyVerficationThunk, tenantVerficationThunk, adminGetUserSessionsThunk, adminUserAccountsThunk } from '../actions/admin.action'
import { TenantProfileDTO } from '../../../dtos/TenantProfile.dto'
import { PropertyDTO } from '../../../dtos/Property.dto'
import { saveUser } from './auth.slice'
import { UserDTO } from '../../../dtos/User.dto'



/* == Initial State == */
const initialState: IAdminSlice = {
  tenantVerification: {
    status: '',
    data: [],
    total: 0,
  },
  propertyVerification: {
    status: '',
    data: [],
    total: 0,
  },
  allProperties: {
    status: '',
    data: [],
    total: 0,
  },
  updatePropertyStatus: {
    status: '',
    data: null,
  },
  planUsers: {
    status: '',
    data: [],
    total: 0,
  },
  accountData: {
    status: '',
    data: [],
    total: 0,
  },
  userAccounts: {
    status: '',
    data: [],
    total: 0,
  },
  userDetail: {
    status: '',
    data: [],
  },
  userSessions: {
    status: '',
    data: [],
  },
  updateTenantStatus: {
    status: '',
    data: null,
  },
  adminAttachment: {
    status: '',
  },
  adminBulkEmailSend: {
    status: '',
  },
  allBulkEmail: {
    status: '',
    data: [],
    total: 0,
  },
  adminUserAccountLogin: {
    status: '',
    data: []
  },
}


/* == Slice == */
export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},


  extraReducers: (builder) => {

    {/* Tenant Verfication */ }
    builder.addCase(tenantVerficationThunk.pending, (state, action) => {
      state.tenantVerification.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantVerficationThunk.fulfilled, (state, action) => {
      state.tenantVerification.status = THUNK_STATUS.SUCCESS
      state.tenantVerification.data = action.payload.data.info.result
      state.tenantVerification.total = action.payload.data.info.totalRecords
    })

    builder.addCase(tenantVerficationThunk.rejected, (state, action) => {
      state.tenantVerification.status = THUNK_STATUS.FAILED
    })


    {/* Properties Verfication */ }
    builder.addCase(propertyVerficationThunk.pending, (state, action) => {
      state.propertyVerification.status = THUNK_STATUS.LOADING
    })

    builder.addCase(propertyVerficationThunk.fulfilled, (state, action) => {
      state.propertyVerification.status = THUNK_STATUS.SUCCESS
      state.propertyVerification.data = action.payload.data.info.result
      state.propertyVerification.total = action.payload.data.info.totalRecords
    })

    builder.addCase(propertyVerficationThunk.rejected, (state, action) => {
      state.propertyVerification.status = THUNK_STATUS.FAILED
    })


    {/* All Properties */ }
    builder.addCase(adminAllPropertiesThunk.pending, (state, action) => {
      state.allProperties.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminAllPropertiesThunk.fulfilled, (state, action) => {
      state.allProperties.status = THUNK_STATUS.SUCCESS
      state.allProperties.data = action.payload.data.info.result
      state.allProperties.total = action.payload.data.info.totalRecords
    })

    builder.addCase(adminAllPropertiesThunk.rejected, (state, action) => {
      state.allProperties.status = THUNK_STATUS.FAILED
    })


    {/* Update Property Status */ }
    builder.addCase(adminUpdatePropertyStatusThunk.pending, (state, action) => {
      state.updatePropertyStatus.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminUpdatePropertyStatusThunk.fulfilled, (state, action) => {
      state.updatePropertyStatus.status = THUNK_STATUS.SUCCESS
      state.updatePropertyStatus.data = action.payload.data
    })

    builder.addCase(adminUpdatePropertyStatusThunk.rejected, (state, action) => {
      state.updatePropertyStatus.status = THUNK_STATUS.FAILED
    })


    {/* Plan Users */ }
    builder.addCase(adminPlanUsersThunk.pending, (state, action) => {
      state.planUsers.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminPlanUsersThunk.fulfilled, (state, action) => {
      state.planUsers.status = THUNK_STATUS.SUCCESS
      state.planUsers.data = action.payload.data.info.result
      state.planUsers.total = action.payload.data.info.totalRecords
    })

    builder.addCase(adminPlanUsersThunk.rejected, (state, action) => {
      state.planUsers.status = THUNK_STATUS.FAILED
    })


    {/* Account Card */ }
    builder.addCase(adminAccountsDataThunk.pending, (state, action) => {
      state.accountData.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminAccountsDataThunk.fulfilled, (state, action) => {
      state.accountData.status = THUNK_STATUS.SUCCESS
      state.accountData.data = action.payload.data.info
    })

    builder.addCase(adminAccountsDataThunk.rejected, (state, action) => {
      state.accountData.status = THUNK_STATUS.FAILED
    })


    {/* Accounts Users */ }
    builder.addCase(adminUserAccountsThunk.pending, (state, action) => {
      state.userAccounts.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminUserAccountsThunk.fulfilled, (state, action) => {
      state.userAccounts.status = THUNK_STATUS.SUCCESS
      state.userAccounts.data = action.payload.data.info.result
    })

    builder.addCase(adminUserAccountsThunk.rejected, (state, action) => {
      state.userAccounts.status = THUNK_STATUS.FAILED
    })


    {/* User Detail Users */ }
    builder.addCase(adminUserDetailByIdThunk.pending, (state, action) => {
      state.userDetail.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminUserDetailByIdThunk.fulfilled, (state, action) => {
      state.userDetail.status = THUNK_STATUS.SUCCESS
      state.userDetail.data = action.payload.data.user_info
    })

    builder.addCase(adminUserDetailByIdThunk.rejected, (state, action) => {
      state.userDetail.status = THUNK_STATUS.FAILED
    })

    {/* Session Log */ }
    builder.addCase(adminGetUserSessionsThunk.pending, (state, action) => {
      state.userSessions.status = THUNK_STATUS.LOADING
    })
    builder.addCase(adminGetUserSessionsThunk.fulfilled, (state, action) => {
      state.userSessions.status = THUNK_STATUS.SUCCESS
      state.userSessions.data = action.payload.data.info.result
    })
    builder.addCase(adminGetUserSessionsThunk.rejected, (state, action) => {
      state.userSessions.status = THUNK_STATUS.FAILED
    })


    {/* Update Tenant Status */ }
    builder.addCase(adminUpdateTenantStatusThunk.pending, (state, action) => {
      state.updateTenantStatus.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminUpdateTenantStatusThunk.fulfilled, (state, action) => {
      state.updateTenantStatus.status = THUNK_STATUS.SUCCESS
      state.updateTenantStatus.data = action.payload.data
    })

    builder.addCase(adminUpdateTenantStatusThunk.rejected, (state, action) => {
      state.updateTenantStatus.status = THUNK_STATUS.FAILED

    })

    {/*  Admin Attachment */ }
    builder.addCase(adminAttachmentThunk.pending, (state, action) => {
      state.adminAttachment.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminAttachmentThunk.fulfilled, (state, action) => {
      state.adminAttachment.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(adminAttachmentThunk.rejected, (state, action) => {
      state.adminAttachment.status = THUNK_STATUS.FAILED

    })

    {/*  Admin Bulk Email Send */ }
    builder.addCase(adminBulkEmailSendThunk.pending, (state, action) => {
      state.adminBulkEmailSend.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminBulkEmailSendThunk.fulfilled, (state, action) => {
      state.adminBulkEmailSend.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(adminBulkEmailSendThunk.rejected, (state, action) => {
      state.adminBulkEmailSend.status = THUNK_STATUS.FAILED

    })


    {/* All Bulk Email */ }
    builder.addCase(adminAllBulkEmailThunk.pending, (state, action) => {
      state.allBulkEmail.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminAllBulkEmailThunk.fulfilled, (state, action) => {
      state.allBulkEmail.status = THUNK_STATUS.SUCCESS
      state.allBulkEmail.data = action.payload.data.info.result
      state.allBulkEmail.total = action.payload.data.info.totalRecords
    })

    builder.addCase(adminAllBulkEmailThunk.rejected, (state, action) => {
      state.allBulkEmail.status = THUNK_STATUS.FAILED
    })


    {/* admin user account login  */ }
    builder.addCase(adminUserAccountsLoginThunk.pending, (state, action) => {
      state.adminUserAccountLogin.status = THUNK_STATUS.LOADING
    })

    builder.addCase(adminUserAccountsLoginThunk.fulfilled, (state, action) => {
      state.adminUserAccountLogin.status = THUNK_STATUS.SUCCESS
      const userInfo: UserDTO = action.payload.user_info
      if (userInfo.isVerified) saveUser(userInfo?.token as string)
    })

    builder.addCase(adminUserAccountsLoginThunk.rejected, (state, action) => {
      state.adminUserAccountLogin.status = THUNK_STATUS.FAILED
    })
  }
})



/* == Interface == */
interface IAdminSlice {
  tenantVerification: {
    status: Status
    data: ITenantVerificationDTO[]
    total: number
  }
  propertyVerification: {
    status: Status
    data: PropertyDTO[]
    total: number
  }
  allProperties: {
    status: Status
    data: any
    total: number
  }
  updatePropertyStatus: {
    status: Status,
    data: any | null,
  }
  planUsers: {
    status: Status
    data: any
    total: number
  }
  accountData: {
    status: Status
    data: any
    total: number
  }
  userAccounts: {
    status: Status
    data: any
    total: number
  }
  userDetail: {
    status: Status
    data: any
  }
  userSessions: {
    status: Status
    data: any
  }
  updateTenantStatus: {
    status: Status
    data: any | null
  }
  adminAttachment: {
    status: Status
  }
  adminBulkEmailSend: {
    status: Status
  }
  allBulkEmail: {
    status: Status
    data: any
    total: number
  }
  adminUserAccountLogin: {
    status: Status
    data: any
  }

}


export interface ITenantVerificationDTO {
  createdAt: string
  email: string
  isVerified: boolean
  name: string
  phone: string
  profile: "64d6ae94ecdb66e838d444f8"
  updatedAt: "2023-08-11T21:56:36.255Z"
  userProfile: TenantProfileDTO
  userRoles: any
  _id: string
}


export const { } = adminSlice.actions
export default adminSlice.reducer
