import { ChangeEvent, useState, useRef, useEffect } from 'react'
import { PropertyFormProps } from './PropertyForm.interface'
import { Col, Row, Form, Stack } from 'react-bootstrap'
import { MdHotel, MdOutlineBathtub, MdDirectionsCar, MdClose, MdOutlineFileUpload } from "react-icons/md"
import { BiDollarCircle } from "react-icons/bi"
import { StyledImageBox, StyledUpload } from './PropertyForm.style'
import { yupResolver } from '@hookform/resolvers/yup'
import { getPlaceIdFromLocation, numberTest, onlyNumberTest, stringTest } from '../../../../utils'
import { useForm, Controller } from 'react-hook-form'
import { propertyAddThunk, updatePropertyThunk } from '../../../../lib/redux/actions/property.action'
import { uploadFileThunk } from '../../../../lib/redux/actions/upload.action'
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux'
import { useNavigate, useParams } from 'react-router-dom'
import { geocodeByPlaceId, geocodeByAddress } from 'react-google-places-autocomplete'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import Typography from '../../../../components/_ui/typography/Typography.component'
import Button from '../../../../components/_ui/button/Button.component'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import MapAutocomplete from '../../../../components/_ui/mapAutocomplete/MapAutocomplete.component'
import Card from '../../../../components/_ui/card/Card.component'



export default function PropertyForm(props: PropertyFormProps) {
  const { mode = 'add' } = props
  const MAX_IMAGE_UPLOAD = 5
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { propertyId } = useParams()
  const { data } = useReduxSelector(state => state.property.propertyDetails)
  const fileInputRef = useRef<null | HTMLInputElement>(null)
  const [loading, setLoading] = useState({
    form: false,
    address: false
  })


  const { handleSubmit, register, formState: { errors, isSubmitted }, control, setValue, watch, getValues, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: mode === 'add' ?
      {
        id: null,
        address: {
          isManual: false,
          country: 'Australia',
        },
        image: []
      }
      :
      {
        id: propertyId,
        image: data?.image || [],
        description: data?.description,
        suburb: data?.suburb,
        numberOfParking: data?.numberOfParking,
        numberOfRoom: data?.numberOfRoom,
        numberOfWashroom: data?.numberOfWashroom,
        propertyPrice: data?.propertyPrice,
        address: {
          name: data?.address.name,
          isManual: data?.address?.isManual,
          city: data?.address.city,
          state: data?.address.state,
          placeId: data?.address.placeId,
          country: data?.address.country,
          latitude: data?.location.coordinates[1],
          longitude: data?.location.coordinates[0],
          postcode: data?.address?.postcode,
        }
      }
  })



  const [isManualAddress, setIsManualAddress] = useState(getValues('address.isManual'))
  const [images, setImages] = useState<any[]>(getValues('image'))


  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    let files = Array.from(event.target.files)
    setImages(items => {
      items = [...items, ...files]
      if (items.length > MAX_IMAGE_UPLOAD) alert(`You can upload maximum ${MAX_IMAGE_UPLOAD} images.`)
      items = items.slice(0, MAX_IMAGE_UPLOAD)
      return items
    })
  }


  const handleImageRemove = (index: number) => {
    setImages(items => items.filter((item, itemIndex) => index !== itemIndex))
    if (fileInputRef?.current?.value) fileInputRef.current.value = ''
  }


  const clearAddress = () => {
    setValue('address.name', '')
    setValue('address.city', '')
    setValue('address.state', '')
    setValue('address.placeId', '')
    setValue('address.postcode', '')
    setValue('address.latitude', null)
    setValue('address.longitude', null)
    setValue('suburb', undefined)
    if (isSubmitted) trigger()
  }


  const handlePlaceSelected = async (data: any) => {
    if (!data) return clearAddress()
    setLoading(items => ({ ...items, address: true }))

    try {
      let postcode: string = ''
      const [response] = await geocodeByPlaceId(data.value.place_id)
      setValue('address.name', data.label)
      setValue('address.latitude', response.geometry.location.lat())
      setValue('address.longitude', response.geometry.location.lng())
      setValue('address.placeId', response.place_id)
      trigger('address.name')

      for (const component of response.address_components) {
        if (component.types.includes('locality')) setValue('address.city', component.long_name)
        else if (component.types.includes('administrative_area_level_1')) setValue('address.state', component.long_name)
        else if (component.types.includes('country')) setValue('address.country', component.long_name)
        else if (component.types.includes('postal_code')) postcode = component.long_name
      }
      setValue('suburb', [{ name: `${getValues('address.city')}`, postcode }])
      setValue('address.postcode', postcode)
    }
    catch (error) {
      console.log('Google Map API Error @geocodeByPlaceId: ', error)
      toast.error('Something went wrong with address')
    }
    finally { setLoading(items => ({ ...items, address: false })) }
  }


  const onSubmit = async (data: FormData) => {
    try {
      setLoading(items => ({ ...items, form: true }))

      if (data?.address?.isManual) {
        const fullAddress = `${data.address.name}, ${data.address.city}, ${data.address.state}, ${data.address.country}`
        try {
          await geocodeByAddress(fullAddress)
            .then(([response]) => {
              if (!response) throw `Unable to find address`
              setValue('address.latitude', response.geometry.location.lat())
              setValue('address.longitude', response.geometry.location.lng())
              setValue('address.placeId', response.place_id)
            })
        }
        catch (err) {
          console.log("something went wrong with geocode By address ", err)
        }
      }

      let finalImages: string[] = []
      const formData = new FormData()
      formData.append('folder', 'PropertyImage')
      images.forEach(item => (item instanceof File) ? formData.append('files', item) : finalImages.push(item))

      if (formData.getAll('files').length > 0) {
        const uploadResponse = await dispatch(uploadFileThunk(formData)).unwrap()
        finalImages = finalImages.concat(uploadResponse.data.urls)
      }

      setImages(finalImages)
      setValue('image', finalImages)

      let addressString = `${data?.address?.city} ${data?.address?.state} ${data?.address?.country}`;
      let getPlaceIdFromGoogleApi = await getPlaceIdFromLocation({ name: addressString })

      if (!!getPlaceIdFromGoogleApi) {
        const finalData = getValues()
        setValue('address.placeId', getPlaceIdFromGoogleApi)
        const resData = await dispatch(mode === 'edit' ? updatePropertyThunk(finalData) : propertyAddThunk(finalData)).unwrap()
        toast.success(mode === 'edit' ? 'Property updated' : 'Property added')
        navigate('/properties/verify/' + resData.data.property_info._id)
      } else {
        toast.error('Please fill property address available on google map.')
      }
    }
    catch (error) { console.error('An error occurred:', error) }
    finally { setLoading(items => ({ ...items, form: false })) }
  }


  const isFieldDisabledPropertyActive = () => {
    if (Boolean(data?.status === 'verified' && mode === 'edit')) {
      return true
    }
    return false

  }


  return (
    <Form className='d-flex flex-column' noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        {/* Address */}
        <div className='position-relative z-1'>
          {isManualAddress ?
            <Card className='animate__animated animate__fadeIn gap-3 d-flex flex-column border mb-1'>
              <Typography $variant='subtitle1' className='text-body-secondary'><span>Enter Property Address Manually</span></Typography>
              <Form.Group controlId='address'>
                <Form.Label>Address</Form.Label>
                <Form.Control type='text' className='fw-medium' disabled={isFieldDisabledPropertyActive()} isInvalid={Boolean(errors.address?.name)} {...register('address.name')} />
                <Form.Text className='text-danger'>{errors.address?.name?.message}</Form.Text>
              </Form.Group>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group controlId='country'>
                    <Form.Label>Country</Form.Label>
                    <Form.Control type='text' className='fw-medium' disabled isInvalid={Boolean(errors.address?.country)} {...register('address.country')} />
                    <Form.Text className='text-danger'>{errors.address?.country?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group controlId='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control type='text' className='fw-medium' disabled={isFieldDisabledPropertyActive()} isInvalid={Boolean(errors.address?.state)} {...register('address.state')} />
                    <Form.Text className='text-danger'>{errors.address?.state?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group controlId='city'>
                    <Form.Label>City</Form.Label>
                    <Form.Control type='text' className='fw-medium' disabled={isFieldDisabledPropertyActive()} isInvalid={Boolean(errors.address?.city)} {...register('address.city')} />
                    <Form.Text className='text-danger'>{errors.address?.city?.message}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group controlId='postcode'>
                    <Form.Label>Post Code</Form.Label>
                    <Form.Control type='text' className='fw-medium' disabled={isFieldDisabledPropertyActive()} isInvalid={Boolean(errors.address?.postcode)} {...register('address.postcode')} />
                    <Form.Text className='text-danger'>{errors.address?.postcode?.message}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card>
            :
            <Form.Group controlId='name' className='animate__animated animate__fadeIn'>
              <label className='w-100 d-block'>
                <Form.Label as='div'>Select property address</Form.Label>
                <Controller
                  name="address.name"
                  control={control}
                  render={({ field: { value, onChange, ...items } }) => (
                    <MapAutocomplete
                      isInvalid={Boolean(errors.address?.name)}
                      selectProps={{
                        ...items as any,
                        isDisabled: isFieldDisabledPropertyActive(),
                        value: value ? { label: value, value: { place_id: value } } : undefined,
                        defaultOptions: value ? [{ label: value, value: { place_id: value } }] : undefined,
                        onChange: handlePlaceSelected,
                        isClearable: true,
                        isLoading: loading.address,
                      }}
                    />
                  )}
                />
              </label>
            </Form.Group>
          }

          <div className='d-flex justify-content-between gap-5'>
            <Form.Text className='text-danger'>{!isManualAddress && errors.address?.name?.message}</Form.Text>
            <Form.Check type="switch" id="manual-address" disabled={isFieldDisabledPropertyActive()} reverse className='text-primary cursor-pointer fw-normal m-0 mt-1' onClick={event => { setIsManualAddress(event.currentTarget.checked); clearAddress() }} {...register('address.isManual')}
              label={
                <Typography $variant='body2'>Can't find your property?&nbsp; <Typography $variant='body2' as='span' className='text-primary'>Enter Manually</Typography></Typography>
              }
            />
          </div>
        </div>


        {/* Image */}
        <Form.Group controlId='upload'>
          <Form.Label>Upload some photos of the property</Form.Label>
          <StyledUpload className='center position-relative rounded'>
            <Stack gap={1} className='center'>
              <Form.Control type='file' accept="image/*" title='' tabIndex={-1} multiple className='position-absolute inset-0 opacity-0' onChange={handleImageChange} ref={fileInputRef} />
              <div className='p-3 bg-light rounded-circle'>
                <MdOutlineFileUpload className='fs-3 text-secondary' />
              </div>
              <Typography>Drag & Drop or &nbsp;<Typography as='span' className='link text-primary'>Choose Image</Typography>&nbsp; to upload</Typography>
              <div className='text-black-50'>Maximum image size 1MB</div>
            </Stack>
          </StyledUpload>
        </Form.Group>

        {Boolean(images.length) &&
          <Row className='row-gap-3'>
            {images.map((item, index) =>
              <StyledImageBox as={Col} xs={6} md={3} key={index}>
                <div className='h-100 border rounded overflow-hidden position-relative animate__animated animate__fadeIn'>
                  <div className='tools d-flex gap-1'>
                    <Button className='bg-body' $variant='text' $icon type='button' onClick={() => handleImageRemove(index)}>
                      <MdClose className='fs-6' />
                    </Button>
                  </div>

                  <img key={index} src={typeof item === 'object' ? URL.createObjectURL(item) : item} className='w-100 h-100' />
                </div>
              </StyledImageBox>
            )}
          </Row>
        }


        <Row className='mt-2 row-gap-4'>
          <Col xs={12} md={3}>
            <Stack gap={3}>

              {/* Rooms */}
              <div>
                <Form.Group className='d-flex align-items-center flex-nowrap gap-4'>
                  <MdHotel className='fs-3 text-dark flex-shrink-0' />
                  <FloatingLabel controlId='numberOfRoom' label='Bedrooms'>
                    <Form.Control type='number' disabled={isFieldDisabledPropertyActive()} className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.numberOfRoom)} {...register('numberOfRoom')} />
                  </FloatingLabel>
                </Form.Group>
                {errors.numberOfRoom && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.numberOfRoom.message}</Form.Text>}
              </div>

              {/* Washrooms */}
              <div>
                <Form.Group className='d-flex align-items-center flex-nowrap gap-4'>
                  <MdOutlineBathtub className='fs-3 text-dark flex-shrink-0' />
                  <FloatingLabel controlId='numberOfWashroom' label='Bathrooms'>
                    <Form.Control type='number' disabled={isFieldDisabledPropertyActive()} className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.numberOfWashroom)} {...register('numberOfWashroom')} />
                  </FloatingLabel>
                </Form.Group>
                {errors.numberOfWashroom && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.numberOfWashroom.message}</Form.Text>}
              </div>

              {/* Parking */}
              <div>
                <Form.Group className='d-flex align-items-center flex-nowrap gap-4'>
                  <MdDirectionsCar className='fs-3 text-dark flex-shrink-0' />
                  <FloatingLabel controlId='numberOfParking' label='Parking'>
                    <Form.Control type='number' disabled={isFieldDisabledPropertyActive()} className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.numberOfParking)} {...register('numberOfParking')} />
                  </FloatingLabel>
                </Form.Group>
                {errors.numberOfParking && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.numberOfParking.message}</Form.Text>}
              </div>

              {/* Price per week */}
              <div>
                <Form.Group className='d-flex align-items-center flex-nowrap gap-4'>
                  <BiDollarCircle className='fs-3 text-dark flex-shrink-0' />
                  <FloatingLabel controlId='propertyPrice' label='Price per week'>
                    <Form.Control type='number' disabled={isFieldDisabledPropertyActive()} className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.propertyPrice)} {...register('propertyPrice')} />
                  </FloatingLabel>
                </Form.Group>
                {errors.propertyPrice && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.propertyPrice.message}</Form.Text>}
              </div>
            </Stack>
          </Col>

          {/* Description */}
          <Col xs={12} md={9}>
            <div className='h-100'>
              <Form.Group className='h-100 d-flex flex-column'>
                <FloatingLabel controlId='description' label='Write a description about your property' className='h-100'>
                  <Form.Control as="textarea" placeholder='ignore' className='h-100-normal' isInvalid={Boolean(errors.description)} {...register('description')} style={{ height: 320 }} />
                </FloatingLabel>
                <Form.Text className='text-danger'>{errors.description?.message}</Form.Text>
              </Form.Group>
            </div>
          </Col>

        </Row>

      </Stack>


      {/* Submit */}
      <div className='d-flex justify-content-end mt-4'>
        <Button type='submit' $loading={loading.form}>Save and Continue</Button>
      </div>

    </Form>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  id: yup.string().nullable(),
  suburb: yup.array().of(
    yup.object({
      name: yup.string().required(),
      postcode: yup.string().required()
    })).min(1),
  image: yup.array().of(yup.string().url()).default([]),
  description: yup.string().trim().required().max(10000).test(stringTest),
  numberOfRoom: yup.number().required().max(10000).test(onlyNumberTest),
  numberOfWashroom: yup.number().required().max(10000).test(onlyNumberTest),
  numberOfParking: yup.number().required().max(10000).test(onlyNumberTest),
  propertyPrice: yup.number().required().min(50, 'Price per week cannot be less than $50').max(10000000).test(onlyNumberTest),
  address: yup.object({
    name: yup.string().trim().required('Address is required *').min(2, 'Enter valid address').max(200, 'Enter valid address').test('', 'Enter valid address', stringTest),
    isManual: yup.boolean().required(),
    country: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(stringTest),
      otherwise: schema => schema.notRequired()
    }),
    state: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(stringTest),
      otherwise: schema => schema.notRequired()
    }),
    city: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(stringTest),
      otherwise: schema => schema.notRequired()
    }),
    placeId: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().notRequired(),
      otherwise: schema => schema.notRequired()
    }),
    postcode: yup.string().when('isManual', {
      is: true,
      then: schema => schema.trim().required().min(2).max(100).test(numberTest),
      otherwise: schema => schema.notRequired()
    }),
    latitude: yup.number().nullable().when('isManual', {
      is: true,
      then: schema => schema.notRequired(),
      otherwise: schema => schema.notRequired()
    }),
    longitude: yup.number().nullable().when('isManual', {
      is: true,
      then: schema => schema.notRequired(),
      otherwise: schema => schema.notRequired()
    }),
  }),
})

type FormData = yup.InferType<typeof schema>