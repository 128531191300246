import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Button from '../button/Button.component';
import { FcGoogle } from "react-icons/fc";
import { useReduxDispatch } from '../../../hooks/redux';
import { GoogleSocialLoginApiResponseDTO } from './GoogleLogin.interface';
import { googleSocialLoginThunk } from '../../../lib/redux/actions/auth.action';
import { analytics, logEvent } from "../../../firebase";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { message } from '../../../utils';



export default function Googlelogin() {

  const dispatch = useReduxDispatch()
  const navigate = useNavigate()

  const googleLogin = useGoogleLogin({

    onSuccess: async (response) => {
      let { access_token } = response
      if (response.access_token) {
        dispatch(googleSocialLoginThunk({ id_token: access_token })).unwrap()
          .then((response: GoogleSocialLoginApiResponseDTO) => {

            const { isVerified, isGoogleLoggedIn, _id, token, name } = response?.data?.user_info
            if (!isVerified && isGoogleLoggedIn) {
              sessionStorage.setItem('id', _id)
              sessionStorage.setItem('token', token)
              if (!!(name)) {
                sessionStorage.setItem('fullName', name)
              }
              logEvent(analytics, 'register_tenant_google_signin', { type: message.SUCCESS })
              logEvent(analytics, 'user_registered')
              logEvent(analytics, 'conversion', {'send_to': 'AW-11419506857/H_24CNvw8qgZEKmxn8Uq'});
              
              navigate(`/auth/google/step-1`)
            }

          })
          .catch((err: any) => {
            logEvent(analytics, 'register_tenant_google_signin', { type: message.USER_NOT_FOUND })
            navigate(`/auth/user-not-found`)
            console.log(err)
          })
      }
    },
    onError: errorResponse => toast.error(errorResponse.error_description || message.SOMETHING_WENT_WRONG),

  });


  return (
    <div>
      <Button $variant="filled" className='w-100 center d-flex gap-1' type="button" onClick={() => googleLogin()}>
        <FcGoogle className='fs-5' />
        <span>Continue with Google</span>
      </Button>
    </div>
  )
}
