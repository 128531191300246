import { useEffect, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { MdLock } from 'react-icons/md'
import { IoMdEyeOff } from 'react-icons/io'
import { BsLightningChargeFill } from 'react-icons/bs'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'

import { theme } from '../../../../../../utils'
import { PlanType } from '../../../../../../interfaces/PlanType.type'
import { useReduxDispatch, useReduxSelector } from '../../../../../../hooks/redux'
import { THUNK_STATUS } from '../../../../../../lib/redux/constants/status.constant'
import { planByIdThunk, plansThunk } from '../../../../../../lib/redux/actions/plan.action'
import StripeForm from './stripeForm/StripeForm.component'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)



export default function StripePayment() {
  let data, status;

  const dispatch = useReduxDispatch()
  const { planById, plans } = useReduxSelector(state => state.plan)
  const { selectPlanDetail } = useReduxSelector(state => state.plan.checkoutModal)

  const [planId, setPlanId] = useState<string | null>(null)
  const [type, setType] = useState<PlanType | string | null>(null)


  if (type === 'boost') {
    data = planById.data
    status = planById.status
  }

  if (type === 'blue_tick') {
    data = plans.data?.[0]
    status = plans.status
  }


  const STRIPE_OPTIONS: StripeElementsOptions = {
    mode: 'payment',
    amount: data?.price ? (data?.price * 100) : undefined,
    currency: 'aud',
    locale: 'en-AU',
    loader: 'never',
    appearance: {
      theme: 'stripe',
      variables: {
        fontFamily: 'Plus Jakarta Sans',
        fontSizeBase: '14px',
        colorPrimary: theme.primary.main
      }
    },
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans'
      }
    ]
  }


  useEffect(() => {
    if (selectPlanDetail !== null) {
      setPlanId(selectPlanDetail?._id)
      setType(selectPlanDetail?.type)
    }

    return () => {
      setPlanId(null);
      setType(null)
    }
  }, [selectPlanDetail?._id, selectPlanDetail?.type])


  useEffect(() => {
    if (type && planById) {
      if (type === 'blue_tick') dispatch(plansThunk({ type: 'blue_tick' }))
      if (planId && type === 'boost') dispatch(planByIdThunk({ id: planId as string }))
    }
  }, [planId, type])


  return (
    <div>
      {status === THUNK_STATUS.SUCCESS && data &&
        <Elements stripe={stripePromise} options={STRIPE_OPTIONS}>
          <StripeForm planId={data._id} plan={data} type={type as PlanType} />
        </Elements>
      }

      {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
      {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}
    </div>
  )
}
