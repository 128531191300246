import { useState } from "react"
import { UpdateNumberFormProps } from "./UpdateNumberForm.interface"
import { Form } from "react-bootstrap"
import { useReduxDispatch, useReduxSelector } from "../../../../../hooks/redux"
import { handleUpdateNumber } from "../../../../../lib/redux/slices/auth.slice"
import { isValidPhoneNumber } from "react-phone-number-input"
import { THUNK_STATUS } from "../../../../../lib/redux/constants/status.constant"
import { resendOtpThunk } from "../../../../../lib/redux/actions/auth.action"
import PhoneInput from "../../../../../components/_ui/phoneInput/PhoneInput.component"
import Button from "../../../../../components/_ui/button/Button.component"



export default function UpdateNumberForm(props: UpdateNumberFormProps) {
  const dispatch = useReduxDispatch()
  const { auth } = useReduxSelector(state => state)
  const { user } = useReduxSelector(state => state.auth)
  const [phone, setPhone] = useState(user?.phone as string)
  const [isSubmitted, setIsSubmitted] = useState(false)


  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitted(true)
    if (!isValidPhoneNumber(phone)) return
    updateNumber(true)
  }


  const updateNumber = async (update: boolean) => {
    if (update && user) {
      await dispatch(resendOtpThunk({
        user_id: user._id,
        phone: phone
      }))
    }

    dispatch(handleUpdateNumber({ show: false, phone: phone }))
  }


  return (
    <Form noValidate onSubmit={onSubmit}>

      <div className="mb-3">
        <Form.Group className="w-100" controlId="phone">
          <Form.Label>Update your mobile number</Form.Label>
          <PhoneInput
            value={phone}
            className={(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}
            onChange={(value: any) => setPhone(value || '')}
            numberInputProps={{
              className: `form-control ${(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}`
            }}
          />
          <Form.Text className="text-danger">{isSubmitted && !isValidPhoneNumber(phone) && 'Enter valid phone'}</Form.Text>
        </Form.Group>
      </div>

      <div className="d-flex justify-content-between mt-5 gap-3">
        <Button $variant="text" type="button" onClick={() => updateNumber(false)}>Cancel</Button>
        <Button type="submit" $loading={auth.resendOtp.status === THUNK_STATUS.LOADING}>Update</Button>
      </div>
    </Form>
  )
}