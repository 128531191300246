import { css, styled } from 'styled-components'



interface IFeedbackPropsProps {
  isOpen: string;
}


export const StyledFeedback = styled.div<IFeedbackPropsProps>`
  position: fixed;
  bottom: 90px;
  width: 450px;
  right: 20px;
  transform: ${(props) => (props.isOpen == 'true' ? 'translateY(0px)' : 'translateY(600px)')};
  transition: 0.3s ease-in-out all;

  @media (max-width: 547px) {
    width: 100%;
    bottom: 0px;
    left: 0px;
    right: 0;
  }

`