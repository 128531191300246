import { useEffect, useState } from "react"
import { Form, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { setRegisterFormData } from "../../../../lib/redux/slices/auth.slice"
import { signUpThunk } from "../../../../lib/redux/actions/auth.action"
import { isValidPhoneNumber } from "react-phone-number-input"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import PhoneInput from "../../../../components/_ui/phoneInput/PhoneInput.component"
import Layout from "../../../../components/_layout/Layout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import Button from "../../../../components/_ui/button/Button.component"
import RegisterLayout from "../component/registerLayout/RegisterLayout.component"
import background_image from '../../../../assets/images/register/background_005.jpg';
import { userSystemInfo } from "../../../../utils/userSystemInfo"


export default function RegisterStep5() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { formData, status } = useReduxSelector(state => state.auth.register)
  const [phone, setPhone] = useState(formData.phone as string)


  useEffect(() => {
    if (!formData.userRoles) navigate('/auth/register/step-1')
  }, [])


  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitted(true)
    if (!isValidPhoneNumber(phone)) return
    dispatch(setRegisterFormData({ phone }))
    dispatch(signUpThunk({ ...formData, phone, userSystemInfo: userSystemInfo() }))
  }


  const handleChange = (value: any) => {
    setPhone(value || '')
    dispatch(setRegisterFormData({ phone }))
  }


  return (
    <Layout title='Register Step 5' user={false} header={false} footer={false}>

      <RegisterLayout
        //headerActionTitle="Already have an account?"
        //headerActionButtonText="Sign In"
        //headerActionButtonLink="/auth/login"
        progress="95"
        bannerText="You'll be in a new house soon!"
        columnFirst={
          <>
            {/* <Steps active={2} /> */}
            <div className='d-flex flex-column gap-1 mb-3'>
              <Typography $variant='body1'>Step 4</Typography>
              <Typography $variant="h3">Enter your mobile</Typography>
            </div>


            <Form noValidate onSubmit={onSubmit}>
              <div className="mb-3">
                <Form.Group className="w-100" controlId="phone">
                  <PhoneInput
                    value={phone}
                    onChange={handleChange}
                    placeholder={'04__ ___ ___'}
                    className={(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}
                    numberInputProps={{
                      className: `form-control fs-6 form-control-lg ${(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}`,
                      autoFocus: false,
                      
                    }}
                  />
                  <Form.Text className="text-danger">{isSubmitted && !isValidPhoneNumber(phone) && 'Enter valid phone'}</Form.Text>
                </Form.Group>
              </div>

              <Row>
                <Col>
                  <Button type='submit' $loading={status === THUNK_STATUS.LOADING} disabled={!isValidPhoneNumber(phone)} className='w-100'>Continue</Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3 text-center text-secondary opacity-50">
                  We will send you a verification SMS
                </Col>
              </Row>

            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout>
  )
}