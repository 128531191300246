import { styled } from 'styled-components'



export const StyledUploadImage = styled.div`
  transition: border 0.2s;
  
  &:hover{
    border-color: var(--primary-main) !important;
  }  
`


export const StyledImageBox = styled.div`
  aspect-ratio: 4/2.8;
  transition: border-color .125s;
  > *{
    &:hover{
      border-color: rgba(var(--bs-primary-rgb), 0.3) !important;
    }
  }

  .tools{
    position: absolute;
    inset: 5px 5px auto auto;
    z-index: 5;
  }
`