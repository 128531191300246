import { useEffect, useRef } from "react";
import { FaApple } from "react-icons/fa"
import { useNavigate } from "react-router-dom";
import { socialLoginThunk } from "../../../lib/redux/actions/auth.action";
import { useReduxDispatch } from "../../../hooks/redux";
import Button from "../button/Button.component"
import toast from "react-hot-toast";
import { socialLoginApiResponseDTO } from "./AppleAuthLogin.interface";
import config from "../../../config";
import { analytics, logEvent } from "../../../firebase";

export default function AppleAuthLogin() {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const appleSignInRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const scope = "name email";
    const state = "origin:web";
    const clientId = process.env.REACT_APP_APPLE_CLIENTID;
    const redirectURI = config.appleReturnUrl;

    if (window) {
      (window as any).AppleID.auth.init(
        { clientId, scope, redirectURI, state, usePopup: true }
      );

      // Listen for authorization success.
      document.addEventListener('AppleIDSignInOnSuccess', async (event) => {
        try {
          const data = await (window as any).AppleID.auth.signIn()
          console.log(event, data)
          const { id_token } = data.authorization

          if (id_token) {
            dispatch(socialLoginThunk({ id_token })).unwrap()
              .then((response: socialLoginApiResponseDTO) => {
                const { isVerified, isAppleLoggedIn, _id, token } = response?.data?.user_info
                if (!isVerified && isAppleLoggedIn) {
                  sessionStorage.setItem('id', _id)
                  sessionStorage.setItem('token', token)
                  if (!!(data?.user?.name?.firstName && data?.user?.name?.lastName)) {
                    sessionStorage.setItem('fullName', data?.user?.name?.firstName + ' ' + data?.user?.name?.lastName)
                  }
                  logEvent(analytics, 'register_tenant_apple_signin', { type: 'success' })
                  logEvent(analytics, 'user_registered')
                  logEvent(analytics, 'conversion', {'send_to': 'AW-11419506857/H_24CNvw8qgZEKmxn8Uq'});
                  
                  navigate(`/auth/apple/step-1`)
                }
              })
              .catch((err) => {
                logEvent(analytics, 'register_tenant_apple_signin', { type: 'user-not-found' })
                navigate(`/auth/user-not-found`)
              })
          }
        } catch (error) {
          console.log('error', error)
          if (error == "popup_blocked_by_browser") {
            logEvent(analytics, 'register_tenant_apple_signin', { type: 'popup-blocked-by-browser' })
            toast.error('Popup blocked by the browser. Please allow popups')
          }
        }
      })


      // Listen for authorization failures.
      document.addEventListener('AppleIDSignInOnFailure', (event: any) => {
        console.log('AppleIDSignInOnFailure error ====>', event?.detail.error)
      })
    }
  }, []);

  const signin = () => {
    logEvent(analytics, 'register_tenant_apple_signin', { type: 'submit' })
    appleSignInRef.current?.click()
  }


  return (
    <>
      <div style={{ display: 'none' }} id="appleid-signin" className="signin-button" data-color="black" data-border="true" data-type="sign-in" ref={appleSignInRef}></div>
      <Button $variant="filled" className='w-100 center d-flex gap-1' type="button" onClick={() => signin()}>
        <FaApple className='fs-5' />
        <span>Continue with Apple</span>
      </Button>
    </>
  );
}