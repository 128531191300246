import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Spinner, Table } from 'react-bootstrap'
import moment from 'moment';

import { limitRowSize } from '../../../../data/config';
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux';
import { adminAllBulkEmailThunk } from '../../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../../lib/redux/constants/status.constant';
import { convertToMelbourneTime } from '../../../../utils';
import SearchImg from '../../../../assets/images/search.svg'
import Card from '../../../../components/_ui/card/Card.component'
import Layout from '../../../../components/_layout/Layout.component'
import Alert from '../../../../components/_ui/alert/Alert.component'
import AdminLayout from '../../components/adminLayout/AdminLayout.component'
import BulkEmailNav from '../../components/bulkEmailNav/Nav.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import PaginationComponent from '../../../../components/pagination/PaginationComponent'
import NoRecordFound from '../../../../components/_ui/noRecordFound/NoRecordFound.component'



export default function AllProperties() {
  let limitSize = limitRowSize
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useReduxDispatch()
  const { allBulkEmail } = useReduxSelector(state => state.admin)


  useEffect(() => {
    dispatch(adminAllBulkEmailThunk({ page: currentPage, size: limitSize })).unwrap()
      .then((response) => {
        setData(response?.data?.info?.result);
        setTotalPages(Math.ceil(response?.data?.info?.totalRecords / limitSize) || 0)
      })
      .catch(err => console.log(err))
  }, [currentPage])


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  console.log(data, allBulkEmail)

  return (
    <Layout title='All Bulk Email' user={true}>
      <AdminLayout nav={<BulkEmailNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">All Bulk Email</Typography>
          <Typography $variant='body1'>Bulk Emails</Typography>
        </div>

        <Card>
          <Table striped responsive>
            <thead>
              <tr>
                <th>User Type</th>
                <th>subject</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {allBulkEmail.status === THUNK_STATUS.SUCCESS &&
                data.map((item: any, index) => (
                  <tr key={index}>
                    <td>{item?.targetType || 'Not Found'}</td>
                    <td>{item?.message?.subject || 'Not Found'}</td>
                    <td>{convertToMelbourneTime(item?.createdAt).format('L') || ''}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-center mb-3'>
            {allBulkEmail.status === THUNK_STATUS.SUCCESS && !Boolean(allBulkEmail.data.length) &&
              <NoRecordFound shadow='shadow-none' className='text-center w-100' image={SearchImg}>
                <Typography $variant='h4'>All Bulk Mail</Typography>
                <Typography $variant='body1'>No record found</Typography>
              </NoRecordFound>
            }
            {allBulkEmail.status === THUNK_STATUS.LOADING && <Spinner className='m-auto my-3 d-block' variant='secondary' />}
            {allBulkEmail.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
          </div>

          <div className='d-flex justify-content-end'>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>

        </Card>
      </AdminLayout>
    </Layout >
  )
}
