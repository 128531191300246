import { useState, FormEvent, useEffect } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { tenantPurchasePlanStatusThunk, tenantPurchasePlanThunk } from '../../../../../lib/redux/actions/plan.action'
import { CheckoutFormProps } from './CheckoutForm.interface'
import { useReduxDispatch } from '../../../../../hooks/redux'
import { Spinner, Stack } from 'react-bootstrap'
import Typography from '../../../../../components/_ui/typography/Typography.component'
import Button from '../../../../../components/_ui/button/Button.component'
import toast from 'react-hot-toast'



export default function CheckoutForm(props: CheckoutFormProps) {
  const { plan, planId, type, setIsStripe } = props
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useReduxDispatch()
  const [loading, setLoading] = useState(false)
  const [isStripeLoading, setIsStripLoading] = useState(true)


  useEffect(() => {
    setIsStripe(Boolean(isStripeLoading))
  }, [isStripeLoading])

  useEffect(() => {
    if (!elements) return
    const element = elements.getElement('payment')
    element?.on('ready', () => setIsStripLoading(false))
  }, [elements])


  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault()
      if (!stripe || !elements) return

      const { error: submitError } = await elements.submit()
      if (submitError) return

      setLoading(true)

      const response = await dispatch(tenantPurchasePlanThunk({ planId })).unwrap()
      await stripe.confirmPayment({
        elements,
        clientSecret: response.data.payment.client_secret,
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        confirmParams: {
          return_url: `${window.location.origin}?type=${type}`,
        }
      })
        .then(async function (result) {
          if (result?.error) {
            // This api status update for current payment
            if (result?.error?.message) {
              await dispatch(tenantPurchasePlanStatusThunk({ transactionId: response.data.transactionId, response: result?.error }))
              toast.error(result?.error?.message as string)
            }
            else {
              toast.error('Something went wrong')
            }
          }
        });
    }
    catch (error) { console.log(error) }
    finally { setLoading(false) }
  }


  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement  />
      {isStripeLoading && <Spinner className='m-auto d-block my-3' variant='secondary' />}
      <Stack gap={3} className='mt-3'>
        {/*<Typography $variant='body2' className='fw-medium'>Rest assured, your payment is processed securely through our trusted payment gateway. For further details regarding payment terms, please refer to our Terms & Conditions. If you have any questions or concerns, our customer support team is here to assist you. Thank you for choosing Rentless.</Typography>*/}
        <Typography $variant='subtitle1' className='text-dark'>Total: ${plan.price}</Typography>
        <Button className='w-100' type='submit' disabled={isStripeLoading} $loading={loading}>Purchase</Button>
        <Typography $variant='body2' className='fw-medium opacity-75'>We do not store card details. These details are passed directly to the bank for processing. Our website uses 256-Bit SSL Encryption for data transfer.</Typography>

      </Stack>
    </form>
  )
}
