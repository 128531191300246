import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import Button from "../_ui/button/Button.component";

const Fallback = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <h2>Uh Oh! There is an error in loading app, Try reloading</h2>
      <Button
        style={{ marginTop: "8px" }}
        onClick={() => window.location.reload()}
      >
        Reload
      </Button>
    </div>
  );
};

export function ErrBoundary({
  children,
}: {
  children: React.JSX.Element[] | React.JSX.Element;
}) {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
}
