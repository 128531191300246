import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import { MdSearchOff } from "react-icons/md"


export default function Test() {
  return (
    <div>
      <Container className='mt-5'>
        <Alert variant='light' className='d-flex flex-column text-center align-items-center'>
          <MdSearchOff className='fs-2 text-secondary ' />
          <span className='text-secondary'>No record found</span>
        </Alert>
      </Container>
    </div>
  )
}
