import { Col, Row } from "react-bootstrap";
import Typography from "../../../../components/_ui/typography/Typography.component";
import Pill from "../../../../components/_ui/pill/Pill";
import { CardProps } from "./ProfileListing.interface";
import { MdDirectionsCar, MdPerson } from "react-icons/md";

export default function Card(props: CardProps) {
  const { payload, title } = props;

  return (
    <Row>
      <Col xs={12} md={12}>
        <div className="pb-4 border-bottom">
          <Typography $variant="h6" className={"mb-4"}>
            {title}
          </Typography>

          <div className="d-flex flex-wrap">
            {payload &&
              payload?.data?.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-4 border shadow-sm d-flex align-items-center p-4 m-1 flex-column"
                  style={{ width: 300 }}
                >
                  {payload.type === "vehicle" ? (
                    <>
                      <MdDirectionsCar
                        color="#14b514"
                        size={50}
                        className="mb-3"
                      />
                      <span className="fw-bold">{item.model}</span>
                      <span className="mb-2">{item.color}</span>
                      <span>{item.vehicleNumber}</span>
                    </>
                  ) : (
                    <>
                      <MdPerson color="#14b514" size={50} className="mb-3" />
                      <span className="fw-bold">{item.name}</span>
                      {item?.relation && (
                        <span className="mb-2">{item?.relation}</span>
                      )}
                      <span>{item.number}</span>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Col>
    </Row>
  );
}
