import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useReduxSelector } from "../../hooks/redux";



// with login access pages
export default function ProtectedRoute({ children }: { children?: ReactNode }) {
  const { isLogggedIn } = useReduxSelector(state => state.auth)

  if (isLogggedIn) {
    return children
  }
  else {
    return <Navigate to={`/auth/login?redirect_to=${window.location.pathname}`} />
  }

}