import React, { useCallback } from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
export function LightImage({ item, index }: { item: string; index: number }) {
  const getItems = useCallback(() => {
    return (
      <a
        className="d-flex justify-content-center align-items-center h-100 item mx-2"
        data-src={item}
      >
        <img
          src={item}
          alt="image"
          style={{ maxHeight: "250px", minHeight: "250px" }}
          className="h-100 w-100 object-fit-cover"
        />
      </a>
    );
  }, [item]);

  return (
    <div className="App">
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        mode="lg-fade"
        elementClassNames="custom-class-name"
      >
        {getItems()}
      </LightGallery>
    </div>
  );
}
