import { Col, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { message } from "../../../../utils";
import { InsightsProps } from "./overview.interface";
import { useReduxSelector } from "../../../../hooks/redux";
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant";
import OverviewCard from "./OverviewCard";
import Inspections from "./Inspections";
import Button from "../../../../components/_ui/button/Button.component";
import Loader from "../../../../components/_ui/Spinner/Spinner.component";
import Spinner from "../../../../components/_ui/Spinner/Spinner.component";
import Typography from "../../../../components/_ui/typography/Typography.component";
import ShortListedProperty from "../shortListedProperties/ShortListedProperty.component";
import Alert from "../../../../components/_ui/alert/Alert.component";



export default function OverView({ insights }: InsightsProps) {

  const { data, totalCount, status } = useReduxSelector(state => state.property.getPropertiesListByShortlistedTenantDashboard)
  const navigate = useNavigate()
  return (
    <Stack gap={4} className="mb-5">
      <Row>
        <Col xs={12} md={12}><Inspections /></Col>
      </Row>
      <Row>
        <Col xs={12} md={8} className="mb-4">
          {!totalCount && status === THUNK_STATUS.SUCCESS && <OverviewCard type="matches" title="Potential Matches" subTitle={`${insights?.shortlist_count ?? 0} Matches`} description="Once a landlord decides to offer you a place, the property will appear here. You can then choose to start a rental agreement if you like what you see."
          //description="Properties that you have been choosen to view will appear here, you can then choose any you wish to start a rental agreement with."
          />}

          {status === THUNK_STATUS.LOADING && <Loader />}
          {status === THUNK_STATUS.FAILED && <Alert $variant="danger">{message.SOMETHING_WENT_WRONG}</Alert>}

          {status === THUNK_STATUS.SUCCESS && Boolean(data?.length) && <>
            <div className="d-flex justify-content-between align-items-center py-3 mb-3">
              <Typography $variant="h2">Potential Matches</Typography>
              {totalCount > 3 ? <Button $variant="text" onClick={() => navigate('/shortlisted/tenant-allProperties')}>View More</Button> : null}
            </div>
            <Row className='gap-3 gap-sm-0'>
              {data?.map((item, index) =>
                <Col xs={12} sm={6} md={6} lg={4} key={index}>
                  <ShortListedProperty data={item} size='small' className='pointer-events-none' addressClassName='line-2' />
                </Col>
              )}
            </Row>
          </>
          }
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <OverviewCard type="views" title="Listing Views" subTitle={`${insights?.profile_view_count ?? 0} Listing Views`} description="Enter as much information about youself to increase the chances of being seen." />
        </Col>
      </Row>
    </Stack>
  );
}
