import moment from 'moment-timezone'



export const getPdfName = (str: string) => {
  if (str) {
    const lastIndex = str.lastIndexOf('/')
    const output = str.slice(lastIndex + 1)
    return output || str
  }
}


export function areAllValuesZero(obj: Record<string, number>): boolean {
  for (const key in obj) {
    if (obj[key] !== 0) {
      return false;
    }
  }
  return true;
}


export const checkAllInfo = (data: any) => {

  var profile = data.profile;

  if (!profile) {
    return {
      isSetup: false,
      score: 0
    };
  }

  var x = {
    isSetup: true,
    score: 100
  };

  //Location
  if (!Boolean(profile.suburb.length)) {
    x.score = x.score - 10;
    x.isSetup = false;
  }

  //Budget
  if (profile.weeklyBudget === null) {
    x.score = x.score - 10;
    x.isSetup = false;
  }
  //Family
  if (areAllValuesZero(profile.familyMembers)) {
    x.score = x.score - 10;
    x.isSetup = false;
  }
  //Salary
  if (profile.salary === undefined) {
    x.score = x.score - 10;
    x.isSetup = false;
  }
  //Description
  if (profile.description === null) {
    x.score = x.score - 10;
    x.isSetup = false;
  }
  //Photo
  if (!Boolean(profile.profilePhotos.length)) {
    x.score = x.score - 10;
    //x.isSetup = false;
  }
  //Status
  if (profile.currentStatus === null) {
    x.score = x.score - 10;
  }
  //Vehicles
  if (!Boolean(profile.vehicles.length)) {
    x.score = x.score - 10;
  }
  //References
  if (!Boolean(profile.references.length)) {
    x.score = x.score - 10;
  }

  return x;

}


export const handleCalculateDiscountAndSavings = (orignalPrice: number, discountPercentage: number) => {
  let discountAmount = (orignalPrice * discountPercentage) / 100;
  let finalPrice = Number((orignalPrice - discountAmount).toFixed(2));
  let saveAmount = Number((orignalPrice - finalPrice).toFixed(2));

  return { finalPrice, saveAmount }
}


// This function match the routes it will be return true
export function routeExists(matchRoute: string[]) {
  return matchRoute.includes(window.location.pathname)
};



// This function match the has File Instance it will be return true otherwise false
export function hasFileInstance(array: any) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] instanceof File) {
      return true;
    }
  }
  return false;
}


export function convertToMelbourneTime(date: string | Date) {
  // return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: "Australia/Melbourne" }));
  return moment(date).tz('Australia/Melbourne')
}

