import { styled } from "styled-components";
import LeftImage from '../../../../assets/images/register/boost-listing/left_img.svg';
import RightImage from '../../../../assets/images/register/boost-listing/right_img.svg';

export const CardDiv = styled.div`
@media (min-width:767px)  {
  position: relative;
   &::after {
    content: url(${LeftImage});
    position: absolute;
    top: 50%;
    left: -36%;
  }
  &::before {
    content: url(${RightImage});
    position: absolute;
    top: 8%;
    right: -36%;
  }
}
`
export const ScrollWrap = styled.div`
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
export const Box = styled.div`
  margin: auto;
  padding: 40px 0px;
`
export const CardBody = styled.div`
  padding: 10px;
	margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const SkipBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`
export const SkipLink = styled.a`
  text-align: center;
  color: #a5baa5;
  text-decoration: underline;
  font-size: 11px;
`
export const BgImage = styled.div`
  background: linear-gradient(291deg, #D3E8D2 -1.76%, #FBFEFA 89.96%);
  /*padding: var(--section-spacing) 0;*/
  height: 100%;
  overflow: none;
  overflow-x: auto;
`
