import { TipsAndSuggestionsProps } from './TipsAndSuggestions.interface'
import { MdClose, MdLightbulb } from "react-icons/md"
import Button from '../../../../components/_ui/button/Button.component'
import Card from '../../../../components/_ui/card/Card.component'
import Typography from '../../../../components/_ui/typography/Typography.component'



export default function TipsAndSuggestions(props: TipsAndSuggestionsProps) {
  const { show = true, setShow, className } = props

  return (
    show ?
      <Card className={`position-relative ${className}`}>
        {Boolean(setShow) && <Button $variant='text' $icon className='position-absolute top-5 right-5' onClick={() => setShow && setShow(false)}><MdClose /></Button>}
        <Typography $variant='subtitle2' className={`mb-2 text-dark fw-semibold ${Boolean(setShow) ? 'pe-4 me-1' : ''}`}>
          <MdLightbulb className='mb-1 text-warning' /> Tips & Suggestions
        </Typography>
        <Typography $variant='body1'>
          <p>Remember to include clear photos of your property and an in depth description about all of the amenities.</p>
        </Typography>
      </Card>
      :
      <></>
  )
}
