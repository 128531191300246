import Pagination from 'react-bootstrap/Pagination';



const PaginationComponent = ({ totalPages, currentPage, onPageChange }: any) => {
  const pageItems = [];

  // Maximum number of pages to show before and after the current page
  const maxPagesToShow = 2; // Adjust this value as needed

  let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  // Adjust the startPage when near the end
  if (endPage - startPage < maxPagesToShow - 1) {
    startPage = Math.max(endPage - maxPagesToShow + 1, 1);
  }


  // Create page items for the current range
  for (let page = startPage; page <= endPage; page++) {
    pageItems.push(
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => onPageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }


  // Add an ellipsis before page items if not starting from page 1
  if (startPage > 1) {
    pageItems.unshift(<Pagination.Ellipsis key="ellipsis-start" />);
  }

  // Add an ellipsis after page items if not ending at the last page
  if (endPage < totalPages) {
    pageItems.push(<Pagination.Ellipsis key="ellipsis-end" />);
  }

  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {pageItems}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationComponent;
