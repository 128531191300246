import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import PropertyForm from '../component/propertyForm/PropertyForm.component'
import TipsAndSuggestions from '../component/tipsAndSuggestions/TipsAndSuggestions.component'
import Card from '../../../components/_ui/card/Card.component'

export default function PropertiesAdd() {

  return (
    <Layout title='Properties Add' user={true}>
      <Container>
        <Typography $variant='h1'>Add your property</Typography>

        <Row className='row-gap-5'>
          <Col xs={12} lg={9}>
            <Card>
              <PropertyForm />
            </Card>
          </Col>

          <Col xs={12} lg={3}>
            <TipsAndSuggestions className='position-sticky top-15' />
          </Col>

        </Row>
      </Container>
    </Layout>
  )
}