import { styled } from "styled-components"



const StyledFooter = styled.footer` 
  .nav-link{
    color: var(--bs-secondary);
    padding: 3px 0;
    &:hover{
      color: ${({ theme }) => theme.primary.main};
      text-decoration: underline;
    }
  }
`


export default StyledFooter