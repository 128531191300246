import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../../interfaces/Status.type'
import { signUpThunk, verifyOtpThunk, loginThunk, currentUserThunk, resendOtpThunk, getProfileThunk, forgotPasswordThunk, updatePasswordThunk, resetPasswordThunk, updateProfileThunk, socialLoginThunk, updateRoleThunk, googleSocialLoginThunk, phoneNoVerificationThunk, verifyOtpWithSocialLoginThunk } from '../actions/auth.action'
import { Roles } from '../../../interfaces/Roles.type'
import { THUNK_STATUS } from '../constants/status.constant'
import routes from '../../../routes'
import { UserDTO } from '../../../dtos/User.dto'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'
import { analytics, logEvent } from "../../../firebase";


/* == Initial State == */
const initialState: IAuthSlice = {
  isLogggedIn: false,
  user: null,
  userRoles: ['noUser'],
  register: {
    status: '',
    activeStep: 1,
    formData: {
      name: '',
      email: '',
      password: '',
      phone: '',
      userRoles: '',
    },
  },
  findHomeSignupTenantFormData: {
    status: undefined,
    activeStep: 1,
    formData: {
      profile: {
        suburb: [],
        isShareHouse: false,
        weeklyBudget: '',
        currentStatus: '',
        familyMembers: {
          children: 0,
          cats: 0,
          dogs: 0,
          adults: 0
        },
        salary: {
          type: '',
          earn: ''
        },
      }
    },
  },
  phoneNoVerificationPopup: {
    isShow: false,
    isVerifyLater: null
  },
  phoneNoVerification: {
    status: '',
  },
  verifyOtpWithSocialLogin: {
    status: '',
  },
  login: {
    status: '',
    remember: false
  },
  socialLogin: {
    status: '',
    id: null
  },
  googleSocialLogin: {
    status: '',
    id: null
  },
  otp: {
    verifyOtp: false,
    updateNumber: false,
    status: '',
  },
  currentUser: {
    status: '',
  },
  resendOtp: {
    status: '',
  },
  forgotPassword: {
    status: '',
  },
  updatePassword: {
    status: '',
  },
  resetPassword: {
    status: '',
  },
  updateProfile: {
    status: '',
  },
  updateRole: {
    status: '',
  },
  appleLogin: {
    status: '',
    activeStep: 1,
    formData: {
      name: '',
      userRoles: '',
    },
  },
  googleLogin: {
    status: '',
    activeStep: 1,
    formData: {
      name: '',
      userRoles: '',
    },
  },
}



/* == Slice == */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    handleSteps: (state, action: PayloadAction<number | undefined>) => {
      const step = action.payload || (state.register.activeStep + 1)
      state.register.activeStep = step
      routes.navigate(`/auth/register/step-${step}`)
    },

    setRegisterFormData: (state, action: PayloadAction<IRegisterFormData>) => {
      state.register.formData = {
        ...state.register.formData,
        ...action.payload
      }
    },

    setFindHomeSignupTenantFormData: (state, action: PayloadAction<{ activeStep: number, status?: string | undefined, formData: IFindHomeSignUpFormData }>) => {
      state.findHomeSignupTenantFormData.status = action.payload.status
      state.findHomeSignupTenantFormData.activeStep = action.payload.activeStep
      state.findHomeSignupTenantFormData.formData.profile = { ...state.findHomeSignupTenantFormData.formData.profile, ...action.payload.formData.profile }
    },

    setAppleLoginFormData: (state, action: PayloadAction<IRegisterFormData>) => {
      state.appleLogin.formData = { ...state.appleLogin.formData, ...action.payload }
    },

    setGoogleLoginFormData: (state, action: PayloadAction<IRegisterFormData>) => {
      state.googleLogin.formData = { ...state.googleLogin.formData, ...action.payload }
    },

    logout: (state, action: PayloadAction) => {
      Cookies.remove('token')
      state.isLogggedIn = false
      window.location.href = '/auth/login'
    },

    handleUpdateNumber: (state, action: PayloadAction<{ show: boolean, phone?: string }>) => {
      state.otp.updateNumber = action.payload.show
      if (action.payload.phone && state.user) state.user.phone = action.payload.phone
    },

    handleRemember: (state, action: PayloadAction<boolean>) => {
      state.login.remember = action.payload
    },

    handlePhoneNoVerificationPopup: (state, action: PayloadAction<{ isShow: boolean, isVerifyLater: boolean }>) => {
      state.phoneNoVerificationPopup = action.payload
    }

  },


  extraReducers: (builder) => {

    {/* Sign Up */ }
    builder.addCase(signUpThunk.pending, (state, action) => {
      state.register.status = THUNK_STATUS.LOADING
    })

    builder.addCase(signUpThunk.fulfilled, (state, action) => {
      const userInfo: UserDTO = action.payload.data.user_info
      state.register.status = THUNK_STATUS.SUCCESS
      state.user = userInfo
      state.register.formData = {
        name: '',
        email: '',
        password: '',
        phone: '',
        userRoles: '',
      }
      if (userInfo.isVerified) saveUser(userInfo?.token as string)
      else {
        state.otp.verifyOtp = true
        routes.navigate(`/auth/verify-otp`)
      }
    })

    builder.addCase(signUpThunk.rejected, (state, action) => {
      state.register.status = THUNK_STATUS.FAILED
    })


    {/* phoneNo Verification  */ }
    builder.addCase(phoneNoVerificationThunk.pending, (state, action) => {
      state.phoneNoVerification.status = THUNK_STATUS.LOADING
    })

    builder.addCase(phoneNoVerificationThunk.fulfilled, (state, action) => {
      state.phoneNoVerification.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(phoneNoVerificationThunk.rejected, (state, action) => {
      state.phoneNoVerification.status = THUNK_STATUS.FAILED
    })


    {/* verify Otp With Social Login  */ }
    builder.addCase(verifyOtpWithSocialLoginThunk.pending, (state, action) => {
      state.verifyOtpWithSocialLogin.status = THUNK_STATUS.LOADING
    })

    builder.addCase(verifyOtpWithSocialLoginThunk.fulfilled, (state, action) => {
      state.verifyOtpWithSocialLogin.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(verifyOtpWithSocialLoginThunk.rejected, (state, action) => {
      state.verifyOtpWithSocialLogin.status = THUNK_STATUS.FAILED
    })


    {/* Verify OTP */ }
    builder.addCase(verifyOtpThunk.pending, (state, action) => {
      state.otp.status = THUNK_STATUS.LOADING
    })

    builder.addCase(verifyOtpThunk.fulfilled, (state, action) => {
      const userInfo: UserDTO = action.payload.data.user_info
      state.user = userInfo
      state.otp.status = THUNK_STATUS.SUCCESS
      saveUser(userInfo?.token as string)
    })

    builder.addCase(verifyOtpThunk.rejected, (state, action) => {
      state.otp.status = THUNK_STATUS.FAILED
    })


    {/* Login */ }
    builder.addCase(loginThunk.pending, (state, action) => {
      state.login.status = THUNK_STATUS.LOADING
    })

    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.login.status = THUNK_STATUS.SUCCESS
      if (!action.payload?.data?.user_info) toast.error('Email or Password is incorrect')
      else {
        const userInfo: UserDTO = action.payload.data.user_info
        state.user = userInfo
        if (userInfo.isVerified) saveUser(userInfo?.token as string, (state.login.remember ? 30 : 30))
        else {
          state.otp.verifyOtp = true
          routes.navigate(`/auth/verify-otp`)
        }
      }
    })

    builder.addCase(loginThunk.rejected, (state, action) => {
      state.login.status = THUNK_STATUS.FAILED
    })


    {/* Current User */ }
    builder.addCase(currentUserThunk.pending, (state, action) => {
      state.currentUser.status = THUNK_STATUS.LOADING
    })

    builder.addCase(currentUserThunk.fulfilled, (state, action) => {
      state.currentUser.status = THUNK_STATUS.SUCCESS
      state.isLogggedIn = true
      state.userRoles = action.payload.data.userRoles.map((item: string) => item)
      state.user = action.payload.data
    })

    builder.addCase(currentUserThunk.rejected, (state, action) => {
      state.currentUser.status = THUNK_STATUS.FAILED
    })


    {/* Get Profile */ }
    builder.addCase(getProfileThunk.pending, (state, action) => {
      state.currentUser.status = THUNK_STATUS.LOADING
      toast.loading('Profile Updating', { id: 'updating_profile' })
    })

    builder.addCase(getProfileThunk.fulfilled, (state, action) => {
      state.userRoles = action.payload.data.userRoles.map((item: string) => item)
      state.user = action.payload.data
      toast.success('Profile Updated', { id: 'updating_profile' })
    })

    builder.addCase(getProfileThunk.rejected, (state, action) => {
      toast.dismiss('updating_profile')
    })


    {/* Resend OTP */ }
    builder.addCase(resendOtpThunk.pending, (state, action) => {
      state.resendOtp.status = THUNK_STATUS.LOADING
    })

    builder.addCase(resendOtpThunk.fulfilled, (state, action) => {
      state.resendOtp.status = THUNK_STATUS.SUCCESS
      toast.success('OTP resend')
    })

    builder.addCase(resendOtpThunk.rejected, (state, action) => {
      state.resendOtp.status = THUNK_STATUS.FAILED
    })

    {/* forgot Password */ }
    builder.addCase(forgotPasswordThunk.pending, (state, action) => {
      state.forgotPassword.status = THUNK_STATUS.LOADING
    })

    builder.addCase(forgotPasswordThunk.fulfilled, (state, action) => {
      state.forgotPassword.status = THUNK_STATUS.SUCCESS
      toast.success('Reset Password Link Sent')
    })

    builder.addCase(forgotPasswordThunk.rejected, (state, action) => {
      state.forgotPassword.status = THUNK_STATUS.FAILED
    })

    {/* update Password */ }
    builder.addCase(updatePasswordThunk.pending, (state, action) => {
      state.updatePassword.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updatePasswordThunk.fulfilled, (state, action) => {
      state.updatePassword.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(updatePasswordThunk.rejected, (state, action) => {
      state.updatePassword.status = THUNK_STATUS.FAILED
    })

    {/* reset Password */ }
    builder.addCase(resetPasswordThunk.pending, (state, action) => {
      state.resetPassword.status = THUNK_STATUS.LOADING
    })

    builder.addCase(resetPasswordThunk.fulfilled, (state, action) => {
      state.resetPassword.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(resetPasswordThunk.rejected, (state, action) => {
      state.resetPassword.status = THUNK_STATUS.FAILED
    })


    {/* update Profile */ }
    builder.addCase(updateProfileThunk.pending, (state, action) => {
      state.updateProfile.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updateProfileThunk.fulfilled, (state, action) => {
      state.updateProfile.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(updateProfileThunk.rejected, (state, action) => {
      state.updateProfile.status = THUNK_STATUS.FAILED
    })


    {/* Upadate Role */ }
    builder.addCase(updateRoleThunk.pending, (state, action) => {
      state.updateRole.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updateRoleThunk.fulfilled, (state, action) => {
      state.updateRole.status = THUNK_STATUS.SUCCESS
      const userInfo: UserDTO = action.payload.data.user_info
      const token = action.payload.data.token
      state.user = userInfo
      if (userInfo.isVerified) saveUser(token as string, (state.login.remember ? 30 : 30))
    })

    builder.addCase(updateRoleThunk.rejected, (state, action) => {
      state.updateRole.status = THUNK_STATUS.FAILED
    })


    {/* Social Login */ }
    builder.addCase(socialLoginThunk.pending, (state, action) => {
      state.socialLogin.status = THUNK_STATUS.LOADING
    })

    builder.addCase(socialLoginThunk.fulfilled, (state, action) => {
      state.socialLogin.status = THUNK_STATUS.SUCCESS
      const userInfo: UserDTO = action.payload.data.user_info
      state.user = userInfo
      if (userInfo.isVerified) saveUser(userInfo.token as string, (state.login.remember ? 30 : 30))
    })

    builder.addCase(socialLoginThunk.rejected, (state, action) => {
      state.socialLogin.status = THUNK_STATUS.FAILED
    })


    {/* Google Social Login */ }
    builder.addCase(googleSocialLoginThunk.pending, (state, action) => {
      state.googleSocialLogin.status = THUNK_STATUS.LOADING
    })

    builder.addCase(googleSocialLoginThunk.fulfilled, (state, action) => {
      state.googleSocialLogin.status = THUNK_STATUS.SUCCESS
      const userInfo: UserDTO = action.payload.data.user_info
      state.user = userInfo
      if (userInfo.isVerified) saveUser(userInfo.token as string, (state.login.remember ? 30 : 30))
    })

    builder.addCase(googleSocialLoginThunk.rejected, (state, action) => {
      state.googleSocialLogin.status = THUNK_STATUS.FAILED
    })

  }
})


export const saveUser = (token: string, expires = 30) => {
  if (!token) return toast.error('Something went wrong with token')
  Cookies.set('token', token, { expires })

  const pageURL = new URL(window.location.href)
  const returnToUrl = pageURL.searchParams.get('redirect_to')

  logEvent(analytics, 'user_registered', {});
  logEvent(analytics, 'conversion', { 'send_to': 'AW-11419506857/H_24CNvw8qgZEKmxn8Uq' });

  if (returnToUrl) window.location.href = returnToUrl
  else window.location.href = '/'
}



/* == Interface == */
interface IAuthSlice {
  isLogggedIn: boolean
  user: UserDTO | null
  userRoles: Roles[]
  register: {
    activeStep: number
    formData: IRegisterFormData
    status: Status
  }
  findHomeSignupTenantFormData: {
    status: String | undefined,
    activeStep: number
    formData: IFindHomeSignUpFormData
  }
  login: {
    status: Status
    remember: boolean
  },
  phoneNoVerificationPopup: {
    isShow: boolean
    isVerifyLater: boolean | null
  },
  phoneNoVerification: {
    status: Status
  },
  verifyOtpWithSocialLogin: {
    status: Status
  },
  socialLogin: {
    status: Status,
    id: string | null
  },
  googleSocialLogin: {
    status: Status,
    id: string | null
  },
  otp: {
    status: Status
    verifyOtp: boolean
    updateNumber: boolean
  }
  currentUser: {
    status: Status
  }
  resendOtp: {
    status: Status
  }
  forgotPassword: {
    status: Status
  },
  updatePassword: {
    status: Status
  },
  resetPassword: {
    status: Status
  },
  updateProfile: {
    status: Status
  },
  updateRole: {
    status: Status,
  },
  appleLogin: {
    activeStep: number
    formData: IRegisterFormData
    status: Status
  },
  googleLogin: {
    activeStep: number
    formData: IRegisterFormData
    status: Status
  }
}

export interface IRegisterFormData {
  name?: string
  email?: string
  password?: string
  phone?: string
  userRoles?: Roles | '',
  userSystemInfo?: {
    browser_details: string | undefined | null,
    operating_system: string | undefined | null,
    submission_date_time: Date,
    time_zone: string | undefined | null
  }
}

export interface IFindHomeSignUpFormData {
  profile: {
    suburb?: any
    isShareHouse?: boolean
    weeklyBudget?: string
    currentStatus?: string
    familyMembers?: {
      children: number,
      cats: number,
      dogs: number,
      adults: number
    }
    salary?: {
      type: string
      earn: string
    }
  }
}



export const { handleSteps, setFindHomeSignupTenantFormData, setRegisterFormData, setAppleLoginFormData, setGoogleLoginFormData, logout, handleUpdateNumber, handleRemember, handlePhoneNoVerificationPopup } = authSlice.actions
export default authSlice.reducer