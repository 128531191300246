import { Col, Row, Stack } from 'react-bootstrap'

import Layout from "../../../components/_layout/Layout.component"
import Typography from "../../../components/_ui/typography/Typography.component"
import AdminLayout from "../components/adminLayout/AdminLayout.component"
import AdminNav from '../components/adminNav/SidebarNav.component'

import Card from '../../../components/_ui/card/Card.component'
import { useEffect } from 'react'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { paymentDataThunk } from '../../../lib/redux/actions/plan.action'
import { adminAccountsDataThunk } from '../../../lib/redux/actions/admin.action'
import { limitSizeTwenty } from '../../../utils/pagination'

export default function Dashboard() {
  const dispatch = useReduxDispatch()
  const { paymentData } = useReduxSelector(state => state.plan)
  const { accountData } = useReduxSelector(state => state.admin)


  useEffect(() => {
    dispatch(paymentDataThunk())
    dispatch(adminAccountsDataThunk({ page: 1, size: limitSizeTwenty }))
  }, [])



  return (
    <Layout title='Dashboard' user={true}>
      <AdminLayout nav={<AdminNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">System Dashboard</Typography>
          <Typography $variant='body1'>Here are some exciting stats to keep us motivated</Typography>
        </div>

        {/* Counts */}
        <Row className='row-gap-4'>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Active Accounts</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.totalRecords || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Tenants</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.totalTenantRecords || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Landlords</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.totalLandLordRecords || 0}</Typography>
            </Card>
          </Col>

          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Signups Today</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.signupsToday || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Signups Yesterday</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.signupsYesterday || 0}</Typography>
            </Card>
          </Col>

          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Payments Total</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>${paymentData?.data?.totalPlanPrice.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Payments 30 Days</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>${paymentData?.data?.oneMonthAgoPlanPrice.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Payments Today</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>${paymentData?.data?.todayPlanPrice.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} lg={4}>
            <Card as={Stack} gap={2} className='h-100 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Failed Payments</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>${paymentData?.data?.oneMonthFailedPayments.toFixed(2) || 0}</Typography>
            </Card>
          </Col>


         
        </Row>

      </AdminLayout>
    </Layout >
  )
}
