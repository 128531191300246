import { Tooltip, OverlayTrigger, Stack, Row, Col, Form } from 'react-bootstrap'
import { MdInfoOutline } from 'react-icons/md'
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from 'react-hook-form'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { updatePasswordThunk } from '../../../lib/redux/actions/auth.action'
import { toast } from 'react-hot-toast'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import Layout from "../../../components/_layout/Layout.component"
import Typography from "../../../components/_ui/typography/Typography.component"
import SettingLayout from "../components/settingLayout/SettingLayout.component"
import Card from '../../../components/_ui/card/Card.component'
import Button from '../../../components/_ui/button/Button.component'
import * as yup from 'yup'


export default function TenantProfile() {
  const dispatch = useReduxDispatch()
  const { status } = useReduxSelector(state => state.auth.updatePassword)
  const { handleSubmit, register, reset, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })


  const onSubmit = (formdata: FormData) => {
    const { password, newPassword } = formdata
    dispatch(updatePasswordThunk({ password, newPassword })).unwrap()
      .then((response) => {
        reset()
        toast.success(response?.data?.message)
      }).catch(() => console.log('error Update Password =>'))
  }


  return (
    <Layout title='Security' user={true}>
      <SettingLayout>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Security Settings</Typography>
          <Typography $variant='body1'>Update your password every few months</Typography>
        </div>

        {/* Descripition */}
        <Card className='mb-3' >
          <Form onSubmit={handleSubmit(onSubmit)}>

            <Row className='mb-1' >
              <Col xs={12}>
                <Form.Group controlId='password'>
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control type='text' placeholder='' isInvalid={Boolean(errors.password)}  {...register('password')} />
                  <Form.Text className="text-danger">{errors.password?.message}</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-1' >
              <Col xs={12}>
                <Form.Group controlId='newPassword'>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type='text' placeholder='' isInvalid={Boolean(errors.newPassword)}  {...register('newPassword')} />
                  <Form.Text className="text-danger">{errors.newPassword?.message}</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-1' >
              <Col xs={12}>
                <Form.Group controlId='confirmPassword'>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type='text' placeholder='' isInvalid={Boolean(errors.confirmPassword)} {...register('confirmPassword')} />
                  <Form.Text className="text-danger">{errors.confirmPassword?.message}</Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Stack gap={3} className='mt-3'>
              <Button $variant='outlined' $loading={status === THUNK_STATUS.LOADING} className='fw-medium' type='submit'>Update Password</Button>
            </Stack>
          </Form>
        </Card>

        {/* <Card>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <Typography $variant='h5'>Multi-factor Authentication</Typography>
            <OverlayTrigger overlay={<Tooltip>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Tooltip>}>
              <span className='fs-5'><MdInfoOutline /></span>
            </OverlayTrigger>
          </div>

          <Row className='mb-1' >
            <Col xs={12}>
              <Form.Group controlId='name'>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type='text' placeholder='' isInvalid={false} />
              </Form.Group>
            </Col>
          </Row>
        </Card> */}

      </SettingLayout>
    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  password: yup.string().required(),
  newPassword: yup.string().min(8, 'New password must be at least 8 characters long').required(),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match').required(),
})

type FormData = yup.InferType<typeof schema>