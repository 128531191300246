import { useEffect, useState } from "react"
import { Spinner, Stack } from "react-bootstrap"

import { SwitchInput } from "../notifications/SwitchInput.style"
import { getProfileThunk } from "../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../hooks/redux"
import { updateIsLiveForPropertiesThunk } from "../../../lib/redux/actions/tenant.action"
import Layout from "../../../components/_layout/Layout.component"
import SettingLayout from "../components/settingLayout/SettingLayout.component"
import ProfileForm from "./component/profileForm/ProfileForm.component"
import Typography from '../../../components/_ui/typography/Typography.component'
import { getAllOtherInfoUserThunk } from "../../../lib/redux/actions/user.action"



export default function TenantProfile() {
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)

  const [loading, setLoading] = useState(false)

  const handleLivelisting = async (e: any) => {
    console.log(e.target.checked)
    setLoading(true)
    await dispatch(updateIsLiveForPropertiesThunk({ isLiveForProperties: e.target.checked })).unwrap()
    await dispatch(getProfileThunk())
    setLoading(false)
  }

  useEffect(() => {
    dispatch(getAllOtherInfoUserThunk())
  }, [])


  return (
    <Layout title='Tenant Profile' user={true}>
      <SettingLayout>
        <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2" >
          <div className='d-flex flex-column gap-0 mb-3'>
            <Typography $variant="h3">My Profile</Typography>
            <Typography $variant='body1'>This information will be shown to landlords</Typography>
          </div>
          {loading && <Spinner />}
          {!loading &&
            <div className="d-flex flex-column align-items-md-end">
              <SwitchInput type="switch" id="custom-switch" defaultChecked={user?.profile?.isLiveForProperties} onChange={(e) => handleLivelisting(e)} />
              {
                user?.profile?.isLiveForProperties ?
                  <Typography className='text-success'>Your listing is currently live</Typography>
                  : <Typography className='text-danger'>Your listing is disable</Typography>
              }
            </div>
          }
        </div>

        <ProfileForm />
      </SettingLayout>
    </Layout >
  )
}