import { css, styled } from 'styled-components'
import { AvatarProps } from './Avatar.interface'



export const StyledAvatar = styled.div<AvatarProps>`
  background-color: var(--bs-tertiary-bg);
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 21px;
  color: var(--primary-main);

  ${props => props.$image && css`
    background-image: url(${props.$image});
    background-size: cover;
    background-position: center;
  `}
`