import { createAsyncThunk } from '@reduxjs/toolkit'
import { adminService } from '../services/admin.service'



export const tenantVerficationThunk = createAsyncThunk('tenantVerfication', adminService.tenantVerfication)
export const propertyVerficationThunk = createAsyncThunk('propertyVerfication', adminService.propertyVerfication)
export const adminAllPropertiesThunk = createAsyncThunk('adminAllProperties', adminService.adminAllProperties)
export const adminPlanUsersThunk = createAsyncThunk('adminPlanUsers', adminService.adminPlanUsers)
export const adminUpdatePropertyStatusThunk = createAsyncThunk('adminUpdatePropertyStatus', adminService.adminUpdatePropertyStatus)
export const adminAccountsDataThunk = createAsyncThunk('adminAccountsData', adminService.adminAccountsData)
export const adminUserAccountsThunk = createAsyncThunk('adminUserAccounts', adminService.adminUserAccounts)
export const adminUserDetailByIdThunk = createAsyncThunk('adminUserDetailById', adminService.adminUserDetailById)
export const adminUpdateTenantStatusThunk = createAsyncThunk('adminUpdateTenantStatus', adminService.adminUpdateTenantStatus)
export const adminAttachmentThunk = createAsyncThunk('adminAttachment', adminService.adminAttachment)
export const adminBulkEmailSendThunk = createAsyncThunk('adminBulkEmailSend', adminService.adminBulkEmailSend)
export const adminAllBulkEmailThunk = createAsyncThunk('adminAllBulkEmail', adminService.adminAllBulkEmail)
export const adminGetUserSessionsThunk = createAsyncThunk('adminGetUserSessions', adminService.adminGetUserSessions)
export const adminUserAccountsLoginThunk = createAsyncThunk('adminUserAccountsLogin', adminService.adminUserAccountsLogin)