import { createAsyncThunk } from "@reduxjs/toolkit"
import { userService } from "../services/user.service"



export const updateTenantProfileThunk = createAsyncThunk('updateTenantProfile', userService.updateTenantProfile)
export const tenantAcceptThunk = createAsyncThunk('acceptTenant', userService.acceptTenant)
export const tenantRejectThunk = createAsyncThunk('rejectTenant', userService.rejectTenant)
export const tenantByIdThunk = createAsyncThunk('tenantById', userService.tenantById)
export const tenantTotalRecordsThunk = createAsyncThunk('tenantTotalRecords', userService.tenantTotalRecords)
export const tenantView = createAsyncThunk('tenantView', userService.tenantView)
export const closeMyAccountThunk = createAsyncThunk('closeMyAccount', userService.closeMyAccount)
export const sendFeedbackThunk = createAsyncThunk('sendFeedback', userService.sendFeedback)
export const getAllOtherInfoUserThunk = createAsyncThunk('getAllOtherInfoUser', userService.getAllOtherInfoUser)
export const notificationSettingsThunk = createAsyncThunk('notificationSettings', userService.notificationSettings)
export const storeUserSessionThunk = createAsyncThunk('storeUserSession', userService.storeUserSession)
