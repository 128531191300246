import { styled, css } from 'styled-components'
import { Variant } from './Alert.interface'


export const StyledAlert = styled.div<{ $variant?: Variant }>`
  --border-color: #cbd2da;
  --background-color: #f5f7f9;
  --color: #565f6d;
  padding: 1rem;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  border-radius: var(--bs-border-radius);

  .theme-button {
    border: unset !important;
  }

  .alert-icon {
    color: var(--color);
  }

  .title {
    color: var(--color);
    font-weight: bold;
  }
  .alert-sub-heading {
    color: var(--color);
    opacity: .6;
  }

  .alert-heading {
    color: var(--bs-dark);
    color: var(--color);
    font-weight: 500;
  }

  ${props => props.$variant === 'info' && css`
    --border-color: #cadbfe;
    --background-color: #f5f8ff;
    --color: #3665d1;

    --border-color: #5c8dff;
    --background-color: #5c8dff;
    --color: #fff;
    
  `}

  ${props => props.$variant === 'danger' && css`
    --border-color: #f9cacb;
    --background-color: #fdf5f5;
    --color: #c02c37;
  `}

  ${props => props.$variant === 'success' && css`
    --border-color: #c0ead2;
    --background-color: #f4fbf7;
    --color: #2a7d4e;
  `}

  ${props => props.$variant === 'warning' && css`
    --border-color: #fccfa2;
    --background-color: #fef5eb;
    --color: #f19342;
  `}

`