import * as yup from 'yup'
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { yupResolver } from '@hookform/resolvers/yup'

import { currencyCheck } from "../../../../utils"
import { Form, InputGroup } from "react-bootstrap"
import { formatCurrency } from "../../../../utils/number"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import { setFindHomeSignupTenantFormData } from "../../../../lib/redux/slices/auth.slice"
import Layout from "../../../../components/_layout/Layout.component"
import { SwitchInput } from "../../../settings/notifications/SwitchInput.style"
import Button from "../../../../components/_ui/button/Button.component"
import background_image from '../../../../assets/images/register/background_005.jpg'
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import useIsExtactUrlMatch from "../../hooks/useIsExtactUrlMatch.hook"
import useFindHomeSignupRedirection from "../../hooks/useFindHomeSignupRedirection.hook"



export default function ProfileOnBoardStep2() {
  useFindHomeSignupRedirection()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  let matchUrlPath = useIsExtactUrlMatch('/onboard/tenant')


  // const { formData } = useReduxSelector(state => state.user.profileOnboard)
  const { user, findHomeSignupTenantFormData } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)



  const { handleSubmit, register, control, watch, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      weeklyBudget: formatCurrency(user?.profile?.weeklyBudget as string || findHomeSignupTenantFormData.formData.profile.weeklyBudget) || '',
      isShareHouse: user?.profile?.isShareHouse || findHomeSignupTenantFormData.formData.profile.isShareHouse || false
    }
  })

  console.log(findHomeSignupTenantFormData.formData.profile.weeklyBudget || '')

  const onSubmit = async (data: FormData) => {
    data.weeklyBudget = data.weeklyBudget.replaceAll(',', '')
    if (!matchUrlPath && user?._id) {
      dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          navigate('/')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))
    }
    else {
      dispatch(setFindHomeSignupTenantFormData({ activeStep: 3, formData: { profile: { ...data } } }))
      navigate('/auth/onboard/tenant/family-member')
    }
    // dispatch(setprofileOnboardFormData(data))
    // navigate('/tenant/profile/step-3')
  }


  return (
    <Layout title='Tenant Step 2' user={!matchUrlPath} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress={matchUrlPath ? "30" : "20"}
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Weekly Budget' description="What is the maximum you're willing to spend on rent per week?" />


            {/* Form */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId='weeklyBudget'>
                <InputGroup>
                  <InputGroup.Text className='fw-medium'>$</InputGroup.Text>
                  <Form.Control type='text' autoFocus value={formatCurrency(watch('weeklyBudget'))} isInvalid={Boolean(errors.weeklyBudget)} {...register('weeklyBudget')} />
                </InputGroup>
                <Form.Text className='text-danger'>{errors.weeklyBudget?.message}</Form.Text>
              </Form.Group>



              {/* Share House */}
              <Form.Group controlId='isShareHouse'>
                <div className={"p-3 mt-3 bg-light rounded border"}>
                  <Form.Label className={"mb-3"}>Enable joint tenancy if you would like to be matched with another tenant looking for a house in the same area. This will help increase the chance of securing a home while lowering your weekly rent.</Form.Label>

                  {/* Enable Share House< */}
                  <div className='d-flex gap-3'>
                    <Controller name="isShareHouse" control={control}
                      render={({ field: { value, onChange } }) => (
                        <SwitchInput type="switch" id="custom-switch" checked={value} onChange={onChange} />
                      )} />
                    <Typography>Joint Tenancy</Typography>
                  </div>
                </div>
              </Form.Group>


              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit' $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
              </div>
            </Form>

          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  weeklyBudget: yup.string().trim().required().min(1).max(1000).matches(/^[0-9,]*(\.\d{1,2})?$/, 'Invalid amount')
    .test('decimal', 'Only two decimal places are allowed', currencyCheck)
    .test('isAbove100', '$100 is below the weekly average', (value) => {
      const budget = value.replaceAll(',', '')
      if (Number(budget) > 100) return true
      else return false
    }),
  isShareHouse: yup.boolean()
})

type FormData = yup.InferType<typeof schema>