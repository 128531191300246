import { ChangeEvent, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { Col, Form, Row, Stack } from "react-bootstrap"
import { MdClose, MdOutlineFileUpload } from "react-icons/md"
import { StyledImageBox, StyledUploadImage } from "../../../settings/tenant-profile/component/profileForm/ProfileForm.style"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { setLandlordProfileOnboardFormData, setprofileOnboardFormData } from "../../../../lib/redux/slices/user.slice"
import { uploadFileThunk } from "../../../../lib/redux/actions/upload.action"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import Layout from "../../../../components/_layout/Layout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import OnBoarding from "../../../../components/authLayout/onBoardInfomation/OnBoarding.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import Button from "../../../../components/_ui/button/Button.component"
import * as yup from 'yup'
import toast from "react-hot-toast"
import background_image from '../../../../assets/images/register/background_001.jpg';




export default function StepProperty3() {
  const MAX_IMAGE_UPLOAD = 5
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { formData } = useReduxSelector(state => state.user.landlordProfileOnboard)
  const { status } = useReduxSelector(state => state.upload.uploadFile)
  const fileInputRef = useRef<null | HTMLInputElement>(null)


  const { handleSubmit, register, control, watch, formState: { errors, isSubmitted }, setValue, getValues, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: formData?.image || [],
    }
  })

  const [images, setImages] = useState<any[]>(getValues('image'))


  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    let files = Array.from(event.target.files)
    setImages(items => {
      console.log(items)
      items = [...items, ...files]
      if (items?.length > MAX_IMAGE_UPLOAD) alert(`You can upload maximum ${MAX_IMAGE_UPLOAD} images.`)
      items = items.slice(0, MAX_IMAGE_UPLOAD)
      return items
    })
  }


  const handleImageRemove = (index: number) => {
    setImages(items => items.filter((item, itemIndex) => index !== itemIndex))
    if (fileInputRef?.current?.value) fileInputRef.current.value = ''
  }


  const onSubmit = async (data: FormData) => {
    if (images?.length === 0) {
      toast.error('Atleast one upload')
      return
    }
    try {
      let finalImages: any = []
      const formData = new FormData()
      formData.append('folder', 'TenantImages')
      images.forEach(item => (item instanceof File) ? formData.append('files', item) : finalImages.push(item))

      if (formData.getAll('files').length > 0) {
        const uploadResponse = await dispatch(uploadFileThunk(formData)).unwrap()
        finalImages = finalImages.concat(uploadResponse.data.urls)
      }

      setImages(finalImages)
      setValue('image', finalImages)

      const finalData: any = getValues()
      console.log(finalData)
      dispatch(setLandlordProfileOnboardFormData(finalData))
      navigate('/landlord/profile/step-4')
    }
    catch (error) { }
  }


  return (
    <Layout title='Landlord Step 1' user={true} header={false} footer={false}>
      <OnBoardProfileLayout
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Upload Photos' description='Upload some photos of your property' />

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className='g-3'>
                <Col xs={12}>
                  <Form.Group controlId='upload' className='h-100'>
                    <StyledUploadImage className='center position-relative rounded border px-3 py-4 border-dashed h-100'>
                      <Stack gap={1} className='center'>
                        <Form.Control type='file' accept='image/*' title='' tabIndex={-1} multiple className='position-absolute inset-0 opacity-0' onChange={handleImageChange} ref={fileInputRef} />
                        <div className='p-3 bg-light rounded-circle'>
                          <MdOutlineFileUpload className='fs-3 text-secondary' />
                        </div>
                        <Typography>Upload Photo</Typography>
                      </Stack>
                    </StyledUploadImage>
                  </Form.Group>
                </Col>
                {images && Boolean(images?.length) && images.map((item, index) =>
                  <StyledImageBox as={Col} xs={12} sm={6} md={4} lg={3} key={index}>
                    <div className='h-100 border rounded overflow-hidden position-relative animate__animated animate__fadeIn'>
                      <div className='tools d-flex gap-1'>
                        <Button className='bg-body' $variant='text' $icon type='button' onClick={() => handleImageRemove(index)}>
                          <MdClose className='fs-6' />
                        </Button>
                      </div>
                      <img key={index} src={typeof item === 'object' ? URL.createObjectURL(item) : item} className='w-100 h-100 cursor-pointer' />
                    </div>
                  </StyledImageBox>
                )}
              </Row>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' $loading={status === THUNK_STATUS.LOADING} type='submit'>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}





{/* == Validation == */ }
const schema = yup.object({
  image: yup.array().of(yup.string().url()).default([]),
})

type FormData = yup.InferType<typeof schema>