import { useEffect, useState } from 'react'

import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux';
import { handlePhoneNoVerificationPopup } from '../../../lib/redux/slices/auth.slice';
import PhoneVerificationPopup from '../../../components/modalPopup/phoneVerification/PhoneVerificationPopup.component';



export default function useIsPhoneVerification() {
  const dispatch = useReduxDispatch()
  const { user, phoneNoVerificationPopup } = useReduxSelector((state) => state.auth);


  useEffect(() => {
    if (user && user.phone === undefined && phoneNoVerificationPopup.isVerifyLater === null) {
      dispatch(handlePhoneNoVerificationPopup({
        isShow: true,
        isVerifyLater: false
      }))
    }
  }, [user])

  return (
    <PhoneVerificationPopup show={phoneNoVerificationPopup.isShow} />
  )
}
