import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { TenantProfileDTO } from '../../../dtos/TenantProfile.dto'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { Container, Row, Stack, Col, Spinner, Badge, Alert as AlertBootsrap } from 'react-bootstrap'
import { searchTenantsByPropertyThunk, shortlistedTenantListThunk, propertyDetailsThunk } from '../../../lib/redux/actions/property.action'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'
import TenantCard from '../../../components/tenantCard/TenantCard.component'
import Alert from '../../../components/_ui/alert/Alert.component'
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component'
import SearchImg from '../../../assets/images/search.svg'


export default function Tenants() {

  const PAGE_SIZE = 8
  const lastCompatibleRef = useRef<any>()
  const { propertyId } = useParams()
  const dispatch = useReduxDispatch()
  const [compatibleLoading, setCompatibleLoading] = useState(true)
  const [compatiblePage, setCompatiblePage] = useState<number>(1)
  const [hasMoreCompatible, setHasMoreCompatible] = useState(true)
  const [compatibleTenants, setCompatibleTenants] = useState<TenantProfileDTO[]>([])
  const { searchTenantsByProperty, shortlistedTenantList, propertyDetails } = useReduxSelector(state => state.property)


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !compatibleLoading) fetchCompatibleTenants()
    })

    if (lastCompatibleRef.current && hasMoreCompatible) observer.observe(lastCompatibleRef.current)

    return () => {
      if (lastCompatibleRef.current) observer.unobserve(lastCompatibleRef.current)
    }
  }, [compatibleTenants])


  const fetchCompatibleTenants = async () => {
    setCompatibleLoading(true)
    await dispatch(searchTenantsByPropertyThunk({ id: propertyId as string, size: PAGE_SIZE, page: compatiblePage, }))
      .unwrap()
      .then(response => {
        const data = response.data.result
        if (!Boolean(data.length)) return setHasMoreCompatible(false)
        setCompatibleTenants(prev => [...prev, ...data])
        setCompatiblePage(prev => prev + 1)
      })
      .catch(error => console.log(error))
      .finally(() => setCompatibleLoading(false))
  }


  useEffect(() => {
    fetchCompatibleTenants()

    dispatch(propertyDetailsThunk({ id: propertyId as string }))

    dispatch(shortlistedTenantListThunk({
      propertyId: propertyId as string,
      size: 3,
      page: 1,
    }))
  }, [])


  return (
    <Layout title='Search Tenants' user={true}>
      <Container>

        <Stack direction='horizontal' className='justify-content-between align-items-start gap-4'>
          <div>
            <Typography $variant='h2' className='mb-1'>{propertyDetails.status === THUNK_STATUS.SUCCESS && propertyDetails.data?.address.name}</Typography>
            {(searchTenantsByProperty.status === 'success' && searchTenantsByProperty.total !== null) && <Typography $variant='subtitle2'>{searchTenantsByProperty.total} Compatible Tenants Found</Typography>}
          </div>
        </Stack>


        {/* Shortlisted */}
        {
          (Boolean(shortlistedTenantList.data.length)) && (<Stack className='mt-5 gap-3'>
              <Typography $variant='h4'>Shortlisted</Typography>

              {shortlistedTenantList.status === THUNK_STATUS.SUCCESS &&
                <Row className='row-gap-4'>
                  {shortlistedTenantList.data.map((item, index) =>
                    <Col xs={12} sm={6} md={3} lg={4} key={index}>
                      <TenantCard propertyId={propertyId as string} data={item} />
                    </Col>
                  )}
                </Row>
              }

              {shortlistedTenantList.status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
              {shortlistedTenantList.status === THUNK_STATUS.FAILED && <Alert className='m-auto d-block my-3 w-100 text-center' $variant='danger'>Something went wrong</Alert>}
              {shortlistedTenantList.status === THUNK_STATUS.SUCCESS && !Boolean(shortlistedTenantList.data.length) &&
                <NoRecordFound className='text-center w-100' image={SearchImg}>
                  <Typography $variant='h4'>Shortlisted Tenant</Typography>
                  <Typography $variant='body1'>No record found</Typography>
                </NoRecordFound>
              }
            </Stack>)

        }
        




        {/* Compatible Tenants */}
        <Stack className='mt-5 gap-3'>
          <Typography $variant='h4'>Compatible Tenants</Typography>

          {Boolean(compatibleTenants.length) &&
            <div>
              <Row className='row-gap-4'>
                {compatibleTenants.map((item, index) =>
                  <Col xs={12} sm={6} md={3} lg={4} key={index}>
                    <TenantCard propertyId={propertyId as string} data={item} />
                  </Col>
                )}
              </Row>
              <div ref={lastCompatibleRef} />
            </div>
          }

          {compatibleLoading && <Spinner className='m-auto d-block' variant='secondary' />}
          {searchTenantsByProperty.status === THUNK_STATUS.SUCCESS && !compatibleLoading && !Boolean(compatibleTenants.length) && <NoRecordFound className='text-center w-100' image={SearchImg}>
            <Typography $variant='h4'>Compatible Tenant</Typography>
            <Typography $variant='body1'>No record found</Typography>
          </NoRecordFound>}
          {searchTenantsByProperty.status === THUNK_STATUS.FAILED && <Alert className='m-auto d-block my-3 w-100 text-center' $variant='danger'>Something went wrong</Alert>}
        </Stack>

      </Container>
    </Layout>
  )
}
