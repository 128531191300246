import { Col, Row, Stack } from "react-bootstrap";
import { MdCalendarToday, MdCircle, MdShowChart, MdCottage, MdCheck, MdPerson, MdPets, MdChildCare, MdHouse, MdGroup, MdDiversity1, MdGroupAdd, MdPhoneAndroid, MdCheckCircle, MdDirectionsCar, MdSchedule } from "react-icons/md";

import { UserDTO } from "../../../../dtos/User.dto";
import { GalleryProps } from "./ProfileListing.interface";
import { TenantProfileDTO } from "../../../../dtos/TenantProfile.dto";
import Tags from "./Tags";
import Card from "./Card";
import OtherInfo from "./OtherInfo";
import LocationCard from "./LocationCard";
import Typography from "../../../../components/_ui/typography/Typography.component";
import CardVehicle from "../../../../components/_ui/cardVehicle/CardVehicle";
import CardReference from "../../../../components/_ui/cardReference/CardReference";


type Tinfo = {
  name: string
  isVerified: boolean
}



export default function UserInfo({ payload, info }: { payload: any; info: Tinfo | null }) {

  const { references, currentStatus, description, weeklyBudget, salary, familyMembers, vehicles, suburb, isBlueTickNeeded, otherInformation } = payload;
  return (
    <Stack gap={4}>
      <div className="pb-4 border-bottom">
        <Row>
          <Col xs={12} md={12}>
            <Typography $variant="h1" className={"mb-0"}>
              {(info && info?.name) ?? ""}
            </Typography>

            {/* <Typography $variant="body1">31 year old man</Typography> */}
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={12} md={12}>
          <div className="pb-4 border-bottom">
            <Row>
              <Col xs={12} md={4} className="d-flex justify-content-center align-items-center flex-column py-4 py-lg-0">
                <div style={{ fontSize: 24 }}>
                  {weeklyBudget ? `$${weeklyBudget}` : "$0"}
                  <span className="fw-normal" style={{ fontSize: 12 }}>/wk</span>
                </div>
                <div className="fw-bold">Budget</div>
              </Col>
              <Col xs={12} md={4} className="d-flex justify-content-center align-items-center flex-column py-4 py-lg-0">
                <div style={{ fontSize: 24 }}>{salary ? `$${salary?.earn}` : "$0"}</div>
                <div className="fw-bold">Combined Income</div>
              </Col>
              {currentStatus && (
                <Col xs={12} md={4} className="d-flex justify-content-center align-items-center flex-column py-4 py-lg-0">
                  <div style={{ fontSize: 14 }}>
                    {currentStatus === "living_with_friends" ? "Living with friends" : "Living with parents"}
                  </div>
                  <div className="fw-bold">Current Status</div>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>

      <Tags title="Verifications"
        payload={[
          ...(isBlueTickNeeded === "accept" ? [{ text: "ID Verified with Equifax", icon: <MdCheckCircle color="14b514" /> }] : []),
          ...(info?.isVerified ? [{ text: "Mobile Verified", icon: <MdPhoneAndroid color="14b514" /> }] : []),
        ]}
      />

      {description && (
        <Row>
          <Col xs={12} md={12}>
            <div className="pb-4 border-bottom">
              <Typography $variant="h6" className={"mb-4"}>About Me</Typography>
              <Typography $variant="body1">{description}</Typography>
            </div>
          </Col>
        </Row>
      )}

      {familyMembers && (
        <Tags title="Family"
          payload={[
            { text: `${familyMembers?.adults} Adults`, icon: <MdPerson color="14b514" /> },
            { text: `${familyMembers?.children} Children`, icon: <MdChildCare color="14b514" /> },
            { text: `${familyMembers?.dogs} Dogs`, icon: <MdPets color="14b514" /> },
            { text: `${familyMembers?.cats} Cat`, icon: <MdPets color="14b514" /> },
          ]}
        />
      )}

      <Tags
        title="Preferred Accommodation"
        payload={[
          { text: "Whole House", icon: <MdHouse color="14b514" /> },
          // {
          //   text: "Share House",
          //   icon: <MdGroup color="14b514" />,
          // },
          // {
          //   text: "Team Up",
          //   icon: <MdGroupAdd color="14b514" />,
          // },
        ]}
      />

      {suburb?.length !== 0 && (
        <LocationCard title="Preferred Locations" payload={{ type: 'suburbs', data: suburb }} />
      )}
      {otherInformation && otherInformation?.length !== 0 && (
        <OtherInfo title="Other Information" payload={{ type: 'OtherInformation', data: otherInformation }} />
      )}
      {vehicles?.length !== 0 && (
        <Card title="Vehicles" payload={{ type: "vehicle", data: vehicles }} />
      )}
      {references?.length !== 0 && (
        <Card title="References" payload={{ type: "references", data: references }} />
      )}
    </Stack>
  );
}
