import { styled } from "styled-components"
import { MapAutocompleteProps } from "./MapAutocomplete.interface"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import toast from "react-hot-toast"



const MapAutocomplete = styled(GooglePlacesAutocomplete).attrs((props: any) => ({
  selectProps: {
    ...props.selectProps,
    styles: {
      control: (baseStyles, state) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? (props?.isInvalid ? '0 0 0 0.25rem rgba(var(--bs-danger-rgb),.25) !important' : '0 0 0 1px var(--primary-main) !important') : 'unset',
        borderColor: props?.isInvalid ? 'var(--bs-form-invalid-border-color) !important' : (state.isFocused ? 'var(--primary-main) !important' : 'var(--bs-border-color) !important'),
      })
    },
    className: 'google-map-autocomplete',
    theme: (theme) => ({
      ...theme,
      borderRadius: 'var(--bs-border-radius)',
      colors: {
        ...theme.colors,
        primary25: 'var(--bs-tertiary-bg)',
        primary50: 'rgba(var(--bs-primary-rgb), .1)',
        primary75: 'rgba(var(--bs-primary-rgb), .2)',
        primary: 'var(--primary-main)',
      },
    })
  }
})) <MapAutocompleteProps>``



MapAutocomplete.defaultProps = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  autocompletionRequest: {
    componentRestrictions: {
      country: 'au'
    },
    // types: ['street_address']
  },
  onLoadFailed: (error: Error) => {
    console.log('Google Map API Error: ', error)
    toast.error('Something went wrong with address')
  },
}

export default MapAutocomplete