export function Button(props: {
  isDisabled: boolean;
  icon: React.ReactElement;
  buttonType: string;
}) {
  const { isDisabled, icon, buttonType } = props;
  return (
    <button
      {...props}
      type="button"
      disabled={isDisabled}
      style={{
        top: "40%",
      }}
      className={`position-absolute bg-white border-0 shadow-sm rounded-circle p-2 ${
        buttonType === "prev" ? "start-0" : "end-0"
      } ${isDisabled ? "opacity-75 pointer-events-none" : ""}`}
    >
      {icon}
    </button>
  );
}
