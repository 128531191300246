import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useReduxDispatch, useReduxSelector } from "../../../hooks/redux";
import { THUNK_STATUS } from "../../../lib/redux/constants/status.constant";
import { limitRowSize } from "../../../data/config";
import { Spinner, Table } from "react-bootstrap";
import { adminAgentsListThunk } from "../../../lib/redux/actions/agent.action";
import moment from 'moment';
import Card from "../../../components/_ui/card/Card.component";
import Layout from "../../../components/_layout/Layout.component";
import Alert from "../../../components/_ui/alert/Alert.component";
import Typography from "../../../components/_ui/typography/Typography.component";
import AdminLayout from "../components/adminLayout/AdminLayout.component";
import ReportsNav from '../components/reportsNav/ReportsNav.component'

import PaginationComponent from "../../../components/pagination/PaginationComponent";
import { adminService } from "../../../lib/redux/services/admin.service";
import { convertToMelbourneTime } from "../../../utils";

export default function AdminAgents() {
  const navigate = useNavigate();
  let limitSize = limitRowSize;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useReduxDispatch();
  const { adminAgentsList } = useReduxSelector((state) => state.agent);

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const data = await adminService.getTenantReports();
        setPageData(data?.info?.result);
      } finally {
        setIsFetching(false);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(adminAgentsListThunk({ page: 1, size: limitSize }));
  }, []);

  const handlePageChange = (page: number) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <Layout title="Tenents List" user={true}>
      <AdminLayout nav={<ReportsNav />}>
        <div className="d-flex flex-column gap-0 mb-3">
          <Typography $variant="h3">Tenants List</Typography>
          <Typography $variant="body1">List of tenants</Typography>
        </div>

        <Card>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Signup</th>
                <th>Budget</th>
                <th>Locations</th>
              </tr>
            </thead>
            <tbody>
              {pageData.length > 0 &&
                pageData.map((item: any, index: number) => (
                  <>
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.phone}</td>
                      <td>{convertToMelbourneTime(item?.createdAt).fromNow()}</td>
                      <td>
                        {item.profile.weeklyBudget
                          ? `$${item.profile.weeklyBudget}`
                          : "-"}
                      </td>
                      <td>
                        {item.profile.suburb.length > 0
                          ? item.profile.suburb[0].name || "Not available"
                          : "Not available"}
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-center mb-3">
            {isFetching && (
              <div className="my-3">
                <Spinner className="m-auto mt-3 d-block" variant="secondary" />
              </div>
            )}
            {adminAgentsList.status === THUNK_STATUS.FAILED && (
              <Alert className="m-auto my-3 d-block" $variant="danger">
                Something went wrong
              </Alert>
            )}
          </div>

          <div className="d-flex justify-content-end">
            <PaginationComponent
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </Card>
      </AdminLayout>
    </Layout>
  );
}
