import { useEffect, useRef } from 'react'
import { CountdownProps } from "./Countdown.interface"
import moment, { Moment } from 'moment'
import { CountdownContainer } from './Countdown.style'



export default function Countdown(props: CountdownProps) {
  const { startDate } = props
  const countdownElRef = useRef<HTMLDivElement | null>(null)


  useEffect(() => {
    const intervalId = setInterval(() => updateCountdown(startDate, 'startDate'), 1000)
    updateCountdown(startDate, 'startDate')
    return () => clearInterval(intervalId)
  }, [])


  const updateCountdown = (date: string | Moment, elementId: string): void => {
    date = moment(date)
    const currentDate = moment()
    const duration = moment.duration(date.diff(currentDate))
    if (date.isAfter()) {
      if (countdownElRef.current) {
        countdownElRef.current.innerHTML = `
          <div class='box'><span class="time">${duration.days()}</span><span class="text">DAYS</span></div>
          <div class='box'><span class="time">${duration.hours()}</span><span class="text">HRS</span></div>
          <div class='box'><span class="time">${duration.minutes()}</span><span class="text">MIN</span></div>
          <div class='box'><span class="time">${duration.seconds()}</span><span class="text">SEC</span></div>
        `
      }
    } else {
      window.location.reload()
    }
  }


  return (
    <CountdownContainer ref={countdownElRef} />
  )
}
