import { useEffect } from 'react'
import { useReduxSelector } from '../../../hooks/redux'
import { useNavigate } from 'react-router-dom'
import Layout from "../../../components/_layout/Layout.component"
import VerifyOtpForm from './component/verifyOtpForm/VerifyOtpForm.component'
import RegisterLayout from '../register/component/registerLayout/RegisterLayout.component'
import background_image from '../../../assets/images/otp-verification/background_001.jpg';


export default function VerifyOtp() {
  const navigate = useNavigate()
  const { verifyOtp } = useReduxSelector(state => state.auth.otp)


  useEffect(() => {
    if (!verifyOtp) navigate('/auth/login')
  }, [])


  return (
    <Layout title='Register Step 5' user={false} header={false} footer={false}>
      {/* <Container>
        <Row className='justify-content-center'><Col xs={12} lg={6}><div></div></Col></Row>
      </Container> */}

      <RegisterLayout 
      headerActionTitle="Already have an account?"
      headerActionButtonText="Sign In"
      headerActionButtonLink="/auth/login"
      progress="95"
      columnFirst={<VerifyOtpForm />} 
      columnSecond={<div className='h-100 w-100' 
      style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>} />

    </Layout>
  )
}
