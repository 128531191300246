import Typography from '../../../components/_ui/typography/Typography.component'
import background_image from '../../../assets/images/login/background.jpg';


export default function OnBoardAuthBanner() {
  return (
    <div className='h-100 w-100' style={{ 
        backgroundImage: `url(${background_image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }}></div>
  )
}
