import { Col, Form } from 'react-bootstrap'
import { useState } from "react"
import { ScheduleHomeProps } from './ScheduleHome.interface'
import Modal from 'react-bootstrap/Modal'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import Button from '../../_ui/button/Button.component'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { propertyDetailsThunk, propertyTenantsSchedulesThunk } from '../../../lib/redux/actions/property.action'
import { useParams } from 'react-router-dom'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import moment from 'moment'



export function ScheduleHome(props: ScheduleHomeProps) {
  const { propertyId } = useParams()
  const dispatch = useReduxDispatch()
  const [scheduleDate, setscheduleDate] = useState<Date | null>(null)
  const [scheduleTime, setscheduleTime] = useState<Date | null>(null)
  const [checkedNotify, setCheckedNotify] = useState<boolean>(true)
  const { status } = useReduxSelector(state => state.property.propertyTenantsSchedules)


  const toggleNotify = () => {
    setCheckedNotify(!checkedNotify);
  };


  const handleSubmit = (e: any) => {
    e.preventDefault()

    const dateObj = new Date(scheduleDate as Date);
    const timeObj = new Date(scheduleTime as Date);

    // Extract date and time components
    const dateComponent = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
    const timeComponent = `${timeObj.getHours().toString().padStart(2, '0')}:${timeObj.getMinutes().toString().padStart(2, '0')}:${timeObj.getSeconds().toString().padStart(2, '0')}`;

    // Concatenate date and time
    const concatenatedDateTime = `${dateComponent} ${timeComponent}`;
    console.log(concatenatedDateTime)
    // const { remember, ...finalFormData } = formData
    dispatch(propertyTenantsSchedulesThunk({ propertyId: propertyId as string, scheduleDate: concatenatedDateTime, notifyTenant: Boolean(checkedNotify) })).unwrap()
      .then((response) => {
        dispatch(propertyDetailsThunk({ id: propertyId as string }))
        setscheduleTime(null)
        setscheduleDate(null)
        setCheckedNotify(true)
        props.onHide()
      })
      .catch(err => console.log(err))
  }



  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered animation className='m-width-200'>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Schedule Open home
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form action='' noValidate onSubmit={handleSubmit}>
          <div className='w-100'>
            <div className='d-flex gap-2 align-items-center mb-3'>
              <label className='mb-2 w-50'>Schedule Date</label>
              <DatePicker className='form-control form-control-lg' showIcon placeholderText="Select Date"
                selected={scheduleDate} onChange={(date) => setscheduleDate(date)}
                minDate={new Date()}
              />
            </div>

            <div className='d-flex gap-2 align-items-center'>
              <label className='mb-2 w-50'>Schedule Time</label>
              <DatePicker
                showIcon
                className='form-control form-control-lg custom-datepicker'
                selected={scheduleTime}
                onChange={(date) => setscheduleTime(date)}
                showTimeSelect showTimeSelectOnly timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText="Select Time"
                minDate={new Date()}
                minTime={moment().clone().set({ hour: 9, minute: 0, second: 0 }).toDate()}
                maxTime={moment().clone().set({ hour: 20, minute: 0, second: 0 }).toDate()}
              />
            </div>

            <div className='row mt-4'>
              <Col xs={4}> <Form.Label>Notify Tenants</Form.Label></Col>
              <Col xs={6}> <Form.Check type='checkbox' defaultChecked={checkedNotify} onChange={toggleNotify} /></Col>
            </div>

          </div>

          <div className='my-3 d-flex justify-content-end'>
            <Button type='submit' $loading={status === THUNK_STATUS.LOADING} >Submit</Button>
          </div>
        </Form>

      </Modal.Body>
    </Modal>
  );
}



