import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { FloatingLabel, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { setLandlordProfileOnboardFormData, setprofileOnboardFormData } from "../../../../lib/redux/slices/user.slice"
import Layout from "../../../../components/_layout/Layout.component"
import OnBoarding from "../../../../components/authLayout/onBoardInfomation/OnBoarding.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import Button from "../../../../components/_ui/button/Button.component"
import * as yup from 'yup'
import background_image from '../../../../assets/images/register/background_004.jpg';




export default function StepProperty4() {
  const dispatch = useReduxDispatch()
  const { formData } = useReduxSelector(state => state.user.landlordProfileOnboard)
  const navigate = useNavigate()

  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: formData.description || ''
    }
  })


  const onSubmit = async (data: FormData) => {
    dispatch(setLandlordProfileOnboardFormData(data))
    navigate('/landlord/profile/step-5')
  }


  return (
    <Layout title='Landlord Step 4' user={true} header={false} footer={false}>
      <OnBoardProfileLayout
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Description' description='Write a description about your property and what you expect from your tenants.' />

            {/* Form */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel controlId='description' label='Write a description about your home...'>
                <Form.Control autoFocus as='textarea' placeholder='ignore' isInvalid={Boolean(errors.description)} {...register('description')} style={{ height: 225 }} />
              </FloatingLabel>
              <Form.Text className='text-danger'>{errors.description?.message}</Form.Text>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit'>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  description: yup.string().max(10000, 'Explain under ${max} characters').required(),
})

type FormData = yup.InferType<typeof schema>