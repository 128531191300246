import { createBrowserRouter } from "react-router-dom"
import { authRoutes } from "./auth.route"
import { publicRoutes } from "./public.route"
import { protectedRoutes } from "./protected.route"



const routes = createBrowserRouter([
  ...authRoutes,
  ...publicRoutes,
  ...protectedRoutes,
])


export default routes