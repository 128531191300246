import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AlertProps } from './Alert.interface'
import { StyledAlert } from './Alert.style'
import { MdInfo, MdClose, MdCheckCircle, MdOutlineWarning } from "react-icons/md"



export default function Alert(props: AlertProps) {
  const { className, children, $close, $variant } = props
  const [close, setClose] = useState(false)


  if (close) return <></>
  return (
    <StyledAlert className={`mb-4 ${className}`} $variant={$variant}>
      <Row className='g-2 align-items-start'>

        {/* Icon */}
        <Col xs="auto" className='d-flex'>
          {!$variant && <MdInfo className='fs-5 alert-icon' />}
          {$variant === 'info' && <MdInfo className='fs-5 alert-icon' />}
          {$variant === 'success' && <MdCheckCircle className='fs-5 alert-icon' />}
          {$variant === 'warning' && <MdOutlineWarning className='fs-5 alert-icon' />}
          {$variant === 'danger' && <MdInfo className='fs-5 alert-icon' />}
        </Col>


        {/* Content */}
        <Col>
          <div className='text-secondary'>
            {children}
          </div>
        </Col>


        {/* Close */}
        {$close &&
          <Col xs="auto">
            <MdClose className='fs-6 ms-2 cursor-pointer' onClick={() => setClose(true)} />
          </Col>
        }

      </Row>
    </StyledAlert>
  )
}
