import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Container, Row, Spinner, Stack } from 'react-bootstrap'
import { MdDirectionsCar, MdHotel, MdOutlineBathtub } from 'react-icons/md'

import { PropertyDTO } from '../../../dtos/Property.dto'
import { useReduxDispatch } from '../../../hooks/redux'
import { getPropertiesListByShortlistedTenantThunk } from '../../../lib/redux/actions/property.action'
import Layout from '../../../components/_layout/Layout.component'
import Card from '../../../components/_ui/card/Card.component'
import placeholderImage from '../../../assets/images/placeholder.jpg'
import Typography from '../../../components/_ui/typography/Typography.component'
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component'


export default function TenantShortlistedProperties() {

  const PAGE_SIZE = 6
  const lastPropertyRef = useRef<any>()
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [properties, setProperties] = useState<PropertyDTO[]>([])


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !loading) fetchProperties()
    })

    if (lastPropertyRef.current && hasMore) observer.observe(lastPropertyRef.current)

    return () => {
      if (lastPropertyRef.current) observer.unobserve(lastPropertyRef.current)
    }
  }, [properties])


  const fetchProperties = async () => {
    setLoading(true)
    await dispatch(getPropertiesListByShortlistedTenantThunk({ size: PAGE_SIZE, page })).unwrap()
      .then(response => {
        console.log(response?.data?.result)
        const data = response?.data?.result
        if (!Boolean(data?.length)) return setHasMore(false)
        setProperties(prev => [...prev, ...data])
        setPage(prev => prev + 1)
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }


  useEffect(() => { fetchProperties() }, [])


  return (
    <Layout title='Properties'>
      <Container>
        <div className='d-flex justify-content-between align-items-start mb-heading'>
          <Typography $variant='h1' className='mb-0 d-flex align-items-center gap-2'>Shortlisted Properties</Typography>
        </div>

        <Stack gap={4}>
          {Boolean(properties?.length) &&
            <div>
              <Row className='row-gap-4'>
                {properties && properties.map((item: any, index) =>
                  <Col xs={12} sm={6} md={4} key={index}>
                    <Card className='p-0 overflow-hidden d-block position-relative' $hover>
                      <Link to={`/properties/profile/${item?.property?._id}`/*`edit/${item._id}`*/}>
                        <img src={item?.property?.image[0] || placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75' }} />
                      </Link>

                      <div className='p-3 d-flex flex-column gap-3'>
                        <Stack direction='horizontal' className='justify-content-between'>
                          <div className='center flex-column'>
                            <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                              <MdHotel className='fs-3' /> {item?.property?.numberOfRoom}
                            </div>
                            <Typography $variant='body3' className=''>Bedooms</Typography>
                          </div>
                          <div className='center flex-column'>
                            <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                              <MdOutlineBathtub className='fs-3' /> {item?.property?.numberOfWashroom}
                            </div>
                            <Typography $variant='body3' className=''>Bathrooms</Typography>
                          </div>
                          <div className='center flex-column'>
                            <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                              <MdDirectionsCar className='fs-3' /> {item?.property?.numberOfParking}
                            </div>
                            <Typography $variant='body3' className=''>Parking</Typography>
                          </div>
                        </Stack>
                        <Typography $variant='subtitle2' title={item.property.address.name} className='text-dark text-nowrap fw-semibold overflow-hidden text-overflow-ellipsis'>
                          {item.property.address.name}
                        </Typography>
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
              <div ref={lastPropertyRef} />
            </div>
          }

          {loading && <Spinner className='m-auto d-block' variant='secondary' />}
          {!loading && !Boolean(properties.length) && <NoRecordFound className='mb-0' variant='light'>No records found</NoRecordFound>}
        </Stack>
      </Container>
    </Layout>
  )
}
