import { MdCheckCircle } from "react-icons/md"
import { styled } from "styled-components"



export const StyledCheckIcon = styled(MdCheckCircle)`
  position: absolute;
  top:15px;
  left:15px;
  color: ${({ theme }) => theme.primary.main};
  font-size: 21px;
`
