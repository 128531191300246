import { setLocale } from 'yup'



export default setLocale({
  mixed: {
    default: (props) => `Enter valid value`,
    required: (props) => `Field is required *`,
    notType: (props) => `Enter valid value`,
  },
  string: {
    min: (props) => `Enter valid value`,
    max: (props) => `Enter valid value`,
    email: 'Enter valid email',
    matches: (props) => `Enter valid value`,
  },
  number: {
    min: (props) => `Enter valid value`,
    max: (props) => `Enter valid value`,
    moreThan: (props) => `Enter valid value`,
    positive: (props) => `Enter valid value`,
  }
})



function handleName(name: any, firstLetterCapital = false) {
  name = name.split('.')
  name = name[name.length - 1]

  const wordsArray = name.split(/(?=[A-Z])/)
  name = wordsArray.map((word: any) => word.toLowerCase()).join(' ')

  if (firstLetterCapital) name = name.replace(/^./, name[0].toUpperCase())

  return name
}