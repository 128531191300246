import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Tooltip, OverlayTrigger, FloatingLabel, Form, Row, Col } from "react-bootstrap"
import { yupResolver } from '@hookform/resolvers/yup'
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import background_image from '../../../../assets/images/register/background_001.jpg';
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import { MdInfoOutline } from 'react-icons/md'


export default function ProfileOnBoardStep7() {
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)

  const navigate = useNavigate()

  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: user?.profile?.description || ''
    }
  })


  const onSubmit = async (data: FormData) => {
    dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
      dispatch(getProfileThunk()).unwrap().then(() => {
        navigate('/tenant/profile/photos')
      }).catch((error) => console.log('error', error))
    }).catch((error) => console.log('error', error))

    // dispatch(setprofileOnboardFormData(data))
    // navigate('/tenant/profile/step-8')
  }

  return (
    <Layout title='Tenant Step 7' user={true} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress="70"
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='About Me' description="Write about yourself and what kind of house you're looking for" />

            {/*Write a description about yourself and why you would be a good fit for the landlords property*/}

            

            <Form onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel controlId='description' label='Description'>
                <Form.Control autoFocus as='textarea' placeholder='ignore' isInvalid={Boolean(errors.description)} {...register('description')} style={{ height: 100, minHeight: 100 }} />
              </FloatingLabel>
              <Form.Text className='text-danger'>{errors.description?.message}</Form.Text>

             

              <Row className="mt-3">
                <Col className="d-flex">
                  <OverlayTrigger overlay={<Tooltip>Hi my name is {user?.name} and im looking for a 3 bedroom house in the area, I like to keep the gardens well maintained and I'm very friendly with all my neighbours, please consider me for your property.</Tooltip>}>
                    <span className='underline'>
                      <u>See an example</u>
                    </span>
                  </OverlayTrigger>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <Button type='submit' $variant='filled' $loading={status === THUNK_STATUS.LOADING}  /*disabled={!Boolean(user?.profile?.description?.length)}*/>Continue</Button>
                </Col>
              </Row>
  

 
            </Form>


            
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  description: yup.string().max(10000, 'Explain under ${max} characters').required(),
})

type FormData = yup.InferType<typeof schema>