import { SettingLayoutProps } from './SettingLayout.interface'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarNav from '../sidebarNav/SidebarNav.component'



export default function SettingLayout(props: SettingLayoutProps) {
  const { children } = props


  return (
    <Container>
      <Row className=' gap-4 gap-md-0'>
        <Col xs={12} md={4} lg={3} className='d-none d-md-block'>
          <SidebarNav />
        </Col>
        <Col xs={12} md={8} lg={9}>
          {children}
        </Col>
      </Row>
    </Container>
  )
}
