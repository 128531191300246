import { createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { Status } from '../../../interfaces/Status.type'
import { landlordDashboardThunk } from '../actions/landlord.actions'



/* == Initial State == */
const initialState: ILandlordSlice = {
  landlordDashboard: {
    status: '',
    data: null,
  },
}



/* == Slice == */
export const landlordSlice = createSlice({
  name: 'landlord',
  initialState,
  reducers: {},


  extraReducers: (builder) => {

    {/* Update Landlord Profile */ }
    builder.addCase(landlordDashboardThunk.pending, (state, action) => {
      state.landlordDashboard.status = THUNK_STATUS.LOADING
    })

    builder.addCase(landlordDashboardThunk.fulfilled, (state, action) => {
      state.landlordDashboard.status = THUNK_STATUS.SUCCESS
      state.landlordDashboard.data = action.payload.data.dashborad_info
    })

    builder.addCase(landlordDashboardThunk.rejected, (state, action) => {
      state.landlordDashboard.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface ILandlordSlice {
  landlordDashboard: {
    status: Status
    data: ILandlordDashboard | null
  }
}

export interface ILandlordDashboard {
  available_tenants: number,
  property_count: number
  shortlist_count: number
}



export const { } = landlordSlice.actions
export default landlordSlice.reducer
