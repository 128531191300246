export const removeFirstSimilarItemFromArray = (data: (string | undefined)[]) => {
  data.reverse()
  const uniqueArray: any[] = []
  const seenItems = new Set()

  data.forEach(item => {
    item = JSON.stringify(item)
    if (!seenItems.has(item) && item) {
      seenItems.add(item)
      uniqueArray.push(JSON.parse(item))
    }
  })

  return uniqueArray.reverse()
}



export const isVerificationImage = (value: any) => {

  let data = value?.filter((item: any) => item?.image !== "" ? true : false)
  return !Boolean(data?.length)
}