import { useEffect } from "react"
import { Col, Container, Row, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { handleSteps, setRegisterFormData } from "../../../../lib/redux/slices/auth.slice"
import { useForm } from 'react-hook-form'
import { stringTest } from "../../../../utils"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import Steps from "../component/steps/Steps.component"
import Button from "../../../../components/_ui/button/Button.component"
import RegisterLayout from "../component/registerLayout/RegisterLayout.component"
import background_image from '../../../../assets/images/register/background_002.jpg';



export default function RegisterStep2() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { formData } = useReduxSelector(state => state.auth.register)
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })


  useEffect(() => {
    if (!formData.userRoles) navigate('/auth/register/step-1')
  }, [formData])


  const onSubmit = (data: FormData) => {
    dispatch(handleSteps(3))
    dispatch(setRegisterFormData(data))
  }


  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    dispatch(setRegisterFormData({ name: event.currentTarget.value }))
  }


  return (
    <Layout title='Register Step 2' user={false} header={false} footer={false}>

      <RegisterLayout
        headerActionTitle="Already have an account?"
        headerActionButtonText="Sign In"
        headerActionButtonLink="/auth/login"
        progress="35"
        bannerText="Finding a rental has never been easier"
        columnFirst={
          <>
            {/* <Steps active={2} /> */}

            <div className='d-flex flex-column gap-1 mb-3'>
              <Typography $variant='body1'>Step 1</Typography>
              <Typography $variant="h3">Enter your name</Typography>
            </div>

            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Control type="text" defaultValue={formData.name} onKeyUp={handleChange} className="fs-6" size="lg" placeholder="John Smith" isInvalid={Boolean(errors.name)} autoFocus={false} {...register('name')} />
                <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
              </Form.Group>

              <Row>
                <Col>
                  <Button type='submit' className='w-100' disabled={!Boolean(formData.name?.length)}>Continue</Button>
                </Col>
              </Row>

            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  name: yup.string().trim().required().min(2).max(100).test(stringTest)
})

type FormData = yup.InferType<typeof schema>