import { styled } from "styled-components"
import AuthBg from '../../../assets/images/checkout/auth_bg.svg'



export const VerifyBgDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  `

export const CardAuthBox = styled.div`
  max-width: '350px';
  margin: 'auto';
  background: url(${AuthBg});
  background-size: 'cover';
  background-repeat: 'no-repeat';
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: var(--bs-border-radius);
  padding: 1rem;
  transition: box-shadow 0.3s;
`

export const CardVerifyBox = styled.div`
  position: relative;
  max-width: '350px';
  margin: 'auto';
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: var(--bs-border-radius);
  padding: 1rem;
  transition: box-shadow 0.3s;
  `

  export const BgColorGetBlueTick = styled.div`
  background: linear-gradient(261deg, #effff7 -11.65%, #edfff1 152.54%);
  padding: 18px;
  bottom: 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #c4efcd
  `

  export const BgColorGetBlueTickStatic = styled.div`
  background: linear-gradient(261deg, #effff7 -11.65%, #edfff1 152.54%);
  padding: 18px;
  bottom: 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: var(--bs-border-radius);
  border: 1px solid #c4efcd
  `