import React from 'react'
import { Spinner } from 'react-bootstrap'


export default function Loader() {
  return (
    <div className="center h-100">
      <Spinner />
    </div>
  )
}
