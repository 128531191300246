import { createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { Status } from '../../../interfaces/Status.type'
import { tenantDashboardThunk, updateIsLiveForPropertiesThunk } from '../actions/tenant.action'



/* == Initial State == */
const initialState: ITenantSlice = {
  tenantDashboard: {
    status: '',
    data: null,
  },
  updateIsLiveForProperties: {
    status: '',
    data: null,
  },
}



/* == Slice == */
export const tenantSlice = createSlice({
  name: 'landlord',
  initialState,
  reducers: {},


  extraReducers: (builder) => {

    {/* Update Tenant Profile */ }
    builder.addCase(tenantDashboardThunk.pending, (state, action) => {
      state.tenantDashboard.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantDashboardThunk.fulfilled, (state, action) => {
      state.tenantDashboard.status = THUNK_STATUS.SUCCESS
      state.tenantDashboard.data = action.payload.data.dashborad_info
    })

    builder.addCase(tenantDashboardThunk.rejected, (state, action) => {
      state.tenantDashboard.status = THUNK_STATUS.FAILED
    })

    {/* update Is Live For Properties Profile */ }
    builder.addCase(updateIsLiveForPropertiesThunk.pending, (state, action) => {
      state.updateIsLiveForProperties.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updateIsLiveForPropertiesThunk.fulfilled, (state, action) => {
      state.updateIsLiveForProperties.status = THUNK_STATUS.SUCCESS
      state.updateIsLiveForProperties.data = action.payload.data
    })

    builder.addCase(updateIsLiveForPropertiesThunk.rejected, (state, action) => {
      state.updateIsLiveForProperties.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface ITenantSlice {
  tenantDashboard: {
    status: Status
    data: ITenantDashboard | null
  }
  updateIsLiveForProperties: {
    status: Status
    data: any | null
  }
}

export interface ITenantDashboard {
  shortlist_count: number,
  profile_view_count: number
  property_count: number
}



export const { } = tenantSlice.actions
export default tenantSlice.reducer
