import { combineReducers } from "redux"
import layoutSlice from "./layout.slice"
import authSlice from "./auth.slice"
import propertySlice from "./property.slice"
import userSlice from "./user.slice"
import uploadSlice from "./upload.slice"
import planSlice from "./plan.slice"
import tenantSlice from "./tenant.slice"
import landlordSlice from "./landlord.slice"
import adminSlice from "./admin.slice"
import agentSlice from "./agent.slice"
import chatSlice from "./chat.slice"
import packageSlice from "./package.slice"



const rootReducer = combineReducers({
  layout: layoutSlice,
  auth: authSlice,
  property: propertySlice,
  user: userSlice,
  upload: uploadSlice,
  plan: planSlice,
  package: packageSlice,
  tenant: tenantSlice,
  landlord: landlordSlice,
  admin: adminSlice,
  agent: agentSlice,
  chat: chatSlice

})


export default rootReducer