import Typography from "../../../../components/_ui/typography/Typography.component";
import Card from "../../../../components/_ui/card/Card.component";
import { OverviewCardProps } from "./overview.interface";
import { MdCalendarToday, MdCottage, MdShowChart } from "react-icons/md";
export default function OverviewCard(props: OverviewCardProps) {
  const { title, subTitle, description, type } = props;
  return (
    <Card className="p-4">
      <Typography $variant="h5">{title}</Typography>

      <div className="d-flex flex-column align-items-center my-5">
        {type === "matches" ? (
          <MdCottage color="#14b514" size={50} className="mb-3" />
        ) : (
          <MdShowChart color="#14b514" size={50} className="mb-3" />
        )}
        <Typography $variant="h4" className={"mb-3 text-center"}>
          {subTitle}
        </Typography>
        <Typography $variant="body2" className={"text-center"}>
          {description}
        </Typography>
      </div>
    </Card>
  );
}
