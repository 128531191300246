import { TenantCardProps } from './TenantCard.interface'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../components/_ui/card/Card.component'
import Typography from '../../components/_ui/typography/Typography.component'
import Avatar from '../../components/avatar/Avatar.component'
import placeholderImage from '../../assets/images/placeholder.jpg'
import { MdVerified } from 'react-icons/md'



export default function TenantCard(props: TenantCardProps) {
  const { data, propertyId, className = '', size = 'medium' } = props
  const user = data?.user_data || data?.user

  const tenantId = user?._id || data._id
  const profile = data?.user?.profile?.profilePhotos?.[0] || data?.profilePhotos?.[0] || data?.profile?.profilePhotos[0]
  const link = `/profile/${tenantId}${propertyId ? `?propertyId=${propertyId}` : ''}`
  const isBlueTick = data?.isBlueTickNeeded || data?.profile?.isBlueTickNeeded

  return (
    <Card className={`p-0 overflow-hidden d-block position-relative ${className}`} as={Link} to={link} $hover>
      {data?.isBoosted ? <Badge as={Typography} $variant='body3' className='position-absolute z-1 top-15 left-15 text-white text-capitalize py-2 fw-light'>Boost</Badge> : null}
      <img src={profile || placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75', opacity: .9 }} />
      <div className={`${size === 'small' ? 'px-3 py-2' : 'p-3'} d-flex justify-content-between gap-3`}>
        <Typography $variant='subtitle2' className='text-dark fw-semibold align-self-center'>
          {user?.name || data.name} <span>{isBlueTick === 'accept' ? <MdVerified className='text-blue' /> : null}</span>
        </Typography>
        <Avatar />
      </div>
    </Card>
  )
}