import { useEffect, useState, useCallback } from 'react'
import { Container, Stack, Form, Modal, Row, Col } from 'react-bootstrap'
import { uploadFileThunk } from '../../../lib/redux/actions/upload.action'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { MdCheck, MdRemoveRedEye, MdOutlineClose } from "react-icons/md"
import { Link } from 'react-router-dom'
import { updateTenantDocumentsThunk } from '../../../lib/redux/actions/plan.action'
import { getProfileThunk } from '../../../lib/redux/actions/auth.action'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'
import Alert from '../../../components/_ui/alert/Alert.component'
import toast from 'react-hot-toast'



export default function TenantVerify() {
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)
  const { userRoles } = useReduxSelector(state => state.auth)
  const [documents, setDocuments] = useState([
    { type: 'drivers_license_front', name: 'Drivers License (Front)', loading: false, show: false, image: '' },
    { type: 'drivers_license_back', name: 'Drivers License (Back)', loading: false, show: false, image: '' },
  ])


  useEffect(() => {
    const verificationDocuments = user?.profile?.verificationDocuments || []
    verificationDocuments?.forEach((item: any) => {
      setDocuments(items => {
        return items.map(docItem => {
          if (docItem.type === item.type) docItem = { ...docItem, ...item }
          return docItem
        })
      })
    })
  }, [])


  const handleLoading = (value: boolean, index: number) => {
    setDocuments(items => {
      items[index].loading = value
      return [...items]
    })
  }


  const handleImageShow = useCallback((value: boolean, index: number) => {
    setDocuments(items => {
      items[index].show = value
      return [...items]
    })
  }, [documents])


  const handleUpdateDocument = async (data: any[]) => {
    let finalData = data?.map(item => ({ image: item.image, type: item.type }))
    let isImage = finalData.every(doc => !doc.hasOwnProperty("image") || doc.image === "");

    if (!isImage) {
      await dispatch(updateTenantDocumentsThunk({ id: user?._id as string, verificationDocuments: finalData })).unwrap()
        .then(() => dispatch(getProfileThunk()))
        .catch((error) => { console.log('error') })
    } else {
      toast.error('Please select at least document')
    }
  }


  const handleUploadFile = useCallback(async (event: React.ChangeEvent<any>, index: number) => {
    if (!event.target.files) return
    handleLoading(true, index)
    const formData = new FormData()
    formData.append(`files`, event.target.files[0])
    formData.append('folder', 'TenantDocuments')

    await dispatch(uploadFileThunk(formData))
      .unwrap()
      .then(async (response) => {
        let updatedDocuments = JSON.parse(JSON.stringify(documents))
        updatedDocuments[index].image = response.data.urls[0]
        await handleUpdateDocument(updatedDocuments)
        setDocuments(items => { items[index].image = response.data.urls[0]; return items })
      })
      .catch(() => { })

    handleLoading(false, index)
  }, [documents])


  const handleRemoveFile = useCallback(async (index: number) => {
    handleLoading(true, index)
    let updatedDocuments = JSON.parse(JSON.stringify(documents))
    updatedDocuments[index].image = ''

    await handleUpdateDocument(updatedDocuments)
      .then(() => setDocuments(items => { items[index].image = ''; return items }))
      .catch(() => { })

    handleLoading(false, index)
  }, [documents])


  return (
    <Layout title='Property Verify' user={true}>
      <Container>
        {userRoles.includes('tenant') && (user?.profile?.isBlueTickNeeded === 'document_pending') && Boolean(user?.tenantPlanPurchase?.price) &&
          <Alert $variant='success'>
            <div className='d-flex g-3'>
              <div className='alert-heading'>Payment Done :-</div>
              <div>Thank you for your payment. Please upload documents below</div>
            </div>
          </Alert>
        }
        <Typography $variant='h1'>Verify Documents</Typography>

        <div>
          <Stack gap={3}>
            {documents.map((item, index) =>
              <div className='border bg-white p-3 rounded d-flex align-items-center justify-content-between' key={index} style={{ minHeight: 75 }}>
                <Typography $variant='subtitle2'>
                  {item.image && <MdCheck className='text-success fs-4 me-2' />}
                  {item.name}
                </Typography>
                <div className='d-flex align-items-center gap-2'>
                  {!item.image ?
                    <div className='position-relative'>
                      <Button $variant='outlined' $loading={item.loading}>Upload</Button>
                      <Form.Control type='file' disabled={item.loading} accept="image/*" title='' tabIndex={0} className='position-absolute inset-0 opacity-0' onChange={(event) => handleUploadFile(event, index)} />
                    </div>
                    :
                    <>
                      <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleImageShow(true, index)} disabled={item.loading}>
                        <MdRemoveRedEye className='fs-5' />
                      </Button>
                      <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleRemoveFile(index)} $loading={item.loading}>
                        <MdOutlineClose className='fs-5' />
                      </Button>
                    </>
                  }
                </div>
              </div>
            )}
          </Stack>

          <div className='d-flex justify-content-end mt-5'>
            <Button as={Link} to='/'>Go To Dashboard</Button>
          </div>
        </div>


        {documents.map((item, index) =>
          <Modal show={item.show} key={index} onHide={() => handleImageShow(false, index)}>
            <Modal.Header closeButton>
              <Typography $variant='subtitle1' className='text-dark'>{item.name}</Typography>
            </Modal.Header>
            <Modal.Body>
              <img src={item.image} className='rounded' />
            </Modal.Body>
          </Modal>
        )}
      </Container>
    </Layout>
  )
}
