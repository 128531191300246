import { Navigation } from 'swiper/modules'
import { Controller } from 'react-hook-form'
import { MdAdd, MdClose } from 'react-icons/md'
import { Col, Form, Row, Stack } from 'react-bootstrap'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { ChangeEvent, useRef } from 'react'
import 'swiper/css/navigation'
import 'swiper/css'

import { StyledImageBox, StyledUploadImage } from '../profileForm/ProfileForm.style'
import Typography from '../../../../../components/_ui/typography/Typography.component'
import Button from '../../../../../components/_ui/button/Button.component'
import ProfilePlaceholder from '../../../../../assets/images/profile-placeholder.svg'



export default function TenantProfileUpload(props: any) {
  const swiper = useRef<SwiperRef | null>(null)
  const MAX_IMAGE_UPLOAD = 5
  const { name, control, label, placeholder, helperText } = props
  const inputRef = useRef<HTMLInputElement>()

  const handleClick = () => {
    inputRef.current?.click()
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { ref, onChange, value } }) => {
        // const imageLink = value ? typeof value === 'string' ? value : URL.createObjectURL(value) : ''
        const errorImage: any = error || []
        const handleChange = ({ target: { files, } }: ChangeEvent<HTMLInputElement>) => {
          if (!files || files.length === 0) return;
          const selectedFiles = Array.from(files).slice(0, MAX_IMAGE_UPLOAD);
          let items = [...value, ...selectedFiles]
          if (items.length > MAX_IMAGE_UPLOAD) alert(`You can upload maximum ${MAX_IMAGE_UPLOAD} images.`)
          items = items.slice(0, MAX_IMAGE_UPLOAD)
          onChange(items);
        };


        const handleRemove = (index: number) => {
          let items = value.filter((item: any, itemIndex: number) => index !== itemIndex)
          if (inputRef?.current?.value) inputRef.current.value = '';
          onChange(items)
        }

        return <>
          <Swiper
            className='mb-4 styled animate__animated animate__fadeIn'
            ref={swiper}
            slidesPerView={1}
            spaceBetween={1}
            autoHeight
            navigation={true}
            modules={[Navigation]}
          >
            {Boolean(value?.length) && value.map((item: any, index: number) =>
              <SwiperSlide key={index}>
                <img src={typeof item === 'object' ? URL.createObjectURL(item) : item} className='w-100 h-100 rounded border border-light-subtle' style={{ maxHeight: 'max(400px, calc(100vh - 300px))' }} />
              </SwiperSlide>
            )}
            {
              !Boolean(value?.length) && <SwiperSlide>
                <img src={ProfilePlaceholder} className='w-100 h-100 rounded border border-light-subtle' style={{ maxHeight: 'max(400px, calc(100vh - 300px))' }} />
              </SwiperSlide>
            }
          </Swiper>


          <Row className='g-3'>
            <Col md={3} xs={6}>
              <Form.Group controlId='upload' className='h-100'>
                <StyledUploadImage className='center position-relative rounded border px-2 py-3 border-dashed h-100  bg-light'>
                  <Stack gap={1} className='center'>
                    <Form.Control type='file' accept='image/*' title='' tabIndex={-1} multiple className='position-absolute inset-0 opacity-0'
                      onChange={handleChange} ref={(el: HTMLInputElement) => { ref(el); inputRef.current = el }} />
                    <MdAdd className='fs-1 text-secondary' />
                    <Typography>Upload Photo</Typography>
                  </Stack>
                </StyledUploadImage>
              </Form.Group>
            </Col>


            {Boolean(value.length) && value?.map((item: any, index: number) =>
              <StyledImageBox as={Col} xs={6} sm={6} md={4} lg={3} key={index}>
                <div className='h-100 border rounded overflow-hidden position-relative animate__animated animate__fadeIn'>
                  <div className='tools d-flex gap-1'>
                    <Button className='bg-body' $variant='text' $icon type='button'
                      onClick={() => handleRemove(index)}
                    >
                      <MdClose className='fs-6' color={'#f00'} />
                    </Button>
                  </div>
                  <img key={index} src={typeof item === 'object' ? URL.createObjectURL(item) : item}
                    className='w-100 h-100 cursor-pointer cover-photo'
                    onClick={() => swiper?.current?.swiper.slideTo(index)}
                  />
                </div>
                {errorImage && (<Form.Text className='text-danger'>{errorImage[index]?.message || ''}</Form.Text>)}
              </StyledImageBox>
            )}

          </Row>
        </>
      }}
    />
  )
}
