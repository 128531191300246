import { Col, Row } from "react-bootstrap";
import Typography from "../../../../components/_ui/typography/Typography.component";
import Pill from "../../../../components/_ui/pill/Pill";
import { TagsProps } from "./ProfileListing.interface";
export default function Tags(props: TagsProps) {
  const { payload, title } = props;

  return (
    <Row>
      <Col xs={12} md={12}>
        <div className="pb-4 border-bottom">
          <Typography $variant="h6" className={"mb-4"}>
            {title}
          </Typography>

          <div className="d-flex flex-wrap">
            {payload &&
              payload?.map((item, index) => {
                return (
                  item && (
                    <Pill key={index} text={item?.text} icon={item?.icon} />
                  )
                );
              })}
          </div>
        </div>
      </Col>
    </Row>
  );
}
