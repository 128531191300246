import * as yup from 'yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Row, Col, Form } from 'react-bootstrap'

import { isValidPhoneNumber } from "react-phone-number-input"
import { logout } from '../../../lib/redux/slices/auth.slice'
import { useReduxSelector, useReduxDispatch } from '../../../hooks/redux'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { closeMyAccountThunk } from '../../../lib/redux/actions/user.action'
import { getProfileThunk, updateProfileThunk } from '../../../lib/redux/actions/auth.action'
import { ConfirmReason } from '../../../components/modalPopup/confirmReason/ConfirmReason.component'
import Card from '../../../components/_ui/card/Card.component'
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'
import Typography from "../../../components/_ui/typography/Typography.component"
import SettingLayout from "../components/settingLayout/SettingLayout.component"
import PhoneInput from '../../../components/_ui/phoneInput/PhoneInput.component'



export default function TenantProfile() {
  const closeAccountDataConfirm = {
    heading: "Close My Account",
    description: "Are you sure you want to close account?",
    buttonName: "Close Account",
  }

  const dispatch = useReduxDispatch()
  const { user, userRoles, } = useReduxSelector(state => state.auth)
  const { closeMyAccount } = useReduxSelector(state => state.user)
  const { status } = useReduxSelector(state => state.auth.updateProfile)
  const { handleSubmit, register, control, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name || '',
      dateOfBirth: user?.dateOfBirth || undefined,
      gender: user?.gender || undefined,
    }
  })

  const [phone, setPhone] = useState<string>(user?.phone || '')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [closeAccountShowModal, setCloseAccountShowModal] = useState(false)
  const [closeAccountReason, setCloseAccountReason] = useState('')


  const handleChange = (value: any) => {
    setPhone(value || '')
  }


  const onSubmit = (formData: FormData) => {
    setIsSubmitted(true)
    if (!isValidPhoneNumber(phone)) return
    dispatch(updateProfileThunk({ ...formData, phone })).unwrap()
      .then((response) => {
        dispatch(getProfileThunk()).unwrap().then().catch((err) => console.log(err))
      })
  }


  const handleCloseAccount = async () => {
    await dispatch(closeMyAccountThunk({ closeAccountReason })).unwrap()
      .then((res) => {
        dispatch(logout())
      })
      .catch((err) => console.log(err))
  }


  return (
    <Layout title='Account Details' user={true}>
      <SettingLayout>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Account Details</Typography>
          <Typography $variant='body1'>Basic information about your account</Typography>
        </div>

        {/* Description */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card className='mb-3'>
            <Row className='mb-3 g-3' >
              <Col md={12}>
                <Form.Group controlId='name'>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type='text' isInvalid={Boolean(errors.name)} {...register('name')} />
                  <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-3' >
              <Col md={12}>
                <Form.Group controlId='email'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type='text' defaultValue={user?.email} disabled />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-3' >
              <Col md={12}>
                <Form.Group className="w-100" controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <PhoneInput
                    value={phone}
                    onChange={handleChange}
                    className={(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}
                  />
                  <Form.Text className="text-danger">{isSubmitted && !isValidPhoneNumber(phone) && 'Enter valid phone'}</Form.Text>
                </Form.Group>
              </Col>
            </Row>

            {/* Date of birth  */}
            <Row className='mb-3' >
              <Col md={12}>
                <Form.Group className="w-100" controlId="phone">
                  <Form.Label>Date of birth</Form.Label>
                  <Controller name="dateOfBirth" control={control} render={({ field }) => (
                    <Form.Control type='date' placeholder='Date of birth' {...field} isInvalid={Boolean(errors.dateOfBirth?.message)} />
                  )} />
                  <Form.Text className='text-danger'>{errors.dateOfBirth?.message}</Form.Text>
                </Form.Group>
              </Col>
            </Row>


            {/* Gender  */}
            <Row className='mb-3'>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <Controller name="gender" control={control} render={({ field: { value, ...rest } }) => (
                    <Row>
                      <Col xs={12} sm={4}>
                        <Form.Check type="radio" label="Male" isInvalid={!!errors.gender?.message} value="male" checked={value === 'male'} {...rest} />
                      </Col>
                      <Col xs={12} sm={4}>
                        <Form.Check type="radio" label="Female" isInvalid={!!errors.gender?.message} value="female" checked={value === 'female'} {...rest} />
                      </Col>
                      <Col xs={12} sm={4}>
                        <Form.Check type="radio" label="I'd prefer not to say" isInvalid={!!errors.gender?.message} value="I'd prefer not to say" checked={value === `I'd prefer not to say`} {...rest} />
                      </Col>
                    </Row>
                  )}
                  />
                  <div>
                    {errors.gender && (<Form.Text className='text-danger'>{errors.gender?.message}</Form.Text>)}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Stack gap={3} className='mt-3'>
              <Button $variant='outlined' className='fw-medium' type='submit' $loading={status === THUNK_STATUS.LOADING}>Save</Button>
            </Stack>

            <Stack gap={3} className='mt-3'>
              <Button $variant='text' type='button' className='fw-medium text-muted opacity-50'
                onClick={() => setCloseAccountShowModal(true)}>Close My Account</Button>
            </Stack>
          </Card>
        </Form>
      </SettingLayout>


      <ConfirmReason
        reason={closeAccountReason} setReason={setCloseAccountReason} data={closeAccountDataConfirm}
        show={closeAccountShowModal} onHide={() => setCloseAccountShowModal(false)}
        callBack={() => handleCloseAccount()} loading={closeMyAccount.status === THUNK_STATUS.LOADING} />
    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  name: yup.string().trim().required(),
  dateOfBirth: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, 'Date of birth must be in the format DD-MM-YYYY').required('Date of birth is required'),
  gender: yup.string().required('Gender is required'),
})

type FormData = yup.InferType<typeof schema>