import moment from 'moment'
import { useEffect, useState } from 'react'

import { useReduxSelector } from '../../../../../hooks/redux'



export default function usePackDealHoursDiff() {

  const { user } = useReduxSelector(state => state.auth)
  const { data: packageData } = useReduxSelector(state => state.package.packageDealByType)
  const [dealEndDateTime, setDealEndDateTime] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)


  useEffect(() => {
    if (user && packageData && !user?.tenantPlanPurchase?._id) {
      const signupTime = moment(user?.profile?.createdAt).utc()
      const currentTime = moment().utc()
      const timeDifference = signupTime.add(packageData.ruleCriteria.noOfHoursPrior, 'hours').diff(currentTime)

      if (timeDifference > 0 && timeDifference <= packageData.ruleCriteria.noOfHoursPrior * 60 * 60 * 1000) {
        setDealEndDateTime(signupTime.add(moment.duration(Math.round(moment.duration(timeDifference).asHours())), 'hours'))
        setEndTime(currentTime)
      } else {
        setDealEndDateTime(null)
        setEndTime(null)
      }
    }

  }, [user?.createdAt, packageData?.ruleCriteria.noOfHoursPrior])


  return { dealEndDateTime, endTime }
}
