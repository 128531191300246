import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { Col, Form, Row, Stack } from "react-bootstrap"
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { stringTest } from "../../../../utils"
import { MdClose } from "react-icons/md"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import PhoneInput from "../../../../components/_ui/phoneInput/PhoneInput.component"
import background_image from '../../../../assets/images/register/background_002.jpg';
import GreetingPopup from "../../../../components/authLayout/greetingPopup/GreetingPopup.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"



export default function ProfileOnBoardStep9() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const [congratulations, setCongratulations] = useState(false)
  const { user } = useReduxSelector(state => state.auth)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)


  const { handleSubmit, register, control, watch, formState: { errors }, setValue, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      references: user?.profile?.references || [{ name: '', number: '' }]
    }
  })

  const referencesField = useFieldArray({ name: 'references', control })

  // const sendData = async (data?: FormData, type = true) => {
  //   const { ...finallyData } = formData
  //   if (Boolean(data?.references?.length) && type) {
  //     finallyData.references = data?.references
  //   }
  //   await dispatch(updateTenantProfileThunk(finallyData)).unwrap().then(async (response) => {
  //     dispatch(getProfileThunk())
  //     setCongratulations(true)
  //   }).catch((err) => toast.error('something went wrong'))
  // }


  const onSubmit = async (data: FormData) => {
    try {
      dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          // setCongratulations(true)
          //navigate('/tenant/profile/boost')
          //navigate('/store/upgrade')
          navigate('/')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))

      // dispatch(setprofileOnboardFormData(data))
      // await sendData(data, true)
    }
    catch (error) { console.log('error', error) }
  }


  return (
    <Layout title='Tenant Step 9' user={true} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout

        headerActionTitle=""
        headerActionButtonText="Skip"
        headerActionButtonLink="/"
        progress="90"

        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='References' description='Enter some references so we can verify your profile' />

            <Form onSubmit={handleSubmit(onSubmit)}>
              {referencesField.fields.map((item, index) =>
                <Row className='mb-3 animate__animated animate__fadeInUp animate__faster' key={item.id}>
                  <Col xs={12} sm={5}>
                    <Form.Group controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control type='text' autoFocus placeholder='' isInvalid={Boolean(errors.references?.[index]?.name)} {...register(`references.${index}.name` as const)} />
                      <Form.Text className='text-danger'>{errors.references?.[index]?.name?.message}</Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={5}>
                    <Form.Group className='w-100' controlId='phone'>
                      <Form.Label>Phone</Form.Label>
                      <PhoneInput
                        value={String(watch(`references.${index}.number` as const))}
                        className={errors.references?.[index]?.number ? 'is-invalid' : ''}
                        onChange={(value: string) => {
                          setValue(`references.${index}.number` as any, value)
                          trigger('references')
                        }}
                        numberInputProps={{
                          className: `form-control fs-6 ${errors.references?.[index]?.number ? 'is-invalid' : ''}`,
                          id: 'phone'
                        }}
                      />
                      <Form.Text className='text-danger'>{errors.references?.[index]?.number?.message}</Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs='auto' className='pt-3' >
                    <Button $icon $variant='outlined' className='mt-3' type='button' onClick={() => referencesField.remove(index)}>
                      <MdClose />
                    </Button>
                  </Col>
                </Row>
              )}

              <Stack gap={3}>
                <Button $variant='outlined' className='fw-medium' type='button' onClick={() => referencesField.append({ name: '', number: '' })}>Add Reference</Button>
              </Stack>

              <div className="d-flex justify-content-end mt-4 gap-3">
                <Button $variant='filled' $loading={status === THUNK_STATUS.LOADING} type='submit'>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

      {/* <GreetingPopup open={congratulations} setOpen={setCongratulations} heading='Congratulations!' buttonText='Boost Listing'
        description='Your profile has been created and now all you have to do is wait for a landlord to see your profile.'
        callback={() => { navigate('/store/boost'); setCongratulations(false) }}
        linkTitle='View your profile'
        link='/settings/tenant-profile'
      /> */}

    </Layout>
  )
}


// /* == Validation == */
const schema = yup.object({
  references: yup.array().of(yup.object({
    name: yup.string().trim().required().min(2).max(100).test(stringTest),
    number: yup.string().required().min(8).max(12)
  }))
})

type FormData = yup.InferType<typeof schema>