import { Col, Container, Row, Form } from 'react-bootstrap'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { emailTest } from '../../../utils'
import { StyledForm } from '../login/component/loginForm/LoginForm.style'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { forgotPasswordThunk } from '../../../lib/redux/actions/auth.action'
import RegisterLayout from '../register/component/registerLayout/RegisterLayout.component'
import OnBoardAuthBanner from '../../../components/authLayout/onBoardAuthBanner/onBoardAuthBanner.component'




export default function ForgotPassword(props: any) {
  const { className } = props
  const distpatch = useReduxDispatch()
  const { handleSubmit, register, reset, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })
  const { status } = useReduxSelector(state => state.auth.forgotPassword)


  const onSubmit = (data: FormData) => {
    distpatch(forgotPasswordThunk({ email: data.email })).unwrap()
      .then((response) => reset())
      .catch((err) => console.log('error => ', err))
  }


  return (
    <Layout title='Forgot Password' user={false} footer={false} header={false}>
      <RegisterLayout
        headerActionTitle="Need an account?"
        headerActionButtonText="Create Account"
        headerActionButtonLink="/auth/register/step-1"
        columnFirst={
          <StyledForm as={Form} className={className} noValidate onSubmit={handleSubmit(onSubmit)}>
       
              <div className='mb-4'>
                <Typography $variant='h3' className='m-0'>Forgot Password</Typography>
              </div>

              <Form.Group className='mb-5' controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control type='email' className='fs-6' size='lg' placeholder='john.smith@gmail.com' isInvalid={Boolean(errors.email)} {...register('email')} />
                <Form.Text className='text-danger'>{errors.email?.message}</Form.Text>
              </Form.Group>

              <Row>
                <Col>
                  <Button type='submit' className='w-100' $loading={status === THUNK_STATUS.LOADING}>Reset Password</Button>
                </Col>
              </Row>

          </StyledForm>
        }
        columnSecond={<OnBoardAuthBanner />}
      />

    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  email: yup.string().trim().required().email().min(2).max(100).test(emailTest)
})

type FormData = yup.InferType<typeof schema>