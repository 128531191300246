import styled from 'styled-components'



const StyledHeader = styled.header`
  --nav-item-spacing-y: 20px;
  --nav-item-spacing-x: 15px;

  background: #fff;
  margin-bottom: 20px;
  margin: 20px 0px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: var(--bs-border-radius);
  
  .navbar-nav{
    margin-left: calc(-1 * var(--nav-item-spacing-x));
  }

  .nav-link{
    font-weight: 500;
    padding: var(--nav-item-spacing-y) var(--nav-item-spacing-x) !important;
    position: relative;
    transition: color 0.15s;
    &:hover{
      color: ${({ theme }) => theme.primary.main};
    }
  }

  .nav-link.active{
    color: ${({ theme }) => theme.primary.main};
    /* &:before{
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      background-color: ${({ theme }) => theme.primary.main};
      width: calc(100% - calc(var(--nav-item-spacing-x) * 2));
    } */
  }

  .logo {
    max-width: min(40vw, 200px);
  }
`


export default StyledHeader