import axios from "axios"



{/* APIs */ }
const tenantDashboard = async () => {
  return await axios.get('/user/tenant/dashborad')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const updateIsLiveForProperties = async (data: { isLiveForProperties: boolean }) => {
  return await axios.put('/user/updateIsLiveForProperties', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


export const tenantService = {
  tenantDashboard,
  updateIsLiveForProperties
}