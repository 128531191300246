import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row, Spinner, Stack } from 'react-bootstrap'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SwipeableViews from 'react-swipeable-views'
import PlanCard from './components/planCard/PlanCard.component'

import { PlanDTO } from '../../../dtos/Plan.dto'
import { analytics, logEvent } from '../../../firebase'
import { plansThunk } from '../../../lib/redux/actions/plan.action'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { handleCheckoutModal } from '../../../lib/redux/slices/plan.slice'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import CheckoutPopup from './components/CheckoutPopup/CheckoutPopup.component'
import { packageDealByTypeThunk } from '../../../lib/redux/actions/package.action'
import PackageDeal from './components/packageDeal/PackageDeal.component'


const styles = {
  slide: { padding: 5 }
};



export default function Boost() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { status, data } = useReduxSelector(state => state.plan.plans)
  const { status: checkoutModalStatus } = useReduxSelector(state => state.plan.checkoutModal)

  const [value, setValue] = React.useState(2)
  const [isCheckoutPopupOpen, setIsCheckoutPopupOpen] = useState<boolean>(false)

  const sortDataByPrice = (data: PlanDTO[]): PlanDTO[] => {
    const sortedData = [...data]
    return sortedData.sort((a, b) => a.price - b.price)
  }

  useEffect(() => {
    dispatch(packageDealByTypeThunk({ ruleType: 'newuser' }))
    dispatch(plansThunk({ type: 'boost' }))
  }, [])


  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)
  const handleChangeIndex = (newValue: number) => setValue(newValue)


  const handleSelectPlan = (item: PlanDTO) => {
    logEvent(analytics, 'user_purchase_plan', { type: item.name })
    if (item.price == 0) {
      navigate('/');
      return;
    }
    setIsCheckoutPopupOpen(true)
    dispatch(handleCheckoutModal({
      status: true,
      selectPlanDetail: item
    }))
  }

  const handleCheckoutPopupHide = () => {
    if (!checkoutModalStatus) {
      setIsCheckoutPopupOpen(false)
      dispatch(handleCheckoutModal({
        status: false,
        selectPlanDetail: null
      }))
    }
  }


  return (
    <Layout title='Boost Your Profile' user={true} feedbackShow={false} footer={false} header={false} >
      <Container className='h-100 d-flex flex-column justify-content-center '>

        {/* <PackageDeal /> */}
        <PackageDeal />

        {/* Small screen view */}
        <Stack className='d-md-none'>
          <Row>
            <Tabs value={value} onChange={handleChange} variant="fullWidth" className='mb-0'>
              <Tab label="FREE" />
              <Tab label="Basic" />
              <Tab label="Full" />
            </Tabs>

            <SwipeableViews index={value} onChangeIndex={handleChangeIndex} style={{ padding: '10px' }}>
              {data && sortDataByPrice(data).map((item, index) => (
                <div style={Object.assign({}, styles.slide)} key={index} className={"h-100"}>
                  <PlanCard item={item} handleSelectPlan={handleSelectPlan} />
                </div>
              ))}
            </SwipeableViews>
          </Row>
        </Stack>


        {/* Laptop screen view */}
        <Row className='d-none d-md-flex row-gap-4'>
          {
            data && sortDataByPrice(data).map((item, index) => (
              <Col key={index} md={4}>
                <PlanCard item={item} handleSelectPlan={handleSelectPlan} />
              </Col>
            ))}
        </Row>

        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
        {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}
      </Container>

      <CheckoutPopup show={isCheckoutPopupOpen} onHide={handleCheckoutPopupHide} />

    </Layout >
  )
}


