import { Stack } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

import StripePayment from "../paymentGateways/stripePayment/StripePayment.component"
import Typography from '../../../../../components/_ui/typography/Typography.component'
import PaypalPayment from "../paymentGateways/paypalPayment/PaypalPayment.component"

interface ICheckoutPopupProps {
  show: boolean
  onHide: () => void
}



function CheckoutPopup(props: ICheckoutPopupProps) {


  return (
    <div>
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered animation className="m-width-800">
        <Modal.Header closeButton>
          <Typography $variant="h5" id="contained-modal-title-vcenter">
            Checkout
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={2}>
            <StripePayment />
            <PaypalPayment />
            <Typography $variant='body2' className='fw-medium opacity-75 mb-2'>We do not store card details. These details are passed directly to the bank for processing. Our website uses 256-Bit SSL Encryption for data transfer.</Typography>
          </Stack>
        </Modal.Body>
      </Modal>
    </div>
  )
}


export default CheckoutPopup