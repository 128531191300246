import { useEffect, useState, useCallback } from 'react'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { Container, Alert, Stack, Form, Spinner, Modal } from 'react-bootstrap'
import { MdInfo } from "react-icons/md"
import { propertyDetailsThunk, updatePropertyDocumentsThunk } from '../../../lib/redux/actions/property.action'
import { uploadFileThunk } from '../../../lib/redux/actions/upload.action'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { Link, useParams } from 'react-router-dom'
import { MdCheck, MdRemoveRedEye, MdOutlineClose } from "react-icons/md"
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'



export default function PropertiesVerify() {
  const { propertyId } = useParams()
  const dispatch = useReduxDispatch()
  const { status, data } = useReduxSelector(state => state.property.propertyDetails)
  const { userRoles } = useReduxSelector(state => state.auth)
  const [documents, setDocuments] = useState([
    { type: 'drivers_license_front', name: 'Drivers License (Front)', loading: false, show: false, image: '' },
    { type: 'drivers_license_back', name: 'Drivers License (Back)', loading: false, show: false, image: '' },
    { type: 'council_rates_notice', name: 'Council Rates Notice', loading: false, show: false, image: '' },
  ])


  useEffect(() => {
    dispatch(propertyDetailsThunk({ id: propertyId as string }))
  }, [])


  useEffect(() => {
    if (status === THUNK_STATUS.SUCCESS) {
      const verificationDocuments = data?.verificationDocuments
      verificationDocuments?.forEach((item: any) => {
        setDocuments(items => {
          return items.map(docItem => {
            if (docItem.type === item.type) docItem = { ...docItem, ...item }
            return docItem
          })
        })
      })
    }
    else {
      setDocuments([
        { type: 'drivers_license_front', name: 'Drivers License (Front)', loading: false, show: false, image: '' },
        { type: 'drivers_license_back', name: 'Drivers License (Back)', loading: false, show: false, image: '' },
        { type: 'council_rates_notice', name: 'Council Rates Notice', loading: false, show: false, image: '' },
      ])
    }
  }, [status])


  const handleLoading = (value: boolean, index: number) => {
    setDocuments(items => {
      items[index].loading = value
      return [...items]
    })
  }


  const handleImageShow = useCallback((value: boolean, index: number) => {
    setDocuments(items => {
      items[index].show = value
      return [...items]
    })
  }, [documents])


  const handleUpdateDocument = async (data: any[]) => {
    await dispatch(updatePropertyDocumentsThunk({
      id: propertyId as string,
      verificationDocuments: data.map(item => ({ image: item.image, type: item.type }))
    }))
  }


  const handleUploadFile = useCallback(async (event: React.ChangeEvent<any>, index: number) => {
    if (!event.target.files) return
    handleLoading(true, index)
    const formData = new FormData()
    formData.append(`files`, event.target.files[0])
    formData.append('folder', 'PropertyImage')

    await dispatch(uploadFileThunk(formData))
      .unwrap()
      .then(async (response) => {
        let updatedDocuments = JSON.parse(JSON.stringify(documents))
        updatedDocuments[index].image = response.data.urls[0]
        await handleUpdateDocument(updatedDocuments)
        setDocuments(items => { items[index].image = response.data.urls[0]; return items })
      })
      .catch(() => { })

    handleLoading(false, index)
  }, [documents])


  const handleRemoveFile = useCallback(async (index: number) => {
    handleLoading(true, index)
    let updatedDocuments = JSON.parse(JSON.stringify(documents))
    updatedDocuments[index].image = ''

    await handleUpdateDocument(updatedDocuments)
      .then(() => setDocuments(items => { items[index].image = ''; return items }))
      .catch(() => { })

    handleLoading(false, index)
  }, [documents])

  console.log(userRoles.includes('landlord'))

  return (
    <Layout title='Property Verify' user={true}>
      <Container>
        <Typography $variant='h1'>Verify Details</Typography>


        {status === THUNK_STATUS.SUCCESS &&
          <div>
            <Alert variant='light' className='w-100 mb-0 d-flex align-items-center'>
              <MdInfo className='fs-5 me-2 flex-shrink-0' />
              <span>Please make sure all the rates notice is for "<span className='text-dark fw-medium'>&nbsp;{data?.address.name}&nbsp;</span>" and the drivers license name matches up with the rates notice name.</span>
            </Alert>

            <Stack gap={3} className='my-5'>
              {documents.map((item, index) =>
                <div className='border bg-white p-3 rounded d-flex align-items-center justify-content-between' key={index} style={{ minHeight: 75 }}>
                  <Typography $variant='subtitle2'>
                    {item.image && <MdCheck className='text-success fs-4 me-2' />}
                    {item.name}
                  </Typography>
                  <div className='d-flex align-items-center gap-2'>
                    {!item.image ?
                      <div className='position-relative'>
                        <Button $variant='outlined' $loading={item.loading}>Upload</Button>
                        <Form.Control type='file' disabled={item.loading} accept="image/*" title='' tabIndex={0} className='position-absolute inset-0 opacity-0' onChange={(event) => handleUploadFile(event, index)} />
                      </div>
                      :
                      <>
                        <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleImageShow(true, index)} disabled={item.loading}>
                          <MdRemoveRedEye className='fs-5' />
                        </Button>
                        <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleRemoveFile(index)} $loading={item.loading}>
                          <MdOutlineClose className='fs-5' />
                        </Button>
                      </>
                    }
                  </div>
                </div>
              )}
            </Stack>

            <Typography><b className='text-primary'>Note:</b> Once we have validated your information, your property will be made live. Once its made live you will be able to browse available tenants that fit your cirteria</Typography>

            <div className='d-flex justify-content-end mt-5'>
              <Button as={Link} to={userRoles.includes('landlord') ? '/properties' : '/admin/property/verifications'}>Continue</Button>
            </div>
          </div>
        }


        {documents.map((item, index) =>
          <Modal show={item.show} key={index} onHide={() => handleImageShow(false, index)}>
            <Modal.Header closeButton>
              <Typography $variant='subtitle1' className='text-dark'>{item.name}</Typography>
            </Modal.Header>
            <Modal.Body>
              <img src={item.image} className='rounded' />
            </Modal.Body>
          </Modal>
        )}


        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
        {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}
      </Container>
    </Layout>
  )
}
