import axios from "axios"



{/* APIs */ }
const propertyAdd = async (data: ICreateProperty) => {
  return await axios.post('/property/createProperty', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const propertyListByUser = async (params: { page: number, size: number }) => {
  return await axios.get('/property/getPropertiesByUserId', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const propertyDetails = async (params: { id: string }) => {
  return await axios.get('/property/getPropertyDetails', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const updatePropertyDocuments = async (data: IUpdatePropertyDocuments) => {
  return await axios.post('/property/updatePropertyDocuments', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const updateProperty = async (data: ICreateProperty) => {
  return await axios.put('/property/updateProperty', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const searchTenantsByProperty = async (data: ISearchTenantsByProperty) => {
  return await axios.post('/property/searchTenantsByProperty', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const shortlistedTenantList = async (params: IShortlistedTenantList) => {
  return await axios.get('/property/shortlist/tenantList', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const addTenantInShortlist = async (data: IAddTenantInShortlist) => {
  return await axios.post('/property/shortlist/addTenant', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const shortlistedProperties = async (params: { size: number, page: number }) => {
  return await axios.get('/property/shortlisted/properties', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const shortlistedTenants = async (params: { size: number, page: number }) => {
  return await axios.get('/property/shortlisted/tenants', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const getPropertiesListByShortlistedTenant = async (params: { size: number, page: number }) => {
  return await axios.get('/property/shortlisted/propertiesListByTenant', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const propertyTenantsSchedules = async (data: IPropertyTenantsSchedules) => {
  return await axios.post('/property/tenantsSchedule', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const propertyDelete = async (data: { id: string }) => {
  return await axios.delete('/property/delete', { data })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


{/* Interface */ }
interface ICreateProperty {
  id?: string | null | undefined
  description: string
  image: any[]
  numberOfRoom: number
  numberOfWashroom: number
  numberOfParking: number
  address: {
    name: string
    isManual: boolean
    latitude?: number | null
    longitude?: number | null
    city?: string
    state?: string
    country?: string
    postcode?: string
  }
}

interface IUpdatePropertyDocuments {
  id: string
  verificationDocuments: {
    type: string
    image: string
  }[]
}

interface ISearchTenantsByProperty {
  id: string
  size?: number
  page?: number
}

interface IShortlistedTenantList {
  propertyId: string
  size?: number
  page?: number
}

interface IAddTenantInShortlist {
  propertyId: string
  tenantId: string
}


export interface IPropertyTenantsSchedules {
  propertyId: string
  scheduleDate: string | undefined
  notifyTenant: boolean
}


export const propertyService = {
  propertyAdd,
  propertyListByUser,
  propertyDetails,
  updatePropertyDocuments,
  updateProperty,
  searchTenantsByProperty,
  shortlistedTenantList,
  addTenantInShortlist,
  shortlistedProperties,
  shortlistedTenants,
  getPropertiesListByShortlistedTenant,
  propertyTenantsSchedules,
  propertyDelete
}