import axios from "axios"



{/* APIs */ }
const uploadFile = async (data: FormData) => {
  return await axios.post('/upload/multiple', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}



export const uploadService = {
  uploadFile
}