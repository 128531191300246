import { IMenu, HeaderProps } from './Header.interface'
import { Container, Navbar, Nav, Badge, NavDropdown, Offcanvas } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { logout } from '../../../../lib/redux/slices/auth.slice'
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux'
import { handleLoader } from '../../../../lib/redux/slices/layout.slice'
import Button from '../../../_ui/button/Button.component'
import StyledHeader from './Header.style'
import { filterByRoles } from '../../../../utils'
import logo from '../../../../assets/images/logo.svg';
import icon from '../../../../assets/images/icon.svg';
import UnReadChatCount from '../../../../pages/messages/components/unreadChats/UnreadChatsCount.components'
import SVG from 'react-inlinesvg';


export default function Header(props: HeaderProps) {
  const dispatch = useReduxDispatch()
  const { userRoles, user } = useReduxSelector(state => state.auth)
  //let isAdminRole = userRoles?.includes('admin') || false


  const menu: IMenu[] = [
    // No Auth
    { label: 'Home', link: 'https://rentless.com.au', permission: ['noUser'], target: '_blank' },
    { label: 'How It Works?', link: 'https://rentless.com.au/how-it-works', permission: ['noUser'], target: '_blank' },
    { label: 'Pricing', link: 'https://rentless.com.au/pricing', permission: ['noUser'], target: '_blank' },

    // Auth
    { label: 'Dashboard', link: '/', permission: ['tenant', 'landlord', 'admin'] },
    { label: 'Message', link: '/messages', permission: ['tenant', 'landlord'], badge: <UnReadChatCount /> },
    { label: 'Profile', link: '/settings/tenant-profile', permission: ['tenant'] },
    { label: 'Properties', link: '/properties', permission: ['landlord'] },
    {
      label: 'System Admin', link: '/admin', permission: ['admin'], children: [
        { label: 'Dashboard', link: '/admin/dashboard', permission: ['admin'] },
        { label: 'Accounts', link: '/admin/accounts', permission: ['admin'] },
        { label: 'All Properties', link: '/admin/all-properties', permission: ['admin'] },
        { label: 'Agents', link: '/admin/agents', permission: ['admin'] },
        { label: 'Payments', link: '/admin/payments', permission: ['admin'] },
        { label: 'Tenant Verification', link: '/admin/tenant/verifications', permission: ['admin'] },
        { label: 'Property Verification', link: '/admin/property/verifications', permission: ['admin'] },
        { label: 'Reports', link: '/admin/reports/tenants-list', permission: ['admin'] },
        { label: 'Bulk Email', link: '/admin/bulk-email', permission: ['admin'] },
      ]
    },
    {
      label: 'Settings', link: '#', permission: ['tenant', 'landlord'], children: [
        { label: 'Account Details', link: '/settings/account-details', permission: ['tenant', 'landlord'] },
        { label: 'Tenant Profile', link: '/settings/tenant-profile', permission: ['tenant'] },
        //{ label: 'Become a Tenant', link: '/settings/become-a-tenant', permission: ['landlord'] },
        { label: 'Notifications', link: '/settings/notifications', permission: ['tenant', 'landlord'] },
        { label: 'Change Password', link: '/settings/security', permission: ['tenant', 'landlord'] },
      ]
    },
  ]


  const handleLogout = () => {
    dispatch(handleLoader(true))
    dispatch(logout())
  }


  return (
    <Container>
      <StyledHeader>
        <Navbar expand="md" className="pt-2 pb-2">
          <Container className='d-flex align-items-stretch justify-content-between gap-3 flex-nowrap'>

            {/* Logo */}
            <div className='d-flex flex-shrink-1'>
              <Navbar.Brand as={Link} to="/" className='d-flex align-items-center flex-shrink-1'>
                <SVG height={40} className='logo d-none d-lg-block' src={logo} />
                <SVG height={30} className='logo d-lg-none' src={logo} />
              </Navbar.Brand>
            </div>


            {/* Menu */}
            <div className='d-flex justify-content-end gap-3'>
              <Navbar.Offcanvas id='header-menus' aria-labelledby='header-menus-expand'>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id='header-menus-expand'>
                    <SVG src={icon} height={40} />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav>
                    {filterByRoles(menu, userRoles).map(item => (
                      item.children ? (
                        <NavDropdown key={item.label} title={item.label}>
                          {item.children.map((childItem: IMenu) => <NavDropdown.Item key={childItem.label} as={NavLink} to={childItem.link}>{childItem.label}</NavDropdown.Item>)}
                        </NavDropdown>
                      ) : (
                        <Nav.Link key={item.label} as={NavLink} to={item.link}>
                          {item.label}
                          {item?.badge}
                        </Nav.Link>
                      )
                    ))}
                  </Nav>


                </Offcanvas.Body>

                <div className='d-flex d-md-none gap-3 mt-4 flex-column text-center p-3'>
                  {userRoles.includes('noUser') ?
                    <>
                      <Button as={Link} to='/auth/register/step-1' className='w-100'>Sign Up</Button>
                      <Button as={Link} to='/auth/login' $variant='outlined' className='w-100'>Sign In</Button>
                    </>
                    :
                    <Button $variant='outlined' onClick={handleLogout} className='text-nowrap w-100'>Sign Out</Button>
                  }
                </div>

              </Navbar.Offcanvas>


              <div className='d-md-flex d-none align-items-center gap-3 '>
                {userRoles.includes('noUser') ?
                  <>
                    <Button as={Link} to='/auth/register/step-1'>Sign Up</Button>
                    <Button as={Link} to='/auth/login' $variant='outlined'>Sign In</Button>
                  </>
                  :
                  <Button $variant='outlined' onClick={handleLogout} className='text-nowrap'>Sign Out</Button>
                }

              </div>


              {/* Toggle */}
              <div className='d-flex align-items-center d-md-none'>
                <Navbar.Toggle aria-controls='header-menus' className='p-0 fs-6 border-0' />
              </div>
            </div>

          </Container>
        </Navbar>
      </StyledHeader>
    </Container>
  )
}
