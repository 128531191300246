import React from 'react'
import { Stack } from 'react-bootstrap'
import { MdPhone, MdEmail } from 'react-icons/md'
import { PropertyDTO } from '../../../../../dtos/Property.dto'
import Card from '../../../../../components/_ui/card/Card.component'
import Typography from '../../../../../components/_ui/typography/Typography.component'
import Avatar from '../../../../../components/avatar/Avatar.component'
import placeholderImage from '../../../../../assets/images/placeholder.jpg'



interface ILandlordDetailCard {
  data: PropertyDTO | null
}


const LandlordDetailCard = (props: ILandlordDetailCard) => {

  const { data } = props

  return (
    <Stack className='my-3'>
      <Typography $variant='body1' className='mb-2'>Property Owner</Typography>
      <Card className='p-0 overflow-hidden d-block position-relative' $hover>
        <img src={placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75', opacity: .9 }} />
        <Stack direction='vertical' gap={2} className='mb-3'>
          <div className='d-flex justify-content-between px-3'>
            <Typography $variant='subtitle2' className='text-dark fw-semibold align-self-center'>
              {data?.userId?.name || ''}
            </Typography>
            <Avatar />
          </div>
          <div className='d-flex justify-content-between px-3'>
            <Typography $variant='subtitle2' className='text-dark fw-semibold align-self-center'>
              {data?.userId?.email || ''}
            </Typography>
            <Avatar $icon={<MdEmail />} />
          </div>
          {data?.userId?.phone && <div className='d-flex justify-content-between px-3'>
            <Typography $variant='subtitle2' className='text-dark fw-semibold align-self-center'>
              {data?.userId?.phone || ''}
            </Typography>
            <Avatar $icon={<MdPhone />} />
          </div>}
        </Stack>
      </Card>
    </Stack>
  )
}

export default LandlordDetailCard
