import { styled } from "styled-components"
import Check from 'react-bootstrap/FormCheck'


export const SwitchInput = styled(Check)`
  .form-check-input {
    width: 2.5rem;
    height: 1.5rem
  }
  .form-check-input:checked {
    background-color: #1ca43a;
    border-color: #1ca43a;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
`