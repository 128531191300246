import { IMenu, SidebarNavProps } from "./SidebarNav.interface"
import { StyledSidebarNav } from "./SidebarNav.style"
import { NavLink } from "react-router-dom"
import { useReduxSelector } from "../../../../hooks/redux"
import { filterByRoles } from "../../../../utils"
import { MdOutlineContactPage, MdNotificationsNone, MdPersonOutline, MdOutlineSettings } from "react-icons/md"
import Typography from "../../../../components/_ui/typography/Typography.component"



export default function SidebarNav(props: SidebarNavProps) {
  const { userRoles } = useReduxSelector(state => state.auth)

  const menus: IMenu[] = [
    { label: 'My Profile', link: '/settings/tenant-profile', Icon: MdOutlineContactPage, permission: ['tenant'], },
    { label: 'Account Details', link: '/settings/account-details', Icon: MdPersonOutline, permission: ['landlord', 'tenant'], },
    { label: 'Security Settings', link: '/settings/security', Icon: MdOutlineSettings, permission: ['landlord', 'tenant'], },
    { label: 'Notifications', link: '/settings/notifications', Icon: MdNotificationsNone, permission: ['landlord', 'tenant'], },
    
  ]



  return (
    <StyledSidebarNav className="position-sticky top-15">
      {filterByRoles(menus, userRoles).map((item, index) =>
        <Typography $variant="subtitle2" as={NavLink} to={item.link} key={index} className='d-flex align-items-center nav-item gap-2 rounded'>
          <item.Icon className="fs-4" /> &nbsp;
          {item.label}
        </Typography>
      )}
    </StyledSidebarNav>
  )
}
