import axios from "axios"
import { IPagination } from "./admin.service"



{/* APIs */ }
const agentRegister = async (data: IAgentRegister) => {
  return await axios.post('/user/registerAgent', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const searchAgentsByProperty = async (params: ISearchAgentByProperty) => {
  return await axios.get('/user/searchAgentsByProperty', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const manageByAgentOrSelf = async (data: IManageByAgentOrSelf) => {
  return await axios.post('/property/manageByAgentOrSelf', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminAgentsList = async (data: IPagination) => {
  return await axios.post('/admin/user/agents', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const adminAgentsVerify = async (data: IAgentVerify) => {
  return await axios.put('/admin/agent/verify', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const agentGetTenantSearchBySuburb = async (params: ISearchTenantBySuburb) => {
  return await axios.get('/user/agent/searchTenantBySuburb', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


export const agentService = {
  agentRegister,
  searchAgentsByProperty,
  manageByAgentOrSelf,
  adminAgentsList,
  adminAgentsVerify,
  agentGetTenantSearchBySuburb
}


{/* Interface */ }
export interface ISearchAgentByProperty {
  id: string
  size: number
  page: number
}

export interface ISearchTenantBySuburb {
  search: string
  size: number
  page: number
}


export interface IManageByAgentOrSelf {
  propertyId: string | undefined
  tenantId: string | null
  agentId: string | null
  managetBy: string
}


export interface IAgentVerify {
  id: string
  status: boolean
}


export interface IAgentRegister {
  name: string
  email: string
  phone: string
  agentAbnAcn: string
  agentLicenseNumber: string
  address: {
    name: string
    city: string
    state: string
    country: string
    isManual: boolean
    longitude: number
    latitude: number
  }

}
