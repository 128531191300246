import { MdVerified } from "react-icons/md"
import { Col, Row, Spinner, Stack, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { limitRowSize } from '../../../data/config';
import { useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux';
import { adminPlanUsersThunk } from '../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant';
import { paymentDataThunk } from '../../../lib/redux/actions/plan.action';
import PaginationComponent from '../../../components/pagination/PaginationComponent';
import Layout from "../../../components/_layout/Layout.component"
import Typography from "../../../components/_ui/typography/Typography.component"
import AdminLayout from "../components/adminLayout/AdminLayout.component"
import PaymentsNav from '../components/paymentsNav/Nav.component'
import Card from '../../../components/_ui/card/Card.component'
import Alert from '../../../components/_ui/alert/Alert.component';
import moment from 'moment';
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component';
import SearchImg from '../../../assets/images/search.svg'
import { convertToMelbourneTime } from "../../../utils";



export default function Payments() {
  let limitSize = limitRowSize
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useReduxDispatch()
  const { planUsers } = useReduxSelector(state => state.admin)
  const { paymentData } = useReduxSelector(state => state.plan)


  useEffect(() => {
    dispatch(paymentDataThunk())
  }, [])


  useEffect(() => {
    dispatch(adminPlanUsersThunk({ page: currentPage, size: limitSize })).unwrap()
      .then((response) => {
        setData(response?.data?.info?.result);
        setTotalPages(Math.ceil(response?.data?.info?.totalRecords / limitSize) || 0)
      })
      .catch(err => console.log(err))
  }, [currentPage])


  const handlePageChange = (page: number) => {
    console.log(page)
    setCurrentPage(page);
  };


  return (
    <Layout title='Payments' user={true}>
      <AdminLayout nav={<PaymentsNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Payments</Typography>
          <Typography $variant='body1'>List of payments recently made</Typography>
        </div>

        {/* Counts */}
        <Row>
          <Col xs={6} md={4}>
            <Card as={Stack} gap={2} className='mb-3 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Payments Total</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>$ {paymentData?.data?.totalPlanPrice.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} md={4}>
            <Card as={Stack} gap={2} className='mb-3 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Payments 30 Days</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>$ {paymentData?.data?.oneMonthAgoPlanPrice.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} md={4}>
            <Card as={Stack} gap={2} className='mb-3 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Payments Today</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>$ {paymentData?.data?.todayPlanPrice.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
          <Col xs={6} md={4}>
            <Card as={Stack} gap={2} className='mb-3 align-items-center'>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <Typography $variant='body1'>Failed Payments</Typography>
              </div>
              <Typography $variant='h1' as='div' className='fs-3 mb-2'>$ {paymentData?.data?.oneMonthFailedPayments.toFixed(2) || 0}</Typography>
            </Card>
          </Col>
        </Row>


        <Card>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {planUsers.status === THUNK_STATUS.SUCCESS &&
                data.map((item: any, index) => (
                  <tr key={index}>
                    <td>{item?.userId?.name || ''}</td>
                    <td>{item?.planId?.type == 'blue_tick' ? <span>Blue Tick <MdVerified className='text-blue' /> </span> : 'Boost'} </td>
                    <td>${item?.amount || 0}</td>
                    <td>{convertToMelbourneTime(item?.createdAt).fromNow() || ''}</td>
                    <td>{item?.status || ''}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-center mb-3'>
            {planUsers.status === THUNK_STATUS.SUCCESS && !Boolean(planUsers.data.length) &&
              <NoRecordFound shadow='shadow-none' className='text-center w-100' image={SearchImg}>
                <Typography $variant='h4'>Payments</Typography>
                <Typography $variant='body1'>No record found</Typography>
              </NoRecordFound>
            }
            {planUsers.status === THUNK_STATUS.LOADING && <Spinner className='m-auto my-3 d-block' variant='secondary' />}
            {planUsers.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
          </div>

          <div className='d-flex justify-content-end'>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>

        </Card>

      </AdminLayout>
    </Layout >
  )
}
