import { AgentCardProps } from './agentCard.interface'
import Card from '../_ui/card/Card.component'
import Typography from '../_ui/typography/Typography.component'
import Avatar from '../avatar/Avatar.component'



export default function AgentCard(props: AgentCardProps) {
  const { data, className = '', size = 'medium' } = props
  const { managetBy, agentAssign } = data

  return (
    <Card className={`p-0 overflow-hidden d-block position-relative ${className}`} $hover>
      <div className='w-100 object-fit-cover bg-green fs-3 text-light d-flex justify-content-center align-items-center' style={{ aspectRatio: '4/2.75', opacity: .9 }}>
        Manage By {managetBy}
      </div>
      {
        Boolean(agentAssign !== null) &&
        <div className={`${size === 'small' ? 'px-3 py-2' : 'p-3'} d-flex justify-content-between gap-3`}>
          <Typography $variant='subtitle2' className='text-dark fw-semibold align-self-center'>
            {agentAssign?.name}
          </Typography>
          <Avatar />
        </div>
      }
    </Card>
  )
}