import { Card } from 'react-bootstrap'

import Typography from '../../../../../components/_ui/typography/Typography.component'
import Countdown from '../../../../../components/_ui/countDown/Countdown.component'
import usePackDealHoursDiff from './usePackDealHoursDiff.hook'



export default function PackageDeal() {
  const { dealEndDateTime } = usePackDealHoursDiff()


  return (
    <>
      <div className='d-flex py-4 text-center justify-content-center'>
        {dealEndDateTime ? <Typography $variant='h4'>Skip the queue and get your dream home now. Upgrade now and save 40%</Typography> : <Typography $variant="h3">Don't miss out on your dream house. Upgrade now</Typography>}
      </div>

      {dealEndDateTime && <>
        <Card className={`d-flex flex-column align-items-center p-2 shadow-none border border-1 mb-3`}>
          <Typography $variant='h4' $hover>Deal expires in:</Typography>
          <div className="100"><Countdown startDate={dealEndDateTime} /></div>
        </Card>
      </>
      }
    </>
  )
}
