import Typography from "../../../../components/_ui/typography/Typography.component";
import Card from "../../../../components/_ui/card/Card.component";
import { MdCalendarToday } from "react-icons/md";
export default function Inspections() {
  return (
    <Card className="d-flex p-5 justify-content-center align-items-center">
      <div
        className="d-flex flex-column align-items-center"
        style={{ maxWidth: 600 }}
      >
        <MdCalendarToday color="#14b514" size={50} className="mb-3" />
        <Typography $variant="h3" className={"mb-3 text-center"}>
          You don't have any upcoming inspections
        </Typography>
        <Typography $variant="body2" className={"text-center"}>
          A list of inspection times will show here once you have been shortlisted by a landlord. Enter as much information in your profile to increase your chances of being chosen.
        </Typography>
      </div>
    </Card>
  );
}
