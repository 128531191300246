import { IMenu, SidebarNavProps } from './SidebarNav.interface'
import { StyledSidebarNav } from './SidebarNav.style'
import { NavLink } from 'react-router-dom'
import { useReduxSelector } from '../../../../hooks/redux'
import { filterByRoles } from '../../../../utils'
import { MdPayments, MdDashboard, MdPeople, MdVerified, MdCheckCircle, MdSupportAgent, MdHome, MdPieChart } from 'react-icons/md'
import { FaMailBulk } from 'react-icons/fa'
import Typography from '../../../../components/_ui/typography/Typography.component'



export default function SidebarNav(props: SidebarNavProps) {
  const { userRoles } = useReduxSelector(state => state.auth)

  const menus: IMenu[] = [
    { label: 'Dashboard', link: '/admin/dashboard', Icon: MdDashboard, permission: ['admin'] },
    { label: 'Accounts', link: '/admin/accounts', Icon: MdPeople, permission: ['admin'] },
    { label: 'All Properties', link: '/admin/all-properties', Icon: MdHome, permission: ['admin'] },
    { label: 'Payments', link: '/admin/payments', Icon: MdPayments, permission: ['admin'] },
    { label: 'Verifications', link: '/admin/tenant/verifications', Icon: MdVerified, permission: ['admin'] },
    { label: 'Reports', link: '/admin/reports/tenants-list', Icon: MdPieChart, permission: ['admin'] },
    { label: 'Bulk Email', link: '/admin/bulk-email', Icon: FaMailBulk, permission: ['admin'] },
  ]

  return (
    <StyledSidebarNav className='position-sticky top-15'>
      {filterByRoles(menus, userRoles).map((item, index) =>
        <Typography $variant='subtitle2' as={NavLink} to={item.link} key={index} className='d-flex align-items-center nav-item gap-2 rounded'>
          <item.Icon className='fs-4 flex-shrink-0' /> &nbsp;
          {item.label}
        </Typography>
      )}
    </StyledSidebarNav>
  )
}
