import { createAsyncThunk } from "@reduxjs/toolkit"
import { planService } from "../services/plan.service"



export const plansThunk = createAsyncThunk('plans', planService.plans)
export const planByIdThunk = createAsyncThunk('planById', planService.planById)
export const paymentDataThunk = createAsyncThunk('paymentData', planService.paymentData)
export const tenantPurchasePlanThunk = createAsyncThunk('tenantPurchasePlan', planService.tenantPurchasePlan)
export const tenantPurchasePlanStatusThunk = createAsyncThunk('tenantPurchasePlanStatus', planService.tenantPurchasePlanStatus)
export const updateTenantDocumentsThunk = createAsyncThunk('updateTenantDocuments', planService.updateTenantDocuments)
export const stripeIdentityCreateVerificationThunk = createAsyncThunk('stripeIdentityCreateVerification', planService.stripeIdentityCreateVerification)