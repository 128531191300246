import { useEffect, useState } from "react"
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { handleSteps, setRegisterFormData } from "../../../../lib/redux/slices/auth.slice"
import { useForm } from 'react-hook-form'
import { passwordTest } from "../../../../utils"
import { yupResolver } from '@hookform/resolvers/yup'
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import Steps from "../component/steps/Steps.component"
import Button from "../../../../components/_ui/button/Button.component"
import RegisterLayout from "../component/registerLayout/RegisterLayout.component"
import background_image from '../../../../assets/images/register/background_004.jpg';


export default function RegisterStep4() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const { formData } = useReduxSelector(state => state.auth.register)
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })


  useEffect(() => {
    if (!formData.userRoles) navigate('/auth/register/step-1')
  }, [formData])


  const onSubmit = (data: FormData) => {
    dispatch(handleSteps(5))
    dispatch(setRegisterFormData({ password: data.newPassword }))
  }


  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    dispatch(setRegisterFormData({ password: event.currentTarget.value }))
  }

  const handleErrorMessage = (message: string | undefined) => {
    message = message || ''
    message = message.replace(/newPassword/gi, `${message.startsWith('NewPassword') ? 'P' : 'p'}assword`)
    return message
  }


  return (
    <Layout title='Register Step 4' user={false} header={false} footer={false}>

      <RegisterLayout
        //headerActionTitle="Already have an account?"
        //headerActionButtonText="Sign In"
        //headerActionButtonLink="/auth/login"
        progress="75"
        bannerText="Landlords will come to you"
        columnFirst={
          <>
            {/* <Steps active={2} /> */}
            <div className='d-flex flex-column gap-1 mb-3'>
              <Typography $variant='body1'>Step 3</Typography>
              <Typography $variant="h3">Create a password</Typography>
            </div>

            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="password">
                <InputGroup>
                  <Form.Control type={showPassword ? 'text' : 'password'} defaultValue={formData.password} autoComplete="new-password" onKeyUp={handleChange} className="fs-6" size="lg" isInvalid={Boolean(errors.newPassword)} autoFocus={false} {...register('newPassword')} />
                  <InputGroup.Text className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ?
                      <MdVisibility className="fs-5" />
                      :
                      <MdVisibilityOff className="fs-5" />
                    }
                  </InputGroup.Text>
                </InputGroup>
                <Form.Text className="text-danger">{handleErrorMessage(errors.newPassword?.message)}</Form.Text>
              </Form.Group>

              <Row>
                <Col>
                  <Button type='submit' className='w-100' disabled={!Boolean(formData.password?.length)}>Continue</Button>
                </Col>
              </Row>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  newPassword: yup.string().trim().required().min(6, 'Enter strong password').max(100).test('', 'Password: Minimum 6 characters with 1 number.', passwordTest)
})

type FormData = yup.InferType<typeof schema>