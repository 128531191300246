import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { propertyAddThunk, propertyListByUserThunk, addTenantInShortlistThunk, propertyDetailsThunk, shortlistedTenantListThunk, updatePropertyDocumentsThunk, updatePropertyThunk, searchTenantsByPropertyThunk, shortlistedPropertiesThunk, shortlistedTenantsThunk, propertyTenantsSchedulesThunk, propertyDeleteThunk, getPropertiesListByShortlistedTenantThunk } from '../actions/property.action'
import { THUNK_STATUS } from '../constants/status.constant'
import { PropertyDTO } from '../../../dtos/Property.dto'
import { Status } from '../../../interfaces/Status.type'
import { TenantProfileDTO } from '../../../dtos/TenantProfile.dto'



/* == Initial State == */
const initialState: IPropertySlice = {
  propertyAdd: {
    status: '',
  },
  uploadFile: {
    status: '',
    data: []
  },
  propertyListByUser: {
    status: '',
    data: [],
    total: null
  },
  propertyDetails: {
    status: '',
    data: null
  },
  updatePropertyDocuments: {
    status: ''
  },
  updateProperty: {
    status: ''
  },
  searchTenantsByProperty: {
    status: '',
    data: [],
    total: null
  },
  shortlistedTenantList: {
    status: '',
    data: [],
    total: null
  },
  addTenantInShortlist: {
    status: '',
  },
  shortlistedProperties: {
    status: '',
    data: [],
  },
  shortlistedTenants: {
    status: '',
    data: [],
  },
  getPropertiesListByShortlistedTenantDashboard: {
    status: '',
    data: [],
    totalCount: 0
  },
  propertyTenantsSchedules: {
    status: '',
    data: [],
  },
  propertyDelete: {
    status: ''
  }
}



/* == Slice == */
export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {},


  extraReducers: (builder) => {

    {/* Add */ }
    builder.addCase(propertyAddThunk.pending, (state, action) => {
      state.propertyAdd.status = THUNK_STATUS.LOADING
    })

    builder.addCase(propertyAddThunk.fulfilled, (state, action) => {
      state.propertyAdd.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(propertyAddThunk.rejected, (state, action) => {
      state.propertyAdd.status = THUNK_STATUS.FAILED
    })


    {/* Property List By User */ }
    builder.addCase(propertyListByUserThunk.pending, (state, action) => {
      state.propertyListByUser.status = THUNK_STATUS.LOADING
    })

    builder.addCase(propertyListByUserThunk.fulfilled, (state, action) => {
      state.propertyListByUser.status = THUNK_STATUS.SUCCESS
      state.propertyListByUser.data = action.payload.data.list
      state.propertyListByUser.total = action.payload.data.totalCount
    })

    builder.addCase(propertyListByUserThunk.rejected, (state, action) => {
      state.propertyListByUser.status = THUNK_STATUS.FAILED
    })


    {/* Property Details */ }
    builder.addCase(propertyDetailsThunk.pending, (state, action) => {
      state.propertyDetails.status = THUNK_STATUS.LOADING
    })

    builder.addCase(propertyDetailsThunk.fulfilled, (state, action) => {
      state.propertyDetails.status = THUNK_STATUS.SUCCESS
      state.propertyDetails.data = action.payload.data.property_info
    })

    builder.addCase(propertyDetailsThunk.rejected, (state, action) => {
      state.propertyDetails.status = THUNK_STATUS.FAILED
    })


    {/* Update Property Documents */ }
    builder.addCase(updatePropertyDocumentsThunk.pending, (state, action) => {
      state.updatePropertyDocuments.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updatePropertyDocumentsThunk.fulfilled, (state, action) => {
      state.updatePropertyDocuments.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(updatePropertyDocumentsThunk.rejected, (state, action) => {
      state.updatePropertyDocuments.status = THUNK_STATUS.FAILED
    })


    {/* Update Property */ }
    builder.addCase(updatePropertyThunk.pending, (state, action) => {
      state.updateProperty.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updatePropertyThunk.fulfilled, (state, action) => {
      state.updateProperty.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(updatePropertyThunk.rejected, (state, action) => {
      state.updateProperty.status = THUNK_STATUS.FAILED
    })


    {/* Search Tenants By Property */ }
    builder.addCase(searchTenantsByPropertyThunk.pending, (state, action) => {
      state.searchTenantsByProperty.status = THUNK_STATUS.LOADING
    })

    builder.addCase(searchTenantsByPropertyThunk.fulfilled, (state, action) => {
      state.searchTenantsByProperty.status = THUNK_STATUS.SUCCESS
      state.searchTenantsByProperty.data = action.payload.data.result
      state.searchTenantsByProperty.total = action.payload.data.totalRecords
    })

    builder.addCase(searchTenantsByPropertyThunk.rejected, (state, action) => {
      state.searchTenantsByProperty.status = THUNK_STATUS.FAILED
    })


    {/* Shortlisted Tenants List */ }
    builder.addCase(shortlistedTenantListThunk.pending, (state, action) => {
      state.shortlistedTenantList.status = THUNK_STATUS.LOADING
    })

    builder.addCase(shortlistedTenantListThunk.fulfilled, (state, action) => {
      state.shortlistedTenantList.status = THUNK_STATUS.SUCCESS
      state.shortlistedTenantList.data = action.payload.data.result
      state.shortlistedTenantList.total = action.payload.data.totalRecords
    })

    builder.addCase(shortlistedTenantListThunk.rejected, (state, action) => {
      state.shortlistedTenantList.status = THUNK_STATUS.FAILED
    })


    {/* Add Tenant In Shortlist */ }
    builder.addCase(addTenantInShortlistThunk.pending, (state, action) => {
      state.addTenantInShortlist.status = THUNK_STATUS.LOADING
    })

    builder.addCase(addTenantInShortlistThunk.fulfilled, (state, action) => {
      state.addTenantInShortlist.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(addTenantInShortlistThunk.rejected, (state, action) => {
      state.addTenantInShortlist.status = THUNK_STATUS.FAILED
    })


    {/* Shortlist Properties */ }
    builder.addCase(shortlistedPropertiesThunk.pending, (state, action) => {
      state.shortlistedProperties.status = THUNK_STATUS.LOADING
    })

    builder.addCase(shortlistedPropertiesThunk.fulfilled, (state, action) => {
      state.shortlistedProperties.status = THUNK_STATUS.SUCCESS
      state.shortlistedProperties.data = action.payload.data.result
    })

    builder.addCase(shortlistedPropertiesThunk.rejected, (state, action) => {
      state.shortlistedProperties.status = THUNK_STATUS.FAILED
    })


    {/* Shortlist Tenants */ }
    builder.addCase(shortlistedTenantsThunk.pending, (state, action) => {
      state.shortlistedTenants.status = THUNK_STATUS.LOADING
    })

    builder.addCase(shortlistedTenantsThunk.fulfilled, (state, action) => {
      state.shortlistedTenants.status = THUNK_STATUS.SUCCESS
      state.shortlistedTenants.data = action.payload.data.result
    })

    builder.addCase(shortlistedTenantsThunk.rejected, (state, action) => {
      state.shortlistedTenants.status = THUNK_STATUS.FAILED
    })


    {/* Get Properties List By Shortlisted Tenants */ }
    builder.addCase(getPropertiesListByShortlistedTenantThunk.pending, (state, action) => {
      state.getPropertiesListByShortlistedTenantDashboard.status = THUNK_STATUS.LOADING
    })

    builder.addCase(getPropertiesListByShortlistedTenantThunk.fulfilled, (state, action) => {
      state.getPropertiesListByShortlistedTenantDashboard.status = THUNK_STATUS.SUCCESS
      state.getPropertiesListByShortlistedTenantDashboard.data = action.payload?.data?.result
      state.getPropertiesListByShortlistedTenantDashboard.totalCount = action.payload?.data?.totalCount
    })

    builder.addCase(getPropertiesListByShortlistedTenantThunk.rejected, (state, action) => {
      state.getPropertiesListByShortlistedTenantDashboard.status = THUNK_STATUS.FAILED
    })


    {/* Property Tenants Schedules */ }
    builder.addCase(propertyTenantsSchedulesThunk.pending, (state, action) => {
      state.propertyTenantsSchedules.status = THUNK_STATUS.LOADING
    })

    builder.addCase(propertyTenantsSchedulesThunk.fulfilled, (state, action) => {
      state.propertyTenantsSchedules.status = THUNK_STATUS.SUCCESS
      state.propertyTenantsSchedules.data = action.payload.data.result
    })

    builder.addCase(propertyTenantsSchedulesThunk.rejected, (state, action) => {
      state.propertyTenantsSchedules.status = THUNK_STATUS.FAILED
    })


    {/* Property Delete */ }
    builder.addCase(propertyDeleteThunk.pending, (state, action) => {
      state.propertyDelete.status = THUNK_STATUS.LOADING
    })

    builder.addCase(propertyDeleteThunk.fulfilled, (state, action) => {
      state.propertyDelete.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(propertyDeleteThunk.rejected, (state, action) => {
      state.propertyDelete.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface IPropertySlice {
  propertyAdd: {
    status: Status
  }
  uploadFile: {
    status: Status
    data: string[]
  }
  propertyListByUser: {
    status: Status
    data: PropertyDTO[]
    total: number | null
  }
  propertyDetails: {
    status: Status
    data: PropertyDTO | null
  }
  updatePropertyDocuments: {
    status: Status
  }
  updateProperty: {
    status: Status
  }
  searchTenantsByProperty: {
    status: Status
    data: TenantProfileDTO[]
    total: number | null
  }
  shortlistedTenantList: {
    status: Status
    data: TenantProfileDTO[]
    total: number | null
  }
  addTenantInShortlist: {
    status: Status
  }
  shortlistedProperties: {
    status: Status
    data: PropertyDTO[]
  }
  shortlistedTenants: {
    status: Status
    data: []
  }
  getPropertiesListByShortlistedTenantDashboard: {
    status: Status
    data: []
    totalCount: number
  }
  propertyTenantsSchedules: {
    status: Status
    data: []
  }
  propertyDelete: {
    status: Status
  }
}



export const { } = propertySlice.actions
export default propertySlice.reducer