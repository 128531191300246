import SVG from 'react-inlinesvg'
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row, Spinner, Stack } from "react-bootstrap";

import { BgColorGetBlueTick } from "../store/checkout/Checkout.style";
import { useReduxDispatch, useReduxSelector } from "../../hooks/redux";
import { useTenantProfileStep } from "../../hooks/useTenantProfileStep";
import { THUNK_STATUS } from "../../lib/redux/constants/status.constant";
import { tenantDashboardThunk } from "../../lib/redux/actions/tenant.action";
import { landlordDashboardThunk } from "../../lib/redux/actions/landlord.actions";
import { getPropertiesListByShortlistedTenantThunk, shortlistedPropertiesThunk, shortlistedTenantsThunk } from "../../lib/redux/actions/property.action";
import Tenant from "./components/containers/Tenant";
import SearchImg from "../../assets/images/search.svg";
import useThankyouPopup from "./hooks/useThankyouPopup.hook";
import Layout from "../../components/_layout/Layout.component";
import Card from "../../components/_ui/card/Card.component";
import Avatar from "../../components/avatar/Avatar.component";
import Button from "../../components/_ui/button/Button.component";
import Alert from "../../components/_ui/alert/Alert.component";
import ChatComponent from "../messages/components/unreadChats/UnreadChats.components";
import NoRecordFound from "../../components/_ui/noRecordFound/NoRecordFound.component";
import GreetingPopup from "../../components/authLayout/greetingPopup/GreetingPopup.component";
import Typography from "../../components/_ui/typography/Typography.component";
import LandlordCountCard from "./components/landlordCount/LandlordCount.component";
import ShortListedTenants from "./components/shortListedTenants/ShortListedTenants.component";
import useIsPhoneVerification from './hooks/useIsPhoneVerification.hook';
import UpsellCard from '../../components/_ui/upSell/upsellCard/UpsellCard.component';
// import bootImg from "../../assets/images/boost.svg";
// import Landlord from "./components/containers/Landlord";
// import Countdown from "../../components/_ui/countDown/Countdown.component";
// import TenantCountCard from "./components/tenantCount/TenantCount.component";
// import ShortListedProperty from "./components/shortListedProperties/ShortListedProperty.component";



import Lottie from "lottie-react";
import animationVerify from "../../assets/lottie/verify.json";
import animationVerifying from "../../assets/lottie/verifying.json";
import animationScanID from "../../assets/lottie/scan-id.json";
import StripeIdentityVerify from './components/stripeIdentityVerify/index.component';
import AgentDashboard from './components/agentDashboard/AgentDashboard.component';


export default function Dashboard() {
  const shortListItemSize = 3;
  const shortListItemPage = 1;
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();

  // this custom hook is profile step check
  useThankyouPopup()
  const step = useTenantProfileStep();
  const PhoneVerificationPopup = useIsPhoneVerification()


  const { user, userRoles } = useReduxSelector((state) => state.auth);
  const { tenantDashboard } = useReduxSelector((state) => state.tenant);
  const { landlordDashboard } = useReduxSelector((state) => state.landlord);
  const { shortlistedProperties, shortlistedTenants } = useReduxSelector((state) => state.property);

  // const planDays = Math.floor(moment.duration(moment(user?.tenantPlanPurchase?.createdAt).add(user?.tenantPlanPurchase?.days, 'days').diff(moment())).asDays())

  // Popup show and hide states define
  // const [tenantWelcome, setTenantWelcome] = useState(false);
  const [landlordWelcome, setLandlordWelcome] = useState(false);


  useEffect(() => {
    if (step !== null && userRoles.includes("tenant")) {
      if (step === "/") {
        //setTenantWelcome(true);
        navigate("/tenant/profile/location");
      } else {
        navigate(step);
      }
    } else if (userRoles.includes("tenant")) {
      dispatch(tenantDashboardThunk());
      dispatch(shortlistedPropertiesThunk({ size: shortListItemSize, page: shortListItemPage }))
      dispatch(getPropertiesListByShortlistedTenantThunk({ size: shortListItemSize, page: shortListItemPage }))
    } else if (userRoles.includes("landlord") && !Boolean(user?.propertyCount)) {
      setLandlordWelcome(true);
    } else if (userRoles.includes("landlord")) {
      dispatch(landlordDashboardThunk());
      dispatch(shortlistedTenantsThunk({ size: shortListItemSize, page: shortListItemPage })
      );
    }
  }, [userRoles, step]);


  return (
    <Layout title="My Dashboard" user={true}>
      <Container>

        {userRoles.includes('tenant') && (user?.profile?.isBlueTickNeeded === 'not_needed' || !user?.profile?.isBlueTickNeeded) &&
          <>
            <UpsellCard />
          </>
        }


        {userRoles.includes('tenant') && user?.profile?.isBlueTickNeeded === 'document_pending' &&
          <BgColorGetBlueTick className="rounded mb-4 gap-2">
            <Row className="gap-3">
              <Col md={12} sm={12}>
                <Stack direction='vertical' className='justify-content-between align-items-center gap-3'>
                  <Typography pography $variant='h5' className='m-0 mb-1 tw-bold'>Upload Photo ID</Typography>
                  <Lottie animationData={animationScanID} loop={true} style={{ width: 150, height: 150 }} />
                  <Typography pography $variant='body2' className='m-0 '>Please upload your photo ID to begin the verification process.</Typography>
                </Stack>
              </Col>
              <Col md={12} sm={12}>
                <Button onClick={() => navigate('/tenant/verify')} className="w-100">Upload Photo ID</Button>
                {/* <StripeIdentityVerify /> */}
              </Col>
            </Row>
          </BgColorGetBlueTick>
        }


        {userRoles.includes('tenant') && (user?.profile?.isBlueTickNeeded === 'not_needed' || !user?.profile?.isBlueTickNeeded) &&
          <BgColorGetBlueTick className="rounded mb-4 gap-2">
            <Row className="gap-3">
              <Col md={12} sm={12}>
                <Stack direction='vertical' className='justify-content-between align-items-center gap-3'>
                  <Typography pography $variant='h5' className='m-0 tw-bold'>Verify Account</Typography>
                  <Lottie animationData={animationVerify} loop={true} style={{ width: 100 }} />
                  <Typography pography $variant='body2' className='m-0 text-center '>It'll only take a few clicks and you'll let landlords know they can trust you.</Typography>
                  <Button onClick={() => navigate('/store/upgrade')} className="w-100 text-white border-white" $variant={"outlined"} style={{ backgroundColor: '#20ac4e' }}>Verify ID</Button>
                </Stack>
              </Col>
            </Row>
          </BgColorGetBlueTick>
        }

        {userRoles.includes('tenant') && user?.profile?.isBlueTickNeeded === 'pending' &&
          <BgColorGetBlueTick className="rounded mb-4 gap-2">
            <Row className="gap-3">
              <Col md={12} sm={12}>
                <Stack direction='vertical' className='justify-content-between align-items-center gap-3'>
                  <Typography pography $variant='h5' className='m-0 mb-1 tw-bold'>Profile Verification (PENDING)</Typography>
                  <Lottie animationData={animationScanID} loop={true} style={{ width: 150 }} />
                  <Typography pography $variant='body2' className='m-0 text-center'>Your profile verification request has been submitted successfully. Our team will review your documents soon.</Typography>
                </Stack>
              </Col>
            </Row>
          </BgColorGetBlueTick>
        }


        {userRoles.includes("tenant") && <Tenant payload={user} insights={tenantDashboard?.data} />}
        {/*userRoles.includes("landlord") && <Landlord />*/}


        <Row className="row-gap-5">
          {/* == Content == */}
          <Col xs={12} md={8}>
            <Stack gap={5}>

              {/* Landlord Counts*/}
              {userRoles.includes('landlord') &&
                <>
                  {landlordDashboard.status === THUNK_STATUS.SUCCESS && landlordDashboard?.data !== null && <LandlordCountCard data={landlordDashboard?.data} />}
                  {landlordDashboard.status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
                  {landlordDashboard.status === THUNK_STATUS.FAILED && <Alert className='m-auto d-block' $variant='danger'>Something went wrong</Alert>}
                </>
              }

              {/*  Shortlisted Properties */}
              {/*userRoles.includes('tenant') &&
                <Stack gap={3}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Typography $variant='h4'>Shortlisted Properties</Typography>
                  </div>
                  {shortlistedProperties.status === THUNK_STATUS.SUCCESS && Boolean(shortlistedProperties?.data?.length) &&
                    <Row className='gap-3 gap-sm-0'>
                      {shortlistedProperties?.data?.map((item, index) =>
                        <Col xs={12} sm={6} md={6} lg={4} key={index}>
                          <ShortListedProperty data={item} size='small' className='pointer-events-none' />
                        </Col>
                      )}
                    </Row>
                  }
                  {shortlistedProperties.status === THUNK_STATUS.SUCCESS && !Boolean(shortlistedProperties.data.length) &&
                    <NoRecordFound className='text-center w-100' image={SearchImg}>
                      <Typography $variant='h4'>Shortlisted Properties</Typography>
                      <Typography $variant='body1'>No record found</Typography>
                    </NoRecordFound>
                  }
                  {shortlistedProperties.status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
                  {shortlistedProperties.status === THUNK_STATUS.FAILED && <Alert className='m-auto d-block' $variant='danger'>Something went wrong</Alert>}
                </Stack>
                */}

              {/*  Shortlisted Tenants */}
              {userRoles.includes('landlord') &&
                <Stack gap={3}>
                  <div className='d-flex justify-content-between align-items-center '>
                    <Typography $variant='h4' className='mb-0'>Shortlisted Tenants</Typography>
                  </div>
                  {shortlistedTenants.status === THUNK_STATUS.SUCCESS && Boolean(shortlistedTenants?.data?.length) &&
                    <Row className='gap-3 gap-sm-0'>
                      {shortlistedTenants?.data?.map((item, index) =>
                        <Col xs={12} sm={6} md={6} lg={4} key={index}>
                          <ShortListedTenants data={item} size='small' className='pointer-events' />
                        </Col>
                      )}
                    </Row>
                  }

                  {shortlistedTenants.status === THUNK_STATUS.SUCCESS && !Boolean(shortlistedTenants?.data?.length) &&
                    <NoRecordFound className='text-center w-100' image={SearchImg}>
                      <Typography $variant='h4'>Shortlisted Tenant</Typography>
                      <Typography $variant='body1'>No record found</Typography>
                    </NoRecordFound>
                  }
                  {shortlistedTenants.status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
                  {shortlistedTenants.status === THUNK_STATUS.FAILED && <Alert className='m-auto mt-3 d-block' $variant='danger'>Something went wrong</Alert>}
                </Stack>
              }



              {/* Chat Module */}
              {/* {!userRoles.includes('admin') && <ChatComponent />} */}
              {Boolean(userRoles.includes('landlord')) && <ChatComponent />}
            </Stack>
          </Col>

          {/* == Sidebar == */}

          {userRoles.includes('landlord') &&
            <Col xs={12} md={4}>
              <Stack gap={4}>

                <Card className='center text-center flex-column d-none d-md-flex'>
                  {
                    Boolean(user?.profile?.profilePhotos.length) ?
                      <Avatar $size={90} $image={user?.profile?.profilePhotos[0]} /> :
                      <Avatar $size={90} />
                  }

                  <Typography $variant='subtitle1' className='mt-2 text-dark'>{user?.name}</Typography>
                  <Typography $variant='body1' className='text-black-50'>{user?.profile?.address?.name}</Typography>

                  <Stack gap={1} className='mt-4'>
                    {userRoles.includes('tenant') && <Button className='w-100' as={Link} to='/settings/tenant-profile'>View Profile</Button>}
                    {userRoles.includes('landlord') && <Button className='w-100' as={Link} to='/settings/account-details'>View Profile</Button>}
                    <Button $variant='text' className='w-100' as={Link} to='/settings/account-details'>Edit Settings</Button>
                  </Stack>
                </Card>
              </Stack>
            </Col>
          }
        </Row>


        {userRoles.includes('agent') && <AgentDashboard />}

        {/* == Welcome popup ==  */}
        { /*<GreetingPopup 
          open={tenantWelcome}
          setOpen={setTenantWelcome}
          heading={"Welcome to Rentless!"}
          buttonText="Continue"
          description="Your account has been activated"
          callback={() => {
            navigate("/tenant/profile/location");
            setTenantWelcome(false);
          }}
        />*/ }

        <GreetingPopup
          open={landlordWelcome}
          setOpen={setLandlordWelcome}
          heading={"Welcome to Rentless!"}
          buttonText="Continue"
          description="Your account has been activated"
          callback={() => {
            navigate("/landlord/profile/step-1");
            setLandlordWelcome(false);
          }}
        />


        {/* == Phone verification == */}
        {PhoneVerificationPopup}
      </Container>
    </Layout>
  );
}
