import AgentViewSearchTenantList from './components/AgentViewSearchTenantList.component'



export default function AgentDashboard() {
  return (
    <div>
      <AgentViewSearchTenantList />
    </div>
  )
}
