import React from "react";
import ReactDOM from "react-dom/client";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./lib/redux/store/store";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils";
import { ErrBoundary } from "./components/errorBoundary";
import * as Sentry from "@sentry/react";
import "react-phone-number-input/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/global.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { hotjar } from 'react-hotjar';

Sentry.init({
  dsn: "https://51e656430fc3dc48e33d775eb43ea044@o4506913400160256.ingest.us.sentry.io/4506913403699200",
  integrations: [
    // See docs for support of different versions of variation of react router
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

hotjar.initialize({id: 4947750, sv: 0});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ErrBoundary>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CILENT_ID as string}>
          <App />
        </GoogleOAuthProvider>
      </ErrBoundary>
    </ThemeProvider>
  </Provider>
);
