import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { Form, Modal, Stack } from "react-bootstrap"
import { setLandlordProfileOnboardFormData, setprofileOnboardFormData } from "../../../../lib/redux/slices/user.slice"
import { MdCheck, MdOutlineClose, MdRemoveRedEye } from "react-icons/md"
import { useCallback, useEffect, useMemo, useState } from "react"
import { uploadFileThunk } from "../../../../lib/redux/actions/upload.action"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { propertyAddThunk } from "../../../../lib/redux/actions/property.action"
import * as yup from 'yup'
import toast from "react-hot-toast"
import Layout from "../../../../components/_layout/Layout.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import Button from "../../../../components/_ui/button/Button.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import GreetingPopup from "../../../../components/authLayout/greetingPopup/GreetingPopup.component"
import background_image from '../../../../assets/images/register/background_003.jpg';
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"




export default function StepProperty5() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const [congratulations, setCongratulations] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)
  const { formData } = useReduxSelector(state => state.user.landlordProfileOnboard)
  const { status } = useReduxSelector(state => state.property.propertyAdd)
  let defaultImage: any = formData?.verificationDocuments?.length

  const [documents, setDocuments] = useState([
    { type: 'drivers_license_front', name: 'Drivers License (Front)', loading: false, show: false, image: '' },
    { type: 'drivers_license_back', name: 'Drivers License (Back)', loading: false, show: false, image: '' },
    { type: 'council_rates_notice', name: 'Council Rates Notice', loading: false, show: false, image: '' },
  ])


  useEffect(() => {
    if (defaultImage > 0) {
      const verificationDocuments = formData?.verificationDocuments
      verificationDocuments?.forEach((item: any) => {
        setDocuments(items => {
          return items.map(docItem => {
            if (docItem.type === item.type) docItem = { ...docItem, ...item }
            return docItem
          })
        })
      })
    }
    else {
      setDocuments([
        { type: 'drivers_license_front', name: 'Drivers License (Front)', loading: false, show: false, image: '' },
        { type: 'drivers_license_back', name: 'Drivers License (Back)', loading: false, show: false, image: '' },
        { type: 'council_rates_notice', name: 'Council Rates Notice', loading: false, show: false, image: '' },
      ])
    }
  }, [formData?.verificationDocuments])


  const handleLoading = (value: boolean, index: number) => {
    setDocuments(items => {
      items[index].loading = value
      return [...items]
    })
  }


  const handleImageShow = useCallback((value: boolean, index: number) => {
    setDocuments(items => {
      items[index].show = value
      return [...items]
    })
  }, [documents])


  const handleUpdateDocument = async (data: any[]) => {
    dispatch(setLandlordProfileOnboardFormData({
      verificationDocuments: data.map(item => ({ image: item.image, type: item.type }))
    }))
  }


  const handleUploadFile = useCallback(async (event: React.ChangeEvent<any>, index: number) => {
    if (!event.target.files) return
    handleLoading(true, index)
    const formData = new FormData()
    formData.append(`files`, event.target.files[0])
    formData.append('folder', 'PropertyImage')

    await dispatch(uploadFileThunk(formData))
      .unwrap()
      .then(async (response) => {
        let updatedDocuments = JSON.parse(JSON.stringify(documents))
        updatedDocuments[index].image = response.data.urls[0]
        await handleUpdateDocument(updatedDocuments)
        setDocuments(items => { items[index].image = response.data.urls[0]; return items })
      })
      .catch(() => { })

    handleLoading(false, index)
  }, [documents])


  const handleRemoveFile = useCallback(async (index: number) => {
    handleLoading(true, index)
    let updatedDocuments = JSON.parse(JSON.stringify(documents))
    updatedDocuments[index].image = ''

    await handleUpdateDocument(updatedDocuments)
      .then(() => setDocuments(items => { items[index].image = ''; return items }))
      .catch(() => { })

    handleLoading(false, index)
  }, [documents])


  // create a function in React that checks if at least one object in the array has a loading state set to true.
  const isAnyLoading = useMemo(() => {
    for (const document of documents) {
      if (document.loading === true) {
        return true; // Return true if any document is loading
      }
    }
    return false; // Return false if none of the documents are loading
  }, [documents]);


  const onsubmit = async () => {
    let form = { ...formData }
    const isAllImagesEmpty = form?.verificationDocuments?.every(doc => !doc.hasOwnProperty("image") || doc.image === "");
    if (Boolean(form?.verificationDocuments?.length) && !isAllImagesEmpty) {
      dispatch(propertyAddThunk(form as any)).unwrap()
        .then((response) => {
          setCongratulations(true)
        }).catch((err) => console.log('error'))
    } else {
      toast.error('Aleast one document upload')
    }
  }


  const goHomePage = () => {
    dispatch(getProfileThunk()).unwrap().then((response) => {
      dispatch(setLandlordProfileOnboardFormData({
        id: null,
        description: '',
        suburb: [{ name: '', postcode: '' }],
        image: [],
        numberOfRoom: 0,
        numberOfWashroom: 0,
        numberOfParking: 0,
        propertyPrice: 0,
        address: {
          name: '',
          country: '',
          city: '',
          state: '',
          isManual: false,
          placeId: '',
          postcode: '',
          latitude: 0,
          longitude: 0,
        },
        verificationDocuments: []
      }))
      navigate('/properties')
      setCongratulations(false)
    }).catch((err) => console.log('error'))
  }

  return (
    <Layout title='Landlord Step 4' user={true} header={false} footer={false}>
      <OnBoardProfileLayout
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Verification' description='Upload photos of these documents so we can verify that you are the owner of the property.' />

            {/* Form */}
            <div>
              <Stack gap={3} className='my-5'>
                {documents.map((item, index) =>
                  <div className='border bg-white p-3 rounded d-flex align-items-center justify-content-between' key={index} style={{ minHeight: 75 }}>
                    <Typography $variant='subtitle2'>
                      {item.image && <MdCheck className='text-success fs-4 me-2' />}
                      {item.name}
                    </Typography>
                    <div className='d-flex align-items-center gap-2'>
                      {!item.image ?
                        <div className='position-relative'>
                          <Button $variant='outlined' $loading={item.loading}>Upload</Button>
                          <Form.Control type='file' disabled={item.loading} accept="image/*" title='' tabIndex={0} className='position-absolute inset-0 opacity-0' onChange={(event) => handleUploadFile(event, index)} />
                        </div>
                        :
                        <>
                          <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleImageShow(true, index)} disabled={item.loading}>
                            <MdRemoveRedEye className='fs-5' />
                          </Button>
                          <Button $variant='outlined' $icon className='border shadow-none' onClick={() => handleRemoveFile(index)} $loading={item.loading}>
                            <MdOutlineClose className='fs-5' />
                          </Button>
                        </>
                      }
                    </div>
                  </div>
                )}
              </Stack>

              <div className='d-flex justify-content-end mt-5'>
                <Button $loading={status === THUNK_STATUS.LOADING} disabled={Boolean(status === THUNK_STATUS.LOADING) || isAnyLoading} onClick={() => onsubmit()}>Continue</Button>
              </div>
            </div>

            {documents.map((item, index) =>
              <Modal show={item.show} key={index} onHide={() => handleImageShow(false, index)}>
                <Modal.Header closeButton>
                  <Typography $variant='subtitle1' className='text-dark'>{item.name}</Typography>
                </Modal.Header>
                <Modal.Body>
                  <img src={item.image} className='rounded' />
                </Modal.Body>
              </Modal>
            )}

          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

      <GreetingPopup open={congratulations} setOpen={setCongratulations} heading='Congratulations!' buttonText='Finish Setup'
        description='Your property has been uploaded, you will receive an email when it has been verified'
        callback={() => goHomePage()}
      />


    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  description: yup.string().max(10000, 'Explain under ${max} characters').required(),
})

type FormData = yup.InferType<typeof schema>