import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiDollar } from "react-icons/bi"
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { MdHotel, MdBathtub, MdDirectionsCar } from "react-icons/md"
import { Container, Row, Col, Stack, Spinner, Alert as AlertBootstrap } from 'react-bootstrap'
import moment from 'moment'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css'

import { isVerificationImage } from '../../../utils'
import { getProfileThunk } from '../../../lib/redux/actions/auth.action'
import { useReduxSelector, useReduxDispatch } from '../../../hooks/redux'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { adminUpdatePropertyStatusThunk } from '../../../lib/redux/actions/admin.action'
import { RejectConfirm } from '../../../components/modalPopup/rejectConfirm/RejectConfirm.component'
import { ScheduleHome } from '../../../components/modalPopup/scheduleHome/ScheduleOpenHome.component'
import { ConfirmatonPopup } from '../../../components/modalPopup/confirmation/ConfirmationPopup.component'
import { rejectConfirmationTypes } from '../../../components/modalPopup/rejectConfirm/RejectConfirm.interface'
import { DataConfirmationTypes } from '../../../components/modalPopup/confirmation/ConfirmatonPopupProps.interface'
import { propertyDeleteThunk, propertyDetailsThunk, shortlistedTenantListThunk } from '../../../lib/redux/actions/property.action'
import placeholderImage from '../../../assets/images/placeholder.jpg'
import Layout from "../../../components/_layout/Layout.component"
import Card from '../../../components/_ui/card/Card.component'
import Alert from '../../../components/_ui/alert/Alert.component'
import VerifyDocument from './components/VerifyDocument.component'
import Badge from '../../../components/_ui/badge/Badge.component'
import Button from '../../../components/_ui/button/Button.component'
import AgentCard from '../../../components/agentCard/agentCard.component'
import TenantCard from '../../../components/tenantCard/TenantCard.component'
import Typography from "../../../components/_ui/typography/Typography.component"
import LandlordDetailCard from './components/landLordDetail/LandlordDetailCard.component'
import NotAvailableCard from './components/notAvailableCard/NotAvailableCard.component'



export default function PropertiesProfile() {

    const { propertyId } = useParams()
    const navigate = useNavigate()
    const dispatch = useReduxDispatch()
    const [scheduleShow, setscheduleShow] = useState(false)
    const [confirmationShow, setconfirmationShow] = useState(false)
    const [rejectShowModal, setrejectShowModal] = useState(false)
    const [deleteShowModal, setdeleteShowModal] = useState(false)
    const [rejectReason, setrejectReason] = useState('')
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
    const [valueAcceptReject, setValueAcceptReject] = useState('')
    const { data } = useReduxSelector(state => state.property.propertyDetails)
    const { shortlistedTenantList } = useReduxSelector(state => state.property)
    const { updatePropertyStatus } = useReduxSelector(state => state.admin)
    const { userRoles } = useReduxSelector(state => state.auth)
    const { total } = useReduxSelector(state => state.property.propertyListByUser)
    const sliderImage = Boolean(data?.image?.length) ? data?.image : [placeholderImage]


    const dataConfirmation: DataConfirmationTypes = {
        heading: 'Approve Confirmation',
        description: 'Are you sure you want to approve the property',
        buttonName: 'Approve'
    }

    const dataDeleteConfirmation: DataConfirmationTypes = {
        heading: 'Delete Confirmation',
        description: 'Are you sure you want to Delete the property',
        buttonName: 'Delete'
    }

    const rejectDataConfirmation: rejectConfirmationTypes = {
        heading: 'Reject Confirmation',
        descriptionFirst: 'Are you sure you want to reject the property ?',
        descriptionSeceond: 'Are you sure you want to reject the tenant',
        buttonNameFirst: 'Reject',
        buttonNameSecond: 'Confirm Reject'
    }


    useEffect(() => {
        dispatch(propertyDetailsThunk({ id: propertyId as string }))
        if (userRoles.includes('landlord') || userRoles.includes('admin')) {
            dispatch(shortlistedTenantListThunk({ propertyId: propertyId as string, page: 1, size: 100 }))
        }
    }, [])


    const handleAcceptReject = (status: string) => {
        if (userRoles.includes('admin')) {
            dispatch(adminUpdatePropertyStatusThunk({ id: propertyId as string, status, rejectReason })).unwrap()
                .then((response) => {
                    setrejectReason('')
                    dispatch(propertyDetailsThunk({ id: propertyId as string }))
                    toast.success(response?.data?.status)
                }).catch((err) => { console.log(err); setrejectReason('') })
        }
    }


    const getProfile = async () => {
        await dispatch(getProfileThunk())
    }


    const navigateToAgent = async (tenantId?: string) => {
        if (Boolean(tenantId)) {
            navigate(`/properties/agent/${propertyId}?tenantId=${tenantId}`)
        }
    }


    const deleteListing = () => {
        if (propertyId !== undefined) {
            dispatch(propertyDeleteThunk({ id: propertyId })).unwrap()
                .then((response) => {
                    toast.success('Property Delete')
                    if (userRoles.includes('landlord')) {
                        if (total === 1) {
                            getProfile()
                        }
                        navigate('/properties')
                    }
                    else {
                        navigate('/admin/property/verifications')
                    }
                })
        }
    }


    return (
        <Layout title='Property Profile' user={true}>
            <Container>
                {userRoles.includes('landlord') && data?.status === 'reject' &&
                    <Alert $variant='danger'>
                        <Row className='align-items-end'>
                            <Col>
                                <div><strong>Rejected</strong> : {data?.rejectReason}</div>
                            </Col>
                        </Row>
                    </Alert>
                }
                <Row className='row-gap-4'>
                    <Col xs={12} md={8}>
                        <Card className='mb-3'>
                            <Stack>
                                <Row className='row-gap-3'>
                                    <Col xs={12} sm={6}><Typography $variant='h2'>Property Listing</Typography></Col>
                                    <Col xs={12} sm={6} className='d-flex justify-content-sm-end'>
                                        {!userRoles.includes('tenant') && <Typography className='mb-3 mb-sm-0'>
                                            <Badge $variant='light'>{data?.status === 'verified' ? 'Active' : data?.status?.toUpperCase()}</Badge>
                                        </Typography>}
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col><Typography>{data?.address.name}</Typography></Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col>
                                        <Swiper spaceBetween={10} navigation autoHeight thumbs={{ swiper: thumbsSwiper }}
                                            modules={[FreeMode, Navigation, Thumbs]} className="styled">
                                            {sliderImage?.map((item, index) =>
                                                <SwiperSlide key={index}>
                                                    <img src={item} className='w-100 h-100 rounded border border-light-subtle object-fit-cover' style={{ maxHeight: 'max(400px, calc(100vh - 300px))' }} />
                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                        <Swiper onSwiper={setThumbsSwiper} spaceBetween={10} slidesPerView={5} freeMode
                                            watchSlidesProgress modules={[FreeMode, Navigation, Thumbs]} className="styled mt-4"
                                        >
                                            {data?.image?.map((item, index) =>
                                                <SwiperSlide key={index}>
                                                    <img src={item} className='w-100 h-100 rounded border border-light-subtle cursor-pointer' />
                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col xs>
                                        <span>
                                            <MdHotel className='fs-5' /> {data?.numberOfRoom}
                                        </span>
                                        <span>
                                            <MdBathtub className='fs-5' /> {data?.numberOfWashroom}
                                        </span>
                                        <span>
                                            <MdDirectionsCar className='fs-5' /> {data?.numberOfParking}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-between gap-4'>
                                            <div>
                                                <Typography $variant='h6' className=''>About this property</Typography>
                                                {data?.description}
                                            </div>
                                            {
                                                data?.propertyPrice &&
                                                <div className='flex-shrink-0'>
                                                    <Typography $variant='h5' className='d-flex justify-content-end'>Price</Typography>
                                                    <Typography $variant='body1' className='d-flex align-items-center'><BiDollar className='fs-6' />{data?.propertyPrice} / week</Typography>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            </Stack>

                        </Card>

                        <Card>
                            <Stack>
                                <Row className='mb-3'>
                                    <Col>
                                        <Typography $variant='h4'>Location</Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/* <iframe width="100%" height="450" loading="lazy" src={`https://www.google.com/maps/embed/v1/place?zoom=18&q=${data?.address.name}&key=AIzaSyAtumFpNeh1H0BI4cRdVQeQnvrb9NimijA`}></iframe> */}
                                        <iframe src={`https://maps.google.com/maps/embed/v1/place?key=AIzaSyAtumFpNeh1H0BI4cRdVQeQnvrb9NimijA&q=place_id:${data?.address.placeId}&zoom=18`} width="100%" height="450" loading="lazy"></iframe>
                                    </Col>
                                </Row>
                            </Stack>
                        </Card>

                    </Col>
                    <Col xs={12} md={4}>


                        {(userRoles.includes('landlord') || userRoles.includes('admin')) && data?.status !== 'leased' &&
                            <Card className='mb-3'>
                                {userRoles.includes('admin') && !!(data?.status === 'pending' || data?.status === 'reject') &&
                                    <Button $variant='outlined' className='shadow-none border-light-subtle w-100 mb-2'
                                        $loading={updatePropertyStatus?.status === THUNK_STATUS?.LOADING && valueAcceptReject === 'verified'}
                                        onClick={() => {
                                            setconfirmationShow(true)
                                            setValueAcceptReject('verified')
                                        }}>
                                        Approve
                                    </Button>
                                }
                                {userRoles.includes('admin') && data?.status !== 'reject' &&
                                    <Button $variant='outlined' className='shadow-none border-light-subtle w-100 mb-2'
                                        $loading={updatePropertyStatus?.status === THUNK_STATUS?.LOADING && valueAcceptReject === 'reject'}
                                        onClick={() => {
                                            setValueAcceptReject('reject')
                                            setrejectShowModal(true)
                                        }
                                        }>
                                        Reject
                                    </Button>
                                }
                                {(userRoles.includes('landlord') || userRoles.includes('admin')) &&
                                    <Button $variant='outlined' className='shadow-none border-light-subtle w-100 mb-2' onClick={() => setdeleteShowModal(true)}>
                                        Delete Listing
                                    </Button>
                                }
                                {(userRoles.includes('landlord') || userRoles.includes('admin')) &&
                                    <Button $variant='outlined' className='shadow-none border-light-subtle w-100 mb-2'
                                        onClick={() => navigate(`/properties/edit/${propertyId}`)}>
                                        Edit Listing
                                    </Button>
                                }
                                {userRoles.includes('admin') &&
                                    <Button $variant='outlined' className='shadow-none border-light-subtle w-100 mb-2'
                                        onClick={() => navigate(`/properties/tenants/${propertyId}`)}>
                                        View Tenant
                                    </Button>
                                }
                                {userRoles.includes('landlord') && data?.status === 'verified' &&
                                    <Button $variant='outlined' className='shadow-none border-light-subtle w-100 mb-2' onClick={() => navigate(`/properties/tenants/${propertyId}`)}>
                                        Search Tenant
                                    </Button>
                                }
                                {userRoles.includes('landlord') && data?.status === 'verified' &&
                                    <>
                                        <Button $variant='outlined' className='shadow-none border-light-subtle w-100' onClick={() => setscheduleShow(true)}>
                                            Schedule Open Home
                                        </Button>
                                    </>
                                }
                            </Card>
                        }


                        {/* === property owner detail === */}
                        {userRoles.includes('admin') && <LandlordDetailCard data={data} />}


                        {/* === Lease property tenant card === */}
                        {userRoles.includes('landlord') && data?.status === 'leased' &&
                            <>
                                <Typography $variant='body1' className='mb-2'>Tenant Lease</Typography>
                                <div className='my-3'>
                                    <TenantCard propertyId={propertyId as string} data={data?.tenantAssign} />
                                </div>
                            </>
                        }


                        {/* === Lease property tenant card no available tenant === */}
                        {userRoles.includes('admin') && data?.status === 'leased' &&
                            <>
                                <Typography $variant='body1' className='mb-2'>Tenant Lease</Typography>
                                <div className='my-3'>
                                    <TenantCard propertyId={propertyId as string} data={data?.tenantAssign} />
                                </div>
                            </>
                        }
                        {userRoles.includes('admin') && !data?.tenantAssign?.name && <>
                            <Typography $variant='body1' className='mb-2'>Tenant Lease</Typography>
                            <NotAvailableCard title={'tenant'} />
                        </>}


                        {/* Show Shortlisted Tenants to the landlord */}
                        {Boolean(userRoles.includes('landlord') || userRoles.includes('admin')) && data?.status === 'verified' &&
                            <>
                                <Typography $variant='body1' className='mb-2'>Shortlisted Tenant</Typography>
                                {shortlistedTenantList.status === THUNK_STATUS.SUCCESS &&
                                    shortlistedTenantList.data.map((item, index) => (
                                        <div className='my-3' key={index}>
                                            <TenantCard propertyId={propertyId as string} data={item} />
                                        </div>
                                    ))
                                }

                                {shortlistedTenantList.status === THUNK_STATUS.SUCCESS && !Boolean(shortlistedTenantList.data.length) && <NotAvailableCard title={'shortlisted tenant'} />}
                                {shortlistedTenantList.status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block my-3' variant='secondary' />}
                                {shortlistedTenantList.status === THUNK_STATUS.FAILED && <Alert className='m-auto d-block my-3' $variant='danger'>Something went wrong</Alert>}
                            </>
                        }

                        {/* choose Agents and show agents cards  */}
                        {userRoles.includes('landlord') && data?.status === 'leased' && (
                            <>
                                <Typography $variant='body1' className='mb-2'>Agent</Typography>
                                {data.managetBy !== 'none' ? (
                                    <div className='my-3'>
                                        <AgentCard propertyId={propertyId as string} data={data} />
                                    </div>
                                ) : (
                                    <Button $variant='outlined' className='w-100' onClick={() => navigateToAgent(data?.tenantAssign?._id)}>
                                        Choose agent
                                    </Button>
                                )}
                            </>
                        )}


                        {/* verification document */}
                        <Stack className='gap-2'>
                            {
                                (userRoles.includes('admin')) && data?.verificationDocuments &&
                                <>
                                    <Typography $variant='body1'>Documents</Typography>
                                    {
                                        data?.verificationDocuments.map((item, index) => (item.image !== '' ? <VerifyDocument key={index} data={item} /> : null))
                                    }

                                    {isVerificationImage(data.verificationDocuments) ?
                                        <AlertBootstrap variant='light' className='text-center mb-2'>No Documents Found</AlertBootstrap>
                                        : null
                                    }
                                </>
                            }
                        </Stack>



                        {/* Show Congratulations message to the tenant */}
                        {userRoles.includes('tenant') && data?.isAllotedMe &&
                            <Card className='mb-3'>
                                <Typography $variant='h5'>Congratulations!</Typography>
                                <Typography>You've been shortlisted for this property</Typography>
                            </Card>
                        }

                        {
                            (userRoles.includes('tenant') || userRoles.includes('landlord')) && Boolean(data?.scheduleDate?.length) &&
                            <Card className='mb-3'>
                                <Typography className='mb-2' $variant='h5'>Open Home Viewing</Typography>
                                {data?.scheduleDate.map((item: any, index: number) => (
                                    <Typography className='mt-1'>{moment(item?.datetime).utc().format('DD MMMM YYYY hh:mm A')}</Typography>
                                ))}
                            </Card>
                        }

                    </Col>
                </Row>
            </Container>


            {/* Modal Pop-up  */}
            <ScheduleHome show={scheduleShow} onHide={() => setscheduleShow(false)} />


            {/* Reject pop-up */}
            <RejectConfirm data={rejectDataConfirmation} show={rejectShowModal} onHide={() => setrejectShowModal(false)}
                callBack={() => handleAcceptReject('reject')} setrejectReason={setrejectReason} rejectReason={rejectReason} />


            {/* Confirmation pop-up */}
            <ConfirmatonPopup data={dataConfirmation} show={confirmationShow} onHide={() => setconfirmationShow(false)}
                callBack={() => handleAcceptReject('verified')} />

            {/* Delete confirmation popup */}
            <ConfirmatonPopup data={dataDeleteConfirmation} show={deleteShowModal} onHide={() => setdeleteShowModal(false)}
                callBack={() => deleteListing()} />
        </Layout >
    )
}
