import * as yup from 'yup'
import { currencyCheck, fileSizeTest, stringTest } from '../../../../utils'



{/* == Validation == */ }
export const schema = yup.object({
  targetType: yup.string().trim().required(),
  subject: yup.string().trim().required(),
  body: yup.string().trim().required(),
})

export type FormDataType = yup.InferType<typeof schema>