import { RejectConfirmProps } from './RejectConfirm.interface'
import Modal from 'react-bootstrap/Modal'
import "react-datepicker/dist/react-datepicker.css"
import Button from '../../_ui/button/Button.component'
import Typography from '../../_ui/typography/Typography.component'
import { Form } from 'react-bootstrap'
import { ChangeEvent, useState } from 'react';

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;



export function RejectConfirm(props: RejectConfirmProps) {
  const { callBack, onHide, setrejectReason, rejectReason, data } = props

  const [confirmation, setConfirmation] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setConfirmation(true)
  }


  const handleChange: (e: ChangeEvent<FormControlElement>) => void = (event) => {
    setrejectReason(event.target.value)
  };


  const confirmReject = () => {
    callBack()
    setConfirmation(false)
    onHide()
  }

  return (
    <Modal {...props} onHide={() => { onHide(); setConfirmation(false); setrejectReason('') }} aria-labelledby="contained-modal-title-vcenter" centered animation className='m-width-200'>
      <Modal.Header closeButton>
        <Typography $variant='h4' id="contained-modal-title-vcenter">{data?.heading}</Typography>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {!confirmation && <>
            <Typography $variant='subtitle2' className='mb-3'>{data.descriptionFirst}</Typography>
            <Form.Control required as='textarea' value={rejectReason} onChange={(e: ChangeEvent<FormControlElement>) => handleChange(e)} placeholder='Write to reason...' style={{ height: 150 }} />
          </>
          }

          {confirmation && <Typography $variant='subtitle2' className='mb-3'>{data.descriptionSeceond}</Typography>}

        </Modal.Body>
        <div className='d-flex justify-content-end m-3'>
          {!confirmation && <Button $variant='filled' type='submit'>{data.buttonNameFirst}</Button>}
          {confirmation && <Button $variant='filled' onClick={confirmReject}>{data.buttonNameSecond}</Button>}
        </div>
      </Form>
    </Modal >
  );
}

