import { MdClose } from "react-icons/md"
import { Form } from "react-bootstrap"
import { stringTest } from "../../../../utils"
import { useNavigate } from "react-router-dom"
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray } from 'react-hook-form'
import { Col, FloatingLabel, Row, Stack } from "react-bootstrap"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import Button from "../../../../components/_ui/button/Button.component"
import background_image from '../../../../assets/images/register/background_003.jpg';



export default function ProfileOnBoardStep8() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { user } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)


  const { handleSubmit, register, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vehicles: user?.profile?.vehicles || []
    }
  })

  const vechiclesField = useFieldArray({ name: 'vehicles', control })

  const onSubmit = async (data: FormData) => {
    // dispatch(setprofileOnboardFormData(data))
    dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
      dispatch(getProfileThunk()).unwrap().then(() => {
        navigate('/tenant/profile/references')
      }).catch((error) => console.log('error', error))
    }).catch((error) => console.log('error', error))

  }


  return (
    <Layout title='Tenant Step 8' user={true} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress="80"
        headerActionTitle=""
        headerActionButtonText="Skip"
        headerActionButtonLink="/tenant/profile/references"
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Vehicles' description='Add any vehicles you might own.' />

            <Form onSubmit={handleSubmit(onSubmit)}>
              {vechiclesField.fields.map((item, index) =>
                <Row className='mb-3 animate__animated animate__fadeInUp animate__faster gap-2 gap-md-0' key={item.id}>
                  <Col xs={12} md={4}>
                    <Form.Group>
                      <FloatingLabel controlId={`vehicles.${index}.model`} label='Make/Model'>
                        <Form.Control autoFocus type='text' placeholder='' isInvalid={Boolean(errors.vehicles?.[index]?.model)} {...register(`vehicles.${index}.model` as const)} />
                      </FloatingLabel>
                      <Form.Text className='text-danger'>{errors.vehicles?.[index]?.model?.message}</Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group>
                      <FloatingLabel controlId={`vehicles.${index}.color`} label='Color'>
                        <Form.Control type='text' placeholder='' isInvalid={Boolean(errors.vehicles?.[index]?.color)} {...register(`vehicles.${index}.color` as const)} />
                      </FloatingLabel>
                      <Form.Text className='text-danger'>{errors.vehicles?.[index]?.color?.message}</Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className='d-flex flex-column justify-content-md-center gap-2'>
                      <Form.Group>
                        <FloatingLabel controlId={`vehicles.${index}.vehicleNumber`} label='Rego'>
                          <Form.Control type='text' placeholder='' isInvalid={Boolean(errors.vehicles?.[index]?.vehicleNumber)} {...register(`vehicles.${index}.vehicleNumber` as const)} />
                        </FloatingLabel>
                        <Form.Text className='text-danger'>{errors.vehicles?.[index]?.vehicleNumber?.message}</Form.Text>
                      </Form.Group>

                      <div className='align-self-end '>
                        <Button $icon $variant='outlined' type='button' onClick={() => vechiclesField.remove(index)}>
                          <MdClose />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <Stack gap={3}>
                <Button $variant='outlined' className='fw-medium' type='button' onClick={() => vechiclesField.append({ model: '', color: '', vehicleNumber: '' })}>Add Vehicle</Button>
              </Stack>

              <div className="d-flex justify-content-end mt-4 gap-3">
                <Button $variant='filled' type='submit' $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}

{/* == Validation == */ }
const schema = yup.object({
  vehicles: yup.array().of(
    yup.object({
      model: yup.string().trim().required().min(2).max(200).test(stringTest),
      color: yup.string().trim().required().min(2).max(100).test(stringTest),
      vehicleNumber: yup.string().trim().matches(/^[a-zA-Z0-9]*$/).max(7, 'Input must be at most 7 characters').required()
    })
  )
})

type FormData = yup.InferType<typeof schema>