import { Col, Row, Spinner, Stack, Table, Alert as AlertBootstrap } from 'react-bootstrap'
import { MdDone, MdVerified, MdWarning } from 'react-icons/md'
import { FaUserCheck, FaUserTimes } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux';
import { adminAccountsDataThunk, adminUserAccountsThunk } from '../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant';
import { limitRowSize } from '../../../data/config';
import { debounce } from '../../../utils/debonce';
import moment from 'moment';

import Card from '../../../components/_ui/card/Card.component'
import Alert from '../../../components/_ui/alert/Alert.component';
import Layout from '../../../components/_layout/Layout.component'
import AdminLayout from "../components/adminLayout/AdminLayout.component"
import AccountsNav from '../components/accountsNav/Nav.component'

import Typography from '../../../components/_ui/typography/Typography.component'
import SearchInput from '../../../components/_ui/searchInput/SearchInput.component';
import PaginationComponent from '../../../components/pagination/PaginationComponent';
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component';
import SearchImg from '../../../assets/images/search.svg'
import { checkAllInfo, convertToMelbourneTime } from '../../../utils';


export default function Accounts() {

  const navigate = useNavigate();
  let limitSize = limitRowSize
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState('')

  const dispatch = useReduxDispatch()
  const { accountData } = useReduxSelector(state => state.admin)
  const { userAccounts } = useReduxSelector(state => state.admin)


  useEffect(() => {
    userDataFetch(inputValue, currentPage)
  }, [])


  // Create debounced search function
  const userDataFetch = useCallback(debounce((value: string, page: number) => {
    dispatch(adminUserAccountsThunk({ page: page, size: limitSize, search: value })).unwrap()
      .then((response) => {
        setData(response?.data?.info?.result);
        setTotalPages(Math.ceil(response?.data?.info?.totalRecords / limitSize) || 0)
      })
      .catch(err => console.log(err))
  }, 1000), []);


  const handlePageChange = (page: number) => {
    userDataFetch(inputValue, page)
    setCurrentPage(page)
  };

  const handleInputChange = (newValue: string) => {
    userDataFetch(newValue, 1)
    setInputValue(newValue)
  }


  return (
    <Layout title='Accounts' user={true}>
      <AdminLayout nav={<AccountsNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Active Accounts</Typography>
          <Typography $variant='body1'>List of user accounts recently created</Typography>
        </div>

        {/* Counts */}
        <Row>
          {accountData?.status === THUNK_STATUS.LOADING && <div className='my-3'><Spinner className='m-auto mt-3 d-block' variant='secondary' /></div>}
          {accountData?.status === THUNK_STATUS.FAILED && <Alert className='m-auto mt-3 d-block' $variant='danger'>Something went wrong</Alert>}

          {accountData?.status === THUNK_STATUS.SUCCESS && <>
            <Col xs={6} md={4}>
              <Card as={Stack} gap={2} className='mb-3 align-items-center'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <Typography $variant='body1'>Active Accounts</Typography>
                </div>
                <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.totalRecords}</Typography>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card as={Stack} gap={2} className='mb-3 align-items-center'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <Typography $variant='body1'>Tenants</Typography>
                </div>
                <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.totalTenantRecords}</Typography>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card as={Stack} gap={2} className='mb-3 align-items-center'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <Typography $variant='body1'>Landlords</Typography>
                </div>
                <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.totalLandLordRecords}</Typography>
              </Card>
            </Col>

            <Col xs={6} md={4}>
              <Card as={Stack} gap={2} className='mb-3 align-items-center'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <Typography $variant='body1'>Signups Today</Typography>
                </div>
                <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.signupsToday}</Typography>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card as={Stack} gap={2} className='mb-3 align-items-center'>
                <div className='d-flex align-items-center justify-content-between gap-3'>
                  <Typography $variant='body1'>Signups Yesterday</Typography>
                </div>
                <Typography $variant='h1' as='div' className='fs-3 mb-2'>{accountData?.data?.signupsYesterday}</Typography>
              </Card>
            </Col>
          </>
          }
        </Row>


        <Card>
          <div className='mb-3'>
            <SearchInput className='w-auto' onChange={handleInputChange} placeholder='Search Name, Phone and Email...' />
          </div>

          <Table striped responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Type</th>
                <th>Signup</th>
                <th>Status</th>
                <th>Verified</th>
                <th>Setup</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {userAccounts?.status === THUNK_STATUS.SUCCESS &&
                data?.map((item: any, index: number) => {

                  var x = checkAllInfo(item)
                  return (
                    <tr key={index} onClick={() => navigate(`/admin/accounts/${item?._id}`)}>
                      <td>
                        {item?.name} {item?.profile?.isBlueTickNeeded === 'accept' ? <MdVerified className='text-blue' /> : null}
                        <br /><span style={{ fontSize: 10 }}>{item?.email}</span>
                      </td>
                      <td>{item?.phone}</td>
                      <td>{item?.profile?.isShareHouse ? 'Co ' : ''} {item?.userRoles[0]?.name}</td>
                      <td>{convertToMelbourneTime(item?.createdAt).fromNow()}</td>
                      <td>{item?.status == 'active' ? <FaUserCheck fontSize={18} className='text-success' /> : <FaUserTimes fontSize={18} className='text-danger' />}</td>
                      <td>{item?.isVerified ? <MdDone className='text-success' /> : <MdWarning className='text-warning' />}</td>
                      <td>{(x.isSetup ? (<span className="text-success">Yes</span>) : (<span className="text-danger fw-bold">No</span>))}</td>
                      <td>{x.score}%</td>
                    </tr>
                  )
                })
              }
            </tbody>

          </Table>
          <div className='d-flex justify-content-center mb-3'>
            {userAccounts.status === THUNK_STATUS.LOADING && <div className='my-3'><Spinner className='m-auto my-3 d-block' variant='secondary' /></div>}
            {userAccounts.status === THUNK_STATUS.SUCCESS && !Boolean(userAccounts?.data?.length) &&
              <NoRecordFound shadow='shadow-none' image={SearchImg} className='text-center w-100'>
                <Typography $variant='h4'>Accounts</Typography>
                <Typography $variant='body1'>No record found</Typography>
              </NoRecordFound>
            }
            {userAccounts.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
          </div>
          <div className='d-flex justify-content-end'>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </Card>

      </AdminLayout>
    </Layout >
  )
}
