import { useEffect } from "react";
import { LayoutProps } from "./Layout.interface";
import { useLocation, useNavigate, ScrollRestoration } from "react-router-dom";
import { useReduxSelector } from "../../hooks/redux";
import Header from "./components/header/Header.component";
import Footer from "./components/footer/Footer.component";
import config from "../../config";
import "../../lib/yup/config";
import { FeedbackPopup } from "../modalPopup/feedback/FeedbackPopup.component";
import { routeExists } from "../../utils";
import analytics from "../../config/analytics";

export default function Layout(props: LayoutProps) {
  const location = useLocation();
  const { isLogggedIn } = useReduxSelector((state) => state.auth);
  const { userRoles } = useReduxSelector((state) => state.auth);
  const { loading } = useReduxSelector((state) => state.layout);
  const {
    children,
    title,
    footer = true,
    header = true,
    user = null,
    roles,
    feedbackShow = true,
  } = props;
  document.title = `${title} | ${config.brandName}`;

  const feedbackMatchRoute = ["/store/checkout"];

  useEffect(() => {
    analytics.trackPage();
  }, [location]);

  // useEffect(() => {
  //   if (!loading) {
  //     if (user && !isLogggedIn) navigate('/auth/login')
  //     if (user === false && isLogggedIn) navigate('/')
  //   }
  // }, [loading, isLogggedIn])

  // if (!loading && roles && !Boolean(userRoles.filter(role => roles?.includes(role)).length)) {
  //   return <Navigate to='/' />
  // }

  return (
    <>
      <ScrollRestoration />
      {((user && isLogggedIn) ||
        (user === false && !isLogggedIn) ||
        user === null) &&
        !loading && (
          <>
            {header && <Header />}
            <main
              className="animate__animated animate__fadeIn animate__faster pt-0"
              key={location.pathname}
            >
              {children}
            </main>
            {footer && <Footer />}
            {Boolean(
              userRoles.includes("landlord") || userRoles.includes("tenant")
            ) &&
              !routeExists(feedbackMatchRoute) &&
              feedbackShow && <FeedbackPopup />}
          </>
        )}
    </>
  );
}
