import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { StyledCheckIcon } from "./Step1.style"
import { Col, Container, Row } from "react-bootstrap"
import { Roles } from "../../../../interfaces/Roles.type"
import { MdApartment, MdHouse, MdOutlineAddHome, MdOutlineHome, MdOutlinePerson } from "react-icons/md"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { handleSteps, setRegisterFormData } from "../../../../lib/redux/slices/auth.slice"
import Steps from "../component/steps/Steps.component"
import onBoard from '../../../../assets/images/onBoard.jpg'
import Card from "../../../../components/_ui/card/Card.component"
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import Typography from "../../../../components/_ui/typography/Typography.component"
import RegisterLayout from "../component/registerLayout/RegisterLayout.component"
import background_image from '../../../../assets/images/register/background_001.jpg';


export default function RegisterStep1() {
  const dispatch = useReduxDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const rolesCanRegister: Roles[] = ['tenant', 'landlord']
  const { formData } = useReduxSelector(state => state.auth.register)


  const handleNext = () => {
    dispatch(handleSteps(2))
  }


  const handleCard = (role: Roles) => {
    navigate(`/auth/register/${role}`)
    //dispatch(setRegisterFormData({ userRoles: role }))
  }


  useEffect(() => {
    const role = searchParams.get('type') as Roles
    if (Boolean(rolesCanRegister.filter(item => item === role).length)) {
      handleCard(role)
      navigate('/auth/register/step-1', { replace: true })
      handleNext()
    }
  }, [])


  return (
    <Layout title='Register Step 1' user={false} header={false} footer={false}>
      <RegisterLayout
        headerActionTitle="Already have an account?"
        headerActionButtonText="Sign In"
        headerActionButtonLink="/auth/login"
        progress="100"
        columnFirst={
          <>
            {/* Heading */}
            <div className='d-flex flex-column gap-2 mb-5'>
              {/* <Steps active={1} /> */}
              <Typography $variant='h3'>Create an Account</Typography>
              <Typography $variant='body1'>Before we get started, are you a tenant looking for a rental property or are you a landlord looking for tenants?</Typography>
            </div>

            {/* Content */}
            <Row className='row-gap-4 mb-3'>
              <Col xs={12} sm={6}>
                <Card className='text-center  cursor-pointer position-relative shadow' tabIndex={0} onClick={() => handleCard('tenant')} $hover $selected={formData?.userRoles?.includes('tenant')}>
                  {formData?.userRoles?.includes('tenant') && <StyledCheckIcon />}
                  <MdOutlinePerson className="mb-2 text-secondary fs-1" />
                  <Typography $variant="h2">I'm a Tenant</Typography>
                  <Typography $variant="body1" as="p" className="mt-2">Looking to rent a house</Typography>
                </Card>
              </Col>
              <Col xs={12} sm={6}>
                <Card className='text-center  cursor-pointer position-relative shadow' tabIndex={0} onClick={() => handleCard('landlord')} $hover $selected={formData?.userRoles?.includes('landlord')}>
                  {formData?.userRoles?.includes('landlord') && <StyledCheckIcon />}
                  <MdOutlineHome className="mb-2 text-secondary fs-1" />
                  <Typography $variant="h2">I'm a Landlord</Typography>
                  <Typography $variant="body1" as="p" className="mt-2">Looking for a tenant</Typography>
                </Card>
              </Col>
            </Row>

            { /*<Row>
              <Col>
                <Button $variant='filled' className='w-100' disabled={!Boolean(formData.userRoles?.length)} onClick={() => handleNext()}>Continue</Button>
              </Col>
            </Row>*/ }
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{ 
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}
