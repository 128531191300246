import { styled } from "styled-components";

export const ChatWrapper = styled.div`
  padding: 0.5rem;
  height: 100%;
  max-height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  border-radius: var(--bs-border-radius);
  transition: box-shadow 0.3s;
  background: #fff;
`

export const LeftSideChat = styled.div`
  background-color: var(--bs-tertiary-bg);
  text-align: start;
  border-radius: 0px 8px 8px 8px;
  max-width: 90%;
  padding: 1rem;
  margin-bottom: 8px;
  border: solid 1px #00000010;
  `

export const RightSideChat = styled.div`
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.primary.main};
  border-radius: 8px 8px 0px 8px;
  padding: 1rem;
  max-width: 90%;
  height: 100%;
  width: auto;
  div {
    color: #ffffff;
  }
`