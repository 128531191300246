import { Outlet, RouteObject } from 'react-router-dom'
import { RolesProvider } from './components/RoleProvider'
import Dashboard from '../pages/dashboard/Dashboard.page'
import Messages from '../pages/messages/Messages.page'
import AccountDetails from '../pages/settings/account-details/AccountDetails.page'
import Security from '../pages/settings/security/Security.page'
import TenantProfile from '../pages/settings/tenant-profile/TenantProfile.page'
import Notifications from '../pages/settings/notifications/Notifications.page'
import PropertiesAdd from '../pages/properties/add/PropertiesAdd.page'
import PropertiesEdit from '../pages/properties/edit/PropertiesEdit.page'
import PropertiesList from '../pages/properties/Properties.page'
import PropertiesProfile from '../pages/properties/profile/PropertiesProfile.page'
import PropertiesVerify from '../pages/properties/verify/PropertiesVerify.page'
import PropertiesAgent from '../pages/properties/agent/PropertiesAgent.page'
import Tenants from '../pages/properties/tenants/Tenants.page'
import Profile from '../pages/profile/Profile.page'
import TenantVerify from '../pages/tenant/verify/TenantVerify.page'

import StoreBoost from '../pages/store/boost/Boost.page'
import StoreVerification from '../pages/store/verification/Verification.page'
import StoreCheckout from '../pages/store/checkout/Checkout.page'
import StoreUpgrade from '../pages/store/upgrade/Upgrade.page'

import ThankYou from '../pages/thank-you/ThankYou.page'

import AdminDashboard from '../pages/admin/dashboard/Dashboard.page'
import AdminAccounts from '../pages/admin/accounts/Accounts.page'
import AdminAllProperties from '../pages/admin/all-properties/AllProperties.page'
import AdminBulkEmail from '../pages/admin/bulk-email/bulkEmailForm/BulkEmail.page'
import AdminAllBulkEmail from '../pages/admin/bulk-email/bulkEmailList/AllBulkEmail.page'
import AdminAccountProfile from '../pages/admin/accounts/AccountProfile.page'
import AdminPayments from '../pages/admin/payments/Payments.page'
import AdminTenantVerifications from '../pages/admin/verifications/tenantVerification/AdminTenantVerify.component'
import AdminPropertyVerifications from '../pages/admin/verifications/propertyVerification/AdminPropertyVerify.component'
import AdminAgents from '../pages/admin/agents/Agents.page'

import AdminReportsTenantList from '../pages/admin/reports/TenantsList.page'

import ProfileOnBoardStep1 from '../pages/tenant/profile/location/Location.page'
import ProfileOnBoardStep2 from '../pages/tenant/profile/budget/Budget.page'
import ProfileOnBoardStep3 from '../pages/tenant/profile/family/Family.page'
import ProfileOnBoardStep4 from '../pages/tenant/profile/salary/Salary.page'
import ProfileOnBoardStep5 from '../pages/tenant/profile/current-status/CurrentStatus.page'
import ProfileOnBoardStep6 from '../pages/tenant/profile/photos/Photos.page'
import ProfileOnBoardStep7 from '../pages/tenant/profile/about-me/AboutMe.page'
import ProfileOnBoardStep8 from '../pages/tenant/profile/vehicles/Vehicles.page'
import ProfileOnBoardStep9 from '../pages/tenant/profile/references/References.page'
import ProfileOnBoardStep10 from '../pages/tenant/profile/boost/Boost.page'

import StepProperty1 from '../pages/properties/onBoardingProperty/step-1/StepProperty1.page'
import StepProperty2 from '../pages/properties/onBoardingProperty/step-2/StepProperty2.page'
import StepProperty3 from '../pages/properties/onBoardingProperty/step-3/StepProperty3.page'
import StepProperty4 from '../pages/properties/onBoardingProperty/step-4/StepProperty4.page'
import StepProperty5 from '../pages/properties/onBoardingProperty/step-5/StepProperty5.page'


import ProtectedRoute from './components/ProtectedRoutes'
import TenantShortlistedProperties from '../pages/shortlisted/tenant-allProperties/TenantShortlistedProperties.component'



export const protectedRoutes: RouteObject[] = [
  { path: '/', element: <ProtectedRoute ><Dashboard /> </ProtectedRoute> },
  { path: '/thank-you', element: <ProtectedRoute><ThankYou /></ProtectedRoute> },
  {
    path: '/messages',
    element: <ProtectedRoute >
      <RolesProvider roles={['landlord', 'tenant']}><Messages /></RolesProvider>
    </ProtectedRoute>
  },
  {
    path: '/shortlisted',
    element: <ProtectedRoute><Outlet /></ProtectedRoute>,
    children: [
      { path: 'tenant-allProperties', element: <RolesProvider roles={['tenant']}><TenantShortlistedProperties /></RolesProvider> },
    ]
  },
  {
    path: '/profile/:tenantId', element: <ProtectedRoute>
      <RolesProvider roles={['admin', 'landlord', 'agent']}> <Profile /></RolesProvider>
    </ProtectedRoute>
  },
  {
    path: '/settings',
    element: <ProtectedRoute><Outlet /></ProtectedRoute>,
    children: [
      { path: 'tenant-profile', element: <RolesProvider roles={['tenant']}><TenantProfile /></RolesProvider> },
      { path: 'account-details', element: <RolesProvider roles={['landlord', 'tenant']}><AccountDetails /></RolesProvider> },
      { path: 'security', element: <RolesProvider roles={['landlord', 'tenant']}><Security /></RolesProvider> },
      { path: 'notifications', element: <RolesProvider roles={['landlord', 'tenant']}><Notifications /></RolesProvider> },
    ]
  },
  {
    path: '/properties',
    element: <ProtectedRoute ><Outlet /></ProtectedRoute>,
    children: [
      {
        path: '',
        element: <RolesProvider roles={['landlord']}><PropertiesList /></RolesProvider>
      },
      {
        path: 'add',
        element: <RolesProvider roles={['landlord']}><PropertiesAdd /></RolesProvider>
      },
      {
        path: 'agent/:propertyId',
        element: <RolesProvider roles={['landlord']}><PropertiesAgent /></RolesProvider>
      },
      {
        path: 'tenants/:propertyId',
        element: <RolesProvider roles={['admin', 'landlord']}><Tenants /></RolesProvider>
      },
      {
        path: 'edit/:propertyId',
        element: <RolesProvider roles={['admin', 'landlord']}><PropertiesEdit /></RolesProvider>
      },
      {
        path: 'verify/:propertyId',
        element: <RolesProvider roles={['admin', 'landlord']}><PropertiesVerify /></RolesProvider>
      },
      { path: 'profile/:propertyId', element: <PropertiesProfile /> },
    ]
  },
  {
    path: '/admin',
    element: <ProtectedRoute ><RolesProvider roles={['admin']}><Outlet /></RolesProvider></ProtectedRoute>,
    children: [
      { path: 'dashboard', element: <AdminDashboard /> },
      { path: 'accounts', element: <AdminAccounts /> },
      { path: 'all-properties', element: <AdminAllProperties /> },
      { path: 'bulk-email', element: <AdminBulkEmail /> },
      { path: 'all-bulk-email', element: <AdminAllBulkEmail /> },
      { path: 'agents', element: <AdminAgents /> },
      { path: 'accounts/:userId', element: <AdminAccountProfile /> },
      { path: 'payments', element: <AdminPayments /> },
      { path: 'reports/tenants-list', element: <AdminReportsTenantList /> },
      { path: 'tenant/verifications', element: <AdminTenantVerifications /> },
      { path: 'property/verifications', element: <AdminPropertyVerifications /> },
      { path: 'property/verifications/:propertyId', element: <PropertiesProfile /> },
      { path: 'tenant/verifications/:tenantId', element: <TenantVerify /> },
    ]
  },
  {
    path: '/tenant',
    element: <ProtectedRoute><RolesProvider roles={['tenant']}><Outlet /></RolesProvider></ProtectedRoute>,
    children: [
      { path: 'verify', element: <TenantVerify /> },
      { path: 'profile/location', element: <ProfileOnBoardStep1 /> },
      { path: 'profile/budget', element: <ProfileOnBoardStep2 /> },
      { path: 'profile/family', element: <ProfileOnBoardStep3 /> },
      { path: 'profile/salary', element: <ProfileOnBoardStep4 /> },
      { path: 'profile/current-status', element: <ProfileOnBoardStep5 /> },
      { path: 'profile/photos', element: <ProfileOnBoardStep6 /> },
      { path: 'profile/about-me', element: <ProfileOnBoardStep7 /> },
      { path: 'profile/vehicles', element: <ProfileOnBoardStep8 /> },
      { path: 'profile/references', element: <ProfileOnBoardStep9 /> },
      { path: 'profile/boost', element: <ProfileOnBoardStep10 /> },
    ]
  },
  {
    path: '/landlord',
    element: <ProtectedRoute ><RolesProvider roles={['landlord']}><Outlet /></RolesProvider></ProtectedRoute>,
    children: [
      { path: 'profile/step-1', element: <StepProperty1 /> },
      { path: 'profile/step-2', element: <StepProperty2 /> },
      { path: 'profile/step-3', element: <StepProperty3 /> },
      { path: 'profile/step-4', element: <StepProperty4 /> },
      { path: 'profile/step-5', element: <StepProperty5 /> },
    ]
  },
  {
    path: '/store',
    element: <ProtectedRoute ><RolesProvider roles={['tenant']}><Outlet /></RolesProvider></ProtectedRoute>,
    children: [
      { path: 'boost', element: <StoreBoost /> },
      { path: 'verification', element: <StoreVerification /> },
      { path: 'checkout', element: <StoreCheckout /> },
      { path: 'upgrade', element: <StoreUpgrade /> },
    ]
  },

]