import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { Spinner, Alert } from 'react-bootstrap'
import { adminUserAccountsLoginThunk, adminUserDetailByIdThunk } from '../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant';
import Layout from "../../../components/_layout/Layout.component"
import AdminLayout from "../components/adminLayout/AdminLayout.component"
import AdminNav from '../components/adminNav/SidebarNav.component'

import TenantDetail from './components/adminTenantDetail/TenantDetail.component';
import LandlordDetail from './components/adminLandlordDetail/LandlordDetail.component';
import Button from '../../../components/_ui/button/Button.component';
import Card from '../../../components/_ui/card/Card.component';
import Typography from '../../../components/_ui/typography/Typography.component';




export default function AccountProfile() {

  const dispatch = useReduxDispatch()
  const { userId } = useParams()
  const { data, status } = useReduxSelector(state => state.admin.userDetail)
  const { status: statusAccountLogin } = useReduxSelector(state => state.admin.adminUserAccountLogin)


  useEffect(() => {
    dispatch(adminUserDetailByIdThunk({ id: userId as string }))
  }, [])


  function handleUserLogin() {
    console.log(data)
    dispatch(adminUserAccountsLoginThunk({ email: data.email }))
  }

  return (
    <Layout title='Accounts' user={true}>

      <AdminLayout nav={<AdminNav />}>
        {
          data?.status === 'active' && status === THUNK_STATUS.SUCCESS ?
            <div className='d-flex justify-content-end p-3'>
              <Button $variant='text' $loading={statusAccountLogin === THUNK_STATUS.LOADING} onClick={() => handleUserLogin()}>Login as User</Button>
            </div> : null
        }

        {!data?.profile?.createdAt && !Boolean(status === THUNK_STATUS.LOADING) && <Card>
          <Typography className='d-flex justify-content-center'>There is no profile data </Typography>
        </Card>}

        {/* Tenant Detail */}
        {status === THUNK_STATUS.SUCCESS && data?.userRoles?.includes('tenant') && <TenantDetail data={data} />}

        {/* Landlord Detail */}
        {status === THUNK_STATUS.SUCCESS && data?.userRoles?.includes('landlord') && <LandlordDetail data={data} />}

        {/* Loading and Error handling  */}
        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto mt-3 d-block' variant='secondary' />}
        {status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' variant='danger'>something went wrong</Alert>}
        {/* {status === THUNK_STATUS.SUCCESS && !Boolean(data?.length) && <NoRecordFound />} */}
      </AdminLayout >
    </Layout >
  )
}
