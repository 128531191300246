import * as yup from 'yup'
import { useEffect } from "react"
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import { Col, Row, Form } from "react-bootstrap"
import { yupResolver } from '@hookform/resolvers/yup'

import { stringTest } from "../../../../utils"
import { updateRoleThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { setGoogleLoginFormData } from "../../../../lib/redux/slices/auth.slice"
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import background_image from '../../../../assets/images/register/background_002.jpg';
import RegisterLayout from "../../register/component/registerLayout/RegisterLayout.component"
import SocialLoginStep2 from "../../components/socialLogin/step-2/SocialLoginStep2"
import { userSystemInfo } from '../../../../utils/userSystemInfo'
import { THUNK_STATUS } from '../../../../lib/redux/constants/status.constant'



export default function GoogleStep2() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { formData } = useReduxSelector(state => state.auth.googleLogin)
  const { status } = useReduxSelector(state => state.auth.updateRole)
  const { formData: stepNewSignUpTenant } = useReduxSelector(state => state.auth.findHomeSignupTenantFormData)
  const { handleSubmit, register, watch, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: sessionStorage.getItem('fullName') || ''
    }
  })


  useEffect(() => {
    if (!formData.userRoles) navigate('/auth/google/step-1')
  }, [formData])


  const onSubmit = (dataForm: FormData) => {
    try {
      let data = {
        _id: sessionStorage.getItem('id'),
        name: dataForm.name,
        userRoles: formData.userRoles
      }

      dispatch(updateRoleThunk({ ...data, ...stepNewSignUpTenant, userSystemInfo: userSystemInfo() })).unwrap().then((res) => {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('fullName')
      }).catch((err) => console.log(err))
    }
    catch (err) {
      console.log(err)
    }
  }


  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    dispatch(setGoogleLoginFormData({ name: event.currentTarget.value }))
  }


  return (
    <Layout title='Register Step 2' user={false} header={false} footer={false}>

      <RegisterLayout
        progress="90"
        bannerText="Finding a rental has never been easier"
        columnFirst={
          <SocialLoginStep2>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Control type="text" defaultValue={formData.name} onKeyUp={handleChange} className="fs-6" size="lg" placeholder="John Smith" isInvalid={Boolean(errors.name)} autoFocus={false} {...register('name')} />
                <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
              </Form.Group>
              <Row>
                <Col>
                  <Button type='submit' className='w-100' disabled={!(watch('name'))} $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
                </Col>
              </Row>
            </Form>
          </SocialLoginStep2>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  name: yup.string().trim().required().min(2).max(100).test(stringTest)
})

type FormData = yup.InferType<typeof schema>