import axios from "axios"



{/* APIs */ }
const landlordDashboard = async () => {
  return await axios.get('/user/landlord/dashborad')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}



export const landlordService = {
  landlordDashboard
}