import { IMenu, ReportsNavProps } from './Nav.interface'
import { StyledReportsNav } from './Nav.style'
import { NavLink } from 'react-router-dom'
import { useReduxSelector } from '../../../../hooks/redux'
import { filterByRoles } from '../../../../utils'
import { MdPeople, MdArrowBack, MdSupportAgent } from 'react-icons/md'
import Typography from '../../../../components/_ui/typography/Typography.component'



export default function SidebarNav(props: ReportsNavProps) {
  const { userRoles } = useReduxSelector(state => state.auth)

  const menus: IMenu[] = [
    { label: 'Back', link: '/admin/dashboard', Icon: MdArrowBack, permission: ['admin'] },
    { label: 'Active Accounts', link: '/admin/accounts', Icon: MdPeople, permission: ['admin'] },
    { label: 'Agents', link: '/admin/agents', Icon: MdSupportAgent, permission: ['admin'] },
  ]

  return (
    <StyledReportsNav className='position-sticky top-15'>
      {filterByRoles(menus, userRoles).map((item, index) =>
        <Typography $variant='subtitle2' as={NavLink} to={item.link} key={index} className='d-flex align-items-center nav-item gap-2 rounded'>
          <item.Icon className='fs-4 flex-shrink-0' /> &nbsp;
          {item.label}
        </Typography>
      )}
    </StyledReportsNav>
  )
}
