import { createGlobalStyle } from 'styled-components'



const GlobalStyle = createGlobalStyle`
  :root {
    --primary-main: ${({ theme }) => theme.primary.main};
    --bs-primary-rgb: ${({ theme }) => theme.primary.mainRgb};
    ${({ theme }) => theme.shadows.map((item: string, index: number) => `--shadow-${index}: ${item}`).join(';')}
  }  
`

export default GlobalStyle
