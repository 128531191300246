import { Alert } from "react-bootstrap"
import { NoRecordFoundProps } from "./NoRecordFound.interface"
import { Children } from "react"
import { MdSearchOff } from "react-icons/md"
import Card from "../card/Card.component"
import SVG from 'react-inlinesvg'



const NoRecordFound = ({ shadow, className, image = null, children = 'No Record Found' }: NoRecordFoundProps) => {

  return (
    <Card className={shadow}>
      <div className={`d-flex flex-column text-center align-items-center ${className} p-4`}>
        {image ?
          <SVG src={image} width={150} title='not found' />
          :
          <MdSearchOff className='fs-2 text-secondary ' />
        }
        <span className='text-secondary'>{children}</span>
      </div>
    </Card>
  )
}

export default NoRecordFound