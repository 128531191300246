import axios from "axios"
import { PlanTypes } from "../../../dtos/Plan.dto"



{/* APIs */ }
const plans = async (params: { type: PlanTypes }) => {
  return await axios.get('/user/plans', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const planById = async (params: { id: string }) => {
  return await axios.get('/user/planById', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const paymentData = async () => {
  return await axios.get('/admin/paymentData')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const tenantPurchasePlan = async (data: { planId: string, endTime?: string, isDealOn?: boolean }) => {
  return await axios.post('/user/tenant/purchasePlan', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const tenantPurchasePlanStatus = async (data: { transactionId: string, response: any }) => {
  return await axios.post('/user/tenant/purchasePlanStatus', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const updateTenantDocuments = async (data: IUpdateTenantDocuments) => {
  return await axios.post('/user/updateTenantDocuments', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const stripeIdentityCreateVerification = async () => {
  return await axios.post('/user/stripe/identityCreateVerification')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}



/* Interface */
interface IUpdateTenantDocuments {
  id: string
  verificationDocuments: {
    type: string
    image: string
  }[]
}



export const planService = {
  plans,
  planById,
  tenantPurchasePlan,
  tenantPurchasePlanStatus,
  updateTenantDocuments,
  stripeIdentityCreateVerification,
  paymentData

}