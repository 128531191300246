import { toast } from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { MdCheck, MdCircle } from "react-icons/md"
import { Container, Row, Col, Stack, Spinner } from 'react-bootstrap'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { IManageByAgentOrSelf } from '../../../lib/redux/services/agent.service'
import { ConfirmatonPopup } from '../../../components/modalPopup/confirmation/ConfirmationPopup.component'
import { manageByAgentOrSelfThunk, searchAgentsByPropertyThunk } from '../../../lib/redux/actions/agent.action'
import { DataConfirmationTypes } from '../../../components/modalPopup/confirmation/ConfirmatonPopupProps.interface'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Card from '../../../components/_ui/card/Card.component'
import Button from '../../../components/_ui/button/Button.component'
import Alert from '../../../components/_ui/alert/Alert.component'
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component'



export default function Agent() {

    const dispatch = useReduxDispatch()
    const { propertyId } = useParams()
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search);
    const tenantId = params.get('tenantId');
    const [agentId, setAgentId] = useState<string>('')
    const [selectedTab, setSelectedTab] = useState('agent')
    const [confirmationShow, setconfirmationShow] = useState(false)
    const { data, status } = useReduxSelector(state => state.agent.searchAgentsByProperty)


    const dataConfirmation: DataConfirmationTypes = {
        heading: 'Agent Confirmation',
        description: selectedTab == 'agent' ? 'Are you sure you want to choose this agent?' : 'Are you sure you want to self manage?',
        buttonName: 'Confirm'
    }


    useEffect(() => {
        dispatch(searchAgentsByPropertyThunk({ id: propertyId as string, page: 1, size: 10 }))
    }, [])


    const handleChooseAgent = (item?: any) => {
        setconfirmationShow(false)
        console.log(agentId)

        let formData: IManageByAgentOrSelf = {
            propertyId: propertyId,
            tenantId: tenantId,
            agentId: selectedTab === 'agent' ? agentId : null,
            managetBy: selectedTab
        }

        dispatch(manageByAgentOrSelfThunk(formData)).unwrap()
            .then((response => {
                selectedTab === 'agent' ? toast.success('Agent accepted') : toast.success('Self managed accept')
                navigate('/properties')
            }))
            .catch((err) => console.log(err))
    }


    return (
        <Layout title='Agent' user={true}>
            <Container>
                <Row className='mb-3'>
                    <Col>
                        <Typography $variant='h1' className={'mb-0'}>Tenant Accepted</Typography>
                        <Typography>Congratulations of finding a tenant, now its time to find an agent.</Typography>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col>
                        <Card onClick={() => selectedTab != 'agent' && setSelectedTab('agent')}>

                            <Typography $variant='h4' className={'mb-0'}>
                                {selectedTab == 'agent' ? <MdCheck className='mb-1' /> : <MdCircle className='mb-1' />}
                                Find Me An Agent
                            </Typography>

                            {selectedTab == 'agent' ? <Stack>
                                <Row className='mt-3'>
                                    <Col>
                                        <Typography>Choose an agent</Typography>

                                        {Boolean(data?.length) && data.map((item: any, index: any) => (
                                            <Card className='mt-2' key={index}>
                                                <Row>
                                                    <Col md={9}>
                                                        <Typography $variant='h6' className={'mb-0'}>{item?.user_data?.name}</Typography>
                                                        <Typography>{item?.address?.name}</Typography>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Button className='w-100'
                                                            onClick={() => {
                                                                setAgentId(item?.user_data?._id as string)
                                                                setconfirmationShow(true)
                                                            }
                                                            }>Choose this Agent</Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))}

                                        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto mt-3 d-block' variant='secondary' />}
                                        {status === THUNK_STATUS.SUCCESS && !Boolean(data.length) && <NoRecordFound />}
                                        {status === THUNK_STATUS.FAILED && <Alert className='m-auto mt-3 d-block' $variant='danger'>Something went wrong</Alert>}

                                    </Col>
                                </Row>
                            </Stack>
                                : null
                            }
                        </Card>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Card onClick={() => selectedTab != 'self' && setSelectedTab('self')}>
                            <Typography $variant='h4' className={'mb-0'}>
                                {selectedTab == 'self' ? <MdCheck className='mb-1' /> : <MdCircle className='mb-1' />}
                                Self Managed</Typography>
                            {selectedTab == 'self' ?
                                <Stack>
                                    <Row><Col>Info about self manmaged here</Col></Row>
                                    <Row>
                                        <Col>
                                            <Button onClick={() => setconfirmationShow(true)} >Self Manage My Property</Button>
                                        </Col>
                                    </Row>
                                </Stack>
                                : null
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Confirmation pop-up */}
            <ConfirmatonPopup data={dataConfirmation} show={confirmationShow} onHide={() => setconfirmationShow(false)} callBack={() => handleChooseAgent()} />
        </Layout >
    )
}
