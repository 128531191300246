import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux';
import { Link, useParams } from "react-router-dom";
import { MdAccountBox, MdMail, MdCall, MdVerified, MdHotel, MdOutlineBathtub, MdDirectionsCar, MdWeb, MdSmartphone, MdSchedule } from 'react-icons/md';
import { Col, Row, Stack } from 'react-bootstrap'
import { LandlordDetailProps } from './LandlordDetail.interface';
import Typography from "../../../../../components/_ui/typography/Typography.component"
import Card from '../../../../../components/_ui/card/Card.component';
import Badge from '../../../../../components/_ui/badge/Badge.component';
import placeholderImage from '../../../../../assets/images/placeholder.jpg'


export default function LandlordDetail(props: LandlordDetailProps) {
  const { name, email, phone, propertyData, userSystemInfo } = props.data


  return (
    <>
      <Typography $variant="h1">{name}</Typography>

      {/* User Detail */}
      <Card as={Stack} gap={3} className='mb-3'>
        <Typography $variant='h5'>User Details</Typography>
        <div className='d-flex flex-column flex-lg-row align-items-lg-center justify-content-between gap-3'>
          <BasicUserInfo dataInfo={{ heading: 'Name', value: name, logo: <MdAccountBox size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Email', value: email, logo: <MdMail size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Phone', value: phone, logo: <MdCall size={24} /> }} />
        </div>
      </Card>
    
      {/* Properties */}
      <Card as={Stack} gap={3} className='mb-3'>
        <Typography $variant='h5' >Properties</Typography>
      </Card>

      {Boolean(propertyData.length) &&
        <div>
          <Row className='row-gap-4'>
            {propertyData.map((item: any, index: number) =>
              <Col xs={12} sm={6} md={4} key={index}>
                <Card className='p-0 overflow-hidden d-block position-relative' $hover>
                  <Link to={`/admin/property/verifications/${item._id}`/*`edit/${item._id}`*/}>
                    <Badge as={Typography} $variant='body3' className='position-absolute top-15 left-15 text-white text-capitalize py-2 fw-light'>{item.status}</Badge>
                    <img src={item.image[0] || placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75' }} />
                  </Link>
                  <div className='p-3 d-flex flex-column gap-3'>
                    <Typography $variant='subtitle2' title={item.address.name} className='text-dark text-nowrap fw-semibold overflow-hidden text-overflow-ellipsis'>
                      {item.address.name}
                    </Typography>

                    <Stack direction='horizontal' className='justify-content-between'>
                      <div className='center flex-column'>
                        <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                          <MdHotel className='fs-3' /> {item.numberOfRoom}
                        </div>
                        <Typography $variant='body3' className=''>Rooms</Typography>
                      </div>
                      <div className='center flex-column'>
                        <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                          <MdOutlineBathtub className='fs-3' /> {item.numberOfWashroom}
                        </div>
                        <Typography $variant='body3' className=''>Washrooms</Typography>
                      </div>
                      <div className='center flex-column'>
                        <div className='d-flex align-items-center text-secondary gap-1 flex-1 justify-content-start fw-semibold'>
                          <MdDirectionsCar className='fs-3' /> {item.numberOfParking}
                        </div>
                        <Typography $variant='body3' className=''>Parkings</Typography>
                      </div>
                    </Stack>

                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      }

      {/* Browser Detail */}
      <Card as={Stack} gap={3} className='mb-3'>
        <Typography $variant='h5'>Device Details</Typography>
        <div className='d-flex flex-column flex-lg-row align-items-lg-center justify-content-between gap-3'>
          <BasicUserInfo dataInfo={{ heading: 'Browser', value: userSystemInfo?.browser_details, logo: <MdWeb size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Operating System', value: userSystemInfo?.operating_system, logo: <MdSmartphone size={24} /> }} />
          <BasicUserInfo dataInfo={{ heading: 'Timezone', value: userSystemInfo?.time_zone, logo: <MdSchedule size={24} /> }} />
        </div>
      </Card>

    </>
  )
}


{/* Basic user info */ }
const BasicUserInfo = (props: BasicInfoData) => {
  const { dataInfo } = props
  return <>
    <div className='d-flex justify-content-between gap-1'>
      <Typography $variant='subtitle2' className='d-flex align-items-center gap-2'>{dataInfo.logo} {dataInfo?.value}</Typography>
    </div>
  </>

}


{/*  interface */ }
interface BasicInfoData {
  dataInfo: {
    heading: string
    value: string | null | undefined
    logo?: any
  }
}