import { MdVerified } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux';
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant';
import { limitRowSize } from '../../../data/config';
import { Spinner, Table } from 'react-bootstrap'
import { adminAgentsListThunk, adminAgentsVerifyThunk } from '../../../lib/redux/actions/agent.action';
import { ConfirmatonPopup } from '../../../components/modalPopup/confirmation/ConfirmationPopup.component';
import { DataConfirmationTypes } from '../../../components/modalPopup/confirmation/ConfirmatonPopupProps.interface';
import moment from 'moment';
import toast from 'react-hot-toast';
import Card from '../../../components/_ui/card/Card.component'
import Layout from '../../../components/_layout/Layout.component'
import Alert from '../../../components/_ui/alert/Alert.component';
import Button from '../../../components/_ui/button/Button.component';
import Typography from '../../../components/_ui/typography/Typography.component'
import AdminLayout from "../components/adminLayout/AdminLayout.component"
import AccountsNav from '../components/accountsNav/Nav.component'

import PaginationComponent from '../../../components/pagination/PaginationComponent';
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component';
import SearchImg from '../../../assets/images/search.svg'
import { convertToMelbourneTime } from '../../../utils';



export default function AdminAgents() {

  const navigate = useNavigate()
  let limitSize = limitRowSize
  const [data, setData] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [confirmationShow, setconfirmationShow] = useState(false)
  const [rejectReason, setrejectReason] = useState('')
  const [rejectShowModal, setrejectShowModal] = useState(false)
  const [selectAgentId, setSelectAgentId] = useState('')


  const dataConfirmation: DataConfirmationTypes = {
    heading: 'Accept Confirmation',
    description: 'Are you sure you want to accept the agent ?',
    buttonName: 'Accept'
  }

  const rejectDataConfirmation: DataConfirmationTypes = {
    heading: 'Reject Confirmation',
    description: 'Are you sure you want to reject the agent ?',
    buttonName: 'Reject'
  }


  const dispatch = useReduxDispatch()
  const { adminAgentsList } = useReduxSelector(state => state.agent)


  useEffect(() => {
    dispatch(adminAgentsListThunk({ page: 1, size: limitSize }))
  }, [])


  const handlePageChange = (page: number) => {
    console.log(page)
    setCurrentPage(page);
  };


  const handleAcceptReject = (status: string) => {
    console.log(selectAgentId, status)
    dispatch(adminAgentsVerifyThunk({ id: selectAgentId, status: status == 'accept' ? true : false })).unwrap()
      .then((res) => {
        toast.success('succss')
        dispatch(adminAgentsListThunk({ page: 1, size: limitSize }))
        setrejectReason('')
      })
      .catch((err) => console.log('error'))

  }


  return (
    <Layout title='Agents' user={true}>
      <AdminLayout nav={<AccountsNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Agents</Typography>
          <Typography $variant='body1'>List of registered real estate agents</Typography>
        </div>

        <Card>
          <Table striped responsive>
            <thead>
              <tr><th>Name</th><th>Address</th><th>Phone</th><th>Status</th><th>Signup</th><th>Action</th></tr>
            </thead>
            <tbody>
              {adminAgentsList?.status === THUNK_STATUS.SUCCESS &&
                adminAgentsList.data?.map((item: any, index: number) => (
                  <>
                    <tr key={index}>
                      <td>{item?.name}<br /><span style={{ fontSize: 10 }}>{item?.email}</span></td>
                      <td>{item?.profile?.address?.name}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.isVerified ? 'Verified' : 'Not Verified'}</td>
                      <td>{convertToMelbourneTime(item?.createdAt).format('L')}</td>

                      <td>
                        <div className='d-flex flex gap-2'>
                          {
                            !item?.isVerified ? <Button $variant='outlined' onClick={() => { setconfirmationShow(true); setSelectAgentId(item?._id) }}>Accept</Button> :
                              <Button $variant='outlined' onClick={() => { setrejectShowModal(true); setSelectAgentId(item?._id) }} >Reject</Button>
                          }
                        </div>
                      </td>
                    </tr>
                  </>
                ))
              }
            </tbody>

          </Table>

          <div className='d-flex justify-content-center mb-3'>
            {adminAgentsList.status === THUNK_STATUS.LOADING && <div className='my-3'><Spinner className='m-auto mt-3 d-block' variant='secondary' /></div>}
            {adminAgentsList.status === THUNK_STATUS.SUCCESS && !Boolean(adminAgentsList?.data?.length) &&
              <NoRecordFound shadow='shadow-none' className='text-center w-100' image={SearchImg}>
                <Typography $variant='h4'>Agents</Typography>
                <Typography $variant='body1'>No record found</Typography>
              </NoRecordFound>
            }
            {adminAgentsList.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
          </div>

          <div className='d-flex justify-content-end'>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </Card>

      </AdminLayout>


      {/* Reject pop-up */}
      <ConfirmatonPopup data={rejectDataConfirmation} show={rejectShowModal} onHide={() => setrejectShowModal(false)} callBack={() => handleAcceptReject('reject')} />

      {/* Confirmation pop-up */}
      <ConfirmatonPopup data={dataConfirmation} show={confirmationShow} onHide={() => setconfirmationShow(false)} callBack={() => handleAcceptReject('accept')} />

    </Layout >
  )
}
