import { Link } from 'react-router-dom'
import { StyledBox } from '../onBoardProfileLayout/OnBoardProfileLayout.style'
import logo from '../../../assets/images/icon.svg'
import Typography from '../../../components/_ui/typography/Typography.component'
import { GreetingPopupProps } from './GreetingPopupProps.interface'
import { Modal } from 'react-bootstrap'
import Button from '../../_ui/button/Button.component'
import background_image from '../../../assets/images/celebration.svg';
import SVG from 'react-inlinesvg';



export default function GreetingPopup(props: GreetingPopupProps) {

  let { heading, description, link, linkTitle, open, setOpen, callback, buttonText } = props

  const onSubmit = () => {
    callback()
  }


  return (
    <Modal show={open} fullscreen={true} onHide={() => setOpen(false)}>
      <StyledBox className='bg-green h-100 overflow-hidden w-100'>
        <header>
          <div className='header-customize-mobile-device'>
            <div className='p-3'>
              <div className='d-flex flex-shrink-1'>
                <Link to="/" className='d-flex align-items-center flex-shrink-1'>
                  <SVG src={logo} height={40} className='logo' />
                </Link>
              </div>
            </div>
          </div>
        </header>


        <div className='h-100'>
          <style>{`main[class]{padding:0 !important}`}</style>
          <div className='d-flex align-items-md-center h-100 pb-110'>
            <div className='w-100 px-4 d-flex align-items-center justify-content-center gap-3'>
              <div style={{ width: '500px' }}>
                <div className='d-flex flex-column align-items-center gap-1'>
                  {/*<div>
                    <img alt='image on boarding' className="w-100 mb-4" src={background_image} />
                  </div>*/}
                  <Typography $variant='h3' className='text-center text-white'>{heading}</Typography>
                  <Typography $variant='body1' className='text-center text-white opacity-75'>{description}</Typography>
                  <Button className='mt-4' onClick={() => onSubmit()}>{buttonText || ''}</Button>
                  <Link className='mt-3 text-decoration-underline text-white opacity-75' to={link || '#'}> {linkTitle || ''}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </StyledBox>
    </Modal>
  )
}