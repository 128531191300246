import { ConfirmatonPopupProps } from "./ConfirmatonPopupProps.interface";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../_ui/button/Button.component";
import Typography from "../../_ui/typography/Typography.component";

export function ConfirmatonPopup(props: ConfirmatonPopupProps) {
  const { callBack, onHide, data } = props;

  const handleFunction = () => {
    callBack();
    onHide();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
      className="m-width-200"
    >
      <Modal.Header closeButton>
        <Typography $variant="h4" id="contained-modal-title-vcenter">
          {data.heading}
        </Typography>
      </Modal.Header>

      <Modal.Body>
        <Typography $variant="subtitle2" className="mb-3">
          {data.description}
        </Typography>
      </Modal.Body>
      <div className="d-flex justify-content-end m-3">
        <Button $variant="filled" onClick={handleFunction}>
          {data.buttonName}
        </Button>
      </div>
    </Modal>
  );
}
