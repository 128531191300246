import { useEffect } from 'react'
import { Alert, Col, Container, Row, Spinner, Stack } from 'react-bootstrap'
import { useNavigate, useSearchParams } from "react-router-dom"
import { MdClose } from "react-icons/md"
import { Link } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { PlanType } from '../../../interfaces/PlanType.type'
import { theme } from '../../../utils'
import { planByIdThunk, plansThunk } from '../../../lib/redux/actions/plan.action'
import { MdVerified, MdLock, MdOutlineArrowForwardIos } from "react-icons/md"
import { BsLightningChargeFill } from "react-icons/bs"
import { IoMdEyeOff } from "react-icons/io"
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Card from '../../../components/_ui/card/Card.component'
import Button from '../../../components/_ui/button/Button.component'
import CheckoutForm from './component/checkoutForm/CheckoutForm.component'
import config from '../../../config'
import VerifyProfile from '../../../assets/images/checkout/checkout-profile.svg'
import VerifyBg from '../../../assets/images/checkout/verified_bg.svg'
import AuthenticateProfile from '../../../assets/images/checkout/authenticate-profile.svg'
import { BgColorGetBlueTick, CardAuthBox, CardVerifyBox, VerifyBgDiv } from './Checkout.style'
import { ReactComponent as Logo } from '../../../assets/images/icon.svg'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)



export default function Checkout() {
  let data, status;
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { planById, plans } = useReduxSelector(state => state.plan)
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('plan')
  const type: (PlanType | string | null) = searchParams.get('type')


  let verifyIdentity = [
    { title: 'FAST', icon: <MdLock className='fs-5' /> },
    { title: 'SECURE', icon: <BsLightningChargeFill className='fs-5' /> },
    { title: 'PRIVATE', icon: <IoMdEyeOff className='fs-5' /> },
  ]


  if (type === 'boost') {
    data = planById.data
    status = planById.status
  }

  if (type === 'blue_tick') {
    data = plans.data?.[0]
    status = plans.status
  }


  const STRIPE_OPTIONS: StripeElementsOptions = {
    mode: 'payment',
    amount: data?.price ? (data?.price * 100) : undefined,
    currency: 'aud',
    locale: 'en-AU',
    loader: 'never',
    appearance: {
      theme: 'stripe',
      variables: {
        fontFamily: 'Plus Jakarta Sans',
        fontSizeBase: '14px',
        colorPrimary: theme.primary.main
      }
    },
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans'
      }
    ]
  }


  useEffect(() => {
    if (type === 'blue_tick') dispatch(plansThunk({ type: 'blue_tick' }))
    if (planId && type === 'boost') dispatch(planByIdThunk({ id: planId as string }))
  }, [])


  const CheckoutDetail = (props: any) => {
    const { data } = props
    return <Card className='shadow-none border'>
      <Stack>
        <Row>
          <Col>
            <Typography $variant='h5' className='text-dark'>{data?.name}</Typography>
            One-time payment
          </Col>
          <Col className='d-flex justify-content-end align-items-center'>
            <Typography $variant='h5' className='text-dark'>${data?.price}</Typography>
          </Col>
          <div>

          </div>
        </Row>
        {/*type === 'boost' && <Button $icon $variant='outlined' as={Link} to='/store/boost' className='center'><MdClose /></Button>*/}
      </Stack>
    </Card>
  }


  return (
    <Layout title='Checkout' user={true} feedbackShow={false}>
      <Container>

        <div className='d-flex flex-column gap-0 mb-3' style={{ paddingLeft: 10 }}>
          <Typography $variant="h3">Checkout</Typography>
          <Typography $variant='body1'>You're one step closer to your new home</Typography>
        </div>


        {status === THUNK_STATUS.SUCCESS && data &&
          <Row className='row-gap-2'>
            <Col xs={12} md={8}>
              <div className='d-none d-md-block'>
                <CheckoutDetail data={data} />
              </div>

            </Col>

            <Col>
              <div className='d-block d-md-none mb-3' id='getBlueTick'>
                <CheckoutDetail data={data} />
              </div>
              <Card>
                <Elements stripe={stripePromise} options={STRIPE_OPTIONS}>
                  <CheckoutForm planId={data._id} plan={data} type={type as PlanType} />
                </Elements>
              </Card>
            </Col>
          </Row>
        }


        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
        {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}
      </Container >
    </Layout >
  )
}
