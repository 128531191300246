import axios from "axios"
import { IPagination } from "./admin.service"



{/* APIs */ }
const chatTenantShortList = async (params: IPagination) => {
  return await axios.get('/property/chat/users', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const chatPropertyShortlisted = async (params: IPagination) => {
  return await axios.get('/property/shortlisted/properties', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


export const chatService = {
  chatTenantShortList,
  chatPropertyShortlisted
}

// export interface IPagination {
//   recipientUid: string | null | undefined
//   senderUid: string | null | undefined
//   message: string

// }
