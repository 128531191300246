import { useEffect, useState } from "react"
import { Form, Stack, Row, Col, FloatingLabel } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { onlyNumberTest } from "../../../../utils"
import { setLandlordProfileOnboardFormData } from "../../../../lib/redux/slices/user.slice"
import * as yup from 'yup'
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoarding from "../../../../components/authLayout/onBoardInfomation/OnBoarding.component"
import { MdDirectionsCar, MdHotel, MdOutlineBathtub } from "react-icons/md"
import { BiDollarCircle } from "react-icons/bi"
import background_image from '../../../../assets/images/register/background_002.jpg';




export default function StepProperty2() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { formData } = useReduxSelector(state => state.user.landlordProfileOnboard)


  const { handleSubmit, register, control, formState: { errors, isSubmitted }, setValue, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      numberOfRoom: formData?.numberOfRoom || 0,
      numberOfWashroom: formData?.numberOfWashroom || 0,
      numberOfParking: formData?.numberOfParking || 0,
      propertyPrice: formData?.numberOfParking || 0,
    }
  })


  const onSubmit = async (data: FormData) => {
    dispatch(setLandlordProfileOnboardFormData(data))
    navigate('/landlord/profile/step-3')
  }


  return (
    <Layout title='Landlord Step 1' user={true} header={false} footer={false}>
      <OnBoardProfileLayout
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Property Details' />

            {/* Form */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="row-gap-4">
                <Col xs={12} sm={6}>
                  {/* Rooms */}
                  <div>
                    <Form.Group className='d-flex align-items-center flex-nowrap gap-2'>
                      <MdHotel className='fs-3 text-dark flex-shrink-0' />
                      <FloatingLabel controlId='numberOfRoom' label='Bedrooms'>
                        <Form.Control type='number' className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.numberOfRoom)} {...register('numberOfRoom')} />
                      </FloatingLabel>
                    </Form.Group>
                    {errors.numberOfRoom && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.numberOfRoom.message}</Form.Text>}
                  </div>
                </Col>

                <Col xs={12} sm={6}>
                  {/* Washrooms */}
                  <div>
                    <Form.Group className='d-flex align-items-center flex-nowrap gap-2'>
                      <MdOutlineBathtub className='fs-3 text-dark flex-shrink-0' />
                      <FloatingLabel controlId='numberOfWashroom' label='Bathrooms'>
                        <Form.Control type='number' className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.numberOfWashroom)} {...register('numberOfWashroom')} />
                      </FloatingLabel>
                    </Form.Group>
                    {errors.numberOfWashroom && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.numberOfWashroom.message}</Form.Text>}
                  </div>
                </Col>


                <Col xs={12} sm={6}>
                  {/* Parking */}
                  <div>
                    <Form.Group className='d-flex align-items-center flex-nowrap gap-2'>
                      <MdDirectionsCar className='fs-3 text-dark flex-shrink-0' />
                      <FloatingLabel controlId='numberOfParking' label='Parking'>
                        <Form.Control type='number' className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.numberOfParking)} {...register('numberOfParking')} />
                      </FloatingLabel>
                    </Form.Group>
                    {errors.numberOfParking && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.numberOfParking.message}</Form.Text>}
                  </div>
                </Col>

                <Col xs={12} sm={6}>
                  {/* Price per week */}
                  <div>
                    <Form.Group className='d-flex align-items-center flex-nowrap gap-2'>
                      <BiDollarCircle className='fs-3 text-dark flex-shrink-0' />
                      <FloatingLabel controlId='propertyPrice' label='Price per week'>
                        <Form.Control type='number' className='fw-medium' placeholder='ignore' isInvalid={Boolean(errors.propertyPrice)} {...register('propertyPrice')} />
                      </FloatingLabel>
                    </Form.Group>
                    {errors.propertyPrice && <Form.Text className='text-danger ms-5 ps-1 d-block mt-0'>{errors.propertyPrice.message}</Form.Text>}
                  </div>
                </Col>

              </Row>


              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit'>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  numberOfRoom: yup.number().required().max(10000).test(onlyNumberTest),
  numberOfWashroom: yup.number().required().max(10000).test(onlyNumberTest),
  numberOfParking: yup.number().required().max(10000).test(onlyNumberTest),
  propertyPrice: yup.number().required().min(50, 'Price per week cannot be less than $50').max(10000000).test(onlyNumberTest),
})

type FormData = yup.InferType<typeof schema>
