import { Link } from 'react-router-dom'
import { Col, Form, Row, Spinner, Stack } from 'react-bootstrap'
import { useCallback, useEffect, useRef, useState } from 'react'

import { debounce } from '../../../../../utils/debonce'
import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux'
import { THUNK_STATUS } from '../../../../../lib/redux/constants/status.constant'
import { agentGetTenantSearchBySuburbThunk } from '../../../../../lib/redux/actions/agent.action'
import Card from '../../../../../components/_ui/card/Card.component'
import Alert from '../../../../../components/_ui/alert/Alert.component'
import Typography from '../../../../../components/_ui/typography/Typography.component'
import SearchInput from '../../../../../components/_ui/searchInput/SearchInput.component'
import NoRecordFound from '../../../../../components/_ui/noRecordFound/NoRecordFound.component'
import placeholderImage from '../../../../../assets/images/profile-placeholder.svg'



export default function AgentViewSearchTenantList() {
  const PAGE_SIZE = 6
  const lastPropertyRef = useRef<any>()
  const dispatch = useReduxDispatch()
  const { searchTenantBySuburb } = useReduxSelector(state => state.agent)

  const [page, setPage] = useState(1)
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [searchValue, setSearchValue] = useState<string>('')


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !loading) fetchProperties(searchValue)
    })

    if (lastPropertyRef.current && hasMore) observer.observe(lastPropertyRef.current)

    return () => {
      if (lastPropertyRef.current) observer.unobserve(lastPropertyRef.current)
    }
  }, [data])


  const fetchProperties = async (value: string) => {
    setLoading(true)
    await dispatch(agentGetTenantSearchBySuburbThunk({ size: PAGE_SIZE, page, search: value || '' })).unwrap()
      .then((response: any) => {
        console.log(response?.data?.result)
        const data = response?.data?.result
        if (!Boolean(data?.length)) return setHasMore(false)
        setData((prev: any) => [...prev, ...data])
        setPage(prev => prev + 1)
      })
      .catch((error: any) => console.log(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    debouncedSearch(searchValue)
  }, []);


  // Create debounced search function
  const debouncedSearch = useCallback(debounce((value?: string) => {
    fetchProperties(value || '')
  }, 1000), []);



  const handleInputChange = (value: string) => {
    setData([])
    setSearchValue(value);
    debouncedSearch(value);
  }


  return (
    <Stack direction='vertical' gap={3} >
      <Card>
        <Form className='d-flex justify-content-between gap-3' >
          <SearchInput className='w-100' onChange={handleInputChange} placeholder='Search tenant, by suburb...' />
        </Form>
      </Card>


      <div>

        <Row className='row-gap-4'>
          {data && data?.map((item: any, index: number) => (
            <Col xs={12} sm={6} md={6} lg={4} key={index}>
              <Card className="p-0 overflow-hidden d-block position-relative my-3" as={Link} to={`/profile/${item.userId}`} $hover >
                <img src={item?.profilePhotos?.length ? item?.profilePhotos[0] : placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75', opacity: .9 }} />
                <div className='px-3 py-2 p-3 d-flex justify-content-between gap-3'>
                  <Typography $variant='subtitle2' className='text-dark fw-semibold py-1 align-self-center'>
                    {item.name}
                  </Typography>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <div ref={lastPropertyRef} />
      </div>

      <div className='d-flex justify-content-center mb-3'>
        {loading && <Spinner className='m-auto d-block' variant='secondary' />}
        {!loading && !Boolean(data.length) && <NoRecordFound className='mb-0' variant='light'>No records found</NoRecordFound>}
        {searchTenantBySuburb.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
      </div>
    </Stack>
  )
}
