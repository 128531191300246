import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';



export default function useIsExtactUrlMatch(urlPath: string) {

  const location = useLocation()
  const [isExtractedString, setIsExtractedString] = useState(false)

  useEffect(() => {
    const startIndex = location.pathname.indexOf(urlPath);
    const endIndex = startIndex + urlPath.length;

    const extractedString = location.pathname.substring(startIndex, endIndex);
    if (extractedString === urlPath) {
      setIsExtractedString(true)
    }
  }, [])


  return isExtractedString
}
