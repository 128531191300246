import React from 'react'
import Card from '../../../../../components/_ui/card/Card.component'
import Typography from '../../../../../components/_ui/typography/Typography.component'
import placeholderImage from '../../../../../assets/images/placeholder.jpg'



export default function NotAvailableCard(props: { title: string }) {
  return (
    <Card className='overflow-hidden d-block position-relative my-3'>
      <img src={placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75', opacity: .9 }} />
      <div className='px-3 py-2 d-flex'>
        <Typography $variant='subtitle2' className='text-dark fw-semibold align-self-center'>
          No {props.title} available
        </Typography>
      </div>
    </Card>
  )
}
