import toast from 'react-hot-toast'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux'
import { THUNK_STATUS } from '../../../../../lib/redux/constants/status.constant'
import { getProfileThunk, verifyOtpWithSocialLoginThunk } from '../../../../../lib/redux/actions/auth.action'
import Button from '../../../../_ui/button/Button.component'
import Typography from '../../../../_ui/typography/Typography.component'
import OtpInput from '../../../../../pages/auth/verify-otp/component/otpInput/OtpInput.component'
import { handlePhoneNoVerificationPopup } from '../../../../../lib/redux/slices/auth.slice'



export default function OtpVerify(props: { phone: string }) {

  const otpLength = 4
  const dispatch = useReduxDispatch()
  const { user, verifyOtpWithSocialLogin } = useReduxSelector(state => state.auth)

  const [otp, setOtp] = useState('')

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (otpLength === otp.length && user) {
      dispatch(verifyOtpWithSocialLoginThunk({ otp, phone: props.phone, user_id: user?._id as string })).unwrap()
        .then((res) => {
          toast.success(res.message)
          dispatch(getProfileThunk())
          dispatch(handlePhoneNoVerificationPopup({ isShow: false, isVerifyLater: false }))
        })
        .catch((err) => console.log(err))
    }
  }

  const handleOTP = (value: string) => {
    setOtp(value)
  }


  return (
    <Form noValidate onSubmit={onSubmit}>
      <div className='d-flex flex-column align-items-center gap-3'>
        <Typography $variant='body1' className='mb-3'>Please enter the verification code sent to your mobile phone</Typography>
        <Form.Group>
          <Form.Label>Verification Code</Form.Label>
          <OtpInput value={otp} valueLength={otpLength} onChange={handleOTP} />
        </Form.Group>
        <Button type='submit' $loading={verifyOtpWithSocialLogin.status === THUNK_STATUS.LOADING}>Verification Account</Button>
      </div>
    </Form>
  )
}
