import SVG from 'react-inlinesvg'
import logo from '../../../../assets/images/upsell-logo.svg'
import { BgUpSellContainer } from './UpsellCard.style'
import { Stack } from 'react-bootstrap'
import Typography from '../../typography/Typography.component'
import Button from '../../button/Button.component'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux'
import { useEffect } from 'react'
import { tenantTotalRecordsThunk } from '../../../../lib/redux/actions/user.action'
import Lottie from "lottie-react";
import animationRocket from "../../../../assets/lottie/rocket.json";

export default function UpsellCard() {
  let navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { data } = useReduxSelector(state => state.user.tenantTotalRecords)

  useEffect(() => {
    dispatch(tenantTotalRecordsThunk())
  }, [])

  return (
    <BgUpSellContainer className="rounded mb-4 gap-2" onClick={() => navigate('/store/upgrade')}>
      
        <Stack direction="vertical" className='justify-content-between align-items-center gap-3'>
          <Lottie animationData={animationRocket} loop={true} style={{width: 100}} />
          <div className='flex-1'>
            <Typography pography $variant='h5' className='m-0 mb-3 tw-bold text-center '>High Demand</Typography>
            <Typography pography $variant='body2' className='m-0 text-center'>There are <strong>{data.totalRecords}</strong> other tenants looking for a property.</Typography>
          </div>
          <div className={"w-100"}>
            <Button className={"w-100"}>Skip the Queue</Button>
          </div>
        </Stack>
    </BgUpSellContainer>
  )
}