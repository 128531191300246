import GAnalytics, { AnalyticsInstance } from 'analytics'
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager'


class Analytics {
    analytics: AnalyticsInstance
    constructor() {
        this.analytics = GAnalytics({
            app: 'rentless',
            plugins: [
                googleTagManager({
                    containerId: 'GTM-TNBHTH52'
                })
            ]
        })
    }

    trackPage() {
        this.analytics.page();
    }

    trackEvent(e: string, payload?: string | object) {
        this.analytics.track(e, payload)
    }

    identifyVisitor(uid: string, details: object) {
        this.analytics.identify(uid, details)
    }

}

const analytics = new Analytics();

export default analytics;