import { Form } from 'react-bootstrap'
import { ChatWrapper } from './ChatView.style'
import { BsFillSendFill } from 'react-icons/bs'
import { useReduxSelector } from '../../../../hooks/redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ref, onValue, set, push, off, update, } from 'firebase/database'
import { database } from '../../../../firebase'
import { Fragment, useEffect, useState } from 'react'
import * as yup from 'yup'
import ChatMessages from './Chats.component'
import Typography from "../../../../components/_ui/typography/Typography.component"
import Button from '../../../../components/_ui/button/Button.component'



export default function ChatBox() {
  const { user } = useReduxSelector(state => state.auth)
  const { chatSelectData } = useReduxSelector(state => state.chat)

  const recipientUid = chatSelectData?.data?.fireBaseUserId
  const senderUid = user?.fireBaseUserId

  // const chatId = `${sortedUids[0]}_${sortedUids[1]}`
  let sortedUids: any = [senderUid, recipientUid].sort()
  sortedUids.propertyid = chatSelectData?.data?.property_id ? chatSelectData?.data?.property_id : chatSelectData?.data?._id;

  // Create the chatId using the modified array
  const chatId = `${sortedUids[0]}_${sortedUids[1]}_${sortedUids.propertyid}`;
  const [chatConversation, setChatConversation] = useState([]);
  const { handleSubmit, register, reset } = useForm({ resolver: yupResolver(schema) })


  const updateMessages = (messageId: string, sendId: string, isSeen: boolean) => {
    if (senderUid !== sendId && !isSeen) {
      const messageRef = ref(database, `chats/${chatId}/messages/${messageId}`)
      update(messageRef, { isSeen: true }).then(() => { }).catch((error) => { })
    }
  }


  useEffect(() => {
    const chatRef = ref(database, `chats/${chatId}/messages`)

    const unsubscribe = onValue(chatRef, (snapshot) => {
      if (snapshot.exists()) {
        const messageData = snapshot.val();

        Object.keys(messageData).forEach((key) => {
          const message = messageData[key];
          if (!message.isSeen) {
            updateMessages(key, message.senderUid, message.isSeen);
          }
        });

        const messageList: any = Object.values(messageData);
        setChatConversation(messageList);
      }
    })

    return () => { unsubscribe(); setChatConversation([]) }

  }, [chatId, chatSelectData?.data?.fireBaseUserId]);


  const onSubmit = (data: any) => {
    if (data.message) {
      const chatRef = ref(database, `chats/${chatId}/messages`)
      const newMessageRef = push(chatRef)
      const timestamp = Date.now()
      const sendByName = user?.name
      const isSeen = false
      set(newMessageRef, {
        message: data.message, recipientUid, senderUid, timestamp, sendByName, isSeen,
      })
    }
    reset()
  }


  return (
    <ChatWrapper className='d-flex flex-column'>
      <div className='position-sticky p-2 pb-3 border-bottom '>
        <Typography $variant='h5'>{chatSelectData?.data?.name}</Typography>
      </div>

      <div className='flex-1 h-100 overflow-auto p-2'>
        {chatConversation.map((item: any, index) => (
          <Fragment key={index}>
            <ChatMessages item={item} index={index} />
          </Fragment>
        ))}
      </div>

      <Form className='d-flex align-items-center gap-3' onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="message" className='w-100'>
          <Form.Control type="text" placeholder='Type a Message...' className="fs-6" size="lg" isInvalid={false} autoFocus  {...register('message')} />
        </Form.Group>
        <Button $variant='filled' $icon type='submit'><BsFillSendFill /></Button>
      </Form>
    </ChatWrapper>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  message: yup.string().trim().required()
})

type FormData = yup.InferType<typeof schema>