import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { Col, Container, Row, Stack, Form, InputGroup } from 'react-bootstrap'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { checkMaxLengthMSG, checkMinLengthMSG, emailTest, onlyDigitEnterMSG, onlyNumberTest, stringTest } from '../../../utils'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'

import { agentRegisterThunk } from '../../../lib/redux/actions/agent.action'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import Typography from "../../../components/_ui/typography/Typography.component"
import Layout from "../../../components/_layout/Layout.component"
import Button from '../../../components/_ui/button/Button.component'
import PhoneInput from '../../../components/_ui/phoneInput/PhoneInput.component'
import MapAutocomplete from '../../../components/_ui/mapAutocomplete/MapAutocomplete.component'
import RegisterLayout from '../register/component/registerLayout/RegisterLayout.component'
import background_image from '../../../assets/images/register/background_008.jpg';



export default function AgentRegister() {

    const dispatch = useReduxDispatch()
    const navigate = useNavigate()
    const [phone, setPhone] = useState<string>('')
    const [loading, setLoading] = useState({ form: false, address: false })
    const [showPassword, setShowPassword] = useState(false)
    const { status } = useReduxSelector(state => state.agent.agentRegister)
    const { handleSubmit, register, reset, formState: { errors, isSubmitted }, control, setValue, trigger } = useForm({ resolver: yupResolver(schema) })


    const clearAddress = () => {
        setValue('address.name', '')
        setValue('address.city', '')
        setValue('address.state', '')
        setValue('address.country', '')
        setValue('address.latitude', null)
        setValue('address.longitude', null)
        // if (isSubmitted) trigger()
    }


    const handlePlaceSelected = async (data: any) => {
        if (!data) return clearAddress()
        setLoading(items => ({ ...items, address: true }))

        try {
            const [response] = await geocodeByPlaceId(data.value.place_id)
            setValue('address.name', data.label)
            setValue('address.latitude', response.geometry.location.lat())
            setValue('address.longitude', response.geometry.location.lng())
            trigger('address.name')

            for (const component of response.address_components) {
                if (component.types.includes('locality')) setValue('address.city', component.long_name)
                else if (component.types.includes('administrative_area_level_1')) setValue('address.state', component.long_name)
                else if (component.types.includes('country')) setValue('address.country', component.long_name)
            }
        }
        catch (error) {
            console.log('Google Map API Error @geocodeByPlaceId: ', error)
            toast.error('Something went wrong with address')
        }
        finally { setLoading(items => ({ ...items, address: false })) }
    }


    const handleChange = (value: any) => { setPhone(value || '') }


    const onSubmit = async (data: any) => {
        if (!isValidPhoneNumber(phone)) return
        const formData = { ...data, phone }
        console.log(formData)
        try {
            setLoading(items => ({ ...items, form: true }))
            dispatch(agentRegisterThunk(formData)).unwrap()
                .then((response) => {
                    toast.success(response.message);
                    clearAddress()
                    setPhone('')
                    reset()
                    navigate('/auth/login')
                })
                .catch((err) => console.log('err', err))
        }
        catch (error) { console.error('An error occurred:', error) }
        finally { setLoading(items => ({ ...items, form: false })) }
    }


    return (
        <Layout title='Agent Register' user={false} header={false} footer={false}>

            <RegisterLayout
                columnFirst={
                    (<Stack as={Form} className='pb-5 pt-5' onSubmit={handleSubmit(onSubmit)}>

                        {/* Heading */}
                        <div className='d-flex flex-column gap-2 mb-3'>
                            {/* <Steps active={1} /> */}
                            <Typography $variant='h2'>Join our network</Typography>
                            <Typography $variant='body1'>Register your agency and we will send you free tenancy referrals.</Typography>
                        </div>

                        <Row className='pb-3'>
                            <Col>
                                <Form.Label>Agency Name</Form.Label>
                                <Form.Group controlId="name">
                                    <Form.Control type="text" className="fs-6" size="lg" isInvalid={Boolean(errors.name)} autoFocus {...register('name')} />
                                    <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='pb-3'>
                            <Col>
                                <Form.Label>Agency ABN/ACN</Form.Label>
                                <Form.Group controlId="agentAbnAcn">
                                    <Form.Control type="text" className="fs-6" size="lg" isInvalid={Boolean(errors.agentAbnAcn)} autoFocus {...register('agentAbnAcn')} />
                                    <Form.Text className="text-danger">{errors.agentAbnAcn?.message}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='pb-3'>
                            <Col>
                                <Form.Label>Agency License Number</Form.Label>
                                <Form.Group controlId="agentLicenseNumber">
                                    <Form.Control type="text" className="fs-6" size="lg" isInvalid={Boolean(errors.agentLicenseNumber)} autoFocus {...register('agentLicenseNumber')} />
                                    <Form.Text className="text-danger">{errors.agentLicenseNumber?.message}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='pb-3'>
                            <Col>
                                <Form.Label>New Applications Email</Form.Label>
                                <Form.Group controlId="email">
                                    <Form.Control type="email" className="fs-6" size="lg" isInvalid={Boolean(errors.email)} autoFocus {...register('email')} />
                                    <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='pb-3'>
                            <Col>
                                <Form.Label>Password</Form.Label>
                                <Form.Group controlId="password">
                                    <InputGroup>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"} autoComplete="new-password" placeholder="Password" className="fs-6" size="lg"
                                            isInvalid={Boolean(errors.password)} autoFocus={false} {...register("password")} />
                                        <InputGroup.Text className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <MdVisibility className="fs-5" /> : <MdVisibilityOff className="fs-5" />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text className="text-danger">{errors.password?.message}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='pb-3'>
                            <Col>
                                <Form.Label>Office Number</Form.Label>
                                <Form.Group className="w-100" controlId="phone">
                                    <PhoneInput
                                        value={phone}
                                        onChange={handleChange}
                                        className={(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}
                                        numberInputProps={{
                                            className: `form-control fs-6 form-control-lg ${(isSubmitted && !isValidPhoneNumber(phone)) ? 'is-invalid' : ''}`,
                                            autoFocus: true,
                                        }}
                                    />
                                    <Form.Text className="text-danger">{isSubmitted && !isValidPhoneNumber(phone) && 'Enter valid phone'}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='pb-3 position-relative z-1'>
                            <Col>
                                <Form.Group controlId='name' className='animate__animated animate__fadeIn'>
                                    <Form.Label>Office Address</Form.Label>
                                    <label className='w-100 d-block'>
                                        <Controller
                                            name="address.name"
                                            control={control}
                                            render={({ field: { value, onChange, ...items } }) => (
                                                <MapAutocomplete
                                                    isInvalid={Boolean(errors.address?.name)}
                                                    selectProps={{
                                                        ...items as any,
                                                        value: value ? { label: value, value: { place_id: value } } : undefined,
                                                        defaultOptions: value ? [{ label: value, value: { place_id: value } }] : undefined,
                                                        onChange: handlePlaceSelected,
                                                        isClearable: true,
                                                        isLoading: loading.address,
                                                    }}
                                                />
                                            )}
                                        />
                                    </label>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row >
                            <Col><Button $loading={status == THUNK_STATUS.LOADING} className='w-100' >Register Agency</Button></Col>
                        </Row>
                    </Stack>)
                }
                columnSecond={<div className='h-100 w-100' style={{
                    backgroundImage: `url(${background_image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center'
                }}></div>}
            />

        </Layout >
    )
}



{/* == Validation == */ }
const schema = yup.object({
    name: yup.string().trim().required().max(10000).test(stringTest),
    email: yup.string().trim().required().email().min(2).max(100).test(emailTest),
    agentAbnAcn: yup.string().trim().required().matches(/^\d+$/, onlyDigitEnterMSG()).min(9, checkMinLengthMSG(9)).max(11, checkMaxLengthMSG(11)),
    agentLicenseNumber: yup.string().max(11).required(),
    password: yup.string().required().trim().min(6, "Enter strong password"),
    address: yup.object({
        name: yup.string().trim().required('Address is required *').min(2, 'Enter valid address').max(200, 'Enter valid address').test('', 'Enter valid address', stringTest),
        isManual: yup.boolean().default(false),
        country: yup.string().when('isManual', {
            is: true,
            then: schema => schema.trim().required().min(2).max(100).test(stringTest),
            otherwise: schema => schema.notRequired()
        }),
        state: yup.string().when('isManual', {
            is: true,
            then: schema => schema.trim().required().min(2).max(100).test(stringTest),
            otherwise: schema => schema.notRequired()
        }),
        city: yup.string().when('isManual', {
            is: true,
            then: schema => schema.trim().required().min(2).max(100).test(stringTest),
            otherwise: schema => schema.notRequired()
        }),
        latitude: yup.number().nullable().when('isManual', {
            is: true,
            then: schema => schema.notRequired(),
            otherwise: schema => schema.notRequired()
        }),
        longitude: yup.number().nullable().when('isManual', {
            is: true,
            then: schema => schema.notRequired(),
            otherwise: schema => schema.notRequired()
        }),
    }),
})
