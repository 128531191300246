import { useReduxSelector } from "./hooks/redux";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import "react-alice-carousel/lib/alice-carousel.css";

import { handleError } from "./utils";
import "./styles.css";
import config from "./config";
import routes from "./routes";
import useAuthentication from "./hooks/useAuthentication.hook";
import GlobalStyle from "./components/globalStyle/GlobalStyle.style";



export default function App() {

  const { loading } = useReduxSelector((state) => state.layout);
  useAuthentication()


  if (loading)
    return (
      <div className="center h-100">
        <Spinner />
      </div>
    );


  return (
    <>
      <GlobalStyle />
      <Toaster position="top-right" />
      <RouterProvider router={routes} />
      {
        process.env.NODE_ENV == "development" && (<div className={"dev-banner"}>Development Mode</div>)
      }
    </>
  );
}

/* == Axios Interceptors == */
axios.defaults.baseURL = config.apiBaseUrl;

axios.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

axios.interceptors.request.use((config) => {
  const token = Cookies.get("token") || sessionStorage.getItem("token");
  if (token) config.headers.token = token;
  return config;
});
