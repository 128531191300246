import { createAsyncThunk } from '@reduxjs/toolkit'
import { agentService } from '../services/agent.service'



export const agentRegisterThunk = createAsyncThunk('agentRegister', agentService.agentRegister)
export const searchAgentsByPropertyThunk = createAsyncThunk('searchAgentsByProperty', agentService.searchAgentsByProperty)
export const manageByAgentOrSelfThunk = createAsyncThunk('manageByAgentOrSelf', agentService.manageByAgentOrSelf)
export const adminAgentsListThunk = createAsyncThunk('adminAgentsList', agentService.adminAgentsList)
export const adminAgentsVerifyThunk = createAsyncThunk('adminAgentsVerify', agentService.adminAgentsVerify)
export const agentGetTenantSearchBySuburbThunk = createAsyncThunk('agentGetTenantSearchBySuburb', agentService.agentGetTenantSearchBySuburb)