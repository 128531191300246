import { styled } from "styled-components"



export const StyledUpload = styled.div`
  border: 1px dashed var(--bs-border-color);
  padding: 1.5rem 1rem;

  &:hover {
    .link {
      text-decoration: underline;
    }
  }
`


export const StyledImageBox = styled.div`
  aspect-ratio: 4/2.8;

  .tools{
    position: absolute;
    inset: 5px 5px auto auto;
  }
`