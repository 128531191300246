import styled, { css } from 'styled-components'
import { BadgeProps } from './Badge.interface'



const Badge = styled.span<BadgeProps>`
  --opacity: 1;
  --color: white;
  --background: var(--bs-primary-rgb);
  padding: 8px;
  background-color: white;
  border-radius: var(--bs-border-radius);
  font-size: 12px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  line-height: 1;
  color: var(--color);
  position: relative;
  z-index: 1;
  font-weight: 500;
  text-transform: capitalize;

  &:before {
    content:'';
    position: absolute;
    inset:0;
    background-color: rgba(var(--background), var(--opacity));
    border-radius: inherit;
    z-index: -1;
  }

  ${props => props.$variant === 'light' && css`
    --opacity:0.1;
    --color: var(--primary-main)
  `}
`


export default Badge