import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { updateTenantProfileThunk, tenantByIdThunk, tenantAcceptThunk, tenantRejectThunk, notificationSettingsThunk, sendFeedbackThunk, tenantTotalRecordsThunk, closeMyAccountThunk, getAllOtherInfoUserThunk } from '../actions/user.action'
import { Status } from '../../../interfaces/Status.type'
import { TenantProfileDTO } from '../../../dtos/TenantProfile.dto'
import { UserDTO } from '../../../dtos/User.dto'



/* == Initial State == */
const initialState: IUserSlice = {
  updateTenantProfile: {
    status: ''
  },
  tenantById: {
    status: '',
    data: null
  },
  tenantTotalRecords: {
    status: '',
    data: { totalRecords: 0 }
  },
  acceptTenant: {
    status: '',
    data: null,
    pagination: {}
  },
  rejectTenant: {
    status: '',
    data: null,
    pagination: {}
  },
  closeMyAccount: {
    status: '',
  },
  notificationSettings: {
    status: '',
  },
  sendFeedbackForm: {
    status: '',
  },
  profileOnboard: {
    activeStep: 0,
    formData: {
      suburb: [],
      profilePhotos: [],
      description: '',
      weeklyBudget: '',
      currentStatus: '',
      familyMembers: { children: 0, cats: 0, dogs: 0, adults: 0 },
      vehicles: [],
      salary: { earn: '', type: '' },
      references: []
    },
    status: ''
  },
  landlordProfileOnboard: {
    activeStep: 0,
    formData: {
      id: null,
      description: '',
      suburb: [{ name: '', postcode: '' }],
      image: [],
      numberOfRoom: 0,
      numberOfWashroom: 0,
      numberOfParking: 0,
      propertyPrice: 0,
      address: {
        name: '',
        country: '',
        city: '',
        state: '',
        isManual: false,
        placeId: '',
        postcode: '',
        latitude: 0,
        longitude: 0,
      },
      verificationDocuments: []
    },
    status: ''
  },
  getAllOtherInfomationUser: {
    status: '',
    data: null
  },
}


/* == Slice == */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setprofileOnboardFormData: (state, action: PayloadAction<IOnboardTFormData>) => {
      state.profileOnboard.formData = { ...state.profileOnboard.formData, ...action.payload }
    },
    setLandlordProfileOnboardFormData: (state, action: PayloadAction<IOnboardLFormData>) => {
      state.landlordProfileOnboard.formData = { ...state.landlordProfileOnboard.formData, ...action.payload }
    },

  },

  extraReducers: (builder) => {

    {/* Update Tenant Profile */ }
    builder.addCase(updateTenantProfileThunk.pending, (state, action) => {
      state.updateTenantProfile.status = THUNK_STATUS.LOADING
    })

    builder.addCase(updateTenantProfileThunk.fulfilled, (state, action) => {
      state.updateTenantProfile.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(updateTenantProfileThunk.rejected, (state, action) => {
      state.updateTenantProfile.status = THUNK_STATUS.FAILED
    })


    {/* Tenant By Id */ }
    builder.addCase(tenantByIdThunk.pending, (state, action) => {
      state.tenantById.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantByIdThunk.fulfilled, (state, action) => {
      state.tenantById.status = THUNK_STATUS.SUCCESS
      state.tenantById.data = action.payload.data.user_info
    })

    builder.addCase(tenantByIdThunk.rejected, (state, action) => {
      state.tenantById.status = THUNK_STATUS.FAILED
    })


    {/* Tenant Total Records */ }
    builder.addCase(tenantTotalRecordsThunk.pending, (state, action) => {
      state.tenantTotalRecords.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantTotalRecordsThunk.fulfilled, (state, action) => {
      state.tenantTotalRecords.status = THUNK_STATUS.SUCCESS
      state.tenantTotalRecords.data.totalRecords = action.payload.data.totalRecords

    })

    builder.addCase(tenantTotalRecordsThunk.rejected, (state, action) => {
      state.tenantTotalRecords.status = THUNK_STATUS.FAILED
    })


    {/* Tenant Accept */ }
    builder.addCase(tenantAcceptThunk.pending, (state, action) => {
      state.acceptTenant.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantAcceptThunk.fulfilled, (state, action) => {
      state.acceptTenant.status = THUNK_STATUS.SUCCESS
      state.acceptTenant.data = action.payload.data
      state.acceptTenant.pagination = action.payload.pagination
    })

    builder.addCase(tenantAcceptThunk.rejected, (state, action) => {
      state.acceptTenant.status = THUNK_STATUS.FAILED
    })


    {/* Tenant reject */ }
    builder.addCase(tenantRejectThunk.pending, (state, action) => {
      state.rejectTenant.status = THUNK_STATUS.LOADING
    })

    builder.addCase(tenantRejectThunk.fulfilled, (state, action) => {
      state.rejectTenant.status = THUNK_STATUS.SUCCESS
      state.rejectTenant.data = action.payload.data
      state.rejectTenant.pagination = action.payload.pagination
    })

    builder.addCase(tenantRejectThunk.rejected, (state, action) => {
      state.rejectTenant.status = THUNK_STATUS.FAILED
    })


    {/* Close My Account */ }
    builder.addCase(closeMyAccountThunk.pending, (state, action) => {
      state.closeMyAccount.status = THUNK_STATUS.LOADING
    })

    builder.addCase(closeMyAccountThunk.fulfilled, (state, action) => {
      state.closeMyAccount.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(closeMyAccountThunk.rejected, (state, action) => {
      state.closeMyAccount.status = THUNK_STATUS.FAILED
    })


    {/*   Notification Settings  */ }
    builder.addCase(notificationSettingsThunk.pending, (state, action) => {
      state.notificationSettings.status = THUNK_STATUS.LOADING
    })

    builder.addCase(notificationSettingsThunk.fulfilled, (state, action) => {
      state.notificationSettings.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(notificationSettingsThunk.rejected, (state, action) => {
      state.notificationSettings.status = THUNK_STATUS.FAILED
    })

    {/*   Send feedback form  */ }
    builder.addCase(sendFeedbackThunk.pending, (state, action) => {
      state.sendFeedbackForm.status = THUNK_STATUS.LOADING
    })

    builder.addCase(sendFeedbackThunk.fulfilled, (state, action) => {
      state.sendFeedbackForm.status = THUNK_STATUS.SUCCESS
    })

    builder.addCase(sendFeedbackThunk.rejected, (state, action) => {
      state.sendFeedbackForm.status = THUNK_STATUS.FAILED
    })

    {/*  Get All Information User Checkbox form  */ }
    builder.addCase(getAllOtherInfoUserThunk.pending, (state, action) => {
      state.getAllOtherInfomationUser.status = THUNK_STATUS.LOADING
    })

    builder.addCase(getAllOtherInfoUserThunk.fulfilled, (state, action) => {
      state.getAllOtherInfomationUser.status = THUNK_STATUS.SUCCESS
      state.getAllOtherInfomationUser.data = action.payload.data.result
    })

    builder.addCase(getAllOtherInfoUserThunk.rejected, (state, action) => {
      state.getAllOtherInfomationUser.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface IUserSlice {
  updateTenantProfile: {
    status: Status
  }
  tenantById: {
    status: Status
    data: ITenantData | null
  }
  tenantTotalRecords: {
    status: Status
    data: {
      totalRecords: number
    }
  }
  acceptTenant: {
    status: Status,
    data: IAcceptReject | null
    pagination: object
  }
  rejectTenant: {
    status: Status,
    data: IAcceptReject | null
    pagination: object
  }
  closeMyAccount: {
    status: Status
  }
  notificationSettings: {
    status: Status,
  },
  sendFeedbackForm: {
    status: Status,
  },
  profileOnboard: {
    status: Status
    formData: IOnboardTFormData
    activeStep: number
  },
  landlordProfileOnboard: {
    status: Status
    formData: IOnboardLFormData
    activeStep: number
  }
  getAllOtherInfomationUser: {
    status: Status
    data: any | null
  }
}

interface IAcceptReject {
  data: {
    statuscode: number
  }
}

interface ITenantData extends UserDTO {
  profile: TenantProfileDTO
}

interface IOnboardTFormData {
  suburb?: { name: string; postcode: string; }[]
  weeklyBudget?: string
  profilePhotos?: (string | undefined)[]
  salary?: { type: string, earn: string }
  description?: string
  currentStatus?: string
  familyMembers?: { children: number, cats: number, dogs: number, adults: number }
  vehicles?: any[]
  references?: any[]
}

interface IOnboardLFormData {
  id?: string | null
  description?: string
  suburb?: [{ name?: string, postcode?: string }]
  image?: string[]
  numberOfRoom?: number
  numberOfWashroom?: number
  numberOfParking?: number
  propertyPrice?: number
  address?: {
    name?: string
    country?: string
    city?: string
    state?: string
    isManual?: boolean
    placeId?: string
    postcode?: string
    latitude?: number
    longitude?: number
  }
  verificationDocuments?: {
    type?: string
    image?: string
  }[]
}


export const { setprofileOnboardFormData, setLandlordProfileOnboardFormData } = userSlice.actions
export default userSlice.reducer