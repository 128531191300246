import { ReactNode } from 'react'
import Typography from '../../../../../components/_ui/typography/Typography.component'


type SocialLoginStep2Props = {
  children: ReactNode;
};


const SocialLoginStep2: React.FC<SocialLoginStep2Props> = ({ children }) => {
  return (
    <>
      {/* <Steps active={2} /> */}
      <div className='d-flex flex-column gap-1 mb-3'>
        {/* <Typography $variant='body1'>Step 2</Typography> */}
        <Typography $variant="h3">Enter your name</Typography>
      </div>
      {children}

    </>
  )
}

export default SocialLoginStep2;