import { MdCheck } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { setKey, setDefaults, setLanguage, setRegion, fromAddress, fromLatLng, fromPlaceId, setLocationType, geocode, RequestType } from "react-geocode";

import { LocationCardProps } from "./ProfileListing.interface";
import Pill from "../../../../components/_ui/pill/Pill";
import Typography from "../../../../components/_ui/typography/Typography.component";



export default function LocationCard(props: LocationCardProps) {
  const { payload, title } = props;

  return (
    <Row>
      <Col xs={12} md={12}>
        <div className="pb-4 border-bottom">
          <Typography $variant="h6" className={"mb-4"}>
            {title}
          </Typography>

          <div className="d-flex flex-wrap">
            {payload &&
              payload.data?.map((item, index) => {
                return (
                  item['name'] && (
                    <Pill key={index} text={`${item['name']}, ${item['postcode'] || ''}`} icon={<MdCheck color="14b514" />} />
                  )
                );
              })}
          </div>
        </div>
      </Col>
    </Row>
  );
}
