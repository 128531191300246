import { useState } from 'react'
import { Form } from "react-bootstrap"
import { VerifyOtpFormProps } from './VerifyOtpForm.interface'
import { verifyOtpThunk } from '../../../../../lib/redux/actions/auth.action'
import { StyledForm } from "./VerifyOtpForm.style"
import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux'
import { handleUpdateNumber } from '../../../../../lib/redux/slices/auth.slice'
import { resendOtpThunk } from '../../../../../lib/redux/actions/auth.action'
import { THUNK_STATUS } from '../../../../../lib/redux/constants/status.constant'
import Typography from "../../../../../components/_ui/typography/Typography.component"
import Button from "../../../../../components/_ui/button/Button.component"
import OtpInput from '../otpInput/OtpInput.component'
import UpdateNumberForm from '../updateNumberForm/UpdateNumberForm.component'



export default function VerifyOtpForm(props: VerifyOtpFormProps) {
  const { className } = props
  const { updateNumber } = useReduxSelector(state => state.auth.otp)


  return (
    <StyledForm as='div' className={className}>
      <fieldset className="py-4 px-3">

        <legend className="float-none w-auto m-0 px-2">
          <Typography $variant="h1" className='m-0'>Verify OTP</Typography>
        </legend>

        {updateNumber ? <UpdateNumberForm /> : <OtpForm />}

      </fieldset>
    </StyledForm>
  )
}



{/* == OTP Form == */ }
function OtpForm() {
  const otpLength = 4
  const dispatch = useReduxDispatch()
  const { auth } = useReduxSelector(state => state)
  const { user } = useReduxSelector(state => state.auth)
  const [otp, setOtp] = useState('')


  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (otpLength === otp.length && user)
      dispatch(verifyOtpThunk({
        otp,
        phone: user.phone,
        user_id: user._id
      }))
  }


  const handleOTP = (value: string) => {
    setOtp(value)
  }


  const updateNumber = () => {
    dispatch(handleUpdateNumber({ show: true }))
  }

  const handleResendOtp = () => {
    if (user)
      dispatch(resendOtpThunk({
        user_id: user._id,
        phone: user.phone
      }))
  }


  return (
    <Form noValidate onSubmit={onSubmit}>
      <Typography $variant="body1">
        Enter the OTP sent to your number&nbsp;
        <Typography className='fw-semibold' as='span'>{user?.phone}</Typography> &nbsp;
        <Typography $hover className='text-primary' as='span' onClick={updateNumber}>change</Typography>
      </Typography>

      <div className='center my-5'>
        <OtpInput value={otp} valueLength={otpLength} onChange={handleOTP} />
      </div>

      <div className="d-flex justify-content-between mt-5 gap-3">
        <Button $variant='text' type="button" $loading={auth.resendOtp.status === THUNK_STATUS.LOADING} onClick={handleResendOtp}>Resend OTP</Button>
        <Button type="submit" $loading={auth.otp.status === THUNK_STATUS.LOADING}>Verify OTP</Button>
      </div>
    </Form>
  )
}