import { Col, Row, Stack } from "react-bootstrap";
import Card from "../../../../components/_ui/card/Card.component";
import Typography from "../../../../components/_ui/typography/Typography.component";
import Pill from "../../../../components/_ui/pill/Pill";
import Gallery from "../../components/profileListing/Gallery";
import UserInfo from "../../components/profileListing/UserInfo";
import Sidebar from "../../components/sidebar";
import { MdCircle } from "react-icons/md";
import OverView from "../../components/overview";
import { InsightsProps } from "../../components/overview/overview.interface";
import { UserDTO } from "../../../../dtos/User.dto";
import { checkAllInfo } from '../../../../utils';
import { useState } from "react";
import { useReduxSelector } from "../../../../hooks/redux";
import Button from "../../../../components/_ui/button/Button.component";
import { useNavigate } from "react-router-dom";
import ProfilePlaceholder from '../../../../assets/images/profile-placeholder.svg'


export default function Tenant({ payload, insights }: { payload: UserDTO | null; insights?: InsightsProps | any }) {

  var x = checkAllInfo(payload)
  const { user } = useReduxSelector(state => state.auth)
  const navigate = useNavigate()

  return (
    <>
      <Row className="mb-3 px-3">
        <Col xs={12} md={7}>
          <Typography $variant="h2">Dashboard</Typography>
          <Typography $variant="body1">
            Here's an overview of your rental journey
          </Typography>
        </Col>
        <Col xs={12} md={5} className="d-flex justify-content-lg-end justify-content-center align-items-center pt-4 py-lg-0">
          {
            user?.profile?.isLiveForProperties ? (<Pill text={"LIVE"} subText={"Your listing is currently live"} icon={<MdCircle color="14b514" />} />)
              : (<Pill text={"Disabled"} subText={"Your listing is currently disable"} icon={<MdCircle color="f00" />} onPress={() => alert('test')} />)
          }


        </Col>
      </Row>

      <OverView insights={insights} />

      <Row className="mb-3 px-3">
        <Col xs={12} sm={10}>
          <Typography $variant="h2">Profile Listing</Typography>
          <Typography $variant="body1">Landlords in the area will see your profile</Typography>
        </Col>
        <Col xs={12} sm={2}>
          <div className="d-flex flex-sm-row justify-content-sm-end align-items-center my-2 my-sm-0">
            <Button $variant='outlined' onClick={() => { navigate('/settings/tenant-profile') }} >Edit Profile</Button>
          </div>
        </Col>
      </Row>

      <Card className="mb-5">
        <Stack gap={4}>
          <Row>
            <Col xs={12} md={12}>
              {
                Boolean(payload?.profile?.profilePhotos?.length)
                  ? <Gallery payload={payload?.profile?.profilePhotos} />
                  : <img src={ProfilePlaceholder} className='w-100 h-100 rounded border border-light-subtle' style={{ maxHeight: 'max(100px, calc(100vh - 300px))' }} />
              }

            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <UserInfo payload={payload?.profile} info={payload} />
            </Col>
            <Col xs={12} md={4}>
              <Sidebar payload={payload?.tenantPlanPurchase} />
            </Col>
          </Row>
        </Stack>
      </Card>
    </>
  );
}
