import { ShortListedTenantsProps } from './ShortListedTenants.interface'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../../components/_ui/card/Card.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import placeholderImage from '../../../../assets/images/placeholder.jpg'



export default function ShortListedTenants(props: ShortListedTenantsProps) {

  const { data, className = '', size = 'medium' } = props
  const name = data?.name || ''
  const img = data?.userProfile?.profilePhotos[0] || ''
  const link = `/profile/${data?._id}?propertyId=${data.property_id}`


  return (
    <Card className={`p-0 overflow-hidden d-block position-relative ${className}`} as={Link} to={link} $hover >
      <img src={img || placeholderImage} className='w-100 object-fit-cover' style={{ aspectRatio: '4/2.75', opacity: .9 }} />
      <div className={`${size === 'small' ? 'px-3 py-2' : 'p-3'} d-flex justify-content-between gap-3`}>
        <Typography $variant='subtitle2' className='text-dark fw-semibold py-1 align-self-center'>
          {name}
        </Typography>
      </div>
    </Card>
  )
}