import { FooterProps } from "./Footer.interface"
import { Container, Col, Row, Stack } from 'react-bootstrap'
import { Link } from "react-router-dom"
import StyledFooter from "./Footer.style"
import { RiFacebookCircleFill, RiTwitterFill, RiLinkedinFill } from "react-icons/ri"
import logo from '../../../../assets/images/logo.svg';
import SVG from 'react-inlinesvg';



export default function Footer(props: FooterProps) {
  return (
    <StyledFooter className="d-none d-md-block">
      <Container>

        {/* Links */}
        <Row className="py-5 gap-3 gap-md-0">
          <Col xs={12} sm={3}>
            <Link to="/" className='fw-bold fs-3 m-0 d-flex align-items-center text-primary w-sm-100'>
              <SVG src={logo} height={40} />
            </Link>
          </Col>
          <Col xs={12} sm={3}>
            <Stack direction="vertical" as='nav'>
              <Link to="https://rentless.com.au" className="nav-link" target="_blank">Home</Link>
              <Link to="https://rentless.com.au/company/about" className="nav-link" target="_blank">About Us</Link>
              <Link to="https://rentless.com.au/pricing" className="nav-link" target="_blank">Pricing</Link>
            </Stack>
          </Col>
          <Col xs={12} sm={3}>
            <Stack direction="vertical" as='nav' className="border-left">
              <Link to="https://rentless.com.au/company/faqs" className="nav-link" target="_blank">FAQs</Link>
              <Link to="https://rentless.com.au/company/contact" className="nav-link" target="_blank">Contact Us</Link>
              <Link to="https://rentless.com.au/company/team" className="nav-link" target="_blank">Our Team</Link>
            </Stack>
          </Col>
          <Col xs={12} sm={3}>
            <Stack direction="vertical" as='nav'>
              <Link to="https://client.rentless.com.au" className="nav-link">My Account</Link>
              <Link to="https://rentless.com.au/legal/privacy" className="nav-link" target="_blank">Privacy Policy</Link>
              <Link to="https://rentless.com.au/legal/terms" className="nav-link" target="_blank">Terms of Use</Link>
            </Stack>
          </Col>
        </Row>


        {/* Divider */}
        <div className="border-bottom" />


        {/* Copyright */}
        <Stack className="py-3 align-items-center" direction="horizontal">
          <div className="text-secondary">&copy; Copyright 2024 | Rights Reserved.</div>
          <Stack className="align-items-center ms-auto gap-3" direction="horizontal">
            <Link to='#' target="_blank" className="nav-link"><RiFacebookCircleFill className="fs-5" /></Link>
            <Link to='#' target="_blank" className="nav-link"><RiTwitterFill className="fs-5" /></Link>
            <Link to='#' target="_blank" className="nav-link"><RiLinkedinFill className="fs-5" /></Link>
          </Stack>

        </Stack>
      </Container>
    </StyledFooter>
  )
}
