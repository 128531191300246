import { Form, Tooltip } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { geocodeByPlaceId } from "react-google-places-autocomplete"
import { removeFirstSimilarItemFromArray } from "../../../../utils"
import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import * as yup from 'yup'
import toast from "react-hot-toast"
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import MapAutocomplete from "../../../../components/_ui/mapAutocomplete/MapAutocomplete.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import background_image from '../../../../assets/images/register/background_006.jpg'
import useIsExtactUrlMatch from "../../hooks/useIsExtactUrlMatch.hook"
import { setFindHomeSignupTenantFormData } from "../../../../lib/redux/slices/auth.slice"



export default function ProfileOnBoardStep1() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  let matchUrlPath = useIsExtactUrlMatch('onboard/tenant')

  const { user, findHomeSignupTenantFormData } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)


  const { handleSubmit, control, formState: { errors, isSubmitted }, setValue, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      suburb: user?.profile?.suburb as any || findHomeSignupTenantFormData.formData.profile.suburb || []
    }
  })


  const handleSuburbSelected = async (data: any) => {
    try {
      let finalData: any = []
      for (const item of data) {
        if (item.value.reference) {
          let postcode = null;
          let name = null;
          const [response] = await geocodeByPlaceId(item.value.place_id)
          for (const component of response.address_components) {
            if (component.types.includes('postal_code')) {
              postcode = component.long_name || null
            }
            if (component.types.includes('locality') && name == null) {
              name = component.long_name || null
            }
            else if (component.types.includes("administrative_area_level_1") && name == null) {
              name = component.long_name || null
            }
          }
          finalData.push({ name, postcode, placeId: item.value.place_id })

          // if (Boolean(finalData.find((item: any) => item.postcode == null))) toast('There is no postal code associated with the suburb', { icon: '❌' })
        }
        else {
          finalData.push({ name: item.value.name, postcode: item.value.postcode, placeId: item.value.placeId })
        }
      }

      finalData = removeFirstSimilarItemFromArray(finalData)
      // .filter((item: any) => item.postcode !== null)
      setValue('suburb', finalData)
      if (isSubmitted) trigger('suburb')
    }
    catch (error) {
      console.log('Google Map API Error @geocodeByPlaceId: ', error)
      toast.error('Something went wrong with address')
    }
  }


  const onSubmit = async (data: FormData) => {
    if (!matchUrlPath && user?._id) {
      dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          navigate('/')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))
    }
    else {
      if (Boolean(data?.suburb?.length)) {
        dispatch(setFindHomeSignupTenantFormData({ activeStep: 2, formData: { profile: { ...data } } }))
        navigate('/auth/onboard/tenant/weekly-budget')
      }
      else {
        toast.error('suburb not be empty')
      }
    }
  }


  return (
    <Layout title='Tenant Step 1' user={!matchUrlPath} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        //headerActionTitle=""
        //headerActionButtonText="Sign Out"
        //headerActionButtonLink="/auth/login"
        progress={matchUrlPath ? "15" : "75"}
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Location' description='What areas would you like to live in?' />

            {/* Form */}
            <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column gap-3">
              <Form.Group controlId='distance'>
                <Controller
                  name="suburb"
                  control={control}
                  render={({ field: { value, onChange, ...items } }) => (
                    <MapAutocomplete
                      isInvalid={Boolean(errors.suburb)}
                      autocompletionRequest={{
                        types: ['geocode'],
                        componentRestrictions: {
                          country: 'au'
                        },
                      }}
                      selectProps={{
                        ...items as any,
                        value: value ? value.map((item: any) => ({ label: item.name, value: { place_id: item.name, ...item } })) : undefined,
                        defaultOptions: value ? value.map((item: any) => ({ label: item.name, value: { place_id: item.name, ...item } })) : undefined,
                        onChange: handleSuburbSelected,
                        isMulti: true as any,
                        isClearable: false,
                        placeholder: 'Surry Hills, NSW...'
                      }}
                    />
                  )}
                />
                {/* <Form.Text className='text-danger'>{errors.suburb?.message}</Form.Text> */}
              </Form.Group>




              {/* Submit Button */}
              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit' $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
              </div>

            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />

    </Layout >
  )
}



{/* == Validation == */ }
const schema = yup.object({
  suburb: yup.array().of(
    yup.object({
      name: yup.string().notRequired(),
      postcode: yup.string().notRequired(),
      placeId: yup.string().notRequired()
    })).max(100)
})

type FormData = yup.InferType<typeof schema>
