import { styled } from 'styled-components'
import Card from '../../../../components/_ui/card/Card.component'



export const StyledSidebarNav = styled(Card)`
  padding:10px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  gap:2px;

  .nav-item{
    padding: 13px 15px;
    &:hover{
      background-color: var(--bs-tertiary-bg);
    }
  }

  .nav-item.active{
    background-color: rgba(${({ theme }) => theme.primary.mainRgb}, 0.05);
    color: var(--primary-main);
  }

  .nav-item:last-of-type{
    border:unset !important
  }
`