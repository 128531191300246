import { createAsyncThunk } from "@reduxjs/toolkit"
import { propertyService } from "../services/property.service"



export const propertyAddThunk = createAsyncThunk('propertyAdd', propertyService.propertyAdd)
export const propertyListByUserThunk = createAsyncThunk('propertyListByUser', propertyService.propertyListByUser)
export const propertyDetailsThunk = createAsyncThunk('propertyDetails', propertyService.propertyDetails)
export const updatePropertyDocumentsThunk = createAsyncThunk('updatePropertyDocuments', propertyService.updatePropertyDocuments)
export const updatePropertyThunk = createAsyncThunk('updateProperty', propertyService.updateProperty)
export const searchTenantsByPropertyThunk = createAsyncThunk('searchTenantsByProperty', propertyService.searchTenantsByProperty)
export const shortlistedTenantListThunk = createAsyncThunk('shortlistedTenantList', propertyService.shortlistedTenantList)
export const addTenantInShortlistThunk = createAsyncThunk('addTenantInShortlist', propertyService.addTenantInShortlist)
export const shortlistedPropertiesThunk = createAsyncThunk('shortlistedProperties', propertyService.shortlistedProperties)
export const shortlistedTenantsThunk = createAsyncThunk('shortlistedTenants', propertyService.shortlistedTenants)
export const getPropertiesListByShortlistedTenantThunk = createAsyncThunk('getPropertiesListByShortlistedTenant', propertyService.getPropertiesListByShortlistedTenant)
export const propertyTenantsSchedulesThunk = createAsyncThunk('propertyTenantsSchedules', propertyService.propertyTenantsSchedules)
export const propertyDeleteThunk = createAsyncThunk('propertyDelete', propertyService.propertyDelete)