import Layout from "../../../components/_layout/Layout.component"
import LoginForm from './component/loginForm/LoginForm.component'
import RegisterLayout from '../register/component/registerLayout/RegisterLayout.component'
import OnBoardAuthBanner from '../../../components/authLayout/onBoardAuthBanner/onBoardAuthBanner.component'



export default function SignIn() {


  return (
    <Layout title='Sign In' user={false} header={false} footer={false}>
      <RegisterLayout
        columnFirst={<LoginForm />}
        columnSecond={<OnBoardAuthBanner />}
        headerActionTitle="Need an account?"
        headerActionButtonText="Create Account"
        headerActionButtonLink="/auth/register/step-1"
      />

      {/* Login Form */}
      {/* <Row className='justify-content-center'>
          <Col xs={12} lg={6} >
            <div>
              <LoginForm />
              <StyledDivider className='center my-5'>
                <Typography $variant='body1' className='bg-white px-3'>Or</Typography>
              </StyledDivider>
            </div>
          </Col>
        </Row> */}


      {/* Sign Up Box */}
      {/* <div className='d-flex align-items-center text-center flex-column'>
          <Typography $variant='h1' as='h2' className='mb-4'>Create an Account</Typography>
          <Button as={Link} to='/auth/register/step-1'>Sign Up</Button>
        </div> */}

    </Layout>
  )
}
