import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAtumFpNeh1H0BI4cRdVQeQnvrb9NimijA",
  authDomain: "rentless-pty-ltd.firebaseapp.com",
  databaseURL: "https://rentless-pty-ltd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rentless-pty-ltd",
  storageBucket: "rentless-pty-ltd.appspot.com",
  messagingSenderId: "475866501742",
  appId: "1:475866501742:web:f6c131bd2b800b443e8c7f",
  measurementId: "G-7QSNKTE56T"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export { database, analytics, logEvent };
