import React, { useEffect, useState } from 'react';
import { ref, get, update, DataSnapshot } from 'firebase/database';
import { database } from '../../../../firebase';
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux';
import { setChatUnreadMsgCount } from '../../../../lib/redux/slices/chat.slice';
import { Badge } from 'react-bootstrap';



interface Message {
  messageId: string;
  text: string;
  recipientUid: string;
  isSeen: boolean;
}


function UnreadChatsCount() {
  const { user } = useReduxSelector(state => state.auth)
  const { chatUnreadMsgCount } = useReduxSelector(state => state.chat)
  const dispatch = useReduxDispatch()
  const recipientUid = user?.fireBaseUserId


  useEffect(() => {
    const chatsRef = ref(database, 'chats');
    get(chatsRef)
      .then((snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          let count = 0

          snapshot.forEach((chatSnapshot) => {
            const messages: any = chatSnapshot.child('messages');

            messages.forEach((messageSnapshot: any) => {
              const messageData: Message = messageSnapshot.val();
              if (messageData.recipientUid === recipientUid && messageData.isSeen == false) {
                count++;
              }
            });
          });
          dispatch(setChatUnreadMsgCount(count))
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [recipientUid]);


  return (
    <Badge pill bg="secondary" className="fw-normal ms-1">{chatUnreadMsgCount?.countMsg || 0}</Badge>
  );
}

export default UnreadChatsCount;
