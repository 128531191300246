
import Modal from 'react-bootstrap/Modal'
import "react-datepicker/dist/react-datepicker.css"
import Button from '../../_ui/button/Button.component'
import Typography from '../../_ui/typography/Typography.component'
import { Form } from 'react-bootstrap'
import { ChangeEvent, useState } from 'react';
import { ConfirmReasonProps } from './ConfirmReason.interface'


type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;


export function ConfirmReason(props: ConfirmReasonProps) {
  const { data, callBack, onHide, reason, setReason, loading } = props


  const handleChange: (e: ChangeEvent<FormControlElement>) => void = (event) => {
    setReason(event.target.value)
  };

  const confirm = async () => {
    await callBack()
    setReason('')
    onHide()
  }

  return (
    <Modal {...props} onHide={() => { onHide(); setReason('') }} aria-labelledby="contained-modal-title-vcenter" centered animation className='m-width-200'>
      <Modal.Header closeButton>
        <Typography $variant='h4' id="contained-modal-title-vcenter">{data?.heading}</Typography>
      </Modal.Header>


      <Modal.Body>
        <Typography $variant='subtitle2' className='mb-3'>{data.description}</Typography>
        <Form.Control required as='textarea' value={reason}
          style={{ height: 150 }} placeholder='Please leave some feedback on how we can improve rentless.'
          onChange={(e: ChangeEvent<FormControlElement>) => handleChange(e)}
        />

      </Modal.Body>

      <div className='d-flex justify-content-end m-3 gap-3'>
        <Button $variant='text' $loading={loading} onClick={() => confirm()}>{data.buttonName}</Button>
        <Button $variant='filled' $color='primary' onClick={() => onHide()}>Cancel</Button>
        
      </div>

    </Modal >
  );
}

