import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Spinner, Table } from 'react-bootstrap'
import moment from 'moment';

import { limitRowSize } from '../../../data/config';
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux';
import { adminAllPropertiesThunk } from '../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant';
import SearchImg from '../../../assets/images/search.svg'
import Card from '../../../components/_ui/card/Card.component'
import Layout from "../../../components/_layout/Layout.component"
import Alert from '../../../components/_ui/alert/Alert.component'
import Button from "../../../components/_ui/button/Button.component"
import AdminLayout from "../components/adminLayout/AdminLayout.component"
import AllPropertiesNav from '../components/allPropertiesNav/Nav.component'
import Typography from "../../../components/_ui/typography/Typography.component"
import PaginationComponent from '../../../components/pagination/PaginationComponent'
import NoRecordFound from '../../../components/_ui/noRecordFound/NoRecordFound.component'
import { convertToMelbourneTime } from '../../../utils';



export default function AllProperties() {
  let limitSize = limitRowSize
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useReduxDispatch()
  const { allProperties } = useReduxSelector(state => state.admin)

  useEffect(() => {
    dispatch(adminAllPropertiesThunk({ page: currentPage, size: limitSize })).unwrap()
      .then((response) => {
        setData(response?.data?.info?.result);
        setTotalPages(Math.ceil(response?.data?.info?.totalRecords / limitSize) || 0)
      })
      .catch(err => console.log(err))
  }, [currentPage])


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  return (
    <Layout title='Payments' user={true}>
      <AdminLayout nav={<AllPropertiesNav />}>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Properties</Typography>
          <Typography $variant='body1'>List of properties recently made</Typography>
        </div>

        <Card>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Property Address</th>
                <th>City</th>
                <th>Status</th>
                <th>Created Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allProperties.status === THUNK_STATUS.SUCCESS &&
                data.map((item: any, index) => (
                  <tr key={index}>
                    <td>{item?.address?.name || 'Not Found'}</td>
                    <td>{item?.address?.city || 'Not Found'}</td>
                    <td>{item?.status || ''}</td>
                    <td>{convertToMelbourneTime(item?.createdAt).format('L') || ''}</td>
                    <td><Button onClick={() => navigate(`/admin/property/verifications/${item?._id}`)} $variant='outlined'>View</Button></td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-center mb-3'>
            {allProperties.status === THUNK_STATUS.SUCCESS && !Boolean(allProperties.data.length) &&
              <NoRecordFound shadow='shadow-none' className='text-center w-100' image={SearchImg}>
                <Typography $variant='h4'>All Properties</Typography>
                <Typography $variant='body1'>No record found</Typography>
              </NoRecordFound>
            }
            {allProperties.status === THUNK_STATUS.LOADING && <Spinner className='m-auto my-3 d-block' variant='secondary' />}
            {allProperties.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
          </div>

          <div className='d-flex justify-content-end'>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>

        </Card>
      </AdminLayout>
    </Layout >
  )
}
