import axios from "axios"



{/* APIs */ }
const updateTenantProfile = async (data: any) => {
  return await axios.put('/user/updateTenantProfile', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const tenantById = async (params: { id: string, propertyId?: string }) => {
  return await axios.get('/user/tenantById', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const tenantTotalRecords = async () => {
  return await axios.get('/user/tenant/tenantTotalRecord')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const tenantView = async (params: ITenantView) => {
  return await axios.get('/user/tenant/view', { params })
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const acceptTenant = async (data: ITenantAcceptReject) => {
  return await axios.post('/user/tenant/acceptReject', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const sendFeedback = async (data: ISendFeedback) => {
  return await axios.post('/user/sendFeedback', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const rejectTenant = async (data: ITenantAcceptReject) => {
  return await axios.post('/user/tenant/acceptReject', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const closeMyAccount = async (data: { closeAccountReason: string }) => {
  return await axios.post('/user/closeMyAccount', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const notificationSettings = async (data: INotificationSetting) => {
  return await axios.put('/user/updateNotificationSettings', data)
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


const getAllOtherInfoUser = async () => {
  return await axios.get('/user/getAllOtherInfo')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}

const storeUserSession = async () => {
  return await axios.get('/user/storeUserSession')
    .then(respose => respose.data)
    .catch(error => Promise.reject(error))
}


{/* Interface */ }
interface ITenantView {
  tenantId: string
  propertyId: string
}

interface ITenantAcceptReject {
  id: string
  propertyId: string
  status: string
}

interface ISendFeedback {
  comment: string
  rating: number
}

interface INotificationSetting {
  isNotificationNewMessage: boolean
  isNotificationPromotions: boolean
  isNotificationShortlisted: boolean
}



export const userService = {
  updateTenantProfile,
  tenantById,
  tenantTotalRecords,
  tenantView,
  acceptTenant,
  rejectTenant,
  notificationSettings,
  sendFeedback,
  closeMyAccount,
  getAllOtherInfoUser,
  storeUserSession
}