import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useReduxSelector } from "../../hooks/redux";
import { Roles } from "../../interfaces/Roles.type";



// Role based routes accessable
export function RolesProvider({ children, roles }: { children?: ReactNode, roles: Roles[] }) {
  const { isLogggedIn, userRoles } = useReduxSelector(state => state.auth)


  if (isLogggedIn) {
    if (roles && !Boolean(userRoles.filter(role => roles?.includes(role)).length)) {
      return <Navigate to='/' />
    }
    else {
      return children
    }
  }
}
