import styled, { css } from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { ButtonProps } from './Button.interface'



const Button = styled.button.attrs((props: any) => {
  const data: any = {}
  if (props.$loading) {
    data.disabled = true
    data.children = <>
      <span className='disabled-text'>{props.children}</span>
      <div className='loader'><Spinner /></div>
    </>
  }
  data.className = `theme-button ${props.className}`
  return data
}) <ButtonProps>`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: var(--bs-border-radius);
  transition: background-color 0.15s;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.primary.main};
  position: relative;
  background-color: transparent;
  display: inline-block;

  &:disabled {
    pointer-events: none;
    opacity:0.15;

    .disabled-text{
      color: rgba(0, 0, 0, 0);
    }

    .loader {
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .spinner-border{
        --bs-spinner-width: 1.5em !important;
        --bs-spinner-height: 1.5em !important;
        --bs-spinner-border-width: .2em
      }

    }
  }

  &:before{
    content: '';
    border-radius: inherit;
    position: absolute;
    inset:-1px;
    background-color: ${({ theme }) => theme.primary.main};
    opacity: 0;
    transition: opacity 0.15s;
  }

  ${props =>
    (props.$variant === 'filled') && css`
      background-color: ${({ theme }) => theme.primary.main};
      box-shadow: ${({ theme }) => theme.shadows[1]};
      color: white;
      &:hover {
        background-color: ${({ theme }) => theme.primary.dark};
        border-color: ${({ theme }) => theme.primary.dark};
      }   
    `
  }

  ${props =>
    (props.$variant === 'outlined') && css`
      background-color: white;
      color: ${({ theme }) => theme.primary.main};
      font-weight: 500;
      box-shadow: ${({ theme }) => theme.shadows[1]};
      &:hover {
        &:before {
          opacity: 0.1;
        }
      }
    `
  }

  ${props =>
    (props.$variant === 'text') && css`
        color: ${({ theme }) => theme.primary.main};
        border-color: transparent;
        &:hover {
          &:before {
            opacity: 0.1;
          }
        }
      `
  }

  ${props =>
    (props.$color === 'info' && props.$variant === 'outlined') && css`
        color: ${({ theme }) => theme.info.main};
        border-color: ${({ theme }) => theme.info.main};
        &:hover {
          &:before {
            opacity: 0.1;
          }
        }
      `
  }
  ${props =>
    (props.$color === 'danger' && props.$variant === 'outlined') && css`
        color: ${({ theme }) => theme.danger.main};
        border-color: ${({ theme }) => theme.danger.main};
        &:hover {
          &:before {
            opacity: 0.1;
          }
        }
      `
  }

${props =>
    props.$icon && css`
        aspect-ratio: 1;
        padding:0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 36px;
        min-width: 36px;
      `
  }
`



Button.defaultProps = {
  $variant: 'filled',
}

export default Button