import styled from "styled-components";



export const StyledOnboardWrapper = styled.div`
  width: 500px;

  @media (max-width: 768px) {
    width: 100%;
  }
`