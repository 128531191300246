import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { uploadFileThunk } from '../actions/upload.action'
import { Status } from '../../../interfaces/Status.type'



/* == Initial State == */
const initialState: IUploadSlice = {
  uploadFile: {
    status: '',
    data: []
  }
}



/* == Slice == */
export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},


  extraReducers: (builder) => {

    {/* Upload File */ }
    builder.addCase(uploadFileThunk.pending, (state, action) => {
      state.uploadFile.status = THUNK_STATUS.LOADING
    })

    builder.addCase(uploadFileThunk.fulfilled, (state, action) => {
      state.uploadFile.status = THUNK_STATUS.SUCCESS
      state.uploadFile.data = action.payload.data.urls
    })

    builder.addCase(uploadFileThunk.rejected, (state, action) => {
      state.uploadFile.status = THUNK_STATUS.FAILED
    })

  }
})



/* == Interface == */
interface IUploadSlice {
  uploadFile: {
    status: Status,
    data: string[]
  }
}



export const { } = uploadSlice.actions
export default uploadSlice.reducer