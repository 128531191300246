import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { THUNK_STATUS } from '../constants/status.constant'
import { Status } from '../../../interfaces/Status.type'
import { PackageDTO } from '../../../dtos/Package.dto'
import { packageDealByTypeThunk } from '../actions/package.action'



/* == Initial State == */
const initialState: IPackageSlice = {
  packageDealByType: {
    status: '',
    data: null
  },
}



/* == Slice == */
export const planSlice = createSlice({
  name: 'Package',
  initialState,
  reducers: {},


  extraReducers: (builder) => {

    {/* Plans */ }
    builder.addCase(packageDealByTypeThunk.pending, (state) => {
      state.packageDealByType.status = THUNK_STATUS.LOADING
    })

    builder.addCase(packageDealByTypeThunk.fulfilled, (state, action) => {
      state.packageDealByType.status = THUNK_STATUS.SUCCESS
      state.packageDealByType.data = action.payload.data.result
    })

    builder.addCase(packageDealByTypeThunk.rejected, (state) => {
      state.packageDealByType.status = THUNK_STATUS.FAILED
    })


  }
})



/* == Interface == */
interface IPackageSlice {
  packageDealByType: {
    status: Status
    data: PackageDTO | null
  }
}



export const { } = planSlice.actions
export default planSlice.reducer