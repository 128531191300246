import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import axios from 'axios';

import { useReduxDispatch, useReduxSelector } from '../../../../../../../../hooks/redux';
import { message } from '../../../../../../../../utils';
import { handleCheckoutModal } from '../../../../../../../../lib/redux/slices/plan.slice';
import Alert from '../../../../../../../../components/_ui/alert/Alert.component';
import Typography from '../../../../../../../../components/_ui/typography/Typography.component';
import usePackDealHoursDiff from '../../../../packageDeal/usePackDealHoursDiff.hook';



export default function PaypalCheckoutButton() {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const [{ isPending, isResolved, isRejected, isInitial }] = usePayPalScriptReducer();
  const { selectPlanDetail } = useReduxSelector(state => state.plan.checkoutModal)
  const { user } = useReduxSelector(state => state.auth)
  const { endTime, dealEndDateTime } = usePackDealHoursDiff()

  async function createOrder() {
    try {
      const response = await axios.post("/user/paypal/createOrder", {
        planId: selectPlanDetail?._id, user_id: user?._id, endTime: endTime, isDealOn: dealEndDateTime !== null ? true : false
      }, { headers: { "Content-Type": "application/json" } }
      );
      let orderData = response.data.data.order_info;

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail ? `${errorDetail?.issue} ${errorDetail?.description} (${orderData?.debug_id})` : JSON.stringify(orderData);
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.log(error)
    }
  }


  async function onApprove(data: any, actions: any) {
    try {
      const response = await axios.post(`/user/paypal/createCapture`, { transactionId: data.orderID, planId: selectPlanDetail?._id, user_id: user?._id, paymentProvider: 'paypal' }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const approveResult = response.data.data.response

      if (approveResult?.status === "COMPLETED") {
        const newUrl = '/?redirect_status=succeeded'
        window.location.href = newUrl;
      }

    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      {isInitial || isPending && <Spinner className='m-auto d-block' variant='secondary' />}
      {isResolved && <>
        <Typography $variant='body1' className='fw-medium opacity-75 center mb-2'>or</Typography>
        <PayPalButtons
          style={{ color: "gold", layout: "horizontal", height: 38, shape: "rect", tagline: false }}
          createOrder={createOrder}
          onApprove={onApprove}
          onClick={(data, actions) => { actions.resolve() }}
          onError={(error) => {
            console.log('error')
          }}
          onCancel={(e) => {
            console.log('cancel popup')
          }}
        />
      </>
      }

      {isRejected && <Alert $variant='danger'>{`Paypal : ${message.SOMETHING_WENT_WRONG}`}</Alert>}
    </>

  )
}
