import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useReduxSelector } from '../../../hooks/redux'



export default function useFindHomeSignupRedirection() {
  const location = useLocation()
  const navigate = useNavigate()
  const { findHomeSignupTenantFormData, isLogggedIn } = useReduxSelector(state => state.auth)

  useEffect(() => {
    if (findHomeSignupTenantFormData.activeStep === 1 && !isLogggedIn) {
      navigate('/auth/onboard/tenant/location')
    }
  }, [])
  console.log(location, findHomeSignupTenantFormData)
  return null
}
