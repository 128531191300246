import { styled } from "styled-components"
import { PhoneInputProps } from "./PhoneInput.interface"
import ReactPhoneInput from 'react-phone-number-input/input'



export const PhoneInput = styled(ReactPhoneInput) <PhoneInputProps>`
  background: white;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 10px 16px;
  width: 100%;
  
  &.is-invalid{
    border-color: var(--bs-form-invalid-border-color);
  }
`



PhoneInput.defaultProps = {
  country: 'AU',
  international: false,
  // defaultCountry: 'AU',
  // withCountryCallingCode: true
}

export default PhoneInput