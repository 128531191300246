import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { message } from '../../../utils'



export default function useThankyouPopup() {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('redirect_status') === 'succeeded') {
      toast.success(message.PAYMENT_SUCCESSFULL)
    }
  }, [])
}
