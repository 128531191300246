import { createAsyncThunk } from "@reduxjs/toolkit"
import { authService } from "../services/auth.service"



export const signUpThunk = createAsyncThunk('signUp', authService.signUp)
export const phoneNoVerificationThunk = createAsyncThunk('phoneNoVerification', authService.phoneNoVerification)
export const verifyOtpWithSocialLoginThunk = createAsyncThunk('verifyOtpWithSocialLogin', authService.verifyOtpWithSocialLogin)
export const verifyOtpThunk = createAsyncThunk('verifyOtp', authService.verifyOtp)
export const loginThunk = createAsyncThunk('login', authService.login)
export const currentUserThunk = createAsyncThunk('currentUser', authService.currentUser)
export const getProfileThunk = createAsyncThunk('getProfileThunk', authService.currentUser)
export const resendOtpThunk = createAsyncThunk('resendOtp', authService.resendOtp)
export const forgotPasswordThunk = createAsyncThunk('forgotPassword', authService.forgotPassword)
export const updatePasswordThunk = createAsyncThunk('updatePassword', authService.updatePassword)
export const resetPasswordThunk = createAsyncThunk('resetPassword', authService.resetPassword)
export const updateProfileThunk = createAsyncThunk('updateProfile', authService.updateProfile)
export const socialLoginThunk = createAsyncThunk('socialLogin', authService.socialLogin)
export const googleSocialLoginThunk = createAsyncThunk('googleSocialLogin', authService.googleSocialLogin)
export const updateRoleThunk = createAsyncThunk('updateRole', authService.updateRole)