import { RegisterLayoutProps } from './RegisterLayout.interface'
import { Row, Col, Container } from 'react-bootstrap'
import { StyledBox } from './RegisterLayout.style'
import logo from '../../../../../assets/images/logo.svg'
import icon from '../../../../../assets/images/icon.svg'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';

import  './style.css'

export default function RegisterLayout(props: RegisterLayoutProps) {
  const { 
    columnFirst, 
    columnSecond, 
    columnSecondBg, 
    headerActionTitle, 
    headerActionButtonText, 
    headerActionButtonLink, 
    progress,
    bannerText
  } = props

  return (
    <StyledBox className='bg-white h-100 overflow-hidden w-100'>
        

        <style>{`main[class]{padding:0 !important}`}</style>

        <Row className='h-100 bg-white'>
          <Col xs={12} md={8} style={{ zIndex: 10, boxShadow: '0px 0px 20px rgb(0 0 0 / 59%)', position: 'relative'}}>

            {
              progress ? (<div className='header-progress'>
                <div className='header-progress-bar' style={{width: `${progress}%`}}></div>
              </div>) : null
            }
            
            <Row className='header'>
            
              <Col xs={6} lg={6}>
                <div className='p-3'>
                  <Link to="https://rentless.com.au">
                    <SVG src={logo} height={40} className='logo d-none d-lg-block' />
                    <SVG src={icon} height={30} className='logo d-lg-none' />
                  </Link>
                </div>
              </Col>
              <Col xs={6} lg={6}>
                {
                  headerActionButtonLink ? (<div className='p-3 d-flex align-items-center justify-content-end'>
                    <div className='header-action-title d-none d-lg-block'>{headerActionTitle}</div>
                    <Link to={`${headerActionButtonLink}`} className='header-action-button'>{headerActionButtonText}</Link>
                  </div>) : null
                }
              </Col>
            </Row>

            <div className='d-flex align-items-start align-items-md-center h-100'>
              <div className='w-100 px-4 d-flex align-items-center justify-content-center'>
                <div style={{ width: '450px', marginBottom: 80 }}>
                  {columnFirst}
                </div>
              </div>
            </div>

            {
              bannerText && <div className='signup-bottom-banner'>
                  {bannerText}
              </div>
            }

          </Col>
          <Col xs={12} md={4} className='d-none d-md-block' style={{ backgroundColor: `${columnSecondBg || 'rgb(60 60 60)'}`, padding: 0 }}>
            <div className='h-100 w-100 bg-box'>
              {columnSecond}
            </div>
          </Col>
        </Row>
        
        
        

    </StyledBox>
  )
}
