import React from 'react'
import { SearchBox } from './SearchInput.style'
import { Form, InputGroup } from 'react-bootstrap'
import { BiSearch } from 'react-icons/bi'
import { searchInputProps } from './SearchInput.interface'



export default function SearchInput(props: searchInputProps) {
  const { name, value, style, className, placeholder, onChange } = props


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };


  return (
    <SearchBox as={Form.Group} controlId='name' className={`position-sticky top-0 bg-white w-100 ${className}`} style={style}>
      <InputGroup as='label'>
        <InputGroup.Text className='bg-white border-end-0 pe-0 text-body-secondary'><BiSearch /></InputGroup.Text>
        <Form.Control type='text' name={name || ''} value={value} placeholder={placeholder || 'Search...'} size='lg' className='border-start-0 ps-2 outline-0 rounded-end-2' onChange={handleInputChange} />
      </InputGroup>
    </SearchBox >
  )
}
