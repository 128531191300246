import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import BoostProfile from '../../../../assets/images/register/boost-listing/boostProfile.svg'
import BoostIcon from '../../../../assets/images/register/boost-listing/BoostIcon.svg'
import GreenTick from '../../../../assets/images/register/boost-listing/greenTick.svg'
import Layout from '../../../../components/_layout/Layout.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import Button from '../../../../components/_ui/button/Button.component'
import Card from '../../../../components/_ui/card/Card.component'
import SVG from 'react-inlinesvg'

import { BgImage, CardBody, CardDiv, SkipLink, SkipBody, ScrollWrap, Box } from './Boost.style'

export default function BoostListing() {
  const navigate = useNavigate()

  /*const description = [
    'Increases your chances of being chosen by a landlord by 30%-70%',
    'Fast track finding your dream home',
    'No contract, flexible payment options'
  ]*/
  const description = [
    '70% more visibility to landlords.',
    'Priority alerts for matching homes.',
    'Top spot in landlord searches.',
    'More profile views.',
    'Longer profile visibility.'
  ]

  return (
    <Layout title='Boost Listing' user={true} header={false} footer={false} feedbackShow={false}>
      
      <style>{`main{padding:0px}`}</style>

      <BgImage>
        
        <ScrollWrap>
          <Box>

          <Container>

            <div className='text-center mb-4'>
              <Typography $variant='h1' className='mb-1'>Boost Profile</Typography>
              <Typography $variant='body1' className='mb-3'>Find your dream home with a boost</Typography>
              {/*<Typography $variant='body2' className='mb-0'>Let’s connect you to our landlord network and find your next home stress-free. </Typography>*/}
            </div>

            <CardDiv>
              <Card style={{ maxWidth: '400px', margin: 'auto', zIndex: 11, position: 'relative' }}>
                <CardBody>
                  <SVG src={BoostIcon} className='img-fluid' title='Boost-profile' />
      
                  <div className='d-flex flex-column gap-2'>
                    {description.map((item, index) => (
                      <div key={index} className='d-flex gap-2' >
                        <SVG src={GreenTick} title='boostIcon' />
                        <Typography $variant='body1' className='opacity-75'>{item}</Typography>
                      </div>
                    ))}
                  </div>
                  {/*<Button onClick={() => navigate('/store/boost')}>Continue</Button>*/}
                  <Button onClick={() => navigate('/store/checkout?plan=64d5f4a2428a8027576ee5a5&type=boost')}>Continue</Button>

                </CardBody>
              </Card>
            </CardDiv>

            <SkipBody>
            <SkipLink href='https://rentless.com.au/legal/terms' target='_blank'>Terms and Conditions</SkipLink>
            <SkipLink href='https://rentless.com.au/legal/privacy' target='_blank'>Privacy Policy</SkipLink>
            <SkipLink onClick={() => navigate('/')} className='mt-2'>I dont want to boost</SkipLink>
            </SkipBody>

          </Container>

          </Box>
        </ScrollWrap>

      </BgImage>
    </Layout >
  )
}
