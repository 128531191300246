import { FeedbackPopupProps } from './FeedbackPopup.interface'
import { MdOutlineKeyboardArrowDown, MdReviews } from 'react-icons/md'
import { RxCross2 } from 'react-icons/rx'
import { StyledFeedback } from './Feedback.style'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FloatingLabel, Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useReduxDispatch, useReduxSelector } from '../../../hooks/redux'
import { sendFeedbackThunk } from '../../../lib/redux/actions/user.action'
import { THUNK_STATUS } from '../../../lib/redux/constants/status.constant'
import "react-datepicker/dist/react-datepicker.css"
import Card from '../../_ui/card/Card.component'
import * as yup from 'yup'
import Button from '../../_ui/button/Button.component'
import Typography from '../../_ui/typography/Typography.component'
import toast from 'react-hot-toast'
import { BsStar, BsStarFill } from 'react-icons/bs'



export function FeedbackPopup(props: FeedbackPopupProps) {
  const dispatch = useReduxDispatch()
  const [rating, setRating] = useState(0);
  const [isOpen, setIsOpen] = useState<Boolean>(false)
  const { status } = useReduxSelector(state => state.user.sendFeedbackForm)
  const { handleSubmit, register, reset, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })


  const handleRatingClick = (selectedRating: any) => {
    setRating(selectedRating);
  };


  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <span key={i} className={`star selected p-1`} onClick={() => handleRatingClick(i)}>
            <BsStarFill />
          </span>
        );
      }
      else {
        stars.push(
          <span key={i} className={`star selected p-1`} onClick={() => handleRatingClick(i)}>
            <BsStar />
          </span>
        );
      }

    }

    return stars;
  };



  const onsubmit = (data: FormData) => {
    console.log(data.comment, rating)
    dispatch(sendFeedbackThunk({ ...data, rating })).unwrap()
      .then((response) => {
        toast.success('Feedback sent')
        setRating(0)
        reset()
        setIsOpen(false)
      }).catch(err => { console.log(err); setIsOpen(false) })
  }

  return (
    <>
      <div className={`position-fixed bottom-0 right-5 m-3 p-3 text-light rounded-circle cursor-pointer ${props.className}`} style={{ background: '#1ca43a' }}
        onClick={() => setIsOpen(!isOpen)}>
        <div>
          {isOpen ? <MdOutlineKeyboardArrowDown className='fs-3' /> : <MdReviews className='fs-3' />}
        </div>
      </div>

      <StyledFeedback isOpen={isOpen ? 'true' : 'false'}>
        <Card className='w-100' style={{ minHeight: '50%' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <Typography $variant='h3'>Feedback</Typography>
            <div className='cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
              <RxCross2 className='fs-3' />
            </div>
          </div>

          <Form onSubmit={handleSubmit(onsubmit)}>
            <Form.Group className='mb-3' controlId="phone">
              <div className="star-rating-stars">{renderStars()}</div>
            </Form.Group>

            <Form.Group className="w-100" controlId="review">
              <FloatingLabel controlId="review" label="Let us know how we can improve Rentless...">
                <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '150px', width: '100%' }} {...register('comment')} />
              </FloatingLabel>
              <Form.Text className="text-danger">{errors.comment?.message}</Form.Text>
            </Form.Group>

            <div className='d-flex justify-content-end'>
              <Button $variant='filled' type='submit' className='mt-3' $loading={status === THUNK_STATUS.LOADING} >Send</Button>
            </div>
          </Form>
        </Card>
      </StyledFeedback>
    </>
  );
}



{/* == Validation == */ }
const schema = yup.object({
  comment: yup.string().trim().max(400).required(),
})

type FormData = yup.InferType<typeof schema>

{/* &#9733; Unicode star character */ }