import { PillProps } from "./Pill.interface"

export default function Pill(props: PillProps) {

  return ( <div className='bg-white rounded-5 border shadow-sm d-flex align-items-center px-3 py-2 m-1'>
    {
      props.icon
    }
    {
      props.text && <span className='fw-bold me-2 ms-2'>{props.text}</span>
    }
    {
      props.subText && <span className='opacity-50'>{props.subText}</span>
    }
  </div>)

}