import { AvatarProps } from "./Avatar.interface"
import { StyledAvatar } from "./Avatar.style"
import { MdPerson } from "react-icons/md"



export default function Avatar(props: AvatarProps) {
  const { $image, $size = 36, $icon, className } = props


  return (
    <StyledAvatar {...props} $size={$size} className={className}>
      {!$image && ($icon ?? < MdPerson style={{ fontSize: `calc(${$size}px - 70%)` }} />)}
    </StyledAvatar>
  )
}
