import toast from 'react-hot-toast'
import { Spinner, Stack } from 'react-bootstrap'
import { useState, FormEvent, useEffect } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

import { StripeFormProps } from './StripeForm.interface'
import { useReduxDispatch, useReduxSelector } from '../../../../../../../hooks/redux'
import { tenantPurchasePlanStatusThunk, tenantPurchasePlanThunk } from '../../../../../../../lib/redux/actions/plan.action'
import Button from '../../../../../../../components/_ui/button/Button.component'
import Typography from '../../../../../../../components/_ui/typography/Typography.component'
import { handleCheckoutModal } from '../../../../../../../lib/redux/slices/plan.slice'
import usePackDealHoursDiff from '../../../packageDeal/usePackDealHoursDiff.hook'
import { handleCalculateDiscountAndSavings } from '../../../../../../../utils'



export default function StripeForm(props: StripeFormProps) {
  const { plan, planId, type } = props
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useReduxDispatch()
  const [loading, setLoading] = useState(false)
  const [isStripeLoading, setIsStripLoading] = useState(true)
  const { data: dealPackageData } = useReduxSelector(state => state.package.packageDealByType)
  const { endTime, dealEndDateTime } = usePackDealHoursDiff()


  useEffect(() => {
    dispatch(handleCheckoutModal({
      status: isStripeLoading || loading,
    }))

  }, [isStripeLoading, loading])

  useEffect(() => {
    if (!elements) return
    const element = elements.getElement('payment')
    element?.on('ready', () => setIsStripLoading(false))
  }, [elements])


  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault()
      if (!stripe || !elements) return

      const { error: submitError } = await elements.submit()
      if (submitError) return

      setLoading(true)

      const response = await dispatch(tenantPurchasePlanThunk({ planId, endTime: endTime, isDealOn: dealEndDateTime !== null ? true : false })).unwrap()
      await stripe.confirmPayment({
        elements,
        clientSecret: response.data.payment.client_secret,
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        confirmParams: {
          return_url: `${window.location.origin}?type=${type}`,
        }
      })
        .then(async function (result) {
          if (result?.error) {
            // This api status update for current payment
            if (result?.error?.message) {
              await dispatch(tenantPurchasePlanStatusThunk({ transactionId: response.data.transactionId, response: result?.error }))
              toast.error(result?.error?.message as string)
            }
            else {
              toast.error('Something went wrong')
            }
          }
        });
    }
    catch (error) { console.log(error) }
    finally {
      setLoading(false)
      // dispatch(handleCheckoutModal({
      //   status: false,
      //   selectPlanDetail: null
      // }))
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {isStripeLoading && <Spinner className='m-auto d-block my-3' variant='secondary' />}
      <Stack gap={3} className='mt-3'>
        {/*<Typography $variant='body2' className='fw-medium'>Rest assured, your payment is processed securely through our trusted payment gateway. For further details regarding payment terms, please refer to our Terms & Conditions. If you have any questions or concerns, our customer support team is here to assist you. Thank you for choosing Rentless.</Typography>*/}
        <Typography $variant='subtitle1' className='text-dark'>Total: ${dealEndDateTime !== null ? handleCalculateDiscountAndSavings(plan.price, dealPackageData?.ruleCriteria?.discountValue || 0).finalPrice : plan.price}</Typography>
        <Button className='w-100' type='submit' disabled={isStripeLoading} $loading={loading}>Purchase</Button>
      </Stack>
    </form>
  )
}
