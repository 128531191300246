import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { MdCheck, MdClose, MdInfoOutline } from "react-icons/md"

import { PlanDTO } from "../../../../../dtos/Plan.dto"
import { useReduxSelector } from "../../../../../hooks/redux"
import { handleCalculateDiscountAndSavings } from "../../../../../utils"
import Card from "../../../../../components/_ui/card/Card.component"
import Typography from "../../../../../components/_ui/typography/Typography.component"
import Button from "../../../../../components/_ui/button/Button.component"
import image_free from "../../../../../assets/images/packages/free.png"
import image_basic from "../../../../../assets/images/packages/basic.png"
import image_full from "../../../../../assets/images/packages/full.png"
import usePackDealHoursDiff from "../packageDeal/usePackDealHoursDiff.hook"


interface IPlanCardProps {
  item: PlanDTO
  handleSelectPlan: (item: PlanDTO) => void
}



function render_image(name: string) {
  if (name == 'Free') {
    return (<img src={image_free} />)
  }
  if (name == 'Basic') {
    return (<img src={image_basic} />)
  }
  if (name == 'Full') {
    return (<img src={image_full} />)
  }
}

const PlanCard = (props: IPlanCardProps) => {
  const { item, handleSelectPlan } = props
  const { dealEndDateTime } = usePackDealHoursDiff()
  const { data, status } = useReduxSelector(state => state.package.packageDealByType)

  return (
    <Card className={`d-flex flex-column align-items-center p-4 h-100 ${item.label !== null ? 'border border-2 border-primary' : 'shadow-none border border-1'}`}>

      <div className='w-100' style={{ height: 40 }}>
        {item.label !== null &&
          <Badge as={Typography} $variant='body1' className='text-white w-100 h-100'>Best Value</Badge>
        }
      </div>

      <div className='d-flex flex-column align-items-center py-4 text-center'>
        <Typography $variant='h4'>{item.name}</Typography>
        {
          dealEndDateTime != null && item.price ?
            <div>
              <Typography $variant='h2'>${handleCalculateDiscountAndSavings(item.price, data?.ruleCriteria?.discountValue || 0).finalPrice}</Typography>
              <Typography $variant='body1' className="text-decoration-line-through">was ${item.price}</Typography>
              <Typography $variant='body1' className="text-danger">save $ {handleCalculateDiscountAndSavings(item.price, data?.ruleCriteria?.discountValue || 0).saveAmount}</Typography>
              
            </div>
            : <Typography $variant='h2'>${item.price}</Typography>
        }
      </div>

      <div className='mb-4 d-flex flex-fill align-items-center'>
        <div className='border rounded-5 py-2 px-3 text-dark'>{item.planType}</div>
      </div>
      
      <div className='mb-4 p-3 d-flex flex-fill align-items-center'>{render_image(item.name)}</div>

      <Button className={`w-100 btn btn-primary`} $variant={(item.price ? 'filled' : 'outlined')}
        onClick={() => handleSelectPlan(item)}>
        {item.price == 0 ? 'Stay Free for Now' : `Buy ${item.name}`}
      </Button>

      <div className='mt-4 text-center'>
        <Typography $variant='subtitle2'>{item.description}</Typography>
      </div>

      <div className='mt-4 w-100'>
        <ul className="list-group list-group-flush">
          {item?.info && item.info.map((infoItem, index) => (
            <li key={index} className="list-group-item d-flex gap-2 align-items-center">
              {infoItem.allowed ? <MdCheck color='14b514' size={19} /> : <MdClose color='e50a0a' size={19} />}
              {infoItem.label}
              {infoItem.label == '30 day extension' && (<OverlayTrigger overlay={<Tooltip>If you don't find a home within 30 days, we will give you an additional 30 days extension.</Tooltip>}>
                <span className='fs-5'>
                  <MdInfoOutline />
                </span>
              </OverlayTrigger>)}
              
            </li>
          ))}
        </ul>
      </div>

    </Card>
  )
}


export default PlanCard