import * as yup from 'yup'
import { useNavigate } from "react-router-dom"
import { Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'

import { getProfileThunk } from "../../../../lib/redux/actions/auth.action"
import { useReduxDispatch, useReduxSelector } from "../../../../hooks/redux"
import { THUNK_STATUS } from "../../../../lib/redux/constants/status.constant"
import { updateTenantProfileThunk } from "../../../../lib/redux/actions/user.action"
import Layout from "../../../../components/_layout/Layout.component"
import Button from "../../../../components/_ui/button/Button.component"
import useIsExtactUrlMatch from "../../hooks/useIsExtactUrlMatch.hook"
import background_image from '../../../../assets/images/register/background_003.jpg';
import OnBoardHeading from "../../../../components/authLayout/onBoardHeading/OnBoardHeading.component"
import OnBoardProfileLayout from "../../../../components/authLayout/onBoardProfileLayout/OnBoardProfileLayout.component"
import { setFindHomeSignupTenantFormData } from '../../../../lib/redux/slices/auth.slice'
import useFindHomeSignupRedirection from '../../hooks/useFindHomeSignupRedirection.hook'



export default function ProfileOnBoardStep5() {
  useFindHomeSignupRedirection()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  let matchUrlPath = useIsExtactUrlMatch('/onboard/tenant')


  const { user, findHomeSignupTenantFormData } = useReduxSelector(state => state.auth)
  const { status } = useReduxSelector(state => state.user.updateTenantProfile)
  // const { formData } = useReduxSelector(state => state.user.profileOnboard)


  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentStatus: user?.profile?.currentStatus || findHomeSignupTenantFormData.formData.profile.currentStatus || ''
    }
  })


  const onSubmit = async (data: FormData) => {
    if (!matchUrlPath && user?._id) {
      dispatch(updateTenantProfileThunk({ ...user?.profile, ...data })).unwrap().then(() => {
        dispatch(getProfileThunk()).unwrap().then(() => {
          navigate('/')
        }).catch((error) => console.log('error', error))
      }).catch((error) => console.log('error', error))
    }
    else {
      dispatch(setFindHomeSignupTenantFormData({ activeStep: 6, formData: { profile: { ...data } } }))
      navigate('/auth/register/tenant')
    }
    // dispatch(setprofileOnboardFormData(data))
    // navigate('/tenant/profile/step-6')
  }


  return (
    <Layout title='Tenant Step 5' user={!matchUrlPath} header={false} footer={false} feedbackShow={false}>
      <OnBoardProfileLayout
        progress={matchUrlPath ? "75" : "50"}
        columnFirst={
          <>
            {/* Heading */}
            <OnBoardHeading heading='Current Status' description='What are your current living arrangements?' />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Select isInvalid={Boolean(errors.currentStatus)} {...register('currentStatus')}>
                <option value='' disabled>Please select one...</option>
                <option value='living_with_parents'>Living with parents</option>
                <option value='living_in_a_rent'>Living in a rental</option>
                <option value='living_in_own_house'>Living in my own house</option>
                <option value='living_with_friends'>Living with friends</option>
                <option value='homeless'>Homeless</option>
              </Form.Select>

              <div className="d-flex justify-content-end mt-4">
                <Button $variant='filled' type='submit' $loading={status === THUNK_STATUS.LOADING}>Continue</Button>
              </div>
            </Form>
          </>
        }
        columnSecond={<div className='h-100 w-100' style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}></div>}
      />
    </Layout>
  )
}



{/* == Validation == */ }
const schema = yup.object({
  currentStatus: yup.string().trim().required().max(100)
})

type FormData = yup.InferType<typeof schema>
