import { Stack } from 'react-bootstrap'
import { ProfileWrapper, SidebarWrapper } from './ChatSidebar.style'
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux'
import { selectSidebarChat } from '../../../../lib/redux/slices/chat.slice'
import { MdOutlineHomeWork, MdOutlineLocationOn } from "react-icons/md"
import Typography from '../../../../components/_ui/typography/Typography.component'
import Avatar from '../../../../components/avatar/Avatar.component'
import SearchInput from '../../../../components/_ui/searchInput/SearchInput.component'



export default function ChatSidebar() {
  const dispatch = useReduxDispatch()
  const { chatTenantShotList } = useReduxSelector(state => state.chat)
  const { chatSelectData } = useReduxSelector(state => state.chat)


  const handleSelectChat = (item: any) => {
    dispatch(selectSidebarChat(item))
  }


  const handleChange = (newValue: string) => {
    // console.log(newValue)
  }
  // console.log(chatTenantShotList, chatSelectData)


  return (
    <SidebarWrapper className='d-flex flex-column gap-3'>

      {/* Search */}
      <SearchInput onChange={handleChange} className='position-sticky top-0' style={{ boxShadow: '0 -50px 0 50px white' }} />

      {/* List */}
      <Stack>
        {chatTenantShotList?.data &&
          <>
            {chatTenantShotList?.data?.map((item: any, index: number) => (
              <ProfileWrapper className='d-flex py-3 px-3 align-items-center gap-2' active={Boolean(item.property_id === chatSelectData?.data?.property_id) ? 'true' : 'false'} key={index} onClick={() => handleSelectChat(item)}>
                <Avatar $icon={<MdOutlineHomeWork />} className='bg-secondary-subtle text-dark-emphasis flex-shrink-0' />
                <div className='d-flex flex-column gap-1 w-100 overflow-hidden'>
                  <Typography $variant='h6' className='m-0 text-nowrap text-overflow-ellipsis w-100 overflow-hidden'>{item.name}</Typography>
                  <Typography $variant='body2' className='fw-medium text-nowrap text-overflow-ellipsis w-100 overflow-hidden'><MdOutlineLocationOn className='flex-shrink-0' />{item?.property_name || item?.address?.name}</Typography>
                </div>
              </ProfileWrapper>
            ))}
          </>
        }
      </Stack>

    </SidebarWrapper >
  )
}
