import { Container, Row, Col, Stack, Form, Spinner } from 'react-bootstrap'
import { useEffect } from 'react'
import { useReduxDispatch, useReduxSelector } from '../../hooks/redux'
import { chatPropertyShortlistedThunk, chatTenantShortListThunk } from '../../lib/redux/actions/chat.action'
import { selectSidebarChat } from '../../lib/redux/slices/chat.slice'
import { THUNK_STATUS } from '../../lib/redux/constants/status.constant'
import Layout from "../../components/_layout/Layout.component"
import ChatSidebar from './components/chatSidebar/ChatSidebar.component'
import ChatBox from './components/chatView/ChatBox.component'
import NoRecordFound from '../../components/_ui/noRecordFound/NoRecordFound.component'
import Alert from '../../components/_ui/alert/Alert.component'
import Typography from '../../components/_ui/typography/Typography.component'
import MsgImg from '../../assets/images/messages.svg'



export default function Messages() {

  const dispatch = useReduxDispatch()
  const { chatTenantShotList } = useReduxSelector(state => state.chat)
  const { userRoles } = useReduxSelector(state => state.auth)

  useEffect(() => {
    if (userRoles.includes('tenant')) {
      dispatch(chatPropertyShortlistedThunk({ page: 1, size: 1000 })).unwrap()
        .then((response) => {
          let item: any = response?.data?.result[0]
          let useAbleData = { ...item?.property?.userId, ...item?.property?.address, property_id: item?.property?._id }
          dispatch(selectSidebarChat(useAbleData))
        })
        .catch((err) => console.log(err))
    }
    else {
      dispatch(chatTenantShortListThunk({ page: 1, size: 1000 })).unwrap()
        .then((response) => {
          let item: any = response?.data?.result[0]
          let useAbleData = { ...item, property_id: item?.property?._id || item?.property_id }
          dispatch(selectSidebarChat(useAbleData))
        })
        .catch((err) => console.log(err))
    }
  }, [])


  return (
    <Layout title='Messages' user={true}>
      <Container>

        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">My Messages</Typography>
          <Typography $variant='body1'>Chat directly with landlords who like your profile</Typography>
        </div>

        {Boolean(chatTenantShotList.data.length) && <Row className='row-gap-5'>

          <Col xs={12} md={4}>
            <ChatSidebar />
          </Col>

          <Col xs={12} md={8}>
            <ChatBox />
          </Col>
        </Row>
        }

        {chatTenantShotList.status === THUNK_STATUS.SUCCESS && !Boolean(chatTenantShotList.data.length) &&
          <NoRecordFound image={MsgImg} className='text-center w-100'>
            <Typography $variant='h4'>Conversation</Typography>
            <Typography $variant='body1'>No records found</Typography>
          </NoRecordFound>
        }
        {chatTenantShotList.status === THUNK_STATUS.FAILED && <Alert $variant='danger'>Something went wrong</Alert>}
        {chatTenantShotList.status === THUNK_STATUS.LOADING && <Spinner className='m-auto mt-3 d-block' variant='secondary' />}
      </Container>
    </Layout>
  )
}
