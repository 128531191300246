import { yupResolver } from '@hookform/resolvers/yup'
import { MdInfoOutline, MdClose } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { Tooltip, OverlayTrigger, Stack, FloatingLabel, Row, Col, InputGroup, Form } from 'react-bootstrap'
import toast from 'react-hot-toast'

import { debounce } from '../../../../../utils/debonce'
import { FormDataType, schema } from './ProfileForm.config'
import { ProfileFormProps } from './ProfileForm.interface'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { SwitchInput } from '../../../notifications/SwitchInput.style'
import { getProfileThunk } from '../../../../../lib/redux/actions/auth.action'
import { uploadFileThunk } from '../../../../../lib/redux/actions/upload.action'
import { useReduxDispatch, useReduxSelector } from '../../../../../hooks/redux'
import { updateTenantProfileThunk } from '../../../../../lib/redux/actions/user.action'
import { removeFirstSimilarItemFromArray, formatCurrency, hasFileInstance } from '../../../../../utils'
import { ConfirmatonPopup } from '../../../../../components/modalPopup/confirmation/ConfirmationPopup.component'
import { DataConfirmationTypes } from '../../../../../components/modalPopup/confirmation/ConfirmatonPopupProps.interface'
import Card from '../../../../../components/_ui/card/Card.component'
import Button from '../../../../../components/_ui/button/Button.component'
import Typography from '../../../../../components/_ui/typography/Typography.component'
import PhoneInput from '../../../../../components/_ui/phoneInput/PhoneInput.component'
import MapAutocomplete from '../../../../../components/_ui/mapAutocomplete/MapAutocomplete.component'
import TenantProfileUpload from '../tenantProfileUpload/TenantProfileUpload.component'



export default function ProfileForm(props: ProfileFormProps) {
  const dispatch = useReduxDispatch()
  const { user } = useReduxSelector(state => state.auth)
  const { getAllOtherInfomationUser } = useReduxSelector(state => state.user)
  const [confirmationShow, setconfirmationShow] = useState(false)
  const [loading, setLoading] = useState({
    form: false,
    // address: false
  })


  const dataConfirmation: DataConfirmationTypes = {
    heading: 'Update profile confirmation',
    description: 'Are you sure you want to update the profile?',
    buttonName: 'Ok'
  }


  useEffect(() => { trigger() }, [])


  const { handleSubmit, register, control, watch, setValue, getValues, trigger, formState: { isValid, errors, isDirty, isSubmitted } } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      profilePhotos: user?.profile?.profilePhotos || [],
      description: user?.profile?.description,
      suburb: user?.profile?.suburb as any || [],
      weeklyBudget: formatCurrency(user?.profile?.weeklyBudget as string),
      familyMembers: user?.profile?.familyMembers,
      isShareHouse: user?.profile?.isShareHouse,
      currentStatus: user?.profile?.currentStatus,
      vehicles: user?.profile?.vehicles || [],
      salary: {
        earn: formatCurrency(user?.profile?.salary.earn as string) || '',
        type: user?.profile?.salary.type
      },
      references: user?.profile?.references || [],
      otherInformation: user?.profile?.otherInformation || [],
      movingDate: user?.profile?.movingDate || undefined,
    },
  })


  const vechiclesField = useFieldArray({ name: 'vehicles', control })
  const referencesField = useFieldArray({ name: 'references', control })


  const handleSuburbSelected = async (data: any) => {
    try {
      let finalData: any = []
      for (const item of data) {
        if (item.value.reference) {
          let postcode = null;
          let name = null;
          const [response] = await geocodeByPlaceId(item.value.place_id)
          for (const component of response.address_components) {
            if (component.types.includes('postal_code')) {
              postcode = component.long_name || null
            }
            if (component.types.includes('locality') && name == null) {
              name = component.long_name || null
            }
            else if (component.types.includes("administrative_area_level_1") && name == null) {
              name = component.long_name || null
            }
          }
          finalData.push({ name, postcode, placeId: item?.value?.place_id })

          // if (Boolean(finalData.find((item: any) => item.postcode == null))) toast('There is no postal code associated with the suburb', { icon: '❌' })
        }
        else {
          finalData.push({ name: item?.value?.name, postcode: item?.value?.postcode, placeId: item?.value?.placeId })
        }
      }

      finalData = removeFirstSimilarItemFromArray(finalData)
      // .filter((item: any) => item.postcode !== null)
      setValue('suburb', finalData)
      if (isSubmitted) trigger('suburb')
    }
    catch (error) {
      console.log('Google Map API Error @geocodeByPlaceId: ', error)
      toast.error('Something went wrong with address')
    }
  }

  // if you have file then image is upload on the server function 
  async function handleImageUpload() {
    let finalImages: string[] = []
    const formData = new FormData()
    formData.append('folder', 'TenantImages')

    getValues('profilePhotos')?.forEach((item: any) => (item instanceof File) ? formData.append('files', item) : finalImages.push(item))

    if (formData.getAll('files')?.length > 0) {
      const uploadResponse = await dispatch(uploadFileThunk(formData)).unwrap()
      finalImages = await finalImages.concat(uploadResponse.data.urls)
    }
    return finalImages
  }


  // final data push in backend
  const finalDataPush = async () => {
    const finalData = getValues()
    await dispatch(updateTenantProfileThunk(finalData))
    dispatch(getProfileThunk())
  }


  const onSubmit = async (data: FormDataType) => {
    try {
      setLoading(items => ({ ...items, form: true }))
      const finalImages = await handleImageUpload()
      setValue('profilePhotos', finalImages)
      setconfirmationShow(true)
    }
    catch (error) { }
    finally { setLoading(items => ({ ...items, form: false })) }
  }


  const autoSaveFormData = async () => {
    try {
      setLoading(items => ({ ...items, form: true }))
      const finalImages = await handleImageUpload()
      setValue('profilePhotos', finalImages)
      await finalDataPush()
      setLoading(items => ({ ...items, form: false }))
    }
    catch (err) {
      console.log('Auto Save form error')
    }
    finally { setLoading(items => ({ ...items, form: false })) }
  }


  const saveFormDataDebounce = debounce(async (data: any) => {
    const saveDataToast = toast.loading('Saving...');
    try {
      await schema.validate(data)
      await autoSaveFormData()
    }
    catch (err) { console.log('form error', err); setLoading(items => ({ ...items, form: false })) }
    toast.dismiss(saveDataToast);
  }, 3000)


  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (isValid && isDirty && name !== 'profilePhotos') {
        saveFormDataDebounce(value)
      }
    })

    return () => { subscription.unsubscribe() }
  }, [watch, isValid, isDirty])


  return (
    <Stack gap={4} as='form' noValidate onSubmit={handleSubmit(onSubmit)}>

      {/* Profile Photos */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Profile Photos</Typography>
          <OverlayTrigger overlay={<Tooltip>Upload some photos or your family, house, cars or anything you wish to show your new landlord.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <TenantProfileUpload name='profilePhotos' control={control} />

        {hasFileInstance(watch('profilePhotos')) ?
          <div className='d-flex justify-content-end mt-3' >
            <Button className='px-5' type='button' $loading={loading.form} onClick={() => autoSaveFormData()}>Save</Button>
          </div> : null
        }
      </Card>


      {/* Description */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Description</Typography>
          <OverlayTrigger overlay={<Tooltip>Remeber to put as much information about yourself to give the landlord a clear picture on who will be living in their property.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <FloatingLabel controlId='description' label='Write a short description about yourself and family...'>
          <Form.Control as='textarea' placeholder='ignore' isInvalid={Boolean(errors.description)} {...register('description')} style={{ height: 225 }} />
        </FloatingLabel>
        <Form.Text className='text-danger'>{errors.description?.message}</Form.Text>
      </Card>


      {/* Location */}
      <Card className='position-relative z-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Location</Typography>
          <OverlayTrigger overlay={<Tooltip>Enter suburb names of where you would like to live, enter as many as you like to increase the chance of finding a home faster.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <Row>
          {/* <Col xs={12} md={6}>
            <Form.Group controlId='location'>
              <Form.Label>Search for where you want to live</Form.Label>
              <Controller
                name="address.name"
                control={control}
                render={({ field: { value, onChange, ...items } }) => (
                  <MapAutocomplete
                    isInvalid={Boolean(errors.address?.name)}
                    selectProps={{
                      ...items as any,
                      value: value ? { label: value, value: { place_id: value } } : undefined,
                      defaultOptions: value ? [{ label: value, value: { place_id: value } }] : undefined,
                      onChange: handlePlaceSelected,
                      isClearable: true,
                      isLoading: loading.address,
                    }}
                  />
                )}
              />
              <Form.Text className='text-danger'>{errors.address?.name?.message}</Form.Text>
            </Form.Group>
          </Col> */}
          <Col xs={12} md={12}>
            <Form.Group controlId='distance'>
              <Form.Label>Select suburb where you want to live</Form.Label>
              <Controller
                name="suburb"
                control={control}
                render={({ field: { value, onChange, ...items } }) => (
                  <MapAutocomplete
                    isInvalid={Boolean(errors.suburb)}
                    autocompletionRequest={{
                      types: ['geocode'],
                      componentRestrictions: {
                        country: 'au'
                      },
                    }}
                    selectProps={{
                      ...items as any,
                      value: value ? value.map(item => ({ label: item.name, value: { place_id: item.name, ...item } })) : undefined,
                      defaultOptions: value ? value.map(item => ({ label: item.name, value: { place_id: item.name, ...item } })) : undefined,
                      onChange: handleSuburbSelected,
                      isMulti: true as any,
                      isClearable: false,
                    }}
                  />
                )}
              />
              <Form.Text className='text-danger'>{errors.suburb?.message}</Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Card>


      {/* Weekly Budget */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Weekly Budget</Typography>
          <OverlayTrigger overlay={<Tooltip>Set yourself a weekly budget on how much you're willing to spend on rent.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <Form.Group controlId='weeklyBudget'>
          <Form.Label>What is the maximum you would like to spend per week?</Form.Label>
          <InputGroup>
            <InputGroup.Text className='fw-medium'>$</InputGroup.Text>
            <Form.Control type='string' isInvalid={Boolean(errors.weeklyBudget)} {...register('weeklyBudget')} value={formatCurrency(watch('weeklyBudget') as string)} />
          </InputGroup>
          <Form.Text className='text-danger'>{errors.weeklyBudget?.message}</Form.Text>
        </Form.Group>
      </Card>


      {/* Family Members */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Family Members</Typography>
          <OverlayTrigger overlay={<Tooltip>Enter the number of people that will be living in the property, you can include your pets as well.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <Row className='gap-2 gap-md-0'>
          <Col xs={12} md={3}>
            <div>
              <FloatingLabel controlId='familyMembers.adults' label='Adults'>
                <Form.Control type='number' placeholder='1' isInvalid={Boolean(errors.familyMembers?.adults)} {...register('familyMembers.adults')} />
              </FloatingLabel>
              <Form.Text className='text-danger'>{errors.familyMembers?.adults?.message}</Form.Text>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div>
              <FloatingLabel controlId='familyMembers.children' label='Children'>
                <Form.Control type='number' placeholder='0' isInvalid={Boolean(errors.familyMembers?.children)} {...register('familyMembers.children')} />
              </FloatingLabel>
              <Form.Text className='text-danger'>{errors.familyMembers?.children?.message}</Form.Text>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div>
              <FloatingLabel controlId='dogs' label='Dogs'>
                <Form.Control type='number' placeholder='0' isInvalid={Boolean(errors.familyMembers?.dogs)} {...register('familyMembers.dogs')} />
              </FloatingLabel>
              <Form.Text className='text-danger'>{errors.familyMembers?.dogs?.message}</Form.Text>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div>
              <FloatingLabel controlId='familyMembers.cats' label='Cats'>
                <Form.Control type='number' placeholder='0' isInvalid={Boolean(errors.familyMembers?.cats)} {...register('familyMembers.cats')} />
              </FloatingLabel>
              <Form.Text className='text-danger'>{errors.familyMembers?.cats?.message}</Form.Text>
            </div>
          </Col>
        </Row>
      </Card>


      {/* Share House */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Joint Tenancy</Typography>
        </div>

        <Form.Group controlId='isShareHouse'>
          <Form.Label>Enable joint tenancy if you would like to be matched with another tenant looking for a house in the same area. This will help increase the chance of securing a home while lowering your weekly rent.</Form.Label>
          {/* Enable Share House< */}
          <div className='d-flex gap-3 card flex-row p-3 mt-2 bg-light'>
            <SwitchInput type="switch" id="custom-switch" {...register('isShareHouse')} />
            <Typography>Joint Tenancy</Typography>
          </div>
        </Form.Group>
      </Card>


      {/* Moving Date */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Moving Date</Typography>
        </div>

        <Form.Group className="w-100" controlId="moving date">
          <Controller name="movingDate" control={control} render={({ field }) => (
            <Form.Control type='date' placeholder='Moving Date' {...field} isInvalid={Boolean(errors.movingDate?.message)} />
          )} />
          <Form.Text className='text-danger'>{errors.movingDate?.message}</Form.Text>
        </Form.Group>
      </Card>


      {/* Other Info  */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Other Infomataion</Typography>
          <OverlayTrigger overlay={<Tooltip>Select a list of features you would like in your new home.</Tooltip>}>
            <span className='fs-5'><MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>
        <Form.Group controlId='otherInformation'>
          <Controller name="otherInformation" control={control}
            render={({ field: { value, ref, onChange, ...rest } }) => (
              <Row>
                {getAllOtherInfomationUser && getAllOtherInfomationUser?.data?.map((item: { title: string, _id: string }) => (
                  <Col xs={12} sm={4}>
                    <Form.Check inline type='checkbox' key={item._id} ref={ref} id={item.title} label={item.title} value={item.title}
                      checked={(value || []).includes(item.title)}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        const updatedValue = (value || []).includes(selectedValue)
                          ? (value || []).filter((val: string) => val !== selectedValue)
                          : [...(value || []), selectedValue];
                        onChange(updatedValue);
                      }}
                    />
                  </Col>
                ))}
              </Row>
            )} />
          {/* {errors.otherInformation && (<Form.Text className='text-danger'>{errors.otherInformation?.message}</Form.Text>)} */}
        </Form.Group>
      </Card>


      {/* Vehicles */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Vehicles</Typography>
          <OverlayTrigger overlay={<Tooltip>It is important to enter your vehicles so we can find you houses with enough car spaces.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        {vechiclesField.fields.map((item, index) =>
          <Row className='mb-3 animate__animated animate__fadeInUp animate__faster gap-2 gap-md-0' key={item.id}>
            <Col xs={12} md={4}>
              <div>
                <FloatingLabel controlId={`vehicles.${index}.model`} label='Model'>
                  <Form.Control type='text' placeholder='ignore' isInvalid={Boolean(errors.vehicles?.[index]?.model)} {...register(`vehicles.${index}.model` as const)} />
                </FloatingLabel>
                <Form.Text className='text-danger'>{errors.vehicles?.[index]?.model?.message}</Form.Text>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div>
                <FloatingLabel controlId={`vehicles.${index}.color`} label='Color'>
                  <Form.Control type='text' placeholder='ignore' isInvalid={Boolean(errors.vehicles?.[index]?.color)} {...register(`vehicles.${index}.color` as const)} />
                </FloatingLabel>
                <Form.Text className='text-danger'>{errors.vehicles?.[index]?.color?.message}</Form.Text>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='d-flex flex-column flex-md-row justify-content-md-center gap-2'>
                <div>
                  <FloatingLabel controlId={`vehicles.${index}.vehicleNumber`} label='Vehicle No.'>
                    <Form.Control type='text' placeholder='ignore' isInvalid={Boolean(errors.vehicles?.[index]?.vehicleNumber)} {...register(`vehicles.${index}.vehicleNumber` as const)} />
                  </FloatingLabel>
                  <Form.Text className='text-danger'>{errors.vehicles?.[index]?.vehicleNumber?.message}</Form.Text>
                </div>

                <div className='align-self-end align-self-md-center'>
                  <Button $icon $variant='outlined' type='button' onClick={() => vechiclesField.remove(index)}>
                    <MdClose />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Stack gap={3}>
          <Button $variant='text' className='fw-medium' type='button' onClick={() => vechiclesField.append({ model: '', color: '', vehicleNumber: '' })}>Add Vehicle</Button>
        </Stack>
      </Card>


      {/* Salary */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Salary</Typography>
          <OverlayTrigger overlay={<Tooltip>Enter the household combined income, this will be verified later when signing a lease.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <Row className='align-items-end gap-3 gap-md-0'>
          <Col xs={12} md={6}>
            <Form.Group controlId='earn'>
              <Form.Label>How much do you earn?</Form.Label>
              <InputGroup>
                <InputGroup.Text className='fw-medium'>$</InputGroup.Text>
                <Form.Control type='string' size='sm' isInvalid={Boolean(errors.salary?.earn)} {...register('salary.earn')} value={formatCurrency(watch('salary.earn') as string) || ''} />
              </InputGroup>
              <Form.Text className='text-danger'>{errors.salary?.earn?.message}</Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId='salary-type'>
              <Form.Select isInvalid={Boolean(errors.salary?.type)} {...register('salary.type')}>
                <option value='per_year'>Per year</option>
                <option value='per_month'>Per month</option>
                <option value='per_day'>Per day</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Card>


      {/* Current Status */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>Current Status</Typography>
          <OverlayTrigger overlay={<Tooltip>What are your current living arrangements.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        <Form.Select isInvalid={Boolean(errors.currentStatus)} {...register('currentStatus')}>
          <option value='living_with_parents'>Living with parents</option>
          <option value='living_in_a_rent'>Living in a rental</option>
          <option value='living_in_own_house'>Living in my own house</option>
          <option value='living_with_friends'>Living with friends</option>
          <option value='homeless'>Homeless</option>
        </Form.Select>
      </Card>


      {/* References */}
      <Card>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <Typography $variant='h5'>References</Typography>
          <OverlayTrigger overlay={<Tooltip>Add as many refrences as you can, we will call them during the verification process later.</Tooltip>}>
            <span className='fs-5'>
              <MdInfoOutline />
            </span>
          </OverlayTrigger>
        </div>

        {referencesField.fields.map((item, index) =>
          <Row className='mb-3 animate__animated animate__fadeInUp animate__faster' key={item.id}>
            <Col xs={12} sm={5}>
              <Form.Group controlId={`name-input-${index}`}>
                <Form.Label>Name</Form.Label>
                <Form.Control type='text' placeholder='' isInvalid={Boolean(errors.references?.[index]?.name)} {...register(`references.${index}.name` as const)} />
                <Form.Text className='text-danger'>{errors.references?.[index]?.name?.message}</Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} sm={5}>
              <Form.Group className='w-100' controlId={`phone-input-${index}`}>
                <Form.Label>Phone</Form.Label>
                <PhoneInput
                  value={String(watch(`references.${index}.number` as const))}
                  className={`form-control ${errors.references?.[index]?.number ? 'is-invalid' : ''}`}
                  onChange={(value: string) => {
                    setValue(`references.${index}.number` as any, value)
                    trigger('references')
                  }}
                  id={`phone-input-${index}`}
                />
                <Form.Text className='text-danger'>{errors.references?.[index]?.number?.message}</Form.Text>
              </Form.Group>
            </Col>
            <Col xs='auto' className='pt-3' >
              <Button $icon $variant='outlined' className='mt-3' type='button' onClick={() => referencesField.remove(index)}>
                <MdClose />
              </Button>
            </Col>
          </Row>
        )}

        <Stack gap={3}>
          <Button $variant='text' className='fw-medium' type='button' onClick={() => referencesField.append({ name: '', number: null })}>Add Reference</Button>
        </Stack>
      </Card>


      <div className='d-flex justify-content-end mt-3'>
        <Button className='px-5' type='submit' $loading={loading.form}>Save</Button>
      </div>


      {/* Confirmation pop-up */}
      <ConfirmatonPopup data={dataConfirmation} show={confirmationShow} onHide={() => setconfirmationShow(false)} callBack={() => finalDataPush()} />


    </Stack >
  )
}
