import { useEffect, useState } from 'react'
import { THUNK_STATUS } from '../../lib/redux/constants/status.constant'
import { useReduxDispatch, useReduxSelector } from '../../hooks/redux'
import { Container, Row, Col, OverlayTrigger, Tooltip, Stack, Spinner, Badge, Alert } from 'react-bootstrap'
import { addTenantInShortlistThunk } from '../../lib/redux/actions/property.action'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { MdVerified } from "react-icons/md"
import { tenantAcceptThunk, tenantByIdThunk, tenantRejectThunk, tenantView } from '../../lib/redux/actions/user.action'
import { useParams } from 'react-router'
import { convertToTitleCase, getPdfName } from '../../utils'
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { DataConfirmationTypes } from '../../components/modalPopup/confirmation/ConfirmatonPopupProps.interface'
import { ConfirmatonPopup } from '../../components/modalPopup/confirmation/ConfirmationPopup.component'
import Typography from "../../components/_ui/typography/Typography.component"
import Layout from "../../components/_layout/Layout.component"
import Card from '../../components/_ui/card/Card.component'
import toast from 'react-hot-toast'
import Button from '../../components/_ui/button/Button.component'
import placeholderImage from '../../assets/images/placeholder.jpg'
import moment from 'moment'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css'
import Attachment from '../admin/accounts/components/adminTenantDetail/components/Attachment.component'
import { StyledUploadImage } from '../settings/tenant-profile/component/profileForm/ProfileForm.style'
import { BsFillFilePdfFill } from 'react-icons/bs'
import UserInfo from '../dashboard/components/profileListing/UserInfo'
import Gallery from '../dashboard/components/profileListing/Gallery'
import ProfilePlaceholder from '../../assets/images/profile-placeholder.svg'


export default function Profile() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { tenantId } = useParams()
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const [searchParams] = useSearchParams()
  const [confirmationShow, setconfirmationShow] = useState(false)
  const [confirmationShortlistShow, setconfirmationShortlistShow] = useState(false)
  const propertyId = searchParams.get('propertyId')

  const { userRoles } = useReduxSelector(state => state?.auth)
  const { status, data } = useReduxSelector(state => state.user.tenantById)
  const { addTenantInShortlist } = useReduxSelector(state => state.property)
  const { acceptTenant, rejectTenant } = useReduxSelector(state => state.user)

  const sliderImage = Boolean(data?.profile.profilePhotos.length) ? data?.profile.profilePhotos : [placeholderImage]
  let tenantPostParams: IParamsTenantById = { id: tenantId as string }
  if (propertyId) { tenantPostParams = { id: tenantId as string, propertyId: propertyId as string } }



  const dataConfirmation: DataConfirmationTypes = {
    heading: 'Accept Confirmation',
    description: 'Are you sure you want to accept this tenant?',
    buttonName: 'Accept Tenant'
  }


  const dataShortlistConfirmation: DataConfirmationTypes = {
    heading: 'Shortlist Confirmation',
    description: 'Are you sure you want to shortlist this tenant?',
    buttonName: 'Shortlist Tenant'
  }


  const goBack = () => navigate(`/properties/tenants/${propertyId}`)


  useEffect(() => {
    dispatch(tenantByIdThunk(tenantPostParams))

    if (propertyId && tenantId) {
      dispatch(tenantView({ tenantId, propertyId }))
    }
  }, [])


  const displayPdf = (item: any) => {
    console.log(item)
    if (Boolean(item && item?.type)) {
      const fileURL = URL.createObjectURL(item);
      window.open(fileURL, '_target');
    } else {
      window.open(item, '_target');
    }
  };


  const handleShortlist = () => {
    if (propertyId && tenantId) {
      dispatch(addTenantInShortlistThunk({ propertyId, tenantId })).unwrap()
        .then(() => {
          toast.success('Shortlisted Tenant')
          goBack()
        })
        .catch(err => console.log(err))
    }
    else toast.error('propertyId is missing')
  }


  const handleAcceptOrReject = (isAccept: string) => {
    if (propertyId && tenantId) {
      if (isAccept === 'true') {
        dispatch(tenantAcceptThunk({ id: tenantId, propertyId, status: isAccept })).unwrap()
          .then(() => {
            toast.success('Accept Tenant')
            dispatch(tenantByIdThunk(tenantPostParams));
            navigate(`/properties/agent/${propertyId}?tenantId=${tenantId}`)
          })
          .catch(err => console.log(err))
      }
      else {
        dispatch(tenantRejectThunk({ id: tenantId, propertyId, status: isAccept })).unwrap()
          .then(() => {
            toast.success('Reject Tenant')
            dispatch(tenantByIdThunk(tenantPostParams));
          })
          .catch(err => console.log(err))
      }
    }
    else toast.error('propertyId is missing')
  }


  console.log(data)

  return (
    <Layout title='Tenant Details' user={true}>
      <Container>
        <Card>

        {status === THUNK_STATUS.SUCCESS && data &&
          <div>
            {userRoles.includes('landlord') && <div className='bg-body-tertiary mb-4 p-3 rounded-3 d-flex gap-3 justify-content-end'>
              {/* <Button $variant='outlined' className='shadow-none border-light-subtle' onClick={() => handleAcceptOrReject('false')} $loading={rejectTenant.status === THUNK_STATUS.LOADING}>Reject Tenant</Button> */}
              {
                data?.isShortListed && !data?.profile?.isOccupied &&
                <Button $variant='outlined' className='shadow-none border-light-subtle' onClick={() => setconfirmationShow(true)}
                  $loading={acceptTenant.status === THUNK_STATUS.LOADING}>
                  Accept Tenant
                </Button>
              }
              {
                !data?.isShortListed && !data?.profile?.isOccupied &&
                <Button $variant='outlined' className='shadow-none border-light-subtle' onClick={() => setconfirmationShortlistShow(true)}
                  $loading={addTenantInShortlist.status === THUNK_STATUS.LOADING}>
                  Shortlist Tenant
                </Button>
              }
            </div>
            }

            <Row className='mb-3'>
              <Col xs={12} md={12}>
                {
                  Boolean(data?.profile?.profilePhotos?.length)
                    ? <Gallery payload={data?.profile?.profilePhotos} />
                    : <img src={ProfilePlaceholder} className='w-100 h-100 rounded border border-light-subtle' style={{ maxHeight: 'max(100px, calc(100vh - 300px))' }} />
                }
              </Col>
            </Row>

            <Row className='g-5'>
              <Col xs={12} md={Boolean(data?.profile?.adminAttachments?.length) ? 8 : 12}>
                <UserInfo payload={data?.profile} info={data} />
              </Col>


              <Col xs={12} md={4}>
                {Boolean(data?.profile?.adminAttachments?.length) && Boolean(userRoles.includes('landlord')) &&
                  <Card className="position-sticky top-15">
                    <div>
                      <Typography $variant='subtitle1' className='text-dark'>Attachments</Typography>
                      {data?.profile?.adminAttachments?.map((item: string) => (
                        <div onClick={() => { displayPdf(item) }} className='cursor-pointer d-flex flex-column align-items-start justify-content-center'>
                          <Typography className='mt-3 line-2 text-blue underline-hover' >{getPdfName(item as string)}</Typography>
                        </div>
                      ))}
                    </div>
                  </Card>
                }
              </Col>
            </Row>
          </div>
          
        }

        {status === THUNK_STATUS.LOADING && <Spinner className='m-auto d-block' variant='secondary' />}
        {status === THUNK_STATUS.FAILED && <Alert variant='warning' className='text-center'>Something went wrong</Alert>}

        </Card>
      </Container>

      {/* Confirmation pop-up  shortlist and accept or reject */}
      <ConfirmatonPopup data={dataConfirmation} show={confirmationShow} onHide={() => setconfirmationShow(false)}
        callBack={() => handleAcceptOrReject('true')} />

      <ConfirmatonPopup data={dataShortlistConfirmation} show={confirmationShortlistShow} onHide={() => setconfirmationShortlistShow(false)} callBack={() => handleShortlist()} />

    </Layout >
  )
}


export interface IParamsTenantById { id: string; propertyId?: string }

