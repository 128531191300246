import { AdminLayoutProps } from './AdminLayout.interface'
import { Container, Row, Col } from 'react-bootstrap'
import SidebarNav from '../adminNav/SidebarNav.component'

export default function AdminLayout(props: AdminLayoutProps) {
  const { children, nav } = props

  return (
    <Container>
      <Row className='row-gap-4'>
        <Col xs={12} sm={4} lg={3} className='d-none d-md-block'>
          {nav}
        </Col>
        <Col xs={12} sm={8} lg={9}>
          {children}
        </Col>
      </Row>
    </Container>
  )
}
