import { Table, Spinner } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../../../hooks/redux';
import { propertyVerficationThunk } from '../../../../lib/redux/actions/admin.action';
import { THUNK_STATUS } from '../../../../lib/redux/constants/status.constant';
import { limitRowSize } from '../../../../data/config';
import Layout from '../../../../components/_layout/Layout.component'
import Typography from '../../../../components/_ui/typography/Typography.component'
import AdminLayout from "../../components/adminLayout/AdminLayout.component"
import VerifyNav from '../../components/verifyNav/Nav.component'

import Card from '../../../../components/_ui/card/Card.component'
import Alert from '../../../../components/_ui/alert/Alert.component';
import PaginationComponent from '../../../../components/pagination/PaginationComponent';
import Button from '../../../../components/_ui/button/Button.component';
import moment from 'moment';
import NoRecordFound from '../../../../components/_ui/noRecordFound/NoRecordFound.component';
import PropertyImg from '../../../../assets/images/property.svg'
import { convertToMelbourneTime } from '../../../../utils';

export default function PropertyVerifications() {
  let limitSize = limitRowSize
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useReduxDispatch()
  const { propertyVerification } = useReduxSelector(state => state.admin)


  useEffect(() => {
    dispatch(propertyVerficationThunk({ page: currentPage, size: limitSize })).unwrap()
      .then((response) => {
        setData(response?.data?.info?.result);
        setTotalPages(Math.ceil(response?.data?.info?.totalRecords / limitSize) || 0)
      })
      .catch(err => console.log(err))
  }, [currentPage])

  console.log(data)

  const handlePageChange = (page: number) => {
    console.log(page)
    setCurrentPage(page);

  };

  return (
    <Layout title='Property Verifications' user={true}>
      <AdminLayout nav={<VerifyNav />}>
        <div className='d-flex flex-column gap-0 mb-3'>
          <Typography $variant="h3">Property Verifications</Typography>
          <Typography $variant='body1'>List of properties that need to be validated</Typography>
        </div>

        <Card>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Property Address</th>
                <th>City</th>
                <th>Create Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {propertyVerification.status === THUNK_STATUS.SUCCESS &&
                data.map((item: any, index) => (
                  <tr key={index} >
                    <td className='max-width:300px'>{item?.address?.name || ''}</td>
                    <td>{item?.address?.city || ''}</td>
                    <td>{convertToMelbourneTime(item?.createdAt).format('L') || ''}</td>
                    <td><Button onClick={() => navigate(`${item?._id}`)} $variant='outlined'>View</Button></td>
                  </tr>
                ))
              }

            </tbody>
          </Table>

          <div className='d-flex justify-content-center mb-3'>
            {propertyVerification.status === THUNK_STATUS.SUCCESS && !Boolean(propertyVerification?.data?.length) &&
              <NoRecordFound shadow='shadow-none' image={PropertyImg} className='text-center w-100'>
                <Typography $variant='h4'>Property</Typography>
                <Typography $variant='body1'>No record found</Typography>
              </NoRecordFound>
            }
            {propertyVerification.status === THUNK_STATUS.LOADING && <Spinner className='m-auto my-3 d-block' variant='secondary' />}
            {propertyVerification.status === THUNK_STATUS.FAILED && <Alert className='m-auto my-3 d-block' $variant='danger'>Something went wrong</Alert>}
          </div>

          <div className='d-flex justify-content-end'>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </Card>

      </AdminLayout>
    </Layout >
  )
}
