import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { useReduxDispatch } from '../../../hooks/redux'
import Button from '../../_ui/button/Button.component'
import OtpVerify from './components/otp/OtpVerify.component'
import Typography from '../../_ui/typography/Typography.component'
import PhoneNumber from './components/PhoneNumber/PhoneNumber.component'
import { handlePhoneNoVerificationPopup } from '../../../lib/redux/slices/auth.slice'


interface IPhoneVerificationPopupProps {
  show: boolean
}


export default function PhoneVerificationPopup(props: IPhoneVerificationPopupProps) {
  const dispatch = useReduxDispatch()
  const [otpisEnterShow, setIsOtpEnterShow] = useState(false)
  const [phone, setPhone] = useState('')

  const handleOtpShow = () => setIsOtpEnterShow(true)


  return (
    <div>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered animation >
        <Modal.Body>
          <div className='d-flex flex-column align-items-center gap-3 p-3'>
            <Typography $variant='h4' id='contained-modal-title-vcenter'>Verify your account</Typography>
            {
              otpisEnterShow ?
                <OtpVerify phone={phone} />
                : <PhoneNumber handleOtpShow={handleOtpShow} phone={phone} setPhone={setPhone} />
            }
            <Button type='button' $variant='text' onClick={() => dispatch(handlePhoneNoVerificationPopup({ isShow: false, isVerifyLater: true }))}>Verify Later</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
