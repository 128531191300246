import { styled } from 'styled-components'
import Card from '../../../../components/_ui/card/Card.component'


interface DivProps {
  active?: string;
}


export const SidebarWrapper = styled(Card)`
  height: 100%;
  max-height: calc(100vh - 120px);
  min-height: calc(100vh - 440px);
  overflow: auto;
  position: relative;


`


export const ProfileWrapper = styled.div<DivProps>`
  cursor: pointer;
  margin:0 -1rem;
  background-color: ${(props) => (props?.active === 'true' ? 'var(--bs-tertiary-bg)' : 'transparent')};
  &:hover{
    background-color: var(--bs-tertiary-bg);
  }
`