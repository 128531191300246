import { AcceptPopupProps } from "./AcceptPopupProps.interface";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../_ui/button/Button.component";
import Typography from "../../_ui/typography/Typography.component";

export function AcceptPopup(props: AcceptPopupProps) {
  const { onHide, content } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
      className="m-width-200"
    >
      {" "}
      <Modal.Header closeButton>
        <Typography $variant="h5" id="contained-modal-title-vcenter">
          Success
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography $variant="subtitle2" className="my-3">
          {content}
        </Typography>
      </Modal.Body>
    </Modal>
  );
}
