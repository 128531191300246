import { Alert, Col, Container, Row, Spinner, Stack } from 'react-bootstrap'
import { useReduxSelector } from "../../../hooks/redux"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Layout from "../../../components/_layout/Layout.component"
import Typography from "../../../components/_ui/typography/Typography.component"
import Button from "../../../components/_ui/button/Button.component"
import Avatar from "../../../components/avatar/Avatar.component"
import toast from 'react-hot-toast'
import { BgColorGetBlueTick, CardAuthBox, CardVerifyBox, VerifyBgDiv, BgColorGetBlueTickStatic } from '../../store/checkout/Checkout.style'
import { MdVerified, MdLock, MdOutlineArrowForwardIos } from "react-icons/md"
import { BsLightningChargeFill } from "react-icons/bs"
import { IoMdEyeOff } from "react-icons/io"

import logo from '../../../assets/images/icon.svg'
import VerifyProfile from '../../../assets/images/checkout/checkout-profile.svg'
import VerifyBg from '../../../assets/images/checkout/verified_bg.svg'
import AuthenticateProfile from '../../../assets/images/checkout/authenticate-profile.png'
import SVG from 'react-inlinesvg'

export default function ThankYou() {
  const { user } = useReduxSelector(state => state.auth)
  console.log(user)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  let verifyIdentity = [
    { title: 'FAST', icon: <MdLock className='fs-5' /> },
    { title: 'SECURE', icon: <BsLightningChargeFill className='fs-5' /> },
    { title: 'PRIVATE', icon: <IoMdEyeOff className='fs-5' /> },
  ]

  return (
    <Layout title='Verification' user={true} feedbackShow={false}>

        <style>{`main{padding:0px}`}</style>

        <Container>

            <div className='d-flex flex-column gap-0 mb-3' style={{marginLeft: 10}}>
                <Typography $variant="h3">Tenancy Verification</Typography>
                <Typography $variant='body1' className={"mb-3"}>Prove you're a qualified tenant here!</Typography>
                <Typography $variant='body2'>Say hello to “blue tick” verification. You can fast-track verification process across Rentless network.</Typography>
            </div>
            

            <Row className='row-gap-4 mb-5'>
                <Col xs={12} sm={6}>
                    <CardVerifyBox>
                    <VerifyBgDiv>
                        <SVG width='100%' src={VerifyBg} className='img-fluid' title="VerifyBg" />
                    </VerifyBgDiv>
                    <div className='d-flex justify-content-center' style={{ zIndex: '11', position: 'relative' }}>
                        <SVG src={VerifyProfile} className='img-fluid' title="VerifyProfileImage" />
                    </div>
                    <Stack direction='vertical' gap={2}>
                        <Stack direction='horizontal' gap={2}>
                        <MdVerified className='fs-3' />
                        <Typography $variant='h5' className=''>Verify Identity Data</Typography>
                        </Stack>
                        <Typography $variant='body1' className=''>Verify your identity details using authorized data sources to streamline the process.</Typography>
                    </Stack>
                    <hr />
                    <Stack direction='horizontal' className='justify-content-between' >
                        {
                        verifyIdentity.map((item, index) => (
                            <Stack key={index} direction='horizontal' gap={1} className='align-items-center'>
                            {item.icon}
                            <Typography $variant='h6' className='m-0'>{item.title}</Typography>
                            </Stack>
                        ))
                        }
                    </Stack>

                    <Stack direction='horizontal' className='justify-content-between align-items-start gap-2 pt-4'>
                        <MdOutlineArrowForwardIos className='mt-1' />
                        <div className='flex-1'>
                        <Typography pography $variant='h6' className='m-0'>Name</Typography>
                        <Typography pography $variant='body2' className='m-0'>Simon Smith Patterson</Typography>
                        </div>
                        <Button $variant='outlined' $color='info'>Match</Button>
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between align-items-start gap-2 pt-4'>
                        <MdOutlineArrowForwardIos className='mt-1' />
                        <div className='flex-1'>
                        <Typography pography $variant='h6' className='m-0'>Phone number</Typography>
                        <Typography pography $variant='body2' className='m-0'>Simon Smith Patterson</Typography>
                        </div>
                        <Button $variant='outlined'>NOT MATCH</Button>
                    </Stack>
                    </CardVerifyBox>
                </Col>

                <Col xs={12} sm={6}>
                    <CardAuthBox>
                    <Stack direction='vertical' className='pt-3'>
                        <Stack direction='horizontal' gap={2}>
                        <MdVerified className='fs-3' />
                        <Typography $variant='h3' className=''>Authenticate IDs</Typography>
                        </Stack>
                        <Typography $variant='body1' className=''>Verify Australian government issued IDs, or overseas passports are authentic and valid.</Typography>
                    </Stack>
                    <div className='p-section'></div>
                    <div className='text-center'>
                        <img src={AuthenticateProfile} className='img-fluid' alt='Authenticate img' />
                    </div>
                    <div style={{ padding: `var(--heading-spacing)` }}></div>
                    </CardAuthBox>
                </Col>
            </Row>
                        
            <Row className='row-gap-4 mb-5'>
                <Col xs={12}>
                    <BgColorGetBlueTickStatic className='d-md-block d-none'>
                        <Col xs={12}>
                            <Stack direction='horizontal' className='flex-wrap justify-content-between align-items-start gap-2'>
                                <SVG src={logo} height={40} />
                                <div className='flex-1'>
                                    <Typography pography $variant='h6' className='m-0 text-white tw-bold'>Rentless</Typography>
                                    <Typography pography $variant='body2' className='m-0 text-white'>Stress-free rental</Typography>
                                </div>
                                <Button onClick={() => navigate('/store/checkout?type=blue_tick')}>Upgrade Now</Button>
                            </Stack>
                        </Col>
                    </BgColorGetBlueTickStatic>
                </Col>
            </Row>

        </Container>
        <BgColorGetBlueTick className='d-md-none d-block'>
            <Col xs={12}>
                <Stack direction='horizontal' className='flex-wrap justify-content-between align-items-start gap-2'>
                    <SVG src={logo} height={40} />
                    <div className='flex-1'>
                        <Typography pography $variant='h6' className='m-0 text-white tw-bold'>Rentless</Typography>
                        <Typography pography $variant='body2' className='m-0 text-white'>Stress-free rental</Typography>
                    </div>
                    <Button onClick={() => navigate('/store/checkout?type=blue_tick')}>Upgrade Now</Button>
                </Stack>
            </Col>
        </BgColorGetBlueTick>
    </Layout >
  )
}
